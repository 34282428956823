import { ILoginSuccessfulResponse, LoginChallenge } from "@store/types";
import { networkService } from "./network-service";
import { AxiosResponse } from "axios";

export type SubmitOtpPayloadType = {
  mfaCode: string;
};

export type AddPhoneNumberPayloadType = {
  phoneNumber: string;
};

export type LoginWithMfaType = {
  mfaCode: string;
  email: string;
  session: string;
  loginChallenge: LoginChallenge;
};

class UserService {
  async getMfaQrCode() {
    return await networkService.get<{
      data: {
        SecretCode: string;
      };
      errors: any;
      message: string;
    }>("auth/mfa-qr-code");
  }

  async setupMfaTotp(payload: SubmitOtpPayloadType) {
    return await networkService.post("auth/setup-mfa-totp", payload);
  }

  async addPhoneNumber(payload: AddPhoneNumberPayloadType) {
    return await networkService.post("/auth/phone-number", payload);
  }

  async verifyPhoneNumber(payload: SubmitOtpPayloadType) {
    return await networkService.post("/auth/verify-phone-number", payload);
  }

  async loginWithMfa(payload: LoginWithMfaType) {
    return await networkService.post<
      LoginWithMfaType,
      AxiosResponse<{
        data: ILoginSuccessfulResponse;
        errors: any;
        message: string;
      }>
    >("login/mfa", payload);
  }
}

export const userService = new UserService();
