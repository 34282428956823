import React from 'react'
import { Row, Col } from 'react-bootstrap'

const TermsOfUse = () => {

    return (
        <Col xs={11} className="mx-auto terms">
            <div className="">
                <Row className="">
                    <Col xs={12}>
                        <strong>
                            Last Updated:‎ August 29, 2023
                        </strong>
        <br/><br/>
                        <p>  This website is owned and operated by SunRisa Solutions LLC dba Flic Financial on behalf of itself and its affiliates (collectively, ‎‎“Flic” “we” “our,” and “us”).‎</p>

                        <p>  These terms of use (“Terms of Use”) govern your use of any website, application or online service that posts a link to these Terms of Use, including any website or mobile application and any interactive features, widgets, plug-ins, applications, content, downloads and other services that we may make available (collectively, the “Web Services”), regardless of how you access or use them, whether via personal computers, mobile devices or otherwise. To the extent that we provide you notice on the Web Services of different or additional terms of use, those additional terms shall govern your use of the Web Services.</p>

                        <p>   Your use of the Web Services constitutes your agreement to these Terms of Use.</p>

                        <p> In addition to reading these Terms of Use, please review our Privacy Policy, which applies to our collection, use and sharing of your information on the Web Services. If you do not agree to the terms of our Privacy Policy or these Terms of Use, please do not access or otherwise use these Web Services or any information or content contained on the Web Services.     </p>
                        <p> These Terms of Use may be revised periodically at any time. It is your responsibility to review carefully the current terms and conditions of use that govern your continued use of the Web Services. When we modify these Terms of Use we will update the effective date above. Your use of the Web Services following the posting of an updated Terms of Use constitutes your acceptance of such new Terms of Use. As a condition of your use of the Web Services, you warrant that you will not use the Web Services for any purpose that is unlawful or prohibited by these Terms of Use, or reproduce or distribute any portions of the Web Services for any commercial use except as authorized herein. </p>
                        <strong>1.    Intellectual Property.</strong>

                        <p>The design of these Web Services and all content, including the text, information, data, software, scripts, graphics, photos, sounds, videos, interactive features, products, materials, services and the like and any trademarks, service marks and logos contained therein (“Web Services Content”), are the property of or are licensed by Flic and are protected by copyright and other intellectual property laws. Flic hereby grants you a limited, non-exclusive, revocable, non-assignable, personal and non-transferrable license to access and use the Web Services Content only for your personal use. You may not otherwise copy, reproduce, modify, adapt, display, perform, publish, create derivative works from, store, sublicense, translate, sell, rent, or otherwise exploit the Web Services or the Web Services Content. Flic reserves all rights not expressly granted in and to the Web Services and the Web Services Content.  You agree to not engage in the use, copying, or distribution of any of the Web Services Content, whether manually or by using a robot, scraper, crawler or any other automated device or process, other than as expressly permitted herein or as reasonably necessary to use the Web Services for their intended purpose, including any use, copying, or distribution of Web Services Content for any commercial purposes.  You in no way will obtain any ownership interest in the Web Services Content.</p>
                        <p> The display of any trade names, trademarks, service marks, logos, or domain names on the Web Services does not imply that a license of any kind has been granted to you with respect to such trade names, trademarks, service marks, logos, or domain names except as explicitly stated herein. Any unauthorized downloading, re-transmission or other copying or modification of trade names, trademarks, service marks, logos or domain names may be a violation of trademark laws and could subject you to legal action. </p>
                        <strong> 2.    Web Services Use and Restrictions.</strong>

                        <p> Any unauthorized use of the Web Services for any purpose is prohibited. We may suspend or terminate the availability or your use of the Web Services (and any elements or features of it), in whole or in part, for any reason, and at any time, in our sole discretion, and without advance notice or liability.  </p>
                        <p> You agree that you will not: (i) use our Web Services for any purpose aside from the purposes offered through the Web Services by Flic and its affiliates and partners; (ii) engage in any activities through or in connection with our Web Services that attempt to or actually do harm any individuals or entities or are unlawful, offensive or abusive, or that violate any right of any third party, or are otherwise objectionable to Flic; (iii) reverse engineer, decompile, disassemble, reverse assemble or modify any Web Services source or object code or any software or other products, services or processes accessible through any portion of our Web Services; (iv) engage in any activity that interferes with another user’s access to our Web Services or the proper operation of our Web Services, or otherwise causes harm to our Web Services, Flic, its affiliates and partners or other users; (v) use Web Services Content in a manner that suggests an unauthorized association with any of our products, services or brands; (vi) interfere with or circumvent any security or access feature of our Web Services; or (vii) otherwise violate these Terms of Use.  </p>
                        <strong>3.    Online Submission of Information.</strong>

                        <p> If you submit information through our Web Services, you agree that you will not submit any information that you know to be, or have reason to believe to be, false, fraudulent, deceptive, inaccurate, misleading or infringing on any third party’s rights.
                        </p>
                        <p>  When submitting information through our Web Services, you may be redirected to a third-party website. Flic does not control any third-party website, and these Terms of Use do not apply to your use of any third-party website. Please be sure to read the terms of use of any third-party website you visit.
                        </p>
                        <strong>  4.     Modifications to Offers, Options, and Terms.
                        </strong>
                        <p> If we provide materials, such as offers to apply for financial products or services, on our Web Services, Flic does not in any way promise that the materials will remain available to you or that you will qualify for any of the products or services offered. FLIC RESERVES THE RIGHT, AT ANY TIME, TO CHANGE ITS PRODUCTS, SERVICES, AND PRICING, EFFECTIVE IMMEDIATELY UPON POSTING ON OUR WEB SERVICES OR APPLICATIONS OR BY E-MAIL DELIVERY TO YOU. </p>
                        <p> In accordance with various laws and other regulatory restrictions, certain products and services referenced on the Web Services may not be available or suitable for all jurisdictions and may not be offered. We may make improvements and/or changes to the products, services and/or programs described on the Web Services at any time without notice.</p>
                        <p>Your eligibility for particular products and services is subject to final determination of and acceptance by Flic or the third party company providing such products or services.</p>
                        <strong>  5.    Security and Communications.
                        </strong>
                        <p> You should be aware that the Internet is not secure. If you choose to send any electronic communications by means of the Web Services, you do so at your own risk, and in doing so you consent to receive communications from us electronically. Flic does not guarantee that electronic communications will not be intercepted or changed or that they will reach the intended recipient securely. Further, please note that we are not obligated to respond to inquiries that we receive. You agree that all agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.  </p>
                        <strong> 6.    Links to Third Party Websites.
                        </strong>
                        <p>The Web Services may contain hyperlinks to websites operated by persons or entities other than Flic. Such hyperlinks are provided for your reference and convenience only. Please be aware that Flic does not monitor, endorse or accept responsibility for the content on such websites or the operator or operations of such websites. You are solely responsible for determining the extent to which you may use any content at any other websites to which you might link from the Web Services. You agree not to hold Flic responsible for the content or operation at any other website to which you might link from the Web Services. Selected content on Flic’s Web Services is provided by a third party. Flic has no control over, and takes no responsibility for supplementing, correcting or updating that information. Furthermore, Flic makes no warranties or representations of any kind whether with respect to such third-party content’s accuracy, completeness, timeliness or otherwise. Flic has no obligation to correct or update that information and shall not be liable for damages of any kind arising out of your access, inability to access or reliance upon the investor information contained in the Web Services. </p>
                        <strong> 7.    United States Operations.
                        </strong>
                        <p> Flic controls and operates our Web Services from its offices in the United States, and makes no representation that our Web Services are appropriate or available for use beyond the United States. If you use our Web Services applications, or services from other locations, you are doing so at your own risk and are responsible for compliance with applicable local laws regarding your online conduct and acceptable content, if and to the extent local laws apply. Our Web Services may describe products and services that are available only in certain states of the United States (or only parts of it). We reserve the right to limit the availability of our Web Services and/or the provision of any Web Services Content, program, product, service, or other feature described or available on our Web Services to any person, entity, geographic area, or jurisdiction, at any time and in our sole discretion, and to limit the quantities of any content, program, product, service, or other feature that we provide.</p>
                        <strong> 8.    Disclaimers.</strong>

                        <p>YOUR ACCESS TO AND USE OF THE WEB SERVICES ARE AT YOUR SOLE RISK. YOU ARE ENTIRELY LIABLE FOR YOUR ACTIVITIES. FLIC DOES NOT WARRANT THE ACCURACY, COMPLETENESS, TIMELINESS, AVAILABILITY, USEFULNESS OR OTHER CHARACTERISTICS OF THE WEB SERVICES OR ANY WEB SERVICES CONTENT. THE WEB SERVICES AND THE INFORMATION ON THEM ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. FLIC MAKES NO REPRESENTATION OR WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, AND HEREBY DISCLAIMS ALL WARRANTIES, INCLUDING ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. FLIC DOES NOT WARRANT THAT THE WEB SERVICES ARE FREE OF VIRUSES, WORMS, TROJAN HORSES OR OTHER HARMFUL COMPONENTS.</p>
                        <p>  FLIC SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY LOSS OR DAMAGE, INCLUDING ANY LOST PROFITS, LOST SAVINGS OR ANY DIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES, CAUSED BY USE OF, OR RELIANCE ON, OR INABILITY TO USE OR ACCESS, OR DELAYS IN, OR INACCURACIES OR ERRORS OR DEFECTS OR OMISSIONS IN, THE WEB SERVICES, WHETHER RESULTING FROM IMPAIRED OR LOST DATA, SOFTWARE OR COMPUTER FAILURE OR ANY OTHER CAUSE, REGARDLESS OF THE CLAIM AS TO THE NATURE OF THE CAUSE OF ACTION, AND EVEN IF FLIC HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE OR LOSS. </p>
                        <p> YOU HEREBY EXPRESSLY RELEASE FLIC FROM ANY AND ALL LIABILITY OR RESPONSIBILITY FOR ANY DAMAGE CAUSED, DIRECTLY OR INDIRECTLY, TO YOU OR ANY THIRD PARTY AS A RESULT OF USE OF THE WEB SERVICES OR THE WEB SERVICES CONTENT, INCLUDING ANY CONTENT OR FILE DOWNLOADED OR ACCESSED. YOU SPECIFICALLY ACKNOWLEDGE THAT FLIC SHALL NOT BE LIABLE FOR YOUR USER SUBMISSIONS, OR THE DEFAMATORY, OFFENSIVE, HARMFUL OR ILLEGAL CONDUCT OF ANY THIRD PARTY, AND THAT THE RISK OF HARM OR DAMAGE FROM THE FOREGOING RESTS ENTIRELY WITH YOU.</p>
                        <p>ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE WEB SERVICES MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED. </p>
                        <strong>  9.  Indemnification.
                        </strong>
                        <p> You agree to defend, indemnify and hold Flic, its affiliates and partners and their respective employees, agents, officers, directors, contractors, suppliers and other representatives harmless from and against all liabilities, damages, demands, settlements, claims, actions, costs and expenses (including reasonable attorneys’ fees) in connection with or arising from your use of the Web Services, the Web Services Content, and/or your submission of any financing application, as it may change from time to time, or your violation of these Terms of Use. Flic may, if necessary, participate in the defense of any claim or action at your expense and any negotiations for settlement. Flic reserves the right, on notice to you, to assume exclusive defense and control of any claim or action subject to indemnification by you, without relieving you of your indemnification obligations hereunder. Upon learning of a claim by a third party that your use violates or allegedly violates a third party’s rights, you agree to promptly notify Flic of any such claim. You agree to cooperate with us as reasonably required in the defense of any such claims, and you shall not in any event settle any such claim or matter without the written consent of Flic. </p>
                        <strong> 10.  No Waiver.</strong>

                        <p> Except as expressly set forth in these Terms of Use, (a) no failure or delay by you or Flic in exercising any rights, powers or remedies under these Terms of Use will operate as a waiver of that or any other right, power or remedy, and (b) no waiver or modification of any term of these Terms of Use will be effective unless in writing and signed by the party against whom the waiver or modification is sought to be enforced. </p>
                        <strong> 11.  Severability and Interpretation.
                        </strong>
                        <p> If any provision of these Terms of Use is for any reason deemed invalid, unlawful, void or unenforceable by a court or arbitrator of competent jurisdiction, then that provision will be deemed severable from these Terms of Use, and the invalidity of the provision will not affect the validity or enforceability of the remainder of these Terms of Use (which will remain in full force and effect). To the extent permitted by applicable law, you agree to waive, and you hereby waive, any applicable statutory and common law that may permit a contract to be construed against its drafter. Wherever the word “including” is used in these Terms of Use, the word will be deemed to mean “including, without limitation.”</p>
                        <strong> 12.  Applicable Law.
                        </strong>
                        <p>These Terms of Use and any dispute or claim arising out of, or related to, them, their subject matter or their formation (in each case, including non-contractual disputes or claims) shall be governed by and construed in accordance with the internal laws of the State of Texas without giving effect to any choice or conflict of law provision or rule (whether of the State of Texas or any other jurisdiction). </p>
                        <p> Any legal suit, action or proceeding arising out of, or related to, these Terms of Use or the Web Services shall be instituted exclusively in the Federal and State courts located within Texas. You agree to waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</p>
                        <p> To the extent permitted by law, if any dispute or claim results in a lawsuit, you and we knowingly and voluntarily agree that a judge, without a jury, will decide the case. YOU UNDERSTAND AND KNOWINGLY AND VOLUNTARILY AGREE THAT YOU AND WE ARE WAIVING THE RIGHT TO A TRIAL BY JURY. </p>
                        <strong> 13.  User Comments.
                        </strong>
                        <p> You acknowledge and agree that any questions, comments, suggestions, ideas, feedback or other information that you provide to Flic are not confidential and you grant us a worldwide, royalty-free license to distribute, publish, modify, edit or otherwise use your submissions. Flic shall be entitled to the unrestricted use and dissemination of these submissions for any purpose, commercial or otherwise without any acknowledgement of or compensation to you.</p>
                        <strong>14.  Contact Us
                        </strong>
                        <p> If you have any questions regarding our Terms of Use, practices, or your use of our Web Services, please contact us at:</p>
                        <p>Flic Financial</p>

                        <p> Mailing Address: 520 Post Oak Blvd, Suite 850 Houston, TX 77027</p>

                        <p>Email: care@flicfinancial.com
                        </p>
                    </Col>
                </Row>
            </div>
        </Col>
    )
}

export default TermsOfUse