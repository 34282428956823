import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import ForgotPassword from "./forgot-password/forgotPassword";
import Login from "./login/Login";
import ResetPassword from "./reset-password/resetPassword";
import Verify from "./set-password/SetPassword";
import { handleLoginSuccessRedirection } from "@shared/helpers/auth.helper";
import { useEffect } from "react";
import { useQuery } from "@shared/helpers/global.helper";
import { useAppSelector } from "@store/hooks";
import { appUserSelector } from "@store/slices/app.slice";
import { useAuthUserContext } from "@contexts/AuthContext";

const Auth = () => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const query = useQuery();
  const user = useAppSelector(appUserSelector);
  const { authUserStatus } = useAuthUserContext();

  useEffect(() => {
    if (user?.token?.accessToken) {
      let returnUrl: any = query.get("returnUrl");
      if (returnUrl === window.location.pathname) {
        returnUrl = "/pipelines";
      }
      const path = returnUrl ? returnUrl : "/pipelines";
      handleLoginSuccessRedirection({
        history,
        loginSuccessResponse: user,
        path,
      });
    }
  }, [authUserStatus, history, query, user]);

  return (
    <Switch>
      <Route path={`${url}/login`}>
        <Login />
      </Route>

      <Route path={`${url}/set-password`}>
        <Verify />
      </Route>
      <Route path={`${url}/forgot-password`}>
        <ForgotPassword />
      </Route>
      <Route path={`${url}/reset-password`}>
        <ResetPassword />
      </Route>

      <Route path="/">
        <Login />
      </Route>
    </Switch>
  );
};

export default Auth;
