import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "@store/hooks";
import {
  getSungageEquipmentAction,
  sungageEquipmentSelector,
} from "@store/slices/commonData.slice";

const useSungageEquipment = () => {
  const sungageEquipment = useAppSelector(sungageEquipmentSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!sungageEquipment?.modules?.length) {
      dispatch(getSungageEquipmentAction());
    }
  }, [dispatch, sungageEquipment]);

  return sungageEquipment;
};

export default useSungageEquipment;
