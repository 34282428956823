import { createUrl } from "@shared/helpers/global.helper";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import {
  errorDetailSelector,
  prequalModifyLoanAction,
  resubmitApplicationAction,
  setError,
} from "@store/slices/loan.slice";
import { Col, Button, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useAuthUserContext } from "@contexts/AuthContext";
import { UserAuthStatus } from "@shared/enums/UserState";
import {
  generateModifyLoanPayload,
  getXPrequalProducts,
  isPrequalApplication,
} from "@shared/helpers/application.helper";
import { appUserSelector } from "@store/slices/app.slice";
import { ProductLos } from "@shared/interfaces/PortalApplicationTypes";

const NoDecision = () => {
  const errorDetail = useAppSelector(errorDetailSelector);
  const history = useHistory();
  const { authUserStatus } = useAuthUserContext();
  const dispatch = useAppDispatch();
  const user = useAppSelector(appUserSelector);
  const application = errorDetail?.requestDetail;
  const onExit = () => {
    dispatch(setError(null));
    history.push(
      createUrl(`/request-detail/${errorDetail?.requestDetail?.losAppNumber}`)
    );
  };

  const resubmitApplication = () => {
    if (!application || !application.losAppNumber) {
      return;
    }

    if (isPrequalApplication(application)) {
      if (!application.programTypeCode || !user) {
        return;
      }
      const isMultiPrequal =
        application.prequalProducts && application.prequalProducts?.length > 1;
      const xprogramtype = Number(application.programTypeCode);
      const { xPrequalProductsSelected, xPrequalProductsAll } =
        getXPrequalProducts(user, xprogramtype.toString());

      dispatch(
        prequalModifyLoanAction({
          appNumber: application.losAppNumber,
          history,
          loanInfo: {
            losAppNumber: application.losAppNumber,
            productLos: ProductLos.DL4,
            programTypeCode: xprogramtype,
            prequalProducts: xPrequalProductsSelected,
          },
          path: "/pre-qualification/confirmation",
          isMultiPrequal,
          xprogramtype,
          xPrequalProducts: xPrequalProductsAll,
        })
      );
    } else {
      const payload = generateModifyLoanPayload(application, user);
      dispatch(
        resubmitApplicationAction({
          loanInfo: payload,
          appNumber: application.losAppNumber,
          history,
          path: "/new-application/confirmation",
        })
      );
    }
  };

  const messages = errorDetail?.requestDetail?.noDecisionReasons;
  const isCreditFreeze = errorDetail?.requestDetail?.isCreditFreeze;
  return (
    <div className="">
      <Row>
        <Col xs={12} sm={9} md={7} lg={5} className="mx-auto mt-5">
          <h6 className="font-bold-800 text-center">
            Your application has been received and additional action is required
            to determine your eligibility for credit. Complete all items and
            resubmit the application.
          </h6>
          <hr className="mt-4 height-2px" />
        </Col>
      </Row>

      <Col xs={12} sm={9} md={7} lg={5} className="mx-auto mt-4">
        <p className="sub-title ">
          The following is required to complete prior to resubmitting for
          decision:
        </p>
        <ul>
          {messages?.map((item) => (
            <li>
              <p>{item.description}</p>
            </li>
          ))}
        </ul>
      </Col>

      <Row>
        <Col>
          <div className="mt-5 d-flex justify-content-center">
            {authUserStatus !== UserAuthStatus.Public && (
              <Button
                variant="outline-primary"
                onClick={() => {
                  onExit();
                }}
              >
                Close
              </Button>
            )}
            {authUserStatus === UserAuthStatus.Authorized && isCreditFreeze && (
              <div className="col-md-2 ms-3">
                <Button variant="primary" onClick={resubmitApplication}>
                  Resubmit App
                </Button>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default NoDecision;
