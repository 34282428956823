import { UseFormTextField } from "@shared/components/TextInput";
import { useHistory, Link } from "react-router-dom";
import { EyeFill, EyeSlash } from "react-bootstrap-icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "@auth/schema";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { signInAction } from "@store/slices/auth.slice";
import { onSubmitError, useQuery } from "@shared/helpers/global.helper";
import { useAuthUserContext } from "@contexts/AuthContext";
import { Button } from "react-bootstrap";
import { appLoadingSelector } from "@store/slices/app.slice";

const LoginMfaForm = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  let query = useQuery();
  const { setAuthUserStatus } = useAuthUserContext();
  const isLoading = useAppSelector(appLoadingSelector);

  const [showPassword, setShowPassword] = useState(false);

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = (event: any) => {
    let returnUrl: any = query.get("returnUrl");
    if (returnUrl === window.location.pathname) {
      returnUrl = "/pipelines";
    }
    dispatch(
      signInAction({
        model: { email: event.email, password: event.password },
        path: returnUrl ? returnUrl : "/pipelines",
        history,
        setAuthUserStatus,
      })
    );
  };
  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit, onSubmitError())}
    >
      <div className="d-flex justify-content-center mt-4">
        <h3 className="h3 text-primary">Login</h3>
      </div>
      <div className="mt-4">
        <UseFormTextField placeholder="Email" control={control} name="email" />
      </div>
      <div className="mt-3">
        <UseFormTextField
          placeholder="Password"
          type={showPassword ? "text" : "password"}
          control={control}
          name="password"
        >
          <div
            className="search-icon"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <EyeSlash size={20} /> : <EyeFill size={20} />}
          </div>
        </UseFormTextField>
      </div>
      <div className="d-flex flex-row mt-3 ">
        <Link to="/auth/forgot-password" className="text-primary">
          Forgot Password
        </Link>
      </div>
      <div className="d-flex flex-row justify-content-center mt-3 w-100">
        <Button
          variant="primary"
          className="w-100"
          type="submit"
          // disabled={isLoading}
        >
          Login
        </Button>
      </div>
    </form>
  );
};

export default LoginMfaForm;
