import { useLayoutEffect, useState, useMemo, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { DebounceInput } from "react-debounce-input";
import { Button } from "react-bootstrap";
import fromPairs from "lodash/fromPairs";
import range from "lodash/range";
import Select, { MultiValue, SingleValue } from "react-select";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import {
  getUsersAction,
  resetUsers,
  usersDataSelector,
} from "@store/slices/user.slice";
import { CheckCircle, DashCircle } from "react-bootstrap-icons";
import {
  appDealersSelector,
  appUserSelector,
  getDealersAction,
} from "@store/slices/app.slice";
import Pagination from "react-js-pagination";
import { environment } from "@env/environment";
import { UserCardSkeleton } from "@shared/skeletons/UserCardSkeleton";
import ChoiceModal from "@shared/components/ChoiceModal";
import { networkService } from "@services/network-service";
import { toasterService } from "@services/toaster.service";
import { getRegionsAction, regionsSelector } from "@store/slices/region.slice";
import { getThemeForReactSelect } from "@shared/helpers/global.helper";
import { USER_STATUS_OPTIONS } from "@shared/constants/app.constant";
import {
  UserActivationState,
  UserRole,
  UserRoleLabel,
} from "@shared/interfaces/User";
import { GetUsersParams } from "@store/types";

interface ActivateOptions {
  busy: boolean;
  id: string | null;
  name: string;
  open: boolean;
}

const choices = [
  {
    title: "No",
    value: "no",
    variant: "secondary",
  },
  {
    title: "Yes",
    value: "yes",
    variant: "primary",
  },
];

const Users = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const user = useAppSelector(appUserSelector);
  const usersData = useAppSelector(usersDataSelector);
  const dealersData = useAppSelector(appDealersSelector);
  const isLoading = useAppSelector((state) => state.app.loading);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [search, setSearch] = useState("");
  const [region, setRegion] = useState<
    MultiValue<{
      label: string;
      value: string;
    }>
  >([]);
  const [userStatus, setUserStatus] = useState<
    SingleValue<{
      label: string;
      value: string;
    }>
  >();
  const [pageLimit, setPageLimit] = useState<number>(
    Number(environment.REACT_APP_PAGE_LIMIT)
  );
  const currentPageLimit =
    pageLimit || Number(environment.REACT_APP_PAGE_LIMIT) || 0;
  const [page, setPage] = useState(1);
  const [deactivateOptions, setDeactivateOptions] = useState<ActivateOptions>({
    busy: false,
    id: null,
    name: "",
    open: false,
  });
  const [reactivateOptions, setReactivateOptions] = useState<ActivateOptions>({
    busy: false,
    id: null,
    name: "",
    open: false,
  });

  const regions = useAppSelector(regionsSelector).map(({ id, name }) => ({
    label: name,
    value: id,
  }));
  const dealers = useMemo<Record<string, string>>(
    () =>
      fromPairs(
        dealersData.map(({ internalClientId, name }) => [
          internalClientId,
          name,
        ])
      ),
    [dealersData]
  );
  const onPageChange = (e: any) => {
    setPage(e);
    dispatch(
      getUsersAction({
        page: e,
        search,
        limit: pageLimit,
        filterByRegion:
          region.length > 0
            ? region.map((obj) => obj.value).join(",")
            : undefined,
        filterByUserStatus: userStatus?.value,
      })
    );
  };
  const onRegionChange = (
    newRegionValue: MultiValue<{
      label: string;
      value: string;
    }>
  ) => {
    setRegion(newRegionValue);
    setPage(1);
    dispatch(
      getUsersAction({
        page: 1,
        search,
        limit: pageLimit,
        filterByRegion:
          newRegionValue.length > 0
            ? newRegionValue.map((obj) => obj.value).join(",")
            : undefined,
        filterByUserStatus: userStatus?.value,
      })
    );
  };

  const onUserStatusChange = (
    newValue: SingleValue<{
      label: string;
      value: string;
    }>
  ) => {
    setUserStatus(newValue);
    setPage(1);
    dispatch(
      getUsersAction({
        page: 1,
        search,
        limit: pageLimit,
        filterByRegion:
          region.length > 0
            ? region.map((obj) => obj.value).join(",")
            : undefined,
        filterByUserStatus: newValue?.value,
      })
    );
  };

  const onReload = () => {
    dispatch(
      getUsersAction({
        page,
        search,
        limit: pageLimit,
        filterByRegion:
          region.length > 0
            ? region.map((obj) => obj.value).join(",")
            : undefined,
        filterByUserStatus: userStatus?.value,
      })
    );
  };

  const onSearch = (value: string) => {
    setSearch(value);
    if (!value) {
      dispatchQuery({
        page: 1,
        limit: pageLimit,
        search: "",
        filterByRegion:
          region.length > 0
            ? region.map((obj) => obj.value).join(",")
            : undefined,
        filterByUserStatus: userStatus?.value,
      });
      return;
    }
    dispatch(
      getUsersAction({
        page: 1,
        limit: pageLimit,
        search: value,
        filterByRegion:
          region.length > 0
            ? region.map((obj) => obj.value).join(",")
            : undefined,
        filterByUserStatus: userStatus?.value,
      })
    );
  };

  const onPageSizeChange = (e: any) => {
    setPageLimit(e.target.value);
    dispatch(
      getUsersAction({
        page: 1,
        limit: e.target.value,
        filterByRegion:
          region.length > 0
            ? region.map((obj) => obj.value).join(",")
            : undefined,
        filterByUserStatus: userStatus?.value,
      })
    );
  };

  const dispatchQuery = (query: GetUsersParams) => {
    dispatch(getUsersAction(query));
  };

  const hideDeactivateModal = () => {
    setDeactivateOptions((prev) => ({
      ...prev,
      open: false,
    }));
  };
  const hideReactivateModal = () => {
    setReactivateOptions((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const deactivateUser = async (userId: string) => {
    try {
      setDisabled(true);
      await networkService.delete(`/users/deactivate/${userId}`);
      toasterService.success("User has been deactivated successfully");
    } finally {
      setDisabled(false);
      setDeactivateOptions((prev) => ({
        busy: false,
        id: null,
        name: "",
        open: false,
      }));
      onReload();
    }
  };

  const reactivateUser = async (userId: string) => {
    try {
      setDisabled(true);
      await networkService.delete(`/users/reactivate/${userId}`);
      toasterService.success("User has been reactivated successfully");
    } finally {
      setDisabled(false);
      setReactivateOptions((prev) => ({
        busy: false,
        id: null,
        name: "",
        open: false,
      }));
      onReload();
    }
  };

  useLayoutEffect(() => {
    dispatch(resetUsers());
    dispatch(
      getUsersAction({
        page: 1,
        limit: pageLimit,
        filterByRegion:
          region.length > 0
            ? region.map((obj) => obj.value).join(",")
            : undefined,
        filterByUserStatus: userStatus?.value,
      })
    );
    dispatch(getRegionsAction(null));
  }, [dispatch, pageLimit]);

  useEffect(() => {
    dispatch(getDealersAction(null));
  }, [dispatch]);

  useEffect(() => {
    setDeactivateOptions((prev) => ({
      ...prev,
      busy: disabled,
    }));
    setReactivateOptions((prev) => ({
      ...prev,
      busy: disabled,
    }));
  }, [disabled]);

  const users = usersData?.results;
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center flex-wrap users-header mb-4">
        <h5 className="h4 text-primary fw-light mb-0">Users </h5>
        <div className="d-flex flex-row justify-content-center align-items-center gap-4">
          {user?.role && [UserRole.providerAdmin].includes(user?.role) && (
            <>
              <Link
                className="navigation-link hover text-nowrap"
                to="/add-user"
              >
                Add New User
              </Link>
              <Link
                className="navigation-link hover text-nowrap"
                to="/ip-whitelist"
              >
                IP Whitelist
              </Link>
            </>
          )}
          <DebounceInput
            minLength={2}
            className={"form-control users-search"}
            placeholder="Search"
            value={search}
            debounceTimeout={1200}
            onChange={(event) => onSearch(event.target.value)}
            disabled={disabled}
          />
          {user?.role === UserRole.providerAdmin && (
            <>
              <div className="select-container">
                <Select
                  id="region"
                  options={regions}
                  placeholder="Region"
                  value={region}
                  onChange={(newValue, actionMeta) => {
                    onRegionChange(newValue);
                  }}
                  theme={getThemeForReactSelect}
                  isMulti
                  isClearable
                  isSearchable={false}
                />
              </div>
              <div className="select-container">
                <Select
                  id="user-status"
                  options={USER_STATUS_OPTIONS}
                  placeholder="User status"
                  value={userStatus}
                  onChange={(newValue, actionMeta) => {
                    onUserStatusChange(newValue);
                  }}
                  theme={getThemeForReactSelect}
                  isClearable
                  isSearchable={false}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <hr className="height-2px" />

      {/* All Users Block */}
      {!isLoading && users?.length > 0 && (
        <div className="row">
          <div className="users-section">
            {users.map(
              ({
                id,
                dealer,
                xEmployeeID,
                email,
                region,
                username,
                employeeId,
                role,
                state,
                preferredMfaSetting,
              }) => (
                <div key={id} className="user-row">
                  <div className="align-items-center">
                    <strong>
                      {xEmployeeID || employeeId
                        ? `${xEmployeeID || employeeId} | `
                        : ""}
                      {username}
                    </strong>
                  </div>
                  <div className="flex-column justify-content-center">
                    <p className="user-email">{email}</p>
                    <p className="region mt-1">
                      {dealer ? dealers[dealer] : dealer || ""}
                      {region ? ` | ${region.name}` : ""}
                    </p>
                  </div>
                  <div className="flex-column justify-content-center">
                    <p className="role">{UserRoleLabel[role]}</p>
                    <p className="mfa-status mt-1 d-flex flex-row align-items-center">
                      {preferredMfaSetting ? (
                        <>
                          <CheckCircle className="w-12 h-12 me-1 mfa-active-icon" />{" "}
                          MFA Active
                        </>
                      ) : (
                        <>
                          <DashCircle className="w-12 h-12 me-1 mfa-not-active-icon" />{" "}
                          MFA Not Active
                        </>
                      )}
                    </p>
                  </div>
                  {user?.role === UserRole.providerAdmin && (
                    <span className="user-row-buttons">
                      {state === UserActivationState.deactivated ? (
                        <Button
                          variant="outline-danger"
                          type="button"
                          onClick={() => {
                            setReactivateOptions((prev) => ({
                              ...prev,
                              id: id,
                              name: username,
                              open: true,
                            }));
                          }}
                        >
                          Reactivate
                        </Button>
                      ) : (
                        <>
                          <Button
                            variant="outline-info"
                            type="button"
                            onClick={() => history.push(`/reset/auth/${id}`)}
                          >
                            Login Help
                          </Button>
                          <Button
                            variant="outline-primary"
                            type="button"
                            onClick={() => history.push(`/modify-user/${id}`)}
                          >
                            Modify
                          </Button>
                          {role !== UserRole.providerAdmin && (
                            <Button
                              variant="outline-danger"
                              type="button"
                              onClick={() => {
                                setDeactivateOptions((prev) => ({
                                  ...prev,
                                  id: id,
                                  name: username,
                                  open: true,
                                }));
                              }}
                            >
                              Deactivate
                            </Button>
                          )}
                        </>
                      )}
                    </span>
                  )}
                </div>
              )
            )}
          </div>
        </div>
      )}

      {/* Pagination Block */}
      {!isLoading && users?.length > 0 && (
        <div className="row mt-5">
          <div className="col-12 d-block mx-auto pagination-container">
            <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center">
              <div className="order-2 order-sm-1">
                <span>Records per active page</span>
                <select
                  className="page-size mx-3"
                  value={pageLimit}
                  onChange={(e) => {
                    onPageSizeChange(e);
                  }}
                >
                  <option value="6">6</option>
                  <option value="12">12</option>
                  <option value="18">18</option>
                </select>
              </div>
              <div className="order-1 order-sm-2 mb-3 mb-sm-0">
                <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={page}
                  itemsCountPerPage={Number(currentPageLimit)}
                  totalItemsCount={usersData?.total}
                  pageRangeDisplayed={9}
                  onChange={onPageChange}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Skeleton Block */}
      {isLoading && (
        <div className="row">
          <div className="users-section">
            {range(currentPageLimit).map((item) => (
              <UserCardSkeleton key={item} />
            ))}
          </div>
        </div>
      )}

      {/* No Users Block */}
      {!isLoading && users?.length === 0 && (
        <div className="row mt-5">
          <div className="col-12 text-center">
            <h4 className="h3 text-primary">No user(s) found</h4>
          </div>
        </div>
      )}

      {/* Deactivation modal */}
      <ChoiceModal
        choices={choices}
        onChoose={(choice) => {
          if (choice === "no") {
            return hideDeactivateModal();
          }
          if (deactivateOptions.id) {
            deactivateUser(deactivateOptions.id);
          }
        }}
        onHide={() => {
          hideDeactivateModal();
        }}
        show={deactivateOptions.open}
        title={`Deactivate ${deactivateOptions.name}`}
      >
        Are you sure you want to deactivate {deactivateOptions.name}?
      </ChoiceModal>

      {/* Reactivation modal */}
      <ChoiceModal
        choices={choices}
        onChoose={(choice) => {
          if (choice === "no") {
            return hideReactivateModal();
          }
          if (reactivateOptions.id) {
            reactivateUser(reactivateOptions.id);
          }
        }}
        onHide={() => {
          hideReactivateModal();
        }}
        show={reactivateOptions.open}
        title={`Reactivate ${reactivateOptions.name}`}
      >
        Are you sure you want to reactivate {reactivateOptions.name}?
      </ChoiceModal>
    </div>
  );
};

export default Users;
