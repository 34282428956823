import { ConditionalRender } from "@shared/components/ConditionalRender";
import { Row, Col } from "react-bootstrap";
import NumberFormat from "@shared/components/NumberFormat";
import DateFormat from "@shared/components/DateFormat";
import PercentageFormat from "@shared/components/PercentageFormat";
import {
  getIncomeInsightValue,
  getValueIfNotNull,
} from "@shared/helpers/global.helper";
import { IPortalApplication } from "@shared/interfaces/PortalApplicationTypes";

type ApplicationDetailsType = Array<{
  label: string;
  value: JSX.Element;
}>;

type ApplicationDetailsType2 = Array<{
  label: JSX.Element;
  value1: JSX.Element;
  value2: JSX.Element;
  value3: JSX.Element;
  isHeader?: boolean;
}>;

type ApplicationDetailsType3 = Array<{
  label: JSX.Element;
  value1: JSX.Element;
  value2: JSX.Element;
  isHeader?: boolean;
}>;

const RequestTable = ({ data }: { data: ApplicationDetailsType2 }) => (
  <>
    {data.map((item, index) => (
      <Row className="" key={index}>
        <Col xs={6}>{item.isHeader ? item.label : <p>{item.label}</p>}</Col>
        <Col xs={2}>
          {item.isHeader ? (
            item.value1
          ) : (
            <p className="font-weight-700">{item.value1}</p>
          )}
        </Col>
        <Col xs={2}>{item.isHeader ? item.value2 : <p>{item.value2}</p>}</Col>
        <Col xs={2}>{item.isHeader ? item.value3 : <p>{item.value3}</p>}</Col>
      </Row>
    ))}
  </>
);

const RequestTable3 = ({ data }: { data: ApplicationDetailsType3 }) => (
  <>
    {data.map((item, index) => (
      <Row className="" key={index}>
        <Col xs={6}>{item.isHeader ? item.label : <p>{item.label}</p>}</Col>
        <Col xs={3}>
          {item.isHeader ? (
            item.value1
          ) : (
            <p className="font-weight-700">{item.value1}</p>
          )}
        </Col>
        <Col xs={3}>{item.isHeader ? item.value2 : <p>{item.value2}</p>}</Col>
      </Row>
    ))}
  </>
);

export const Loan = ({
  data,
  id,
}: {
  data: IPortalApplication | undefined;
  id: string;
}) => {
  const loanTerms: ApplicationDetailsType = [
    {
      label: "Qualifying Payment",
      value: <NumberFormat amount={data?.loanOffer?.payment} />,
    },
    {
      label: "Qualifying Rate",
      value: <PercentageFormat percentage={data?.loanOffer?.rate} />,
    },
    {
      label: "APR",
      value: <PercentageFormat percentage={data?.loanOffer?.contractAPR} />,
    },
    {
      label: "Paper Grade",
      value: <ConditionalRender value={data?.decisionMetrics?.paperGrade} />,
    },
  ];

  const applicationDates: ApplicationDetailsType = [
    {
      label: "Prequal Date",
      value: (
        <DateFormat
          date={getValueIfNotNull(data?.applicationDates?.prequalDate)}
        />
      ),
    },
    {
      label: "Full Application Date",
      value: <DateFormat date={data?.applicationDates?.fullApplicationDate} />,
    },
    {
      label: "NTP Date",
      value: (
        <DateFormat
          date={getValueIfNotNull(data?.applicationDates?.ntpIssuedDate)}
        />
      ),
    },
    {
      label: "Fund Date",
      value: <DateFormat date={data?.applicationDates?.fundDate} />,
    },
  ];

  const contractDates: ApplicationDetailsType = [
    {
      label: "Est. Fund Date",
      value: <DateFormat date={data?.loanOffer?.contractDate} />,
    },
    {
      label: "Est. First Payment Date",
      value: <DateFormat date={data?.loanOffer?.firstPaymentDate} />,
    },
    {
      label: "Est. Maturity Date",
      value: <DateFormat date={data?.loanOffer?.maturityDate} />,
    },
  ];

  const fees: ApplicationDetailsType = [
    {
      label: "Flic Fee",
      value: (
        <>
          <NumberFormat amount={data?.loanOffer?.flicPlatformFee} />
          <span className="mx-1" />
          (
          <PercentageFormat
            percentage={data?.loanOffer?.flicPlatformFeePercent}
          />
          )
        </>
      ),
    },
    {
      label: "Lender Pts",
      value: (
        <>
          <NumberFormat amount={data?.loanOffer?.sellersPoints} />
          <span className="mx-1" />
          (
          <PercentageFormat
            percentage={data?.loanOffer?.sellersPointsPercent}
          />
          )
        </>
      ),
    },
    {
      label: "Net To Flic",
      value: <NumberFormat amount={data?.loanOffer?.netToFlicFinancial} />,
    },
    {
      label: "Net to Installer",
      value: <NumberFormat amount={data?.loanOffer?.netToDealer} />,
    },
  ];

  const ratios: ApplicationDetailsType2 = [
    {
      label: <h4>Ratios</h4>,
      value1: <h4>Total</h4>,
      value2: <h4>App</h4>,
      value3: <h4>CoApp</h4>,
      isHeader: true,
    },
    {
      label: <>Debt To Income (DTI)</>,
      value1: (
        <PercentageFormat
          percentage={
            data?.decisionMetrics?.combinedDecisionCalcRec?.xFullyAmortizedDti
          }
        />
      ),
      value2: (
        <PercentageFormat
          percentage={
            data?.decisionMetrics?.applicantDecisionCalcRec?.xFullyAmortizedDti
          }
        />
      ),
      value3: (
        <PercentageFormat
          percentage={
            data?.decisionMetrics?.coApplicantDecisionCalcRec
              ?.xFullyAmortizedDti
          }
        />
      ),
    },
    {
      label: <>Current DTI</>,
      value1: (
        <PercentageFormat
          percentage={
            data?.decisionMetrics?.combinedDecisionCalcRec?.xCurrentDti
          }
        />
      ),
      value2: (
        <PercentageFormat
          percentage={
            data?.decisionMetrics?.applicantDecisionCalcRec?.xCurrentDti
          }
        />
      ),
      value3: (
        <PercentageFormat
          percentage={
            data?.decisionMetrics?.coApplicantDecisionCalcRec?.xCurrentDti
          }
        />
      ),
    },
    {
      label: <>Unsecured Debt Ratio (UDR)</>,
      value1: (
        <PercentageFormat
          percentage={
            data?.decisionMetrics?.combinedDecisionCalcRec?.xUnsecuredDebtRatio
          }
        />
      ),
      value2: (
        <PercentageFormat
          percentage={
            data?.decisionMetrics?.applicantDecisionCalcRec?.xUnsecuredDebtRatio
          }
        />
      ),
      value3: (
        <PercentageFormat
          percentage={
            data?.decisionMetrics?.coApplicantDecisionCalcRec
              ?.xUnsecuredDebtRatio
          }
        />
      ),
    },
    {
      label: <>Payment To Income (PTI)</>,
      value1: (
        <PercentageFormat
          percentage={
            data?.decisionMetrics?.combinedDecisionCalcRec?.xFullyAmortizedPTI
          }
        />
      ),
      value2: (
        <PercentageFormat
          percentage={
            data?.decisionMetrics?.applicantDecisionCalcRec?.xFullyAmortizedPTI
          }
        />
      ),
      value3: (
        <PercentageFormat
          percentage={
            data?.decisionMetrics?.coApplicantDecisionCalcRec
              ?.xFullyAmortizedPTI
          }
        />
      ),
    },
  ];
  const revolvingDebt: ApplicationDetailsType2 = [
    {
      label: <h4>Revolving Debt</h4>,
      value1: <h4>Total</h4>,
      value2: <h4>App</h4>,
      value3: <h4>CoApp</h4>,
      isHeader: true,
    },
    {
      label: <>Total Revolving Balance</>,
      value1: (
        <NumberFormat
          amount={
            data?.decisionMetrics?.combinedDecisionCalcRec?.dtiRevolvingBalance
          }
          minimumFractionDigits={0}
          maximumFractionDigits={0}
        />
      ),
      value2: (
        <NumberFormat
          amount={
            data?.decisionMetrics?.applicantDecisionCalcRec?.dtiRevolvingBalance
          }
          minimumFractionDigits={0}
          maximumFractionDigits={0}
        />
      ),
      value3: (
        <NumberFormat
          amount={
            data?.decisionMetrics?.coApplicantDecisionCalcRec
              ?.dtiRevolvingBalance
          }
          minimumFractionDigits={0}
          maximumFractionDigits={0}
        />
      ),
    },
    {
      label: <>Total Revolving Limit</>,
      value1: (
        <NumberFormat
          amount={
            data?.decisionMetrics?.combinedDecisionCalcRec?.xRevolvingLimitDti
          }
          minimumFractionDigits={0}
          maximumFractionDigits={0}
        />
      ),
      value2: (
        <NumberFormat
          amount={
            data?.decisionMetrics?.applicantDecisionCalcRec?.xRevolvingLimitDti
          }
          minimumFractionDigits={0}
          maximumFractionDigits={0}
        />
      ),
      value3: (
        <NumberFormat
          amount={
            data?.decisionMetrics?.coApplicantDecisionCalcRec
              ?.xRevolvingLimitDti
          }
          minimumFractionDigits={0}
          maximumFractionDigits={0}
        />
      ),
    },
    {
      label: <>Revolving Utilization</>,
      value1: (
        <PercentageFormat
          percentage={
            data?.decisionMetrics?.combinedDecisionCalcRec
              ?.xRevolvingDebutUtilization
          }
        />
      ),
      value2: (
        <PercentageFormat
          percentage={
            data?.decisionMetrics?.applicantDecisionCalcRec
              ?.xRevolvingDebutUtilization
          }
        />
      ),
      value3: (
        <PercentageFormat
          percentage={
            data?.decisionMetrics?.coApplicantDecisionCalcRec
              ?.xRevolvingDebutUtilization
          }
        />
      ),
    },
  ];
  const income: ApplicationDetailsType2 = [
    {
      label: <h4>Income</h4>,
      value1: <h4>Total</h4>,
      value2: <h4>App</h4>,
      value3: <h4>CoApp</h4>,
      isHeader: true,
    },
    {
      label: <>Application Income:</>,
      value1: (
        <NumberFormat
          amount={data?.decisionMetrics?.combinedDecisionCalcRec?.totalIncome}
          minimumFractionDigits={0}
          maximumFractionDigits={0}
        />
      ),
      value2: (
        <NumberFormat
          amount={data?.decisionMetrics?.applicantDecisionCalcRec?.totalIncome}
          minimumFractionDigits={0}
          maximumFractionDigits={0}
        />
      ),
      value3: (
        <NumberFormat
          amount={
            data?.decisionMetrics?.coApplicantDecisionCalcRec?.totalIncome
          }
          minimumFractionDigits={0}
          maximumFractionDigits={0}
        />
      ),
    },
    {
      label: <>Income Insights:</>,
      value1: (
        <NumberFormat
          amount={getIncomeInsightValue(
            data?.decisionMetrics?.combinedDecisionCalcRec?.xIncomeInsightScore
          )}
          minimumFractionDigits={0}
          maximumFractionDigits={0}
        />
      ),
      value2: (
        <NumberFormat
          amount={getIncomeInsightValue(
            data?.decisionMetrics?.applicantDecisionCalcRec?.xIncomeInsightScore
          )}
          minimumFractionDigits={0}
          maximumFractionDigits={0}
        />
      ),
      value3: (
        <NumberFormat
          amount={getIncomeInsightValue(
            data?.decisionMetrics?.coApplicantDecisionCalcRec
              ?.xIncomeInsightScore
          )}
          minimumFractionDigits={0}
          maximumFractionDigits={0}
        />
      ),
    },
  ];

  const trades: ApplicationDetailsType3 = [
    {
      label: <h4>Trades</h4>,
      value1: <h4>App</h4>,
      value2: <h4>CoApp</h4>,
      isHeader: true,
    },
    {
      label: <>Total Trades</>,
      value1: (
        <NumberFormat
          amount={
            data?.decisionMetrics?.applicantDecisionCalcRec?.xTotalTradelines
          }
          isCurrency={false}
          symbol=""
          minimumFractionDigits={0}
        />
      ),
      value2: (
        <NumberFormat
          amount={
            data?.decisionMetrics?.coApplicantDecisionCalcRec?.xTotalTradelines
          }
          isCurrency={false}
          symbol=""
          minimumFractionDigits={0}
        />
      ),
    },
    {
      label: <>Total Active Trades</>,
      value1: (
        <NumberFormat
          amount={
            data?.decisionMetrics?.applicantDecisionCalcRec
              ?.xTotalOpenTradelines
          }
          isCurrency={false}
          symbol=""
          minimumFractionDigits={0}
        />
      ),
      value2: (
        <NumberFormat
          amount={
            data?.decisionMetrics?.coApplicantDecisionCalcRec
              ?.xTotalOpenTradelines
          }
          isCurrency={false}
          symbol=""
          minimumFractionDigits={0}
        />
      ),
    },
    {
      label: <>Time in Bureau</>,
      value1: (
        <NumberFormat
          amount={
            data?.decisionMetrics?.applicantDecisionCalcRec?.fileAgeMonths
          }
          isCurrency={false}
          symbol=" Mo."
          minimumFractionDigits={0}
        />
      ),
      value2: (
        <NumberFormat
          amount={
            data?.decisionMetrics?.coApplicantDecisionCalcRec?.fileAgeMonths
          }
          isCurrency={false}
          symbol=" Mo."
          minimumFractionDigits={0}
        />
      ),
    },
  ];

  const delinquencies: ApplicationDetailsType3 = [
    {
      label: <h4>Delinquencies</h4>,
      value1: <h4>App</h4>,
      value2: <h4>CoApp</h4>,
      isHeader: true,
    },
    {
      label: <>Current Delinquent</>,
      value1: (
        <NumberFormat
          amount={
            data?.decisionMetrics?.applicantDecisionCalcRec?.numPastDueAccounts
          }
          isCurrency={false}
          symbol=""
          minimumFractionDigits={0}
        />
      ),
      value2: (
        <NumberFormat
          amount={
            data?.decisionMetrics?.coApplicantDecisionCalcRec
              ?.numPastDueAccounts
          }
          isCurrency={false}
          symbol=""
          minimumFractionDigits={0}
        />
      ),
    },
    {
      label: <>Delinquent past 12 months</>,
      value1: (
        <NumberFormat
          amount={
            data?.decisionMetrics?.applicantDecisionCalcRec
              ?.xCountOfDelinquenciesLast12Months
          }
          isCurrency={false}
          symbol=""
          minimumFractionDigits={0}
        />
      ),
      value2: (
        <NumberFormat
          amount={
            data?.decisionMetrics?.coApplicantDecisionCalcRec
              ?.xCountOfDelinquenciesLast12Months
          }
          isCurrency={false}
          symbol=""
          minimumFractionDigits={0}
        />
      ),
    },
    // {
    //   label: <>Months Since Last Delinquency</>,
    //   value1: (
    //     <NumberFormat
    //       amount={data?.decision?.combinedDecisionCalcRec?.xLastPastDueMonths}
    //       isCurrency={false}
    //       symbol=""
    //       minimumFractionDigits={0}
    //     />
    //   ),
    //   value1: (
    //     <NumberFormat
    //       amount={
    //         data?.decision?.applicantDecisionCalcRec?.xLastPastDueMonths
    //       }
    //       isCurrency={false}
    //       symbol=""
    //       minimumFractionDigits={0}
    //     />
    //   ),
    //   value2: (
    //     <NumberFormat
    //       amount={
    //         data?.decision?.coApplicantDecisionCalcRec?.xLastPastDueMonths
    //       }
    //       isCurrency={false}
    //       symbol=""
    //       minimumFractionDigits={0}
    //     />
    //   ),
    // },
  ];

  const derogatoryTrades: ApplicationDetailsType3 = [
    {
      label: <h4>Derogatory Trades</h4>,
      value1: <h4>App</h4>,
      value2: <h4>CoApp</h4>,
      isHeader: true,
    },
    {
      label: <>Foreclosures</>,
      value1: (
        <NumberFormat
          amount={
            data?.decisionMetrics?.applicantDecisionCalcRec?.numForeclosures
          }
          isCurrency={false}
          symbol=""
          minimumFractionDigits={0}
        />
      ),
      value2: (
        <NumberFormat
          amount={
            data?.decisionMetrics?.coApplicantDecisionCalcRec?.numForeclosures
          }
          isCurrency={false}
          symbol=""
          minimumFractionDigits={0}
        />
      ),
    },
    {
      label: <>Reposessions</>,
      value1: (
        <NumberFormat
          amount={data?.decisionMetrics?.applicantDecisionCalcRec?.numRepos}
          isCurrency={false}
          symbol=""
          minimumFractionDigits={0}
        />
      ),
      value2: (
        <NumberFormat
          amount={data?.decisionMetrics?.coApplicantDecisionCalcRec?.numRepos}
          isCurrency={false}
          symbol=""
          minimumFractionDigits={0}
        />
      ),
    },
    {
      label: <>Charge Offs</>,
      value1: (
        <NumberFormat
          amount={
            data?.decisionMetrics?.applicantDecisionCalcRec?.xNumChargeOffs
          }
          isCurrency={false}
          symbol=""
          minimumFractionDigits={0}
        />
      ),
      value2: (
        <NumberFormat
          amount={
            data?.decisionMetrics?.coApplicantDecisionCalcRec?.xNumChargeOffs
          }
          isCurrency={false}
          symbol=""
          minimumFractionDigits={0}
        />
      ),
    },
  ];

  const publicRecords: ApplicationDetailsType3 = [
    {
      label: <h4>Public Records</h4>,
      value1: <h4>App</h4>,
      value2: <h4>CoApp</h4>,
      isHeader: true,
    },
    {
      label: <>Public Records</>,
      value1: (
        <NumberFormat
          amount={
            data?.decisionMetrics?.applicantDecisionCalcRec?.numPublicRecords
          }
          isCurrency={false}
          symbol=""
          minimumFractionDigits={0}
        />
      ),
      value2: (
        <NumberFormat
          amount={
            data?.decisionMetrics?.coApplicantDecisionCalcRec?.numPublicRecords
          }
          isCurrency={false}
          symbol=""
          minimumFractionDigits={0}
        />
      ),
    },
    {
      label: <>Bankruptcies</>,
      value1: (
        <NumberFormat
          amount={
            data?.decisionMetrics?.applicantDecisionCalcRec?.numBankruptcies
          }
          isCurrency={false}
          symbol=""
          minimumFractionDigits={0}
        />
      ),
      value2: (
        <NumberFormat
          amount={
            data?.decisionMetrics?.coApplicantDecisionCalcRec?.numBankruptcies
          }
          isCurrency={false}
          symbol=""
          minimumFractionDigits={0}
        />
      ),
    },
    {
      label: <>Bankruptcy Age</>,
      value1: (
        <NumberFormat
          amount={
            data?.decisionMetrics?.applicantDecisionCalcRec?.numBankruptcies &&
            data?.decisionMetrics?.applicantDecisionCalcRec?.numBankruptcies >=
              1
              ? data?.decisionMetrics?.applicantDecisionCalcRec?.xBankruptcyAge
              : undefined
          }
          isCurrency={false}
          symbol=" Mo."
          minimumFractionDigits={0}
        />
      ),
      value2: (
        <NumberFormat
          amount={
            data?.decisionMetrics?.coApplicantDecisionCalcRec
              ?.numBankruptcies &&
            data?.decisionMetrics?.coApplicantDecisionCalcRec
              ?.numBankruptcies >= 1
              ? data?.decisionMetrics?.coApplicantDecisionCalcRec
                  ?.xBankruptcyAge
              : undefined
          }
          isCurrency={false}
          symbol=" Mo."
          minimumFractionDigits={0}
        />
      ),
    },
  ];

  return (
    <>
      <div className="mt-5">
        <h3 className="h3 text-primary-dark mt-4">Loan Details</h3>
        <hr className="bg-primary-dark height-2px opacity-1" />

        <Row className="mt-3">
          <Col xs={12} sm={6} md={3}>
            <h4 className="font-bold-800 my-3">Loan Terms</h4>
            {loanTerms.map((item, index) => (
              <p key={index}>
                {item.label}:&nbsp;{item.value}
              </p>
            ))}
          </Col>
          <Col xs={12} sm={6} md={3}>
            <h4 className="font-bold-800 my-3">Application Dates</h4>
            {applicationDates.map((item, index) => (
              <p key={index}>
                {item.label}:&nbsp;{item.value}
              </p>
            ))}
          </Col>
          <Col xs={12} sm={6} md={3}>
            <h4 className="font-bold-800 my-3">Contract Dates</h4>
            {contractDates.map((item, index) => (
              <p key={index}>
                {item.label}:&nbsp;{item.value}
              </p>
            ))}
          </Col>
          <Col xs={12} sm={6} md={3}>
            <h4 className="font-bold-800 my-3">Fees</h4>
            {fees.map((item, index) => (
              <p key={index}>
                {item.label}:&nbsp;{item.value}
              </p>
            ))}
          </Col>
        </Row>
      </div>

      <Row className="mt-3">
        <Col xs={12} md={12} lg={6}>
          <h3 className="h3 text-primary-dark mt-4">Liabilities and Income</h3>
          <hr className="bg-primary-dark height-2px opacity-1" />
          <div className="mt-3">
            <RequestTable data={ratios} />
          </div>

          <hr className="hr-gray-300" />

          <div className="mt-4">
            <RequestTable data={revolvingDebt} />
          </div>

          <hr className="hr-gray-300" />

          <div className="mt-4">
            <RequestTable data={income} />
          </div>
        </Col>

        <Col xs={12} md={12} lg={6}>
          <h3 className="h3 text-primary-dark mt-4">Bureau Info</h3>
          <hr className="bg-primary-dark height-2px opacity-1" />

          <div className="mt-3">
            <RequestTable3 data={trades} />
          </div>

          <hr className="hr-gray-300" />

          <div className="mt-4">
            <RequestTable3 data={delinquencies} />
          </div>

          <hr className="hr-gray-300" />

          <div className="mt-4">
            <RequestTable3 data={derogatoryTrades} />
          </div>
          <hr className="hr-gray-300" />

          <div className="mt-4">
            <RequestTable3 data={publicRecords} />
          </div>
        </Col>
      </Row>
    </>
  );
};
