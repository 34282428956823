import { environment } from "@env/environment";
import crypto from "crypto";

const secretKey = environment.REACT_APP_CRYPTOGRAPHIC_KEY || "";
const iv = environment.REACT_APP_CRYPTOGRAPHIC_IV || "fswrqj99RMkxnKIf";

const encrypt = (text: string): string => {
  const cipher = crypto.createCipheriv("aes-256-cbc", secretKey, iv);
  let encrypted = cipher.update(text, "utf8", "base64");
  encrypted += cipher.final("base64");

  return encrypted;
};

const decrypt = (text: string): string => {
  const secretKey = environment.REACT_APP_CRYPTOGRAPHIC_KEY || "";

  const decipher = crypto.createDecipheriv("aes-256-cbc", secretKey, iv);
  let decrypted = decipher.update(text, "base64", "utf8");
  decrypted += decipher.final("utf8");
  return decrypted;
};

export { encrypt, decrypt };
