import { UseFormTextField } from "@shared/components/TextInput";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { mfaForLoginSchema } from "@auth/schema";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { signInSubmitMfaAction } from "@store/slices/auth.slice";
import { onSubmitError, useQuery } from "@shared/helpers/global.helper";
import { useAuthUserContext } from "@contexts/AuthContext";
import { Button } from "react-bootstrap";
import {
  appLoadingSelector,
  loginProcessDataSelector,
} from "@store/slices/app.slice";
import { LoginChallenge } from "@store/types";

const LoginTotpMfaForm = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  let query = useQuery();
  const { setAuthUserStatus } = useAuthUserContext();
  const isLoading = useAppSelector(appLoadingSelector);
  const loginProcessData = useAppSelector(loginProcessDataSelector);

  const mfaLoginFormHandler = useForm({
    resolver: yupResolver(mfaForLoginSchema),
  });

  const onMfaLoginSubmit = (event: any) => {
    if (!loginProcessData) {
      return;
    }

    let returnUrl: any = query.get("returnUrl");
    if (returnUrl === window.location.pathname) {
      returnUrl = "/pipelines";
    }
    dispatch(
      signInSubmitMfaAction({
        model: { mfaCode: event.mfa },
        loginProcessData,
        path: returnUrl ? returnUrl : "/pipelines",
        history,
        setAuthUserStatus,
        loginChallenge: LoginChallenge.SOFTWARE_TOKEN_MFA,
      })
    );
  };

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={mfaLoginFormHandler.handleSubmit(
        onMfaLoginSubmit,
        onSubmitError()
      )}
      className="login-mfa"
    >
      <div className="d-flex justify-content-center ">
        <p className="mt-5 mb-3">
          Open your MFA app and type the authentication code below.
        </p>
      </div>
      <div className="w-100 d-flex justify-content-center">
        <div className="d-flex flex-column align-items-center w-75">
          <UseFormTextField
            placeholder="6-Digit Code"
            control={mfaLoginFormHandler.control}
            name="mfa"
            autoFocus
          />

          <Button
            className="my-5"
            variant="primary"
            type="submit"
            disabled={isLoading}
          >
            Submit
          </Button>
        </div>
      </div>
    </form>
  );
};

export default LoginTotpMfaForm;
