import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import Calendar from "@assets/images/calendar.svg";
import { DATE_FORMATS } from "@shared/constants/app.constant";

type FieldProps = {
  control: any;
  controlName: any;
  placeholder?: string;
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  maxDate?: Date;
};

export const UseFormDatePicker = ({
  control,
  controlName,
  maxDate,
  disabled = false,
  placeholder,
  defaultValue = "",
}: FieldProps) => {
  return (
    <Controller
      name={controlName}
      control={control}
      defaultValue={defaultValue}
      render={({
        field: { onChange, value },
        fieldState: { error, isDirty },
      }) => (
        <>
          <div
            className={`input-group date-picker radius-5 ${
              error ? "border-red" : ""
            }`}
            style={{}}
          >
            <span className="input-group-text">
              <img src={Calendar} alt="" />
            </span>
            <DatePicker
              maxDate={maxDate}
              className={`form-control${!!error ? " is-invalid-input" : ""}`}
              placeholderText={placeholder}
              disabled={disabled}
              onChange={onChange}
              selected={value}
              dateFormat={DATE_FORMATS}
            />
          </div>
          {error && (
            <Form.Text
              id="passwordHelpBlock"
              className="text-danger px-2"
              muted
            >
              {error?.message}
            </Form.Text>
          )}
        </>
      )}
    />
  );
};
