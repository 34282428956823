import { ConditionalRender } from "@shared/components/ConditionalRender";
import { getIssueDocTypeLabel } from "@shared/helpers/documents.helper";
import { IPortalApplication } from "@shared/interfaces/PortalApplicationTypes";
import { useAppSelector } from "@store/hooks";
import { issueLoanDocTypeSelector } from "@store/slices/commonData.slice";

export const SystemInfo = ({
  requestDetail,
}: {
  requestDetail: IPortalApplication | undefined;
}) => {
  const issueLoanDocType = useAppSelector(issueLoanDocTypeSelector);
  return (
    <div>
      <h4 className="font-bold-800">System Info</h4>
      <p>
        Panels:{" "}
        <ConditionalRender
          value={[
            requestDetail?.contractPrepInfo?.panelQuantity,
            getIssueDocTypeLabel(
              requestDetail?.contractPrepInfo?.moduleType,
              issueLoanDocType?.moduleType
            ),
          ]
            .join(" ")
            .trim()}
        />
      </p>
      <p>
        Inverters:{" "}
        <ConditionalRender
          value={[
            requestDetail?.contractPrepInfo?.inverterQuantity,
            getIssueDocTypeLabel(
              requestDetail?.contractPrepInfo?.inverterType,
              issueLoanDocType?.inverterType
            ),
          ]
            .join(" ")
            .trim()}
        />
      </p>
      <p>
        Batteries:{" "}
        <ConditionalRender
          value={[
            requestDetail?.contractPrepInfo?.batteryQuantity,
            getIssueDocTypeLabel(
              requestDetail?.contractPrepInfo?.batteryType,
              issueLoanDocType?.batteryType
            ),
          ]
            .join(" ")
            .trim()}
        />
      </p>
    </div>
  );
};
