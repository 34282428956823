import React from 'react'
import { Row, Col } from 'react-bootstrap'
import DecisionLoader from '@assets/images/decision-loader.gif';
const RequestProcessing = () => {

    return (
        <div className="decisionPage">
            <Row className="align-items-center h-100">
                <Col className="text-center">
                    <h5>Your application is being processed.</h5>
                    <img src={DecisionLoader} alt="Flic Financial" />
                </Col>
            </Row>
        </div>
    )
}

export default RequestProcessing