import { useMemo } from "react";
import useSungageEquipment from "./useSungageEquipment";

interface IUseTotalSystemSizeArgs {
  moduleType: string;
  batteryType: string;
  batteryExtensionType: string;
  panelQuantity: string;
  batteryQuantity: string;
  batteryExtensionQuantity: string;
}

const useTotalSystemSize = ({
  moduleType,
  batteryType,
  batteryExtensionType,
  panelQuantity,
  batteryQuantity,
  batteryExtensionQuantity,
}: IUseTotalSystemSizeArgs) => {
  const sungageEquipment = useSungageEquipment();

  const {
    totalBatterySize,
    totalSolarSize,
    moduleSize,
    batterySize,
    batteryExtensionSize,
  } = useMemo(() => {
    const moduleSize =
      sungageEquipment?.modules.find((i) => i.value === Number(moduleType))
        ?.size || 0;
    const batterySize =
      sungageEquipment?.batteries.find((i) => i.value === Number(batteryType))
        ?.size || 0;

    const batteryExtensionSize =
      sungageEquipment?.batteryExtensions.find(
        (i) => i.value === Number(batteryExtensionType)
      )?.size || 0;

    const totalSolarSize = Number(panelQuantity) * (Number(moduleSize) / 1000);
    const totalBatterySize =
      Number(batteryQuantity) * Number(batterySize) +
      Number(batteryExtensionQuantity) * Number(batteryExtensionSize);

    return {
      totalSolarSize: isNaN(totalSolarSize) ? 0 : totalSolarSize,
      totalBatterySize: isNaN(totalBatterySize) ? 0 : totalBatterySize,
      moduleSize,
      batterySize,
      batteryExtensionSize,
    };
  }, [
    sungageEquipment?.modules,
    sungageEquipment?.batteries,
    sungageEquipment?.batteryExtensions,
    panelQuantity,
    batteryQuantity,
    batteryExtensionQuantity,
    moduleType,
    batteryType,
    batteryExtensionType,
  ]);

  return {
    totalBatterySize,
    totalSolarSize,
    moduleSize,
    batterySize,
    batteryExtensionSize,
  };
};

export default useTotalSystemSize;
