import { ReactNode } from "react";

export const CardCustom = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  return (
    <div className="w-100 card-custom">
      <p className="text-center card-custom-title">{title}</p>
      <div className="card-custom-content">{children}</div>
    </div>
  );
};
