import { IAddIpWhitelistPayload } from "src/features/ip-whitelist/add-ip-whitelist/AddIpWhiteList";
import { networkService } from "./network-service";
import { IpWhitelist } from "@shared/interfaces/IpWhitelist";

class IpWhitelistService {
  async addIpWhitelist(model: IAddIpWhitelistPayload) {
    return await networkService.post<
      IAddIpWhitelistPayload,
      {
        data: IpWhitelist;
      }
    >("ip-whitelist", model);
  }
}

export const ipWhitelistService = new IpWhitelistService();
