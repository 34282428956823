import React from 'react'
import { Col, Button, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
const PublicSucess = () => {
    return (
        <div className="mt-4">
            <Row>
                <Col xs={12} sm={9} md={7} lg={5} className="mx-auto mt-5">
                    <h6 className="font-bold-800 text-center text-primary">Your application has been submitted successfully.
                    </h6>
                </Col>

            </Row>
            <div className="text-center mt-5">
                <Link to={`/auth/login`}>
                    <Button >Exit</Button>
                </Link>
            </div>
        </div>
    )
}

export default PublicSucess