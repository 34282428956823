import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import Confirmation from "./confirmation/Confirmation";
import Request from "./request/Request";
import ModifyApplicant from "./modify-applicant/ModifyApplicant";
import CoBorrower from "./co-borrower/CoBorrower";

const NewApplication = () => {
  let { url } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route path={`${url}/request`} component={Request} />
        <Route path={`${url}/edit/:id`} component={ModifyApplicant} />
        <Route path={`${url}/confirmation/:id`} component={Confirmation} />
        <Route path={`${url}/co-borrower/:id`} component={CoBorrower} />
        <Route
          path="*"
          render={() => (
            <Redirect
              to={{
                pathname: `${url}/request`,
              }}
            />
          )}
        />
      </Switch>
    </div>
  );
};

export default NewApplication;
