import { REGEX } from "@shared/constants/app.constant";
import * as yup from "yup";

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required.")
    .matches(REGEX.EMAIL, "Please enter a valid email"),
  password: yup.string().required("Password is required."),
});

export const mfaForLoginSchema = yup.object().shape({
  mfa: yup
    .string()
    .required("MFA is required.")
    .matches(REGEX.NUMBERS_ONLY_REGEX, "Please enter only digits.")
    .min(6, "MFA should of length 6")
    .max(6, "MFA should of length 6"),
});

export const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required.")
    .matches(REGEX.EMAIL, "Please enter a valid email"),
});
export const resetPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required.")
    .matches(REGEX.EMAIL, "Please enter a valid email"),
  code: yup.string().required("Code is required."),
  password: yup
    .string()
    .required("Password is required.")
    .matches(
      REGEX.PASSWORD,
      "Password must consist of a minimum of 8 characters, at least one capital letter, one lowercase letter, one number and one special character."
    ),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Confirm Password does not match"),
});

export const setNewPasswordSchema = yup.object().shape({
  email: yup.string().required("Email is required."),
  password: yup
    .string()
    .required("Password is required.")
    .matches(
      REGEX.PASSWORD,
      "Password must consist of a minimum of 8 characters, at least one capital letter, one lowercase letter, one number and one special character."
    ),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Confirm Password does not match"),
});
