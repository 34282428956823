import { NA } from '@shared/constants/app.constant'

export const ConditionalRender = ({ value, symbol, position }: { value: any, symbol?: string, position?: string }) => {
    return (
        <span>
            {/* {(position == SymbolPosition.LEFT && symbol && value) ? symbol : ''} */}
            {(value === null || value === undefined || value === '') ? NA : (
                value
            )}
            {/* {(position == SymbolPosition.RIGHT && symbol && (value || value === 0)) ? symbol : ''} */}
        </span>
    )
}
