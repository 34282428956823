import React from 'react'
import { Row, Col } from 'react-bootstrap'

const ElectronicDisclosure = () => {
    return (
        <Col xs={11} className="mx-auto terms">
            <div className="">
                <Row className="">
                    <Col xs={12}>
                        <strong>Last Updated: August 29, 2023</strong>
                        <br />
                        <br />
                        <strong>
                        CONSENT TO RECEIVE DISCLOSURES THROUGH ELECTRONIC MEANS
                        </strong>
                        <br />
                        <br />
                        <p>You have initiated a transaction with SunRisa Solutions LLC dba Flic Financial (referred to as “Flic,” “Us,” or “We”) using electronic records and signatures. Before proceeding, We ask for your consent to allow Us to electronically deliver to you disclosures and other information (“Required Information”) relating to the proposed transaction or future transactions with Us. Your consent also allows Us to use electronic records and signatures in this and future transactions unless and until you withdraw consent. We may still send some notices to you in paper at your regular mailing address. For this reason, it is ‎important that you inform Us of any changes to your regular mailing address.‎ </p>
                        <p> When you receive an email from Us alerting you to take action, you agree to ‎promptly review the email and take the action requested in that email, including accessing the website identified in that email. Your rights relating to a transaction with Us may be dependent on when you receive certain ‎information from Us. You are considered to have received information from Us when We notify ‎you at your email address alerting you that information is waiting for you on the website identified in that email. ‎</p>
                        <p> If you wish to change the email address where We send the notices and disclosures to you, you ‎must update your email address by contacting us at care@flicfinancial.com. Please include your full name, old email address, updated email address and your telephone number. You may later revoke this consent by notifying Us in writing at Flic Financial, 520 Post Oak Blvd, Suite 850 Houston, TX 77027 or by email to care@flicfinancial.com. It may take a few days for Us to process your revocation. Withdrawal of consent may also result in some processing delay or the need for you to complete new forms on paper.</p>
                        <p> You may request a paper copy of Required Information‎ by contacting Us in writing at Flic Financial, 520 Post Oak Blvd, Suite 850 Houston, TX 77027 or by email to care@flicfinancial.com. There is not a charge for paper copies. </p>
                        <p> The computer hardware and software used to access this page is all you will need to access the ‎Required Information and other documents provided to you in electronic form.  Any documents you complete online with us will be emailed to you.  You may then save them by printing them locally, saving them in your email account, or otherwise saving them locally for later reference.</p>
                        <p> By clicking the check box that “Yes, I have read and agree to the following consents and disclosures,” you agree to the above. </p>
                    </Col>
                </Row>
            </div>
        </Col>
    )
}

export default ElectronicDisclosure