import { networkService } from "./network-service";

export interface Address {
  address: string;
  city: string;
  id: string;
  name: string;
  state: string;
  street: string;
  zipCode: string;
}

const cache: {
  places: Record<string, Address[]>;
} = {
  places: {},
};

export const searchPlaces = async (query: string): Promise<Address[]> => {
  try {
    if (cache.places[query]) {
      return cache.places[query];
    }
    const { data } = await networkService.get<{ data: Address[] }>(
      "/google/addresses/search",
      {
        params: {
          query,
        },
      }
    );
    return (cache.places[query] = data?.data || []);
  } catch (error) {
    return [];
  }
};
