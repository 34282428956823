import { IRule } from "@shared/interfaces/Application";
import {
  DecisionRulesTab,
  RULE_DIDNT_RUN,
} from "@features/request-detail/components/decisionTabs/DecisionRules";

const YES = "Yes";
const NO = "No";
const NA = "NA";

type OptionType = typeof YES | typeof NO | typeof NA;

export const getRuleResult = (rule: IRule): string => {
  switch (rule.resultStr) {
    case "Passed":
      return "PASS";
    case "Failed":
      return "FAIL";
    case RULE_DIDNT_RUN:
      return "N/A";

    default:
      return "";
  }
};

export const getConsumerStatement = (xConsStmtExist?: boolean): string => {
  switch (xConsStmtExist) {
    case true:
      return YES;
    case false:
      return NO;
    default:
      return NA;
  }
};

export const getConsumerStatementStatusColor = (
  xConsStmtExist?: boolean
): string => {
  switch (xConsStmtExist) {
    case true:
      return "text-warning";
    case false:
      return "text-success";
    default:
      return "";
  }
};

export const getOfacManualClearStatusColor = (
  xOfacManualClearValue: OptionType
): string => {
  switch (xOfacManualClearValue) {
    case YES:
      return "text-success";
    case NO:
      return "text-warning";
    default:
      return "";
  }
};

export const getConsumerStatementManualClearStatusColor = (
  xConsStmtManualClearValue?: OptionType
): string => {
  switch (xConsStmtManualClearValue) {
    case YES:
      return "text-success";
    case NO:
      return "text-warning";
    default:
      return "";
  }
};

export const getStatementManuallyCleared = (
  data:
    | {
        consumerStatementManualClear?: boolean | undefined;
        consumerStatementExist?: boolean | undefined;
      }
    | undefined
    | null
): OptionType => {
  if (!data) return NA;
  const { consumerStatementExist, consumerStatementManualClear } = data;
  if (
    consumerStatementExist === undefined ||
    consumerStatementExist === null ||
    consumerStatementExist === false
  )
    return NA;

  if (consumerStatementExist === true) {
    switch (consumerStatementManualClear) {
      case true:
        return YES;
      case false:
        return NO;
      default:
        return NA;
    }
  }
  return NA;
};

export const getOfacAlert = (xOFACMatch?: string | boolean): string => {
  switch (xOFACMatch) {
    case "true":
    case true:
      return YES;
    case "false":
    case false:
      return NO;
    default:
      return NA;
  }
};

export const getOfacAlertStatusColor = (
  xOFACMatch?: string | boolean
): string => {
  switch (xOFACMatch) {
    case "true":
    case true:
      return "text-warning";
    case "false":
    case false:
      return "text-success";
    default:
      return "";
  }
};

export const getOfacManualClear = (
  data:
    | {
        xOFACMatch?: string | boolean;
        xOfacManualClear?: boolean;
      }
    | undefined
    | null
): OptionType => {
  if (!data) return NA;
  const { xOFACMatch, xOfacManualClear } = data;
  if (!xOFACMatch || xOFACMatch === "false") {
    return NA;
  }
  if (xOFACMatch === "true" || xOFACMatch === true) {
    switch (xOfacManualClear) {
      case true:
        return YES;
      case false:
        return NO;
      default:
        return NA;
    }
  }
  return NA;
};

export const getUnderwritingException = (xHasUwException?: boolean): string => {
  switch (xHasUwException) {
    case true:
      return YES;
    case false:
      return NO;
    default:
      return NA;
  }
};

export const getUnderWritingExceptionStatusColor = (
  xHasUwException?: boolean
): string => {
  switch (xHasUwException) {
    case true:
      return "text-success";
    case false:
      return "text-warning";
    default:
      return "";
  }
};

export const getIsManufacturedHome = (
  corelogicPropertyIsManufacturedHome?: boolean
): string => {
  switch (corelogicPropertyIsManufacturedHome) {
    case true:
      return YES;
    case false:
      return NO;
    default:
      return NA;
  }
};

export const getIsManufacturedHomeStatusColor = (
  corelogicPropertyIsManufacturedHome?: boolean
): string => {
  switch (corelogicPropertyIsManufacturedHome) {
    case true:
      return "text-warning";
    case false:
      return "text-success";
    default:
      return "";
  }
};

export const getRuleResultStatusColor = (rule: IRule) => {
  switch (rule.resultStr) {
    case "Passed":
      return "text-success";
    case "Failed":
      return "text-danger";
    case RULE_DIDNT_RUN:
      return "text-warning";

    default:
      return "text-danger";
  }
};

export const getIsOwnedByLlc = (corelogicIsCorporate?: boolean): string => {
  switch (corelogicIsCorporate) {
    case true:
      return YES;
    case false:
      return NO;
    default:
      return NA;
  }
};

export const getIsOwnedByLlcStatusColor = (corelogicIsCorporate?: boolean) => {
  switch (corelogicIsCorporate) {
    case true:
      return "text-warning";
    case false:
      return "text-success";

    default:
      return "";
  }
};

export const getCoreLogicPropertyTypeCode = (
  corelogicPropertyTypeCode?: string
): string => {
  switch (corelogicPropertyTypeCode) {
    case "10":
      return "SFR";
    case "11":
      return "CONDO";
    case "20":
      return "CONDO";
    case "22":
      return "MULTI-FAM";
    case "70":
      return "FARM/RNCH";
    case "80":
      return "LOT";
    case "Other":
      return "OTHR";
    default:
      return NA;
  }
};

export const getActualValue = (rule: IRule): string => {
  return rule.valueStr.indexOf(":") >= 0
    ? rule.valueStr.split(":")[1]
    : rule.valueStr;
};

export const withRuleSorting = (data: IRule[]): IRule[] => {
  return data.sort((a, b) =>
    a?._sortIndex && b?._sortIndex ? a?._sortIndex - b?._sortIndex : 0
  );
};

export const filterRules = (
  data: IRule[],
  activeTab: DecisionRulesTab,
  search = ""
): IRule[] => {
  let specificTabRules: IRule[] = [];
  switch (activeTab) {
    case DecisionRulesTab.AllRules:
      specificTabRules = data;
      break;
    case DecisionRulesTab.Refer:
      specificTabRules = data.filter((rule) => rule.isCritical === false);
      break;
    case DecisionRulesTab.Pass:
      specificTabRules = data.filter((rule) => rule.resultStr === "Passed");
      break;
    case DecisionRulesTab.Fail:
      specificTabRules = data.filter((rule) => rule.resultStr === "Failed");
      break;
    case DecisionRulesTab.NotRun:
      specificTabRules = data.filter(
        (rule) => rule.resultStr === RULE_DIDNT_RUN
      );
      break;

    default:
      break;
  }

  if (!search || search === "") {
    return withRuleSorting(specificTabRules);
  }
  return withRuleSorting(
    specificTabRules.filter((rule) =>
      rule.label.toLowerCase().includes(search.toLowerCase())
    )
  );
};

export const getSortIndexForRule = (result: IRule): number => {
  switch (result.resultStr) {
    case "Failed":
      return 1;
    case "Passed":
      return 2;
    case RULE_DIDNT_RUN:
      return 4;

    default:
      if (result.isCritical === false) {
        // Refer
        return 3;
      }
      return 0;
  }
};
