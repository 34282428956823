import { UseFormTextField } from "@shared/components/TextInput";
import { Button, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { toasterService } from "@services/toaster.service";
import { addIpWhitelistSchema } from "@shared/schema/request";
import { useHistory } from "react-router-dom";

import { onSubmitError } from "@shared/helpers/global.helper";
import { ipWhitelistService } from "@services/ipWhitelist.service";

export interface IAddIpWhitelistPayload {
  label: string;
  ipAddress: string;
}

const AddIpWhiteList = () => {
  const history = useHistory();
  const {
    control,
    handleSubmit,
    reset,

    formState,
  } = useForm<IAddIpWhitelistPayload>({
    resolver: yupResolver(addIpWhitelistSchema),
  });

  const onSubmit = async (event: IAddIpWhitelistPayload) => {
    try {
      const response = await ipWhitelistService.addIpWhitelist(event);

      toasterService.success("IP has been added successfully to whitelist.");
      history.push("/ip-whitelist");
      reset({
        ipAddress: "",
        label: "",
      });
    } catch (error) {}
  };

  return (
    <div className="d-flex align-items-center justify-content-center">
      <div className="login-container form-container">
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit, onSubmitError())}
        >
          <div className="row">
            <div className="col-xs-12 col-md-8 offset-md-2">
              <div className="d-flex justify-content mt-3">
                <h3 className="h3 flex-grow-1">Add IP To Whitelist</h3>
              </div>
              <h5>New IP</h5>
              <Row className="mb-3">
                <Col>
                  <UseFormTextField
                    placeholder="Label"
                    control={control}
                    name="label"
                  />
                </Col>
                <Col>
                  <UseFormTextField
                    placeholder="IP Address"
                    control={control}
                    name="ipAddress"
                  />
                </Col>
              </Row>

              <Row className="justify-content-center mt-5">
                <Col xs={3} className="d-flex justify-content-end">
                  <Button
                    variant="outline-primary"
                    type="button"
                    disabled={formState.isSubmitting}
                    onClick={() => history.goBack()}
                  >
                    Exit
                  </Button>
                </Col>
                <Col xs={3} className="d-grid">
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={formState.isSubmitting}
                  >
                    Add IP
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddIpWhiteList;
