import { UseFormTextField } from "@shared/components/TextInput";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { appService } from "@services/app.service";
import { toasterService } from "@services/toaster.service";
import { addRegionSchema } from "@shared/schema/request";
import { useHistory } from "react-router-dom";
import { onSubmitError } from "@shared/helpers/global.helper";
import { UseFormSelect } from "@shared/components/UserFormSelect";
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { appDealersSelector, getDealersAction } from "@store/slices/app.slice";

const AddRegion = () => {
  const history = useHistory();
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(addRegionSchema),
  });
  const dispatch = useAppDispatch();
  const dealersData = useAppSelector(appDealersSelector);

  const dealers = useMemo(
    () =>
      dealersData.map(({ internalClientId, name }) => ({
        text: name,
        value: internalClientId,
      })),
    [dealersData]
  );

  const onSubmit = async (event: any) => {
    try {
      const response: any = await appService.addRegion({
        name: event.name,
        dealer: event.dealer,
      });
      if (!!response?.data?.data?.id) {
        history.push("/add-user");
        toasterService.success(
          `${
            response?.data?.data?.name || ""
          } region has been created successfully`.trimStart()
        );
        reset({
          name: "",
          dealer: "",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    dispatch(getDealersAction(null));
  }, [dispatch]);

  return (
    <div className="d-flex align-items-center justify-content-center">
      <div className="login-container form-container">
        <form
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit, onSubmitError())}
        >
          <div className="row">
            <div className="col-xs-12 col-md-4 offset-md-4">
              <div className="d-flex justify-content mt-3">
                <h3 className="h3 flex-grow-1">Add Region</h3>
              </div>
              <h5>Dealer</h5>
              <Row className="mb-4">
                <Col>
                  <UseFormSelect
                    list={dealers}
                    control={control}
                    name="dealer"
                    placeholder="Select Dealer"
                    placeholderValue={""}
                  />
                </Col>
              </Row>
              <h5>Region Name</h5>
              <Row className="mb-4">
                <Col>
                  <UseFormTextField
                    placeholder="Region name"
                    control={control}
                    name="name"
                  />
                </Col>
              </Row>
              <Row className="justify-content-center mt-5">
                <Col xs={3}>
                  <Button
                    variant="outline-primary"
                    type="button"
                    onClick={() => history.goBack()}
                  >
                    Exit
                  </Button>
                </Col>
                <Col xs={5} className="d-grid">
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <div className="px-4">
                        <Spinner
                          animation="border"
                          size="sm"
                          role="status"
                        ></Spinner>
                      </div>
                    ) : (
                      "Add Region"
                    )}
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddRegion;
