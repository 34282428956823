import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@store/store";
import { networkService } from "@services/network-service";
import { IpWhitelist } from "@shared/interfaces/IpWhitelist";
import {
  GetPaginationResult,
  IpWhitelistState,
  PaginationParams,
} from "@store/types";

const initialState: IpWhitelistState = {
  ipWhitelistData: {
    results: [],
    total: 0,
  },
};

export const getIpWhitelist = async ({
  page,
  limit,
  search,
}: PaginationParams): Promise<GetPaginationResult<IpWhitelist>> => {
  const { data } = await networkService.get<
    { data: GetPaginationResult<IpWhitelist> } | undefined
  >(`/ip-whitelist`, {
    params: {
      maxRecords: limit,
      page,
      search,
    },
  });
  return {
    results: [],
    total: 0,
    ...(data?.data || {}),
  };
};

export const getIpWhitelistAction = createAsyncThunk(
  "ipWhitelist/getIpWhitelist",
  async (
    { page, limit, search }: PaginationParams,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const ipWhitelistResult = await getIpWhitelist({
        page,
        limit,
        search,
      });
      dispatch(setIpWhitelistData(ipWhitelistResult));
      return ipWhitelistResult;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const ipWhitelistSlice = createSlice({
  name: "ipWhitelist",
  initialState,
  reducers: {
    resetIpWhitelist: (state: IpWhitelistState) => ({
      ...state,
      ipWhitelistData: {
        ...state.ipWhitelistData,
        results: [],
        total: 0,
      },
    }),
    setIpWhitelistData: (
      state: IpWhitelistState,
      {
        payload,
      }: PayloadAction<{
        results: IpWhitelist[];
        total: number;
      }>
    ) => ({
      ...state,
      ipWhitelistData: {
        ...state.ipWhitelistData,
        results: payload.results || [],
        total: payload.total || 0,
      },
    }),
  },
  extraReducers: (builder) => {},
});

export const { reducer: ipWhitelistReducer } = ipWhitelistSlice;
export const { resetIpWhitelist, setIpWhitelistData } =
  ipWhitelistSlice.actions;

export const ipWhitelistDataSelector = (rootState: RootState) =>
  rootState.ipWhitelist.ipWhitelistData;
