import { Clipboard } from "react-bootstrap-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const CopyCredentials = ({
  handleCopyText,
}: {
  handleCopyText: () => void;
}) => {
  return (
    <OverlayTrigger
      placement="right"
      overlay={<Tooltip id={`tooltip-right`}>Copy</Tooltip>}
    >
      <Clipboard
        size={16}
        className="cursor-pointer mr-2"
        onClick={handleCopyText}
      />
    </OverlayTrigger>
  );
};

export default CopyCredentials;
