import { getProgramType } from '@shared/helpers/global.helper';
import { useAppSelector } from '@store/hooks';
import Pagination from "react-js-pagination";
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import { setDraftDetail } from '@store/slices/loan.slice'
import { useAppDispatch } from '@store/hooks'
import { DATE_FORMAT } from '@shared/constants/app.constant';
import { PipelineCardSkeleton } from '@shared/skeletons/PipelineCardSkeleton';

const DraftApplication = ({ draftApplications, onPageSizeChange, onPageChange, page, pageLimit }: { draftApplications: Array<any>, onPageSizeChange: any, onPageChange: any, page: any, pageLimit: any }) => {
    const isLoading = useAppSelector(state => state.app.loading);
    const history = useHistory();
    const dispatch = useAppDispatch();

    const onClickHandler = (item: any) => {
        dispatch(setDraftDetail(item))
        history.push(`/request-detail/new`)
    }
    return (
        <div>
            <div className="row">
                {draftApplications?.map((data: any, i: number) => (
                    <div key={i} className={`col-md-6 col-lg-4 col-sm-12 pipeline-box  `} >
                        <div className="border p-3 hover" id={data.id} >
                            <div onClick={() => onClickHandler(data)}>
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex flex-row align-items-center">
                                        <h6 className="mb-0">{data?.first_name + ' ' + data?.last_name}</h6>
                                    </div>
                                    <h6 className="text-end">{data?.app_status === 'new' ? 'New' : data?.app_status} </h6>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <span className="text-muted font-12">Application has been sent to the applicant(s) </span>
                                    <h6 >{data?.application_type}  </h6>
                                </div>
                                <div className="d-flex flex-column">
                                    <span className="text-muted font-12" >Loan Product: {getProgramType(data?.loan_product)}</span>
                                    <span className="text-muted font-12" >Created on:  <Moment style={{ fontSize: '12px' }} format={DATE_FORMAT}>{data?.created_at}</Moment></span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {!isLoading && draftApplications?.length === 0 && (
                <div className="row mt-5">
                    <div className="col-12 text-center">
                        <h4 className="h3 text-primary">No application found</h4>
                    </div>
                </div>
            )}
            <div className="row">
                {isLoading && [1, 2, 3, 4, 5, 6].map(item => (
                    <div key={item} className="col-md-6 col-lg-4 col-sm-12 skeleton-box">
                        <PipelineCardSkeleton />
                    </div>
                ))}
            </div>
            {(!isLoading && draftApplications?.length > 0) && (
                <div className="row mt-5">
                    <div className="col-12 d-block mx-auto pagination-container">
                        <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center">
                            <div className="order-2 order-sm-1">
                                <span>Records per active page</span>
                                <select className="page-size mx-3" value={pageLimit} onChange={(e) => { onPageSizeChange(e) }}>
                                    <option value="6">6</option>
                                    <option value="12">12</option>
                                    <option value="18">18</option>
                                </select>
                            </div>
                            <div className="order-1 order-sm-2 mb-3 mb-sm-0">
                                <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={page}
                                    itemsCountPerPage={Number(pageLimit)}
                                    totalItemsCount={Number(draftApplications[0].totalcount)}
                                    onChange={onPageChange}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            )}
        </div>
    )
}

export default DraftApplication

