import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "@store/store";
import { networkService } from "@services/network-service";

export interface Region {
  id: string;
  name: string;
  dealer: string;
}

interface RegionState {
  regions: Region[];
}

const initialState: RegionState = {
  regions: [],
};

export const getRegionsAction = createAsyncThunk(
  "region/getRegions",
  async (args: null, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await networkService.get("regions");
      const regions = data?.data || [];
      dispatch(setRegions(regions));
      return regions;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const regionSlice = createSlice({
  name: "region",
  initialState,
  reducers: {
    resetRegions: (state: RegionState) => ({
      ...state,
      regions: [],
    }),
    setRegions: (state: RegionState, { payload }: PayloadAction<Region[]>) => ({
      ...state,
      regions: [...payload],
    }),
  },
});

export const { reducer: regionReducer } = regionSlice;
export const { resetRegions, setRegions } = regionSlice.actions;

export const regionsSelector = (rootState: RootState) =>
  rootState.region.regions;
