import { Row, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useHistory, useParams } from "react-router-dom";
import { getProductsList, onSubmitError } from "@shared/helpers/global.helper";
import {
  modifyLoanAction,
  prequalModifyLoanAction,
} from "@store/slices/loan.slice";
import { useEffect, useState } from "react";
import { modifyLoanSchema } from "@shared/schema/request";
import { loanService } from "@services/loan.service";
import {
  INSTALLATION_RESIDENCY,
  OWNERSHIP_LIST,
} from "@shared/constants/app.constant";
import ApplicationGuard from "@shared/components/ApplicationGuard";
import { UseFormSelect } from "@shared/components/UserFormSelect";
import { UserFormCurrencyField } from "@shared/components/UserFormCurrencyField";
import { UseFormTextField } from "@shared/components/TextInput";
import { GoogleAutoComplete } from "@shared/components/GoogleAutoComplete";
import { appUserSelector } from "@store/slices/app.slice";
import { useAuthUserContext } from "@contexts/AuthContext";
import {
  getXPrequalProducts,
  isPrequalApplication,
} from "@shared/helpers/application.helper";
import { UseFormCheck } from "@shared/components/UserFormCheck";
import {
  IPortalApplication,
  ProductLos,
} from "@shared/interfaces/PortalApplicationTypes";
import { IXPrequalProductsTypeValues } from "@shared/interfaces/Application";
import useInstallationAddress from "@hooks/useInstallationAddress";

const ModifyLoan = () => {
  const dispatch = useAppDispatch();
  const [application, setApplication] = useState<IPortalApplication | null>(
    null
  );
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const { authUserStatus } = useAuthUserContext();

  const { control, handleSubmit, reset, setValue, watch } = useForm({
    resolver: yupResolver(modifyLoanSchema),
  });
  const user = useAppSelector(appUserSelector);

  const isFullApplication = watch("isFull");

  const { isInstallationAddressUpdated, setInitialInstAddressFields } =
    useInstallationAddress({
      currentAddressFields: {
        addressLine: watch("xInstallationAddressLine"),
        city: watch("xInstallationCity"),
        state: watch("xInstallationState"),
        zipCode: watch("xInstallationZipCode"),
      },
    });

  const onSubmit = (event: any) => {
    if (!application || !application?.losAppNumber) {
      return;
    }

    const path = isFullApplication
      ? "/new-application/confirmation"
      : "/pre-qualification/confirmation";

    const { xPrequalProductsSelected, xPrequalProductsAll } =
      getXPrequalProducts(user, event.loanType);
    const xprogramtype = Number(event?.loanType);
    const productInfo: IXPrequalProductsTypeValues | undefined =
      xPrequalProductsSelected?.[0];

    if (isFullApplication) {
      const fullAppModel = {
        xProgramTypeName: productInfo?.xProgramTypeName,
        decisionProcessPrefs: {
          defaultCarletonDATFileName: productInfo?.defaultCarletonDATFileName,
        },
        xLender: productInfo?.xLender,
        xStatedInstallationHomeOwner: Number(
          event.xStatedInstallationHomeOwner
        ),
        xInstallationAddressLine: event.xInstallationAddressLine,
        xInstallationCity: event.xInstallationCity,
        xInstallationState: event.xInstallationState,
        xInstallationZipCode: event.xInstallationZipCode,
        installationResidency:
          event.installationResidency || INSTALLATION_RESIDENCY[0].value,
        loanInformation: {
          loanVersions: [
            {
              prequalStage: 0,
              totalCashPrice: parseInt(event.loanAmount),
              requestedTerm: productInfo?.requestedTerm,
              firstPaymentDateStandard: productInfo?.firstPaymentDateStandard,
              contractDateStandard: productInfo?.contractDateStandard,
              userRate: productInfo?.userRate,
              xRiskBasedRateTable: productInfo?.xRisckBasedIntialRateTable,
              xRiskBasedInitialRateTable:
                productInfo?.xRisckBasedIntialRateTable,

              commonLoanInfo: {
                xprogramtype,

                xStatedInstallationHomeOwner: Number(
                  event.xStatedInstallationHomeOwner
                ),
                xInstallationAddressLine: event.xInstallationAddressLine,
                xInstallationCity: event.xInstallationCity,
                xInstallationState: event.xInstallationState,
                xInstallationZipCode: event.xInstallationZipCode,
                installationResidency:
                  event.installationResidency ||
                  INSTALLATION_RESIDENCY[0].value,
                xinitialTerm: productInfo?.xinitialTerm,
                xAmortizationTerm: productInfo?.xAmortizationTerm,
                xFinalPmtDiff: productInfo?.xFinalPmtDiff,
                xMembershipFee: productInfo?.xMembershipFee,
                xFlicFlatFee: productInfo?.xFlicFlatFee,
                xFlicPlatformFeePercent: productInfo?.xFlicPlatformFeePrecent,
                xSellersPointsPercent: productInfo?.xSellerPointsPercent,
                xInitialRate: productInfo?.xInitialRate,
                xFinalRate: productInfo?.xFinalRate,
              },
            },
          ],
        },
      };

      dispatch(
        modifyLoanAction({
          model: fullAppModel,
          id,
          history,
          path,
          isInstallationAddressUpdated,
        })
      );
    } else {
      const isMultiPrequal = event.isMultiPrequal;

      const prequalAppModel = {
        loanInformation: {
          loanVersions: [
            {
              prequalStage: 1,
              totalCashPrice: undefined,
              commonLoanInfo: {
                xprogramtype: event?.loanType,
              },
            },
          ],
        },
      };

      dispatch(
        prequalModifyLoanAction({
          appNumber: id,
          history,
          loanInfo: {
            losAppNumber: application.losAppNumber,
            programTypeCode: application.programTypeCode,
            productLos: ProductLos.DL4,
            prequalProducts: xPrequalProductsSelected,
          },
          path,
          isMultiPrequal,
          xprogramtype,
          xPrequalProducts: xPrequalProductsAll,
        })
      );
    }
  };

  useEffect(() => {
    const getRequestData = async (id: string) => {
      const response = await loanService.getRequestById(id);
      const data = response?.data?.data;
      setApplication(data);
      reset({
        isFull: !isPrequalApplication(data),
        loanAmount: data?.loanOffer?.amountFinanced,
        loanType: data?.programTypeCode,
        xStatedInstallationHomeOwner:
          data?.installationAddress?.installationHomeOwner || undefined,
        xInstallationAddressLine:
          data?.installationAddress?.addressLine || undefined,
        xInstallationCity: data?.installationAddress?.city || undefined,
        xInstallationState: data?.installationAddress?.state || undefined,
        xInstallationZipCode: data?.installationAddress?.zipCode || undefined,
        installationResidency:
          data?.installationAddress?.installationResidency ||
          INSTALLATION_RESIDENCY[0].value,
      });
      setInitialInstAddressFields({
        xInstallationAddressLine: data?.installationAddress?.addressLine || "",
        xInstallationCity: data?.installationAddress?.city || "",
        xInstallationState: data?.installationAddress?.state || "",
        xInstallationZipCode: data?.installationAddress?.zipCode || "",
      });
    };
    getRequestData(id);
  }, [reset, id]);

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit, onSubmitError())}
    >
      <ApplicationGuard application={application} />
      <Col xs={12} md={10} lg={7} className="mx-auto mt-3 fullapp-request">
        <h3 className="h3 text-primary">Modify Loan Information</h3>
        <hr className="text-primary height-2px" />

        <Row className="mt-4">
          <Col xs={12}>
            <h4 className="font-bold">Loan Information</h4>
          </Col>
          <Col xs={12} md={6} className="mt-3 ">
            <UseFormSelect
              list={getProductsList(
                authUserStatus,
                user?.role,
                user?.userDetails?.accessManagement,
                "modify",
                application?.programTypeCode
              )}
              control={control}
              name="loanType"
              placeholder="Select Loan Product"
              disabled={isFullApplication}
            />
          </Col>
          {isFullApplication && (
            <Col xs={12} md={6} className="mt-3 ">
              <UserFormCurrencyField
                placeholder="Loan Amount"
                control={control}
                name="loanAmount"
                type="number"
              />
            </Col>
          )}
        </Row>
        {isFullApplication === false && (
          <Row className="mt-4">
            <Col>
              <UseFormCheck
                control={control}
                controlName="isMultiPrequal"
                content="If I am not prequalified for the above product, try to prequalify me for other products."
              />
            </Col>
          </Row>
        )}
        {isFullApplication && (
          <>
            <Row className="mt-4">
              <Col xs={12}>
                <h4 className="font-bold">Home Ownership</h4>
              </Col>
              <Col xs={12} className="mt-3">
                <UseFormSelect
                  list={OWNERSHIP_LIST}
                  control={control}
                  name="xStatedInstallationHomeOwner"
                  placeholder="Who is the current owner of the installation address?"
                />
              </Col>
            </Row>

            <Row className="mt-4">
              <Col xs={12} className="mt-3">
                <h4 className="font-bold">Installation Address</h4>
              </Col>
              <Col xs={12} className="mt-3">
                <GoogleAutoComplete
                  placeholder="Street"
                  control={control}
                  name="xInstallationAddressLine"
                  onSelect={(address) => {
                    setValue("xInstallationAddressLine", address.street);
                    setValue("xInstallationCity", address.city);
                    setValue("xInstallationState", address.state);
                    setValue("xInstallationZipCode", address.zipCode);
                  }}
                />
              </Col>
              <Col xs={12} md={6} className="mt-3">
                <UseFormTextField
                  placeholder="City"
                  control={control}
                  name="xInstallationCity"
                />
              </Col>
              <Col xs={6} md={3} className="mt-3">
                <UseFormTextField
                  placeholder="State"
                  control={control}
                  name="xInstallationState"
                />
              </Col>
              <Col xs={6} md={3} className="mt-3">
                <UseFormTextField
                  placeholder="Zip Code"
                  control={control}
                  name="xInstallationZipCode"
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={12}>
                <h4 className="font-bold">Installation Address Residency</h4>
              </Col>
              <Col xs={12} className="mt-3">
                <UseFormSelect
                  list={INSTALLATION_RESIDENCY}
                  control={control}
                  name="installationResidency"
                  defaultValue={INSTALLATION_RESIDENCY[0].value}
                  placeholderValue={0}
                  placeholder="What is the residency type of the installation address?"
                />
              </Col>
            </Row>
          </>
        )}
        <div className="mt-5 d-flex justify-content-center mb-5">
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </div>
      </Col>
    </form>
  );
};

export default ModifyLoan;
