import NavLogo from "@assets/images/logo.svg";
import { useHistory, Link } from "react-router-dom";

import { resetPasswordSchema } from "@auth/schema";
import { UseFormTextField } from "@shared/components/TextInput";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import {
  resetPasswordAction,
  resetPasswordDataSelector,
} from "@store/slices/auth.slice";
import { EyeFill } from "react-bootstrap-icons";
import { EyeSlash } from "react-bootstrap-icons";

import "../style.scss";
import { useEffect, useState } from "react";
import { onSubmitError, useQuery } from "@shared/helpers/global.helper";

const ResetPassword = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const query = useQuery();
  const resetPasswordData = useAppSelector(resetPasswordDataSelector);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const code = query.get("code");
  const email = query.get("email");

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(resetPasswordSchema),
  });

  const onSubmit = (event: any) => {
    dispatch(
      resetPasswordAction({
        email: event.email,
        code: event.code,
        password: event.password,
        history,
      })
    );
  };

  useEffect(() => {
    if (code) {
      setValue("code", code);
    }
  }, [code, setValue]);

  useEffect(() => {
    if (!!resetPasswordData?.email) {
      setValue("email", resetPasswordData.email);
    }
  }, [dispatch, resetPasswordData?.email, setValue]);

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <div className="login-container">
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit, onSubmitError())}
        >
          <div className="row">
            <div className="col-xs-12 col-md-6  offset-md-3">
              <div className="d-flex justify-content-center">
                <img src={NavLogo} alt="Flic Financial" />
              </div>
              <div className="d-flex justify-content-center mt-4">
                <h3 className="h3 text-primary">Reset Password</h3>
              </div>

              <div className="d-flex justify-content-center mt-2">
                <h4 className="fw-normal text-muted">
                  Enter below details to reset your password.{" "}
                </h4>
              </div>
              <div className="mt-4">
                <UseFormTextField
                  placeholder={email || "Email"}
                  control={control}
                  name="email"
                />
              </div>
              <div className="mt-3">
                <UseFormTextField
                  placeholder="Code"
                  control={control}
                  name="code"
                  type="password"
                />
              </div>
              <div className="mt-3">
                <UseFormTextField
                  placeholder="Password"
                  type={showNewPassword ? "text" : "password"}
                  control={control}
                  name="password"
                >
                  <div
                    className="search-icon"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  >
                    {showNewPassword ? (
                      <EyeSlash size={20} />
                    ) : (
                      <EyeFill size={20} />
                    )}
                  </div>
                </UseFormTextField>
              </div>
              <div className="mt-3">
                <UseFormTextField
                  placeholder="Confirm Password"
                  type={showConfirmPassword ? "text" : "password"}
                  control={control}
                  name="confirmPassword"
                >
                  <div
                    className="search-icon"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <EyeSlash size={20} />
                    ) : (
                      <EyeFill size={20} />
                    )}
                  </div>
                </UseFormTextField>
              </div>

              <div className="d-flex flex-row justify-content-center mt-3 w-100">
                <Button variant="primary" className="w-100" type="submit">
                  Submit
                </Button>
              </div>
              <div className="d-flex justify-content-center mt-3 ">
                <Link to="/auth/login">
                  <a href="#/auth/login" className="text-primary">
                    {" "}
                    Login to your account
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
