import { ConditionalRender } from "@shared/components/ConditionalRender";
import NumberFormat from "@shared/components/NumberFormat";
import { NA } from "@shared/constants/app.constant";
import { getLoanDocStatusColor } from "@shared/helpers/documents.helper";
import {
  enableIssueLoanDocs,
  getProgramType,
} from "@shared/helpers/global.helper";
import { useAppSelector } from "@store/hooks";
import { appSelector, appUserSelector } from "@store/slices/app.slice";
import { Row, Col, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { SystemInfo } from "./SystemInfo";
import clsx from "clsx";
import { isPrequalApplication } from "@shared/helpers/application.helper";
import { IPortalApplication } from "@shared/interfaces/PortalApplicationTypes";

export const LoanDocuments = ({
  requestDetail,
  variant = "default",
  canBeModified,
}: {
  requestDetail: IPortalApplication | undefined;
  variant?: "default" | "new";
  canBeModified: boolean;
}) => {
  const appSlice = useAppSelector(appSelector);
  let { id } = useParams<{ id: string }>();
  const user = useAppSelector(appUserSelector);

  return (
    <div>
      <h3 className="h3 text-primary-dark mt-4">Loan Documents</h3>
      <hr className="bg-primary-dark height-2px opacity-1" />
      <Row className="mt-3">
        <Col xs={4} sm={4}>
          <h4 className="font-bold-800">Product</h4>
          <p>
            Product Name:{" "}
            <ConditionalRender
              value={
                requestDetail?.programTypeCode
                  ? getProgramType(requestDetail?.programTypeCode)
                  : undefined
              }
            />
          </p>
          <p>
            {isPrequalApplication(requestDetail)
              ? "Prequalified For"
              : "Amount Financed"}
            : <NumberFormat amount={requestDetail?.loanOffer?.amountFinanced} />
          </p>
        </Col>
        <Col xs={4} sm={4}>
          <SystemInfo requestDetail={requestDetail} />
        </Col>
        <Col xs={4} sm={4}>
          <h4 className="font-bold-800">Doc Status</h4>
          {appSlice.loading ? (
            <p>{NA}</p>
          ) : (
            <p
              className={clsx(
                requestDetail && getLoanDocStatusColor(requestDetail)
              )}
            >
              {requestDetail?.status?.loanDocStatus
                ? requestDetail?.status?.loanDocStatus
                : " Loan docs have not been generated."}
            </p>
          )}
        </Col>
        <Col xs={12} sm={12}>
          {requestDetail && enableIssueLoanDocs(requestDetail, user?.role) && (
            <div className="d-flex justify-content-center mt-3">
              <Link to={`/issue-docs/${id}`}>
                <Button>Issue Loan Docs</Button>
              </Link>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};
