import { Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { createUrl } from "@shared/helpers/global.helper";

const NoPermission = () => {
  return (
    <div className="mt-4">
      <h6 className="font-bold-800 text-center">{}</h6>
      <Col
        xs={12}
        sm={9}
        md={7}
        lg={5}
        className="mx-auto mt-5 d-flex align-items-center justify-content-start flex-column"
      >
        <h3 className="h3 text-danger mb-4">Permission denied</h3>
        <div className="d-flex mb-3">
          <h3>You do not have permission to view this!</h3>
        </div>
      </Col>
      <div className="text-center mt-4">
        <Link to={createUrl(`/pipelines`)}>
          <Button>Return to Pipeline</Button>
        </Link>
      </div>
    </div>
  );
};

export default NoPermission;
