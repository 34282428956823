import { UseFormTextField } from "@shared/components/TextInput";
import { Button, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { appService } from "@services/app.service";
import { toasterService } from "@services/toaster.service";
import { modifyUserSchema } from "@shared/schema/request";
import { Link, useHistory, useParams } from "react-router-dom";
import { UseFormSelect } from "@shared/components/UserFormSelect";
import {
  PRIVATE_PROGRAM_TYPE,
  PRODUCT_25_YEAR_3_99_INTEREST,
  PRODUCT_25_YEAR_4_99_INTEREST,
  PRODUCT_25_YEAR_6_99_INTEREST,
  PRODUCT_20_YEAR_RISK_BASED_PRICING,
  PRODUCT_2_YEAR_NO_PAYMENT_RISK_BASED_PRICING,
  PRODUCT_20_YEAR_2_49_INTEREST,
  PRODUCT_20_YEAR_2_99_INTEREST,
  PRODUCT_20_YEAR_5_99_INTEREST,
  PRODUCT_20_YEAR_7_49_INTEREST,
  PRODUCT_20_YEAR_8_99_INTEREST,
  PRODUCT_20_YEAR_1_49_INTEREST,
  PRODUCT_20_YEAR_1_99_INTEREST,
  PRODUCT_20_YEAR_3_99_INTEREST,
  PRODUCT_20_YEAR_6_99_INTEREST,
  PRODUCT_10_YEAR_8_99_INTEREST,
  PRODUCT_15_YEAR_8_99_INTEREST,
  PRODUCT_15_YEAR_7_99_INTEREST,
  PRODUCT_25_YEAR_3_74_INTEREST,
  PRODUCT_25_YEAR_4_74_INTEREST,
  PRODUCT_25_YEAR_5_24_INTEREST,
  USER_ROLES,
  MESSAGES,
} from "@shared/constants/app.constant";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { getRegionsAction, regionsSelector } from "@store/slices/region.slice";
import {
  appDealersSelector,
  appLendersSelector,
  appLoading,
  getDealersAction,
  getLendersAction,
} from "@store/slices/app.slice";
import { useEffect, useMemo, useState } from "react";
import {
  getUserAction,
  setUserData,
  userDataSelector,
} from "@store/slices/user.slice";
import {
  hasAccessToClientCredentials,
  onSubmitError,
} from "@shared/helpers/global.helper";
import { UseFormCheck } from "@shared/components/UserFormCheck";
import { EyeFill } from "react-bootstrap-icons";
import { EyeSlash, CheckCircle } from "react-bootstrap-icons";
import { environment } from "@env/environment";
import { UserRole } from "@shared/interfaces/User";
import CopyCredentials from "./copyCredentials";

const clientCredentialCryptographicKey =
  environment?.REACT_APP_CLIENT_CRED_SECRET ?? "";
const clientCredentialCryptographicIv =
  environment?.REACT_APP_CRYPTOGRAPHIC_IV ?? "";

const ModifyUser = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { id: userId } = useParams<{ id: string }>();
  const dealersData = useAppSelector(appDealersSelector);
  const userResponse = useAppSelector(userDataSelector);
  const lendersData = useAppSelector(appLendersSelector);
  const userData = userResponse?.user;
  const isLoading = useAppSelector(appLoading);

  const [clientIdCopied, setClientIdCopied] = useState(false);
  const [clientSecretCopied, setClientSecretCopied] = useState(false);
  const [clientCredentialKey, setClientCredentialKey] = useState(false);
  const [clientCredentialIv, setClientCredentialIv] = useState(false);

  const [displayClientCredentials, setDisplayClientCredentials] =
    useState(false);
  const [generateCredentialsLoading, setGenerateCredentialsLoading] =
    useState(false);
  const regionsData = useAppSelector(regionsSelector);
  const {
    control,
    handleSubmit,
    reset,
    formState,
    watch,
    setValue,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(modifyUserSchema),
  });
  const role = watch("role");
  const initialRole = watch("initialRole");
  const dealer = watch("dealer");

  const dealers = useMemo(
    () =>
      dealersData.map(({ internalClientId, name }) => ({
        text: name,
        value: internalClientId,
      })),
    [dealersData]
  );
  const regions = useMemo(
    () =>
      regionsData
        .filter((item) => item.dealer === dealer)
        .map(({ id, name }) => ({
          text: name,
          value: id,
        })),
    [regionsData, dealer]
  );

  const lenders = useMemo(
    () =>
      lendersData.map(({ label, value }) => ({
        text: label,
        value: String(value),
      })),
    [lendersData]
  );

  const isBusy = formState.isSubmitting;

  const resetFormData = () => {
    reset({
      username: "",
      role: "",
      employeeId: "",
      dealer: "",
      regionId: "",
      lenderId: "",
      [PRODUCT_20_YEAR_2_49_INTEREST]: false,
      [PRODUCT_20_YEAR_2_99_INTEREST]: false,
      [PRODUCT_20_YEAR_5_99_INTEREST]: false,
      [PRODUCT_20_YEAR_7_49_INTEREST]: false,
      [PRODUCT_20_YEAR_8_99_INTEREST]: false,
      [PRODUCT_25_YEAR_3_99_INTEREST]: false,
      [PRODUCT_25_YEAR_4_99_INTEREST]: false,
      [PRODUCT_25_YEAR_6_99_INTEREST]: false,
      [PRODUCT_10_YEAR_8_99_INTEREST]: false,
      [PRODUCT_15_YEAR_8_99_INTEREST]: false,
      [PRODUCT_20_YEAR_RISK_BASED_PRICING]: false,
      [PRODUCT_2_YEAR_NO_PAYMENT_RISK_BASED_PRICING]: false,
      [PRODUCT_20_YEAR_1_49_INTEREST]: false,
      [PRODUCT_20_YEAR_1_99_INTEREST]: false,
      [PRODUCT_20_YEAR_3_99_INTEREST]: false,
      [PRODUCT_20_YEAR_6_99_INTEREST]: false,
      [PRODUCT_15_YEAR_7_99_INTEREST]: false,
      [PRODUCT_25_YEAR_3_74_INTEREST]: false,
      [PRODUCT_25_YEAR_4_74_INTEREST]: false,
      [PRODUCT_25_YEAR_5_24_INTEREST]: false,
    });
  };

  const onSubmit = async (event: any) => {
    if (isBusy) {
      return;
    }
    const dealer = dealers.find(
      ({ value }) => String(value) === String(event.dealer)
    );
    const region = regions.find(
      ({ value }) => String(value) === String(event.regionId)
    );
    const isAdminOrFI =
      role === UserRole.providerAdmin || role === UserRole.financialInstitution;
    const productAccess: string[] = [];
    PRIVATE_PROGRAM_TYPE.forEach((element) => {
      event[element.id] === true && productAccess.push(element.id);
    });
    const response: any = await appService.modifyUser(userId, {
      employeeId: event.employeeId || undefined,
      dealer: isAdminOrFI ? null : String(dealer?.value),
      regionId: isAdminOrFI ? null : region?.value || null,

      username: event.username,
      role: event.role,
      lenderId:
        role === UserRole.financialInstitution ? Number(event.lenderId) : null,
      productAccess: isAdminOrFI ? null : productAccess,
    });
    if (response?.data?.isSuccess) {
      toasterService.success("User has been modified successfully");
      history.push("/users");
      resetFormData();
    }
  };

  useEffect(() => {
    dispatch(getDealersAction(null));
    dispatch(getRegionsAction(null));
    dispatch(getLendersAction(null));
    dispatch(getUserAction(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (
      role === UserRole.providerAdmin ||
      role === UserRole.financialInstitution
    ) {
      setValue(PRODUCT_20_YEAR_2_49_INTEREST, false);
      setValue(PRODUCT_20_YEAR_2_99_INTEREST, false);
      setValue(PRODUCT_20_YEAR_5_99_INTEREST, false);
      setValue(PRODUCT_20_YEAR_7_49_INTEREST, false);
      setValue(PRODUCT_20_YEAR_8_99_INTEREST, false);
      setValue(PRODUCT_25_YEAR_3_99_INTEREST, false);
      setValue(PRODUCT_25_YEAR_4_99_INTEREST, false);
      setValue(PRODUCT_25_YEAR_6_99_INTEREST, false);
      setValue(PRODUCT_10_YEAR_8_99_INTEREST, false);
      setValue(PRODUCT_15_YEAR_8_99_INTEREST, false);
      setValue(PRODUCT_20_YEAR_RISK_BASED_PRICING, false);
      setValue(PRODUCT_2_YEAR_NO_PAYMENT_RISK_BASED_PRICING, false);
      setValue(PRODUCT_20_YEAR_1_49_INTEREST, false);
      setValue(PRODUCT_20_YEAR_1_99_INTEREST, false);
      setValue(PRODUCT_20_YEAR_3_99_INTEREST, false);
      setValue(PRODUCT_20_YEAR_6_99_INTEREST, false);
      setValue(PRODUCT_15_YEAR_7_99_INTEREST, false);
      setValue(PRODUCT_25_YEAR_3_74_INTEREST, false);
      setValue(PRODUCT_25_YEAR_4_74_INTEREST, false);
      setValue(PRODUCT_25_YEAR_5_24_INTEREST, false);
    }
  }, [role, setValue]);

  useEffect(() => {
    if (userData) {
      // Make sure the role belongs to the specified roles
      const validRoles = USER_ROLES.map(({ value }) => value);
      const role = validRoles.includes(userData.role)
        ? userData.role
        : validRoles[0];
      reset({
        username: userData.username,
        role,
        initialRole: role,
        employeeId: userData.employeeId || userData.xEmployeeID || "",
        dealer: userData.dealer || "",
        // Setting regionId from useEffect based on Dealer and Region data
        lenderId: userData.lenderId ? String(userData.lenderId) : "",
        [PRODUCT_20_YEAR_2_49_INTEREST]:
          userData.accessManagement?.productAccess?.includes(
            PRODUCT_20_YEAR_2_49_INTEREST
          )
            ? true
            : false,
        [PRODUCT_20_YEAR_2_99_INTEREST]:
          userData.accessManagement?.productAccess?.includes(
            PRODUCT_20_YEAR_2_99_INTEREST
          )
            ? true
            : false,
        [PRODUCT_20_YEAR_5_99_INTEREST]:
          userData.accessManagement?.productAccess?.includes(
            PRODUCT_20_YEAR_5_99_INTEREST
          )
            ? true
            : false,
        [PRODUCT_20_YEAR_7_49_INTEREST]:
          userData.accessManagement?.productAccess?.includes(
            PRODUCT_20_YEAR_7_49_INTEREST
          )
            ? true
            : false,
        [PRODUCT_20_YEAR_8_99_INTEREST]:
          userData.accessManagement?.productAccess?.includes(
            PRODUCT_20_YEAR_8_99_INTEREST
          )
            ? true
            : false,
        [PRODUCT_25_YEAR_3_99_INTEREST]:
          userData.accessManagement?.productAccess?.includes(
            PRODUCT_25_YEAR_3_99_INTEREST
          )
            ? true
            : false,
        [PRODUCT_25_YEAR_4_99_INTEREST]:
          userData.accessManagement?.productAccess?.includes(
            PRODUCT_25_YEAR_4_99_INTEREST
          )
            ? true
            : false,
        [PRODUCT_25_YEAR_6_99_INTEREST]:
          userData.accessManagement?.productAccess?.includes(
            PRODUCT_25_YEAR_6_99_INTEREST
          )
            ? true
            : false,
        [PRODUCT_10_YEAR_8_99_INTEREST]:
          userData.accessManagement?.productAccess?.includes(
            PRODUCT_10_YEAR_8_99_INTEREST
          )
            ? true
            : false,
        [PRODUCT_15_YEAR_8_99_INTEREST]:
          userData.accessManagement?.productAccess?.includes(
            PRODUCT_15_YEAR_8_99_INTEREST
          )
            ? true
            : false,
        [PRODUCT_20_YEAR_RISK_BASED_PRICING]:
          userData.accessManagement?.productAccess?.includes(
            PRODUCT_20_YEAR_RISK_BASED_PRICING
          )
            ? true
            : false,
        [PRODUCT_2_YEAR_NO_PAYMENT_RISK_BASED_PRICING]:
          userData.accessManagement?.productAccess?.includes(
            PRODUCT_2_YEAR_NO_PAYMENT_RISK_BASED_PRICING
          )
            ? true
            : false,
        [PRODUCT_20_YEAR_1_49_INTEREST]:
          userData.accessManagement?.productAccess?.includes(
            PRODUCT_20_YEAR_1_49_INTEREST
          )
            ? true
            : false,
        [PRODUCT_20_YEAR_1_99_INTEREST]:
          userData.accessManagement?.productAccess?.includes(
            PRODUCT_20_YEAR_1_99_INTEREST
          )
            ? true
            : false,
        [PRODUCT_20_YEAR_3_99_INTEREST]:
          userData.accessManagement?.productAccess?.includes(
            PRODUCT_20_YEAR_3_99_INTEREST
          )
            ? true
            : false,
        [PRODUCT_20_YEAR_6_99_INTEREST]:
          userData.accessManagement?.productAccess?.includes(
            PRODUCT_20_YEAR_6_99_INTEREST
          )
            ? true
            : false,
        [PRODUCT_15_YEAR_7_99_INTEREST]:
          userData.accessManagement?.productAccess?.includes(
            PRODUCT_15_YEAR_7_99_INTEREST
          )
            ? true
            : false,
        [PRODUCT_25_YEAR_3_74_INTEREST]:
          userData.accessManagement?.productAccess?.includes(
            PRODUCT_25_YEAR_3_74_INTEREST
          )
            ? true
            : false,
        [PRODUCT_25_YEAR_4_74_INTEREST]:
          userData.accessManagement?.productAccess?.includes(
            PRODUCT_25_YEAR_4_74_INTEREST
          )
            ? true
            : false,
        [PRODUCT_25_YEAR_5_24_INTEREST]:
          userData.accessManagement?.productAccess?.includes(
            PRODUCT_25_YEAR_5_24_INTEREST
          )
            ? true
            : false,
      });
    } else {
      resetFormData();
    }
    // Below is required to avoid the infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, userData]);

  useEffect(() => {
    return () => {
      // Reset user data on leave page
      dispatch(setUserData(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      userData?.regionId &&
      regions.length &&
      regions.some((item) => item.value === userData?.regionId)
    ) {
      setValue("regionId", userData.regionId);
    } else {
      setValue("regionId", "");
    }
  }, [regions, setValue, userData?.regionId]);

  useEffect(() => {
    dealer && !regions.length
      ? setError("regionId", {
          message: MESSAGES.NO_REGION_IN_DEALER,
        })
      : clearErrors("regionId");
  }, [clearErrors, dealer, regions.length, setError]);

  const hasClientCredentials = userData?.clientId && userData?.clientSecret;

  const handleCopyText = (
    field: "clientId" | "clientSecret" | "cryptographicKey" | "cryptographicIv"
  ) => {
    const copyText =
      field === "clientId"
        ? userData?.clientId ?? ""
        : field === "clientSecret"
        ? userData?.clientSecret ?? ""
        : field === "cryptographicKey"
        ? clientCredentialCryptographicKey ?? ""
        : field === "cryptographicIv"
        ? clientCredentialCryptographicIv ?? ""
        : "";
    navigator.clipboard.writeText(copyText);
    if (field === "clientId") {
      setClientIdCopied(true);
      setTimeout(() => {
        setClientIdCopied(false);
      }, 2200);
    }
    if (field === "clientSecret") {
      setClientSecretCopied(true);
      setTimeout(() => {
        setClientSecretCopied(false);
      }, 2200);
    }
    if (field === "cryptographicKey") {
      setClientCredentialKey(true);
      setTimeout(() => {
        setClientCredentialKey(false);
      }, 2200);
    }
    if (field === "cryptographicIv") {
      setClientCredentialIv(true);
      setTimeout(() => {
        setClientCredentialIv(false);
      }, 2200);
    }
  };

  const handleGenerateClientCredentials = async () => {
    try {
      setGenerateCredentialsLoading(true);
      await appService.generateClientCredentials(userId);
      dispatch(getUserAction(userId));
      toasterService.success("Client Credentials are generated successfully!");
    } catch (error) {
      console.error(error);
    } finally {
      setGenerateCredentialsLoading(false);
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-center">
      <div className="login-container form-container">
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit, onSubmitError())}
        >
          <div className="row">
            <div className="col-xs-12 col-md-8 offset-md-2">
              <div className="d-flex justify-content mt-3">
                <h3 className="h3 flex-grow-1">Modify User</h3>
              </div>
              <h5>User Info</h5>
              <Row className="mb-3">
                <Col>
                  <UseFormTextField
                    placeholder="Full Name"
                    control={control}
                    name="username"
                  />
                </Col>
                <Col>
                  <UseFormSelect
                    list={USER_ROLES}
                    control={control}
                    name="role"
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <UseFormTextField
                    placeholder="Employee ID"
                    control={control}
                    name="employeeId"
                  />
                </Col>
                <Col>
                  {role === UserRole.financialInstitution && (
                    <UseFormSelect
                      list={lenders}
                      control={control}
                      name="lenderId"
                      placeholder="Select Lender"
                      placeholderValue={""}
                    />
                  )}
                </Col>
              </Row>
              {role !== UserRole.providerAdmin &&
                role !== UserRole.financialInstitution && (
                  <>
                    <h5>Installer Info</h5>
                    <Row className="mb-3">
                      <Col>
                        <UseFormSelect
                          list={dealers}
                          control={control}
                          name="dealer"
                          placeholder="Select Dealer"
                          placeholderValue={""}
                        />
                      </Col>
                      <Col>
                        <UseFormSelect
                          list={regions}
                          control={control}
                          name="regionId"
                          placeholder="Select Region"
                          placeholderValue={""}
                          disabled={!regions.length}
                        />
                      </Col>
                    </Row>
                    <div className="text-end">
                      <Link to="/add-region" className="text-primary">
                        Add Region
                      </Link>
                    </div>
                  </>
                )}

              {hasAccessToClientCredentials(initialRole) && (
                <Row className="mt-3 modify-user-client-credentials">
                  <Col xs={12}>
                    <div className="d-flex align-items-center mb-2">
                      <h5 className="mb-0">Client Credentials</h5>
                      {hasClientCredentials && (
                        <div
                          className="cursor-pointer"
                          onClick={() =>
                            setDisplayClientCredentials(
                              !displayClientCredentials
                            )
                          }
                        >
                          {displayClientCredentials ? (
                            <EyeSlash size={18} />
                          ) : (
                            <EyeFill size={18} />
                          )}
                        </div>
                      )}
                    </div>
                  </Col>
                  {hasClientCredentials ? (
                    <>
                      <Col xs={12} className="mb-3">
                        <p className="mb-0">Client ID</p>
                        <div className="d-flex justify-content-between border border-secondary align-items-center p-2 copy-text-container">
                          <p className="mb-0">
                            {displayClientCredentials
                              ? userData?.clientId
                              : "✻✻✻✻✻✻✻✻"}
                          </p>
                          {clientIdCopied ? (
                            <CheckCircle size={16} className="" />
                          ) : (
                            <CopyCredentials
                              handleCopyText={() => handleCopyText("clientId")}
                            />
                          )}
                        </div>
                      </Col>
                      <Col xs={12} className="mb-3">
                        <p className="mb-0">Client Secret</p>
                        <div className="d-flex justify-content-between border border-secondary align-items-center p-2 copy-text-container">
                          <p className="mb-0">
                            {displayClientCredentials
                              ? userData?.clientSecret
                              : "✻✻✻✻✻✻✻✻"}
                          </p>
                          {clientSecretCopied ? (
                            <CheckCircle size={16} className="" />
                          ) : (
                            <CopyCredentials
                              handleCopyText={() =>
                                handleCopyText("clientSecret")
                              }
                            />
                          )}
                        </div>
                      </Col>
                      <Col xs={12} className="mb-3">
                        <p className="mb-0">Cryptographic Key</p>
                        <div className="d-flex justify-content-between border border-secondary align-items-center p-2 copy-text-container">
                          <p className="mb-0">
                            {displayClientCredentials
                              ? clientCredentialCryptographicKey
                              : "✻✻✻✻✻✻✻✻"}
                          </p>
                          {clientCredentialKey ? (
                            <CheckCircle size={16} className="" />
                          ) : (
                            <CopyCredentials
                              handleCopyText={() =>
                                handleCopyText("cryptographicKey")
                              }
                            />
                          )}
                        </div>
                      </Col>
                      <Col xs={12} className="mb-3">
                        <p className="mb-0">Cryptographic IV</p>
                        <div className="d-flex justify-content-between border border-secondary align-items-center p-2 copy-text-container">
                          <p className="mb-0">
                            {displayClientCredentials
                              ? clientCredentialCryptographicIv
                              : "✻✻✻✻✻✻✻✻"}
                          </p>
                          {clientCredentialIv ? (
                            <CheckCircle size={16} className="" />
                          ) : (
                            <CopyCredentials
                              handleCopyText={() =>
                                handleCopyText("cryptographicIv")
                              }
                            />
                          )}
                        </div>
                      </Col>
                      <Col xs={12}>
                        <p className="mb-0">
                          <strong>Cryptographic Algorithm:</strong> AES-256-CBC
                        </p>
                      </Col>
                    </>
                  ) : (
                    <div className="d-flex flex-md-row flex-column justify-content-between align-items-center mt-2">
                      <p className="display-text">
                        No client ID or client secret associated.
                      </p>
                      <Button
                        disabled={generateCredentialsLoading || isLoading}
                        onClick={handleGenerateClientCredentials}
                        variant="outline-primary btn-sm"
                      >
                        Generate Client Credentials
                      </Button>
                    </div>
                  )}
                </Row>
              )}
              {role !== UserRole.providerAdmin &&
                role !== UserRole.financialInstitution && (
                  <div className="mt-5">
                    <h5>Product Access</h5>
                    <Row className="">
                      <Col xs={12}>
                        {PRIVATE_PROGRAM_TYPE.map((product) => (
                          <div className="my-3" key={product.value}>
                            <UseFormCheck
                              content={product.text}
                              control={control}
                              elementName={product.text}
                              controlName={product.id}
                            />
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </div>
                )}
              <div className="d-flex flex-row justify-content-center mt-3 w-100 form-buttons">
                <Button
                  variant="outline-primary"
                  type="button"
                  onClick={() => history.goBack()}
                >
                  Exit
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={formState.isSubmitting}
                >
                  Modify User
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModifyUser;
