import {
  ISungageDisclosures,
  ISungageEquipmentResponse,
} from "@shared/interfaces/SungageTypes";
import { networkService } from "./network-service";

class SungageService {
  async getSungageDisclosures() {
    const { data } = await networkService.get<{
      data: ISungageDisclosures;
      errors: any;
      message: string;
    }>("/application/sungage-disclosures");

    return data;
  }

  async getSungageEquipment() {
    const { data } = await networkService.get<{
      data: ISungageEquipmentResponse;
      errors: any;
      message: string;
    }>("/application/sungage-equipment");

    return data;
  }
}

export const sungageService = new SungageService();
