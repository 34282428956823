import { Row, Col, Button } from "react-bootstrap";
import { UseFormTextField } from "@shared/components/TextInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch } from "@store/hooks";
import { useHistory, useParams } from "react-router-dom";
import { onSubmitError } from "@shared/helpers/global.helper";
import { updatePrequalForMultiPrequalAction } from "@store/slices/loan.slice";
import { useEffect, useState } from "react";
import { modifyPreQualSchema } from "@shared/schema/request";
import { loanService } from "@services/loan.service";
import { MONTHS_ARRAY, YEARS_ARRAY } from "@shared/constants/app.constant";
import { UserFormCurrencyField } from "@shared/components/UserFormCurrencyField";
import { GoogleAutoComplete } from "@shared/components/GoogleAutoComplete";
import { UseFormSelect } from "@shared/components/UserFormSelect";
import ApplicationGuard from "@shared/components/ApplicationGuard";
import {
  IPortalApplication,
  ProductLos,
} from "@shared/interfaces/PortalApplicationTypes";

const ModifyApplicant = () => {
  const dispatch = useAppDispatch();
  let { id } = useParams<{ id: string }>();
  const [requestDetail, setRequestDetail] = useState<IPortalApplication>();
  const history = useHistory();
  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(modifyPreQualSchema),
  });

  const onSubmit = (event: any) => {
    if (!requestDetail || !requestDetail?.losAppNumber) {
      return;
    }
    const applicantAddresses = [];
    const currentAddress = {
      ownershipType: {
        name: "Other",
        label: "Other",
        value: 4,
      },
      addressType: {
        name: "Primary",
        label: "Primary",
        value: 1,
      },
      addressFormat: {
        name: "Postal Standard",
        label: "Postal Standard",
        value: 5,
      },
      postalCode: event.postalCode,
      addressLine1: event.addressLine1,
      city: event.city,
      state: event.state,
      yearsAtResidence: Number(event.yearsAtResidence),
      monthsAtResidence: Number(event.monthsAtResidence),
    };
    applicantAddresses.push(currentAddress);

    const employment = {
      employmentType: {
        name: "Primary",
        label: "Primary",
        value: 1,
      },
      grossIncome: Number(event.grossIncome),
      payPeriod: 5,
      employmentStatus: {
        name: "Undefined",
        label: "Undefined",
        value: 0,
      },
    };

    const primaryApplicantModel = {
      jsonType: "Applicant",
      applicantType: {
        name: "primary",
        label: "primary",
        value: 1,
      },
      lastName: event.lastName,
      firstName: event.firstName,
      addresses: applicantAddresses,
      employments: [employment],
      xInstallationHomeOwner: 1,

      internalClientId: requestDetail?.losInternalClientId,
      applicationId: requestDetail?.losApplicationId,
      applicantId: requestDetail?.primaryApplicant?.applicantId,
    };

    dispatch(
      updatePrequalForMultiPrequalAction({
        appNumber: id,
        history,
        loanInfo: {
          losAppNumber: requestDetail.losAppNumber,
          productLos: ProductLos.DL4,
          primaryApplicantModel,
        },
        path: "/pre-qualification/confirmation",
        isMultiPrequal: false,
      })
    );
  };

  useEffect(() => {
    const getRequestData = async (id: string) => {
      const response = await loanService.getRequestById(id);
      let applicant = response?.data?.data?.primaryApplicant;
      setRequestDetail(response?.data?.data);
      const primaryAddress = applicant?.currentAddress;
      const mailingAddress = applicant?.mailingAddress;
      reset({
        firstName: applicant?.firstName,
        lastName: applicant?.lastName,
        isUSCitizen: applicant?.isUSCitizen ? "Yes" : "No",
        grossIncome: applicant?.employment?.grossIncome,
        xDiffAddressThanApplicant:
          applicant?.differentAddressThanPrimaryApplicant ? "Yes" : "No",

        addressLine1: primaryAddress?.addressLine,
        city: primaryAddress?.city,
        state: primaryAddress?.state,
        postalCode: primaryAddress?.postalCode,
        yearsAtResidence: primaryAddress?.yearsAtResidence,
        monthsAtResidence: primaryAddress?.monthsAtResidence,

        mailingAddressLine1: mailingAddress
          ? mailingAddress?.mailingAddress
          : "",
        mailingCity: mailingAddress ? mailingAddress?.mailingCity : "",
        mailingState: mailingAddress ? mailingAddress?.mailingState : "",
        mailingZipCode: mailingAddress ? mailingAddress?.mailingPostalCode : "",
      });
    };
    getRequestData(id);
  }, [id, reset]);

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit, onSubmitError())}
    >
      <ApplicationGuard application={requestDetail} />

      <Col xs={12} md={10} lg={7} className="mx-auto mt-3 fullapp-request">
        <h3 className="h3 text-primary">Modify Applicant</h3>
        <hr className="text-primary height-2px" />

        <Row className="mt-4">
          <Col xs={12}>
            <h4 className="font-bold">Applicant Information</h4>
          </Col>
          <Col xs={12} md={6} className="mt-3 mt-md-0">
            <UseFormTextField
              placeholder="First Name"
              control={control}
              name="firstName"
            />
          </Col>
          <Col xs={12} md={6} className="mt-3 mt-md-0">
            <UseFormTextField
              placeholder="Last Name"
              control={control}
              name="lastName"
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs={12}>
            <h4 className="font-bold">Your Current Address</h4>
          </Col>
          <Col xs={12}>
            <GoogleAutoComplete
              placeholder="Street"
              control={control}
              name="addressLine1"
              onSelect={(address) => {
                setValue("addressLine1", address.street);
                setValue("city", address.city);
                setValue("state", address.state);
                setValue("postalCode", address.zipCode);
              }}
            />
          </Col>
          <Col xs={12} md={7} className="mt-3">
            <UseFormTextField
              placeholder="City"
              control={control}
              name="city"
            />
          </Col>
          <Col xs={6} md={2} className="mt-3">
            <UseFormTextField
              placeholder="State"
              control={control}
              name="state"
            />
          </Col>
          <Col xs={6} md={3} className="mt-3">
            <UseFormTextField
              placeholder="Zip Code"
              control={control}
              name="postalCode"
            />
          </Col>
        </Row>

        <Row className="mt-3 d-flex align-items-center">
          <Col xs={4} sm={4} lg={3}>
            <label className="form-check-label">
              Time with Current Residence:
            </label>
          </Col>
          <Col xs={6} sm={4} lg={3}>
            <UseFormSelect
              list={YEARS_ARRAY}
              control={control}
              name="yearsAtResidence"
              placeholder="Years"
              placeholderValue={""}
            />
          </Col>
          <Col xs={6} sm={4} lg={3} className="mt-0 mt-sm-0">
            <UseFormSelect
              list={MONTHS_ARRAY}
              control={control}
              name="monthsAtResidence"
              placeholder="Months"
              placeholderValue={""}
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col xs={12} sm={6}>
            <h4 className="font-bold"> Gross Annual Income</h4>
            <UserFormCurrencyField
              placeholder="Gross Annual Income"
              control={control}
              name="grossIncome"
              disabled
            />
          </Col>
          <p className="mt-2">
            Please enter annual income, which may include any income that is
            verifiable and reported to the Government, such as employment
            income, social security, pensions, etc.
          </p>
        </Row>
        <div className="mt-5 d-flex justify-content-center mb-5">
          <Button variant="primary" type="submit">
            Submit Request
          </Button>
        </div>
      </Col>
    </form>
  );
};

export default ModifyApplicant;
