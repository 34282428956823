import { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { UseFormTextField } from "@shared/components/TextInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { UseFormSelect } from "@shared/components/UserFormSelect";
import { UseFormCheck } from "@shared/components/UserFormCheck";
import { UseFormDatePicker } from "@shared/components/UserFormDatePicker";
import {
  CITIZENSHIP,
  EMPLOYMENT_STATUS_LIST,
  MOBILE_MASK,
  MONTHS_ARRAY,
  SSN_MASK,
  TIME_ON_JOB_TEXT,
  YEARS_ARRAY,
} from "@shared/constants/app.constant";
import { MyInputMask } from "@shared/components/MyInputMask";
import { useHistory, useParams } from "react-router-dom";
import { loanService } from "@services/loan.service";
import {
  maskMobile,
  removeSpaces,
  unmaskMobile,
  useQuery,
  onSubmitError,
} from "@shared/helpers/global.helper";
import { addApplicantAction } from "@store/slices/loan.slice";
import { newAppAddCoBorrowerSchema } from "@shared/schema/request";
import { UserFormCurrencyField } from "@shared/components/UserFormCurrencyField";
import {
  ApplicantType,
  ApplicationType,
} from "@shared/enums/ApplicationStatus";
import UserConsent from "@shared/components/UserConsent";
import ApplicationGuard from "@shared/components/ApplicationGuard";
import { GoogleAutoComplete } from "@shared/components/GoogleAutoComplete";
import { UseFormRadio } from "@shared/components/UserFormRadio";
import { formatDateForDl4Submission } from "@utils/date";
import { IPortalApplication } from "@shared/interfaces/PortalApplicationTypes";
import {
  getEmployerNameForRequestPayloadToDl4,
  getXPrequalProducts,
} from "@shared/helpers/application.helper";
import { appUserSelector } from "@store/slices/app.slice";
import { IXPrequalProductsTypeValues } from "@shared/interfaces/Application";
import { EmploymentStatus } from "@shared/enums/EmploymentStatus";

const CoBorrower = () => {
  const dispatch = useAppDispatch();
  let { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [requestDetail, setRequestDetail] = useState<IPortalApplication>();
  const [type, setType] = useState<any>("");
  let query = useQuery();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(newAppAddCoBorrowerSchema),
  });
  const user = useAppSelector(appUserSelector);

  const onSubmit = (event: any) => {
    if (!requestDetail?.programTypeCode) {
      return;
    }
    const primaryApplicantPrimaryAddress =
      requestDetail?.primaryApplicant?.currentAddress;

    const employment = {
      employmentType: {
        name: "Primary",
        label: "Primary",
        value: 1,
      },
      grossIncome: Number(event.coBorrowerGrossIncome),
      employerName: getEmployerNameForRequestPayloadToDl4({
        employmentStatus: event?.coBorrowerEmploymentStatus,
        employerName: event?.coBorrowerEmployerName || "",
      }),
      monthsEmployed: event.coBorrowerMonths
        ? Number(event.coBorrowerMonths)
        : 0,
      yearsEmployed: event.coBorrowerYear ? Number(event.coBorrowerYear) : 0,
      payPeriod: 5,
      employmentStatus: {
        name: "Undefined",
        label: "Undefined",
        value: 0,
      },
    };

    let coBorrowerAddress = [];
    if (event.xDiffAddressThanApplicant === "No") {
      coBorrowerAddress.push({
        ownershipType: {
          name: "Other",
          label: "Other",
          value: 4,
        },
        addressType: {
          name: "Primary",
          label: "Primary",
          value: 1,
        },
        addressFormat: {
          name: "Postal Standard",
          label: "Postal Standard",
          value: 5,
        },
        postalCode: event.coBorrowerPostalCode,
        addressLine1: event.coBorrowerAddressLine1,
        city: event.coBorrowerCity,
        state: event.coBorrowerState,
        yearsAtResidence: Number(event.coBorrowerYearsAtResidence),
        monthsAtResidence: Number(event.coBorrowerMonthsAtResidence),
      });
    } else {
      coBorrowerAddress.push({
        ownershipType: {
          name: "Other",
          label: "Other",
          value: 4,
        },
        addressType: {
          name: "Primary",
          label: "Primary",
          value: 1,
        },
        addressFormat: {
          name: "Postal Standard",
          label: "Postal Standard",
          value: 5,
        },
        postalCode: primaryApplicantPrimaryAddress?.postalCode,
        addressLine1: primaryApplicantPrimaryAddress?.addressLine,
        city: primaryApplicantPrimaryAddress?.city,
        state: primaryApplicantPrimaryAddress?.state,
        yearsAtResidence: Number(
          primaryApplicantPrimaryAddress?.yearsAtResidence
        ),
        monthsAtResidence: Number(
          primaryApplicantPrimaryAddress?.monthsAtResidence
        ),
      });
    }

    if (event.isCoBorrowerMailingAddressDifferrent) {
      coBorrowerAddress.push({
        ownershipType: {
          name: "Other",
          label: "Other",
          value: 4,
        },
        addressType: {
          name: "Mailing",
          label: "Mailing",
          value: 3,
        },
        addressFormat: {
          name: "Postal Standard",
          label: "Postal Standard",
          value: 5,
        },
        postalCode: event.coBorrowerMailingZipCode,
        addressLine1: event.coBorrowerMailingAddressLine,
        city: event.coBorrowerMailingCity,
        state: event.coBorrowerMailingState,
      });
    }

    const model = {
      content: {
        jsonType: "Applicant",
        applicantType: {
          name: "coBorrower",
          label: "Co-Borrower",
          value: 2,
        },
        lastName: event.lastName,
        firstName: event.firstName,
        ...(type !== "edit" && {
          // As we will be appending values from backend for edit
          SSN: removeSpaces(event.SSN),
          dateOfBirth: formatDateForDl4Submission(event.dateOfBirth),
        }),
        email: event.email,
        isUSCitizen: event.isUSCitizen === "Yes" ? true : false,
        xIsPermanentResident:
          event.isUSCitizen === "Yes"
            ? null
            : event.xIsPermanentResident === "Yes"
            ? true
            : false,

        mobilePhone: unmaskMobile(event.mobilePhone),
        addresses: coBorrowerAddress,
        xDiffAddressThanApplicant:
          event.xDiffAddressThanApplicant === "Yes" ? false : true,
        employments: [employment],
        xInstallationHomeOwner: 1,

        ...(type === "edit" && {
          internalClientId: requestDetail?.losInternalClientId,
        }),
        ...(type === "edit" && {
          applicationId: requestDetail?.losApplicationId,
        }),
        ...(type === "edit" && {
          applicantId: requestDetail?.coApplicant?.applicantId,
        }),
      },
      redecisionRequested: 0,
    };

    const { xPrequalProductsSelected } = getXPrequalProducts(
      user,
      requestDetail?.programTypeCode?.toString()
    );
    const productInfo: IXPrequalProductsTypeValues | undefined =
      xPrequalProductsSelected?.[0];

    const loanModel = {
      xProgramTypeName: productInfo?.xProgramTypeName,
      decisionProcessPrefs: {
        defaultCarletonDATFileName: productInfo?.defaultCarletonDATFileName,
      },
      xLender: productInfo?.xLender,
      xInstallationAddressLine: requestDetail?.installationAddress?.addressLine,
      xInstallationCity: requestDetail?.installationAddress?.city,
      xInstallationState: requestDetail?.installationAddress?.state,
      xInstallationZipCode: requestDetail?.installationAddress?.zipCode,
      xInstalationAddressSame:
        !!!requestDetail?.installationAddress?.isInstallationAddressDifferent,
      xStatedInstallationHomeOwner:
        requestDetail?.installationAddress?.installationHomeOwner,
      installationResidency:
        requestDetail?.installationAddress?.installationResidency,
      loanInformation: {
        loanVersions: [
          {
            Prequal: false,
            totalCashPrice: requestDetail?.loanOffer?.amountFinanced,
            requestedTerm: productInfo?.requestedTerm,
            firstPaymentDateStandard: productInfo?.firstPaymentDateStandard,
            contractDateStandard: productInfo?.contractDateStandard,
            userRate: productInfo?.userRate,
            xRiskBasedRateTable: productInfo?.xRisckBasedIntialRateTable,
            xRiskBasedInitialRateTable: productInfo?.xRisckBasedIntialRateTable,
            commonLoanInfo: {
              xprogramtype: requestDetail?.programTypeCode,
              xInstallationAddressLine:
                requestDetail?.installationAddress?.addressLine,
              xInstallationCity: requestDetail?.installationAddress?.city,
              xInstallationState: requestDetail?.installationAddress?.state,
              xInstallationZipCode: requestDetail?.installationAddress?.zipCode,
              xInstalationAddressSame:
                !!!requestDetail?.installationAddress
                  ?.isInstallationAddressDifferent,
              xStatedInstallationHomeOwner:
                requestDetail?.installationAddress?.installationHomeOwner,
              installationResidency:
                requestDetail?.installationAddress?.installationResidency,
              xinitialTerm: productInfo?.xinitialTerm,
              xAmortizationTerm: productInfo?.xAmortizationTerm,
              xFinalPmtDiff: productInfo?.xFinalPmtDiff,
              xMembershipFee: productInfo?.xMembershipFee,
              xFlicFlatFee: productInfo?.xFlicFlatFee,
              xFlicPlatformFeePercent: productInfo?.xFlicPlatformFeePrecent,
              xSellersPointsPercent: productInfo?.xSellerPointsPercent,
              xInitialRate: productInfo?.xInitialRate,
              xFinalRate: productInfo?.xFinalRate,
            },
          },
        ],
      },
    };

    dispatch(
      addApplicantAction({
        model,
        loanInfo: loanModel,
        appNumber: id,
        history,
        path: "/new-application/confirmation",
        isEdit: type === "edit",
      })
    );
  };

  useEffect(() => {
    const getRequestData = async (id: string) => {
      const param = query.get("type");

      const response = await loanService.getRequestById(id);
      setRequestDetail(response?.data?.data);

      const applicant = response?.data?.data?.coApplicant;
      const coBorrowerAddress = applicant?.currentAddress;

      const primaryApplicant = response?.data?.data?.primaryApplicant;

      if (param !== "edit") {
        // For Adding new CoBorrower
        reset({
          isModify: false,
          primaryApplicantEmail: primaryApplicant?.email || "",
          xDiffAddressThanApplicant: "Yes",
        });
        return;
      }
      setType(param);

      reset({
        isModify: true,
        firstName: applicant?.firstName,
        lastName: applicant?.lastName,
        email: applicant?.email,
        mobilePhone: maskMobile(applicant?.mobilePhone || ""),
        isUSCitizen: applicant?.isUSCitizen ? "Yes" : "No",
        ...(typeof applicant?.isUSCitizen == "boolean" &&
          !applicant?.isUSCitizen && {
            xIsPermanentResident: applicant.isPermanentResident ? "Yes" : "No",
          }),
        coBorrowerGrossIncome: applicant?.employment?.grossIncome,
        xDiffAddressThanApplicant:
          applicant?.differentAddressThanPrimaryApplicant ? "No" : "Yes",
        coBorrowerEmployerName:
          applicant?.employment?.employmentStatus === EmploymentStatus.EMPLOYED
            ? applicant?.employment?.employerName
            : "",

        coBorrowerYear: Number(applicant?.employment?.yearsEmployed),
        coBorrowerMonths: Number(applicant?.employment?.monthsEmployed),
        primaryApplicantEmail: primaryApplicant?.email,
        coBorrowerAddressLine1: applicant?.differentAddressThanPrimaryApplicant
          ? coBorrowerAddress?.addressLine
          : "",
        coBorrowerCity: applicant?.differentAddressThanPrimaryApplicant
          ? coBorrowerAddress?.city
          : "",
        coBorrowerState: applicant?.differentAddressThanPrimaryApplicant
          ? coBorrowerAddress?.state
          : "",
        coBorrowerPostalCode: applicant?.differentAddressThanPrimaryApplicant
          ? coBorrowerAddress?.postalCode
          : "",
        coBorrowerYearsAtResidence:
          applicant?.differentAddressThanPrimaryApplicant
            ? coBorrowerAddress?.yearsAtResidence
            : "",
        coBorrowerMonthsAtResidence:
          applicant?.differentAddressThanPrimaryApplicant
            ? coBorrowerAddress?.monthsAtResidence
            : "",
        isCoBorrowerMailingAddressDifferrent:
          applicant?.myMailingAddressIsDifferentThanCurrentAddress,
        coBorrowerMailingAddressLine: applicant?.mailingAddress?.mailingAddress,
        coBorrowerMailingCity: applicant?.mailingAddress?.mailingCity,
        coBorrowerMailingState: applicant?.mailingAddress?.mailingState,
        coBorrowerMailingZipCode: applicant?.mailingAddress?.mailingPostalCode,
        coBorrowerEmploymentStatus: applicant?.employment?.employmentStatus,
        // coBorrowerTitle: '' TODO: Pending for sungage integration
      });
    };
    getRequestData(id);
  }, [id, query, reset]);

  const xDiffAddressThanApplicant = watch("xDiffAddressThanApplicant");
  const isUSCitizen = watch("isUSCitizen");
  const isCoBorrowerMailingAddressDifferrent = watch(
    "isCoBorrowerMailingAddressDifferrent"
  );
  const isSungageProduct =
    requestDetail?.programTypeCode?.toString() === "1001";

  const coBorrowerEmploymentStatus = Number(
    watch("coBorrowerEmploymentStatus")
  );

  const isSecondaryEmployed =
    coBorrowerEmploymentStatus === EmploymentStatus.EMPLOYED ||
    (isSungageProduct &&
      coBorrowerEmploymentStatus === EmploymentStatus.SELF_EMPLOYED);

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit, onSubmitError())}
    >
      <ApplicationGuard application={requestDetail} />
      <Col xs={12} md={10} lg={7} className="mx-auto mt-3 fullapp-request">
        <h3 className="h3 text-primary">
          {type === "edit" ? "Modify" : "Add"} CoBorrower
        </h3>
        <hr className="text-primary height-2px" />
        <Row className="mt-4">
          <Col xs={12}>
            <h4 className="font-bold">Applicant Information</h4>
          </Col>
          <Col xs={12} md={6} className="mt-3">
            <UseFormTextField
              placeholder="First Name"
              control={control}
              name="firstName"
            />
          </Col>
          <Col xs={12} md={6} className="mt-3">
            <UseFormTextField
              placeholder="Last Name"
              control={control}
              name="lastName"
            />
          </Col>
          {type !== "edit" && (
            <>
              <Col xs={12} md={6} className="mt-3">
                <MyInputMask
                  control={control}
                  mask={SSN_MASK}
                  name="SSN"
                  placeholder="SSN"
                />
              </Col>
              <Col xs={12} md={6} className="mt-3">
                <UseFormDatePicker
                  placeholder="Date of Birth"
                  maxDate={new Date()}
                  control={control}
                  controlName="dateOfBirth"
                />
              </Col>
            </>
          )}
          <Col xs={12} md={6} className="mt-3 ">
            <UseFormTextField
              placeholder="Email"
              control={control}
              name="email"
            />
          </Col>
          <Col xs={12} md={6} className="mt-3 ">
            <MyInputMask
              control={control}
              defaultValue={""}
              name="mobilePhone"
              mask={MOBILE_MASK}
              placeholder="Mobile Phone"
            />
          </Col>
          <Col xs={12} md={6} className="mt-3">
            <UseFormSelect
              list={CITIZENSHIP}
              control={control}
              name="isUSCitizen"
              placeholder="Are you a US citizen"
            />
          </Col>
          {isUSCitizen === "No" && (
            <Col xs={12} md={6} className="mt-3">
              <UseFormSelect
                list={CITIZENSHIP}
                control={control}
                name="xIsPermanentResident"
                placeholder="Are you a Permanent Resident?"
              />
            </Col>
          )}
        </Row>
        {typeof xDiffAddressThanApplicant !== "undefined" && (
          <Row>
            <Col className="mt-4">
              <h4 className="font-bold mr-4 mb-0">Co-Residency </h4>
              <h4 className="fw-normal">
                Does coborrower reside at the same residence as the primary
                borrower?{" "}
              </h4>
              <div className="d-flex flex-column">
                <UseFormRadio
                  control={control}
                  elementName="co-residency"
                  controlName="xDiffAddressThanApplicant"
                  content="Yes"
                  checked={xDiffAddressThanApplicant === "Yes" ? true : false}
                />
                <UseFormRadio
                  control={control}
                  elementName="co-residency"
                  controlName="xDiffAddressThanApplicant"
                  content="No"
                  checked={xDiffAddressThanApplicant === "No" ? true : false}
                />
              </div>
            </Col>
          </Row>
        )}

        {xDiffAddressThanApplicant === "No" && (
          <>
            <Row>
              <Col xs={12} className="mt-3">
                <h4 className="font-bold">Coborrower Address</h4>
              </Col>
              <Col xs={12}>
                <GoogleAutoComplete
                  placeholder="Street"
                  control={control}
                  name="coBorrowerAddressLine1"
                  onSelect={(address) => {
                    setValue("coBorrowerAddressLine1", address.street);
                    setValue("coBorrowerCity", address.city);
                    setValue("coBorrowerState", address.state);
                    setValue("coBorrowerPostalCode", address.zipCode);
                  }}
                />
              </Col>
              <Col xs={12} md={6} className="mt-3">
                <UseFormTextField
                  placeholder="City"
                  control={control}
                  name="coBorrowerCity"
                />
              </Col>
              <Col xs={6} md={3} className="mt-3">
                <UseFormTextField
                  placeholder="State"
                  control={control}
                  name="coBorrowerState"
                />
              </Col>
              <Col xs={6} md={3} className="mt-3">
                <UseFormTextField
                  placeholder="Zip Code"
                  control={control}
                  name="coBorrowerPostalCode"
                />
              </Col>
            </Row>

            <Row className="mt-3 align-items-center flex-nowrap">
              <Col xs={4} md={3}>
                <label className="form-check-label">Time in Residence:</label>
              </Col>
              <Col xs={4} md={3}>
                <UseFormSelect
                  list={YEARS_ARRAY}
                  control={control}
                  name="coBorrowerYearsAtResidence"
                  placeholder="Years"
                  placeholderValue={""}
                />
              </Col>
              <Col xs={4} md={3}>
                <UseFormSelect
                  list={MONTHS_ARRAY}
                  control={control}
                  name="coBorrowerMonthsAtResidence"
                  placeholder="Months"
                  placeholderValue={""}
                />
              </Col>
            </Row>
          </>
        )}

        <Row className="mt-4">
          <h4 className="font-bold">Co-Borrower Mailing Address</h4>
          <Col xs={12}>
            <UseFormCheck
              control={control}
              controlName="isCoBorrowerMailingAddressDifferrent"
              content="Is the co-borrower mailing address is different than their primary residence?"
            />
          </Col>
        </Row>
        {/* TODO: Ask Jaymin for Sungage disclosures */}

        {isCoBorrowerMailingAddressDifferrent && (
          <>
            <Row className="mt-3">
              <Col xs={12}>
                <GoogleAutoComplete
                  placeholder="Street"
                  control={control}
                  name="coBorrowerMailingAddressLine"
                  onSelect={(address) => {
                    setValue("coBorrowerMailingAddressLine", address.street);
                    setValue("coBorrowerMailingCity", address.city);
                    setValue("coBorrowerMailingState", address.state);
                    setValue("coBorrowerMailingZipCode", address.zipCode);
                  }}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={12} md={6}>
                <UseFormTextField
                  placeholder="City"
                  control={control}
                  name="coBorrowerMailingCity"
                />
              </Col>
              <Col xs={6} md={2} className="mt-3 mt-md-0">
                <UseFormTextField
                  placeholder="State"
                  control={control}
                  name="coBorrowerMailingState"
                />
              </Col>
              <Col xs={6} md={4} className="mt-3 mt-md-0">
                <UseFormTextField
                  placeholder="Zip Code"
                  control={control}
                  name="coBorrowerMailingZipCode"
                />
              </Col>
            </Row>
          </>
        )}

        <Row className="mt-4">
          <Col xs={12} sm={6}>
            <h4 className="font-bold">Co-Borrower Gross Annual Income</h4>
            <UserFormCurrencyField
              placeholder="Gross Annual Income"
              control={control}
              name="coBorrowerGrossIncome"
              disabled={type === "edit"}
            />
          </Col>
          <p className="mt-2">
            Please enter annual income, which may include any income that is
            verifiable and reported to the Government, such as employment
            income, social security, pensions, etc.
          </p>
        </Row>

        <Row>
          <Col xs={12}>
            <h4 className="font-bold">Co-Borrower Employer Information</h4>
          </Col>
          <Col xs={12} md={6}>
            <UseFormSelect
              list={EMPLOYMENT_STATUS_LIST}
              control={control}
              name="coBorrowerEmploymentStatus"
              placeholder="Employment Status"
            />
          </Col>
        </Row>
        {isSecondaryEmployed && (
          <Row className="mt-3">
            <Col xs={12} md={6}>
              <UseFormTextField
                placeholder="Employer Name"
                control={control}
                name="coBorrowerEmployerName"
              />
            </Col>
            {isSungageProduct && (
              <Col xs={12} md={6} className="mt-3 mt-md-0">
                <UseFormTextField
                  placeholder="Title"
                  control={control}
                  name="coBorrowerTitle"
                />
              </Col>
            )}
          </Row>
        )}
        <Row className="mt-3 align-items-center flex-nowrap">
          <Col xs={4} md={3}>
            <label className="form-check-label me-3">
              {TIME_ON_JOB_TEXT[
                coBorrowerEmploymentStatus as EmploymentStatus
              ] || "Time with current employer"}
              :
            </label>
          </Col>
          <Col xs={4} md={3}>
            <UseFormSelect
              list={YEARS_ARRAY}
              control={control}
              name="coBorrowerYear"
              placeholder="Years"
            />
          </Col>
          <Col xs={4} md={3}>
            <UseFormSelect
              list={MONTHS_ARRAY}
              control={control}
              name="coBorrowerMonths"
              placeholder="Months"
            />
          </Col>
        </Row>

        <UserConsent
          applicant={ApplicantType.CoBorrower}
          type={ApplicationType.Full}
          isSungageProduct={isSungageProduct}
          authorization={
            <UseFormCheck
              control={control}
              controlName="authorization"
              content="Yes, I have read and agree to the following credit authorization:"
            />
          }
          consent={
            <UseFormCheck
              control={control}
              controlName="consent"
              content="Yes, I have read and agree to the following consents and disclosures:"
            />
          }
        />

        <div className="mt-5 d-flex justify-content-center mb-5">
          <Button variant="primary" type="submit">
            Submit Request
          </Button>
        </div>
      </Col>
    </form>
  );
};

export default CoBorrower;
