import { combineReducers } from "@reduxjs/toolkit";
import appReducer from "./app.slice";
import { authReducer } from "./auth.slice";
import { loanReducer } from "./loan.slice";
import { userReducer } from "./user.slice";
import { regionReducer } from "./region.slice";
import { commonDataReducer } from "./commonData.slice";
import { ipWhitelistReducer } from "./ipWhitelist.slice";
import { awardcoReducer } from "./awardco.slice";

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  loan: loanReducer,
  user: userReducer,
  region: regionReducer,
  commonData: commonDataReducer,
  ipWhitelist: ipWhitelistReducer,
  awardco: awardcoReducer,
});

export default rootReducer;
