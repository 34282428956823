import { useAppDispatch, useAppSelector } from "@store/hooks";
import range from "lodash/range";
import { Button } from "react-bootstrap";
import { useEffect, useLayoutEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import { environment } from "@env/environment";
import { UserCardSkeleton } from "@shared/skeletons/UserCardSkeleton";
import {
  getIpWhitelistAction,
  ipWhitelistDataSelector,
  resetIpWhitelist,
} from "@store/slices/ipWhitelist.slice";
import { PaginationParams } from "@store/types";
import { formatDateForIpWhitelist } from "@utils/date";
import ChoiceModal from "@shared/components/ChoiceModal";
import { networkService } from "@services/network-service";
import { toasterService } from "@services/toaster.service";

const choices = [
  {
    title: "No",
    value: "no",
    variant: "outline-primary",
  },
  {
    title: "Yes",
    value: "yes",
    variant: "primary",
  },
];

interface IDeleteIpStae {
  busy: boolean;
  id: string | null;
  name: string;
  open: boolean;
}

const ViewIpWhitelist = () => {
  const [pageLimit, setPageLimit] = useState<number>(
    Number(environment.REACT_APP_PAGE_LIMIT)
  );
  const dispatch = useAppDispatch();
  const [deleteIp, setDeleteIp] = useState<IDeleteIpStae>({
    busy: false,
    id: null,
    name: "",
    open: false,
  });
  const currentPageLimit =
    pageLimit || Number(environment.REACT_APP_PAGE_LIMIT) || 0;
  const [disabled, setDisabled] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  // const [search, setSearch] = useState("");
  const ipWhitelistData = useAppSelector(ipWhitelistDataSelector);
  const isLoading = useAppSelector((state) => state.app.loading);
  const { results } = ipWhitelistData;

  const onPageChange = (e: any) => {
    setPage(e);
    dispatch(
      getIpWhitelistAction({
        page: e,
        // search,
        limit: pageLimit,
      })
    );
  };

  const onPageSizeChange = (e: any) => {
    setPageLimit(e.target.value);
    dispatch(
      getIpWhitelistAction({
        page: 1,
        limit: e.target.value,
      })
    );
  };

  const onReload = () => {
    dispatch(
      getIpWhitelistAction({
        page,
        // search,
        limit: pageLimit,
      })
    );
  };

  // const onSearch = (value: string) => {
  //   setSearch(value);
  //   if (!value) {
  //     dispatchQuery({
  //       page: 1,
  //       limit: pageLimit,
  //       search: "",
  //     });
  //     return;
  //   }
  //   dispatch(
  //     getIpWhitelistAction({
  //       page: 1,
  //       limit: pageLimit,
  //       search: value,
  //     })
  //   );
  // };
  // const dispatchQuery = (query: PaginationParams) => {
  //   dispatch(getIpWhitelistAction(query));
  // };

  useLayoutEffect(() => {
    dispatch(resetIpWhitelist());
    dispatch(
      getIpWhitelistAction({
        page: 1,
        limit: pageLimit,
      })
    );
  }, [dispatch, pageLimit]);

  const hideDeleteIpModal = () => {
    setDeleteIp((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const deleteIpWhitelist = async (id: string) => {
    try {
      setDisabled(true);
      await networkService.delete(`/ip-whitelist/${id}`);
      toasterService.success("IP has been deleted successfully!");
    } finally {
      setDisabled(false);
      setDeleteIp((prev) => ({
        busy: false,
        id: null,
        name: "",
        open: false,
      }));
      onReload();
    }
  };

  useEffect(() => {
    setDeleteIp((prev) => ({
      ...prev,
      busy: disabled,
    }));
  }, [disabled]);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center flex-wrap users-header mb-4">
        <h5 className="h4 text-primary fw-light mb-0">IP Whitelist</h5>
        <Link
          className="navigation-link hover text-nowrap"
          to="/ip-whitelist/add"
        >
          Add New IP to Whitelist
        </Link>
      </div>
      <hr className="height-2px" />

      {/* All Users Block */}
      {!isLoading && results?.length > 0 && (
        <div className="row">
          <div className="users-section">
            {results?.map(({ id, label, ipAddress, createdAt }) => (
              <div key={id} className="user-row" data-role={undefined}>
                <div className="align-items-center">
                  <strong>{label}</strong>
                </div>
                <div className="align-items-center">
                  <strong>{ipAddress}</strong>
                </div>
                <div className="align-items-center">
                  <span>Added On: {formatDateForIpWhitelist(createdAt)}</span>
                </div>
                <span className="user-row-buttons">
                  <Button
                    variant="outline-danger"
                    type="button"
                    onClick={() => {
                      setDeleteIp((prev) => ({
                        ...prev,
                        id: id,
                        name: ipAddress,
                        open: true,
                      }));
                    }}
                  >
                    Delete IP
                  </Button>
                </span>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Pagination Block */}
      {!isLoading && results?.length > 0 && (
        <div className="row mt-5">
          <div className="col-12 d-block mx-auto pagination-container">
            <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center">
              <div className="order-2 order-sm-1">
                <span>Records per active page</span>
                <select
                  className="page-size mx-3"
                  value={pageLimit}
                  onChange={(e) => {
                    onPageSizeChange(e);
                  }}
                >
                  <option value="6">6</option>
                  <option value="12">12</option>
                  <option value="18">18</option>
                </select>
              </div>
              <div className="order-1 order-sm-2 mb-3 mb-sm-0">
                <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={page}
                  itemsCountPerPage={Number(currentPageLimit)}
                  totalItemsCount={ipWhitelistData?.total}
                  pageRangeDisplayed={9}
                  onChange={onPageChange}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Skeleton Block */}
      {isLoading && (
        <div className="row">
          <div className="users-section">
            {range(currentPageLimit).map((item) => (
              <UserCardSkeleton key={item} />
            ))}
          </div>
        </div>
      )}

      {/* No Users Block */}
      {!isLoading && results?.length === 0 && (
        <div className="row mt-5">
          <div className="col-12 text-center">
            <h5 className="h5 text-primary">
              No records found for IP Whitelist
            </h5>
          </div>
        </div>
      )}

      {/* Delete IP modal */}
      <ChoiceModal
        centered
        choices={choices}
        onChoose={(choice) => {
          if (choice === "no") {
            return hideDeleteIpModal();
          }
          if (deleteIp.id) {
            deleteIpWhitelist(deleteIp.id);
          }
        }}
        onHide={() => {
          hideDeleteIpModal();
        }}
        show={deleteIp.open}
        title={`Delete ${deleteIp.name}`}
      >
        Are you sure you want to delete {deleteIp.name}?
      </ChoiceModal>
    </div>
  );
};

export default ViewIpWhitelist;
