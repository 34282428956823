import dotenv from "dotenv";
import React from "react";
import ReactDOM from "react-dom";
import "./assets/scss/app.scss";
import App from "./App";
import { store } from "./store/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { AuthUserContext } from "./contexts/AuthContext";
const persistor = persistStore(store);

dotenv.config();

ReactDOM.render(
  <React.StrictMode>
    <AuthUserContext>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </AuthUserContext>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
