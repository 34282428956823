
import React from 'react'
import SquareOpen from '@assets/images/square-open.svg';
import SquareDone from '@assets/images/square-done.svg';
import SquareUnavailable from '@assets/images/square-unavailable.svg';

export const StatusSquare = ({ status, statusName, statusClass }: { status: string, statusName: string, statusClass: string }) => {
    return (
        <>
            <div className="status-list d-flex align-items-center">
                <img src={status === "open" ? SquareOpen : '' || status === "done" ? SquareDone: '' || status === "unavailable" ? SquareUnavailable: ''  } alt="" />
                <div className={`status-name ${statusClass}`}>
                    <p className="m-0">
                        {statusName}
                    </p>
                </div>
            </div>
        </>
    )
}