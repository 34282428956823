import { environment } from "@env/environment";
import { mfaEnforceDateForNewUser } from "@shared/constants/app.constant";
import { UserRole } from "@shared/interfaces/User";
import { IAuthData } from "@store/slices/app.slice";
import { ILoginSuccessful, LoginMethod } from "@store/types";
import { RouteComponentProps } from "react-router-dom";

export const handleLoginSuccessRedirection = ({
  path,
  history,
  loginSuccessResponse,
  isNewUser = false,
}: {
  path: string;
  history: RouteComponentProps["history"];
  loginSuccessResponse: ILoginSuccessful;
  isNewUser?: boolean;
}) => {
  if (loginSuccessResponse.loginMethod === LoginMethod.DEFAULT) {
    const bypassUsersForMfa =
      environment.REACT_APP_BYPASS_MFA_USRS?.split(",") || [];

    if (
      loginSuccessResponse.role === UserRole.providerAdmin &&
      !bypassUsersForMfa.includes(loginSuccessResponse.email)
    ) {
      history.push("/setup/mfa", {
        isNewUser,
      });
      return;
    }
  }
  history.push(path);
};

export const addSpaceEveryFourChars = (str: string): string => {
  return str.replace(/(.{4})/g, "$1 ");
};

export const enforceMfa = (user: IAuthData): boolean =>
  user?.createdAt && new Date(user?.createdAt) > mfaEnforceDateForNewUser;
