import { environment } from "@env/environment";
import { Button, Col, Row } from "react-bootstrap";

const redirectLink = environment?.REACT_APP_EXT_PRODUCT_REDIRECT_URL ?? "#";

const PrivateLoanProductRedirect = () => {
  return (
    <Row className="mt-4 private-product-redirect-container">
      <Col>
        <p>
          By clicking the button below, you will leave Flic Financial’s site and
          be redirected to the SunPower Financial Portal
        </p>
        <div className="d-flex justify-content-center mt-4">
          <a referrerPolicy="origin" href={redirectLink} target="_blank">
            <Button variant="primary" type="button" className="mt-4">
              Access SunPower Financial Portal
            </Button>
          </a>
        </div>
      </Col>
    </Row>
  );
};

export default PrivateLoanProductRedirect;
