import { Route } from "react-router-dom";
import React, { useEffect } from "react";
import { useAppSelector } from "@store/hooks";
import {
  appAccessTokenSelector,
  getAuthDetailsAction,
  setLenders,
} from "@store/slices/app.slice";
import { useAppDispatch } from "@store/hooks";
import { LoadingSpinner } from "./Loader";
import { useQuery } from "@shared/helpers/global.helper";
import { appService } from "@services/app.service";
import {
  getSungageDisclosuresAction,
  loanSliceSelector,
  setIsDraftValidated,
  setRequestDraft,
} from "@store/slices/loan.slice";
import { UserAuthStatus } from "@shared/enums/UserState";
import { useAuthUserContext } from "@contexts/AuthContext";
import { NotAuthorized } from "./NotAuthorized";
import { getTypesAction } from "@store/slices/commonData.slice";

export const PrivateRoute = ({ component, isAuthenticated, ...rest }: any) => {
  const dispatch = useAppDispatch();
  const loanSlice = useAppSelector(loanSliceSelector);
  const appAccessToken = useAppSelector(appAccessTokenSelector);
  const query = useQuery();
  const { authUserStatus, setAuthUserStatus } = useAuthUserContext();

  useEffect(() => {
    const validateToken = async () => {
      dispatch(setRequestDraft(null));

      if (authUserStatus === UserAuthStatus.Authorized) {
        return;
      }

      let token: any = query.get("token");
      setAuthUserStatus(UserAuthStatus.Loading);
      if (appAccessToken) {
        // Check for authenticated user based on accessToken
        dispatch(
          getAuthDetailsAction({
            setAuthUserStatus,
          })
        );
        dispatch(setLenders([]));
        return;
      }

      if (token) {
        // For customers
        const response = await new Promise<any>(async (resolve, reject) => {
          try {
            dispatch(setIsDraftValidated(false));
            const response = await appService.validateToken({ token });
            dispatch(setRequestDraft(response?.data?.data));
            resolve(response?.data?.data);
          } catch (error) {
            reject(false);
          }
        });

        if (response) {
          setAuthUserStatus(UserAuthStatus.Public);
        } else {
          setAuthUserStatus(UserAuthStatus.Unauthorized);
        }
        dispatch(setIsDraftValidated(true));
        return;
      }

      setAuthUserStatus(UserAuthStatus.Unauthorized);
    };
    validateToken();
  }, [dispatch, query, appAccessToken]);

  useEffect(() => {
    if (authUserStatus === UserAuthStatus.Authorized) {
      dispatch(getTypesAction());
      dispatch(getSungageDisclosuresAction())
    }
  }, [authUserStatus, dispatch]);

  if (
    (!loanSlice?.IsDraftValidated && query.get("token")) ||
    authUserStatus === UserAuthStatus.Loading
  ) {
    return (
      <div className="loader">
        <LoadingSpinner />
      </div>
    );
  }

  if (authUserStatus === UserAuthStatus.Unauthorized) {
    return <NotAuthorized />;
  }

  const routeComponent = (props: any) =>
    authUserStatus === UserAuthStatus.Public ||
    authUserStatus === UserAuthStatus.Authorized
      ? React.createElement(component, props)
      : null;

  return (
    <>
      <Route {...rest} render={routeComponent} />
    </>
  );
};
