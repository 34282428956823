import React, { useState } from "react";
import NavLogo from "@assets/images/logo.svg";
import { ButtonDropdown } from "@shared/components/ButtonDropdown";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "@store/hooks";
import { useAppDispatch } from "@store/hooks";
import {
  appUserSelector,
  setAccessToken,
  setLenders,
  setLoggedInUser,
} from "@store/slices/app.slice";
import { ApplicationType } from "@shared/enums/ApplicationStatus";
import { UserAuthStatus } from "@shared/enums/UserState";
import { environment } from "@env/environment";
import { useAuthUserContext } from "@contexts/AuthContext";
import { UserRole } from "@shared/interfaces/User";
import clsx from "clsx";
import { setPipelineData } from "@store/slices/loan.slice";
import { isSalesRole } from "@shared/helpers/global.helper";
import {
  awardcoStateSelector,
  resetAwardcoBalanceAction,
} from "@store/slices/awardco.slice";

var emailOptions = [
  {
    value: 1,
    text: "Email a PreQual Application Link",
    url: `/email-link/send/${ApplicationType.PreQual}`,
  },
  {
    value: 2,
    text: "Email a Full Application Link",
    url: `/email-link/send/${ApplicationType.Full}`,
  },
];
var applicationOptions = [
  {
    value: 1,
    text: "Start a PreQual Application",
    url: "/pre-qualification/request",
  },
  {
    value: 2,
    text: "Start a Full Application",
    url: "/new-application/request",
  },
];

const redirectLink = environment?.REACT_APP_EXT_PRODUCT_REDIRECT_URL ?? "#";
const awardcoUrl = environment?.REACT_APP_AWARDCO_URL ?? "#";

const Navbar = () => {
  const [activeMenu, setActiveMenu] = useState(false);
  let history = useHistory();
  const dispatch = useAppDispatch();
  const user = useAppSelector(appUserSelector);
  const awardcoState = useAppSelector(awardcoStateSelector);
  const { authUserStatus, setAuthUserStatus } = useAuthUserContext();

  const onApplicationChange = (item: any) => {
    history.push(item.url);
  };

  const onLogout = (event: any) => {
    dispatch(setLoggedInUser(null));
    dispatch(setAccessToken(null));
    dispatch(setPipelineData(null));
    dispatch(resetAwardcoBalanceAction());
    dispatch(setLenders([]));
    setAuthUserStatus(UserAuthStatus.Unauthorized);
    history.push("/auth/login");

    // dispatch(signOutAction(history))
  };

  return (
    <>
      <div className="nav-header d-flex justify-content-between">
        <div className="flex-shrink-1">
          <Link to="/pipelines">
            <img src={NavLogo} alt="Flic Financial" />
          </Link>
        </div>
        {authUserStatus === UserAuthStatus.Authorized && (
          <>
            <div className="flex-fill bd-highlight text-end">
              <div className="d-flex justify-content-end justify-content-sm-center mt-4 mt-sm-0">
                {isSalesRole(user?.role) && awardcoState.success ? (
                  <p className="user-awards flex-fill text-center text-lg-end d-none d-sm-block">
                    Welcome <span className="username">{user?.username}</span>,
                    You Have{" "}
                    <span className="user-points">{awardcoState.balance}</span>{" "}
                    Flicer Award Points!
                    <Link
                      to={{
                        pathname: awardcoUrl,
                      }}
                      className="ms-4"
                      target="_blank"
                    >
                      Go to Awards
                    </Link>
                  </p>
                ) : (
                  <p className="user flex-fill text-center text-lg-end d-none d-sm-block">
                    Logged in as {user?.username}
                  </p>
                )}
                <div className="mobile-menu">
                  <div
                    className={`menu-toggle navbar-toggler-icon flex-shrink-1 d-block d-lg-none ${
                      activeMenu ? "activeMenu" : ""
                    }`}
                    onClick={() => setActiveMenu(!activeMenu)}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  {activeMenu ? (
                    <div className="mobile-list">
                      <ul>
                        <li>
                          <h5>Start an Application</h5>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: "/pre-qualification/request",
                            }}
                            onClick={() => setActiveMenu(!activeMenu)}
                          >
                            Start a PreQual Application
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: "/new-application/request",
                            }}
                            onClick={() => setActiveMenu(!activeMenu)}
                          >
                            Start a Full Application
                          </Link>
                        </li>

                        <li>
                          <h5>Email Application Link</h5>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: `/email-link/send/${ApplicationType.PreQual}`,
                            }}
                            onClick={() => setActiveMenu(!activeMenu)}
                          >
                            Email a PreQual Application Link
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: `/email-link/send/${ApplicationType.Full}`,
                            }}
                            onClick={() => setActiveMenu(!activeMenu)}
                          >
                            Email A Full Application Link
                          </Link>
                        </li>
                        <li>
                          <h5>Resources</h5>
                        </li>
                        <li>
                          <a href="#/" className="hover" onClick={onLogout}>
                            Logout
                          </a>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="settings">
                {user?.role && user?.role !== UserRole.financialInstitution && (
                  <div className="menus justify-content-center d-flex d-lg-inline-block p-0 pe-lg-4">
                    <ButtonDropdown
                      onChange={onApplicationChange}
                      btnRedirectUrl={`/email-link/send/${ApplicationType.PreQual}`}
                      buttonColor="primary"
                      dropDownClass="d-none d-lg-inline-block me-3"
                      buttonText="Email An Application"
                      dropDownProps={emailOptions}
                    />

                    <ButtonDropdown
                      btnRedirectUrl="/pre-qualification/request"
                      onChange={onApplicationChange}
                      buttonColor="primary"
                      dropDownClass="d-none d-lg-inline-block"
                      buttonText="Start An Application"
                      dropDownProps={applicationOptions}
                    />

                    <div className="divider" />
                  </div>
                )}
                <div
                  className={clsx(
                    "options d-none d-lg-inline-block ps-3",
                    user?.role !== UserRole.financialInstitution && "divider"
                  )}
                >
                  <Link to="/pipelines" className="me-3">
                    View Pipeline
                  </Link>
                  {user?.role &&
                    [UserRole.providerAdmin, UserRole.regionalManager].includes(
                      user?.role
                    ) && (
                      <Link to="/users" className="me-3">
                        User Management
                      </Link>
                    )}
                  {user?.role &&
                    ![UserRole.financialInstitution].includes(user?.role) && (
                      // Causing issue when trying to login to the ext web when rel="noreferrer", keep that disabled
                      // eslint-disable-next-line react/jsx-no-target-blank
                      <a
                        referrerPolicy="origin"
                        href={redirectLink}
                        target="_blank"
                        // rel="noreferrer"
                        className="me-3"
                      >
                        SunPower Loans
                      </a>
                    )}
                  <a href="#/" className="hover " onClick={onLogout}>
                    Logout
                  </a>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Navbar;
