import NavLogo from "@assets/images/logo.svg";
import { useAppDispatch, useAppSelector } from "@store/hooks";

import "../style.scss";
import { useEffect } from "react";
import {
  loginProcessDataSelector,
  setLoginProcessData,
} from "@store/slices/app.slice";
import { LoginChallenge } from "@store/types";
import LoginForm from "./LoginForm";
import LoginTotpMfaForm from "./LoginTotpMfaForm";
import LoginSmsMfaForm from "./LoginSmsMfaForm";

const Login = () => {
  const dispatch = useAppDispatch();
  const loginProcessData = useAppSelector(loginProcessDataSelector);

  useEffect(() => {
    dispatch(setLoginProcessData(null));

    return () => {
      dispatch(setLoginProcessData(null));
    };
  }, [dispatch]);

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <div className="login-container">
        <div className="row">
          <div className="col-xs-12 col-md-6  offset-md-3">
            <div className="d-flex justify-content-center">
              <img src={NavLogo} alt="Flic Financial" />
            </div>

            {loginProcessData?.challengeName ===
              LoginChallenge.SOFTWARE_TOKEN_MFA && <LoginTotpMfaForm />}
            {loginProcessData?.challengeName === LoginChallenge.SMS_MFA && (
              <LoginSmsMfaForm />
            )}

            {!loginProcessData?.challengeName && <LoginForm />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
