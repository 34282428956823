import { ChangeEvent } from "react";
import { Stipulations } from "./documentCards/Stipulations";
import { ApplicantsIdentification } from "./documentCards/ApplicantsIdentification";
import { SolarContract } from "./documentCards/SolarContract";
import Funding from "./Funding";
import Inspections from "./Inspections";
import { LoanDocuments } from "./documentCards/LoanDocuments";
import { AdditionalDocumentation } from "./documentCards/AdditionalDocumentation";
import { LoanDocPackage } from "./documentCards/LoanDocPackage";
import { IStipulation } from "@shared/interfaces/Application";
import { DownloadDocumentPayload } from "@store/slices/loan.slice";
import { IPortalApplication } from "@shared/interfaces/PortalApplicationTypes";

const ReviwerDocuments = ({
  requestDetail,
  onDocumentInputFileChange,
  uploadingDocType,
  errorMessage,
  docId,
  stipulations,
  uploadIdStip,
  solarContractStip,
  installationPhotoStip,
  ptoDocumentUploadedStip,
  downloadDocument,
  canBeModified,
}: {
  requestDetail: IPortalApplication | undefined;
  onDocumentInputFileChange: (
    event: ChangeEvent<HTMLInputElement>,
    docId: string,
    callback?: () => void
  ) => Promise<void>;
  uploadingDocType: string;
  errorMessage: string;
  docId: string;
  stipulations: Array<IStipulation>;
  uploadIdStip: any;
  solarContractStip: IStipulation | undefined;
  installationPhotoStip: IStipulation | undefined;
  ptoDocumentUploadedStip: IStipulation | undefined;
  downloadDocument: (value: DownloadDocumentPayload) => void;
  canBeModified: boolean;
}) => {
  return (
    <div className="mt-5">
      <Stipulations
        docId={docId}
        errorMessage={errorMessage}
        onDocumentInputFileChange={onDocumentInputFileChange}
        requestDetail={requestDetail}
        stipulations={stipulations}
        uploadingDocType={uploadingDocType}
        downloadDocument={downloadDocument}
        canBeModified={canBeModified}
      />
      <ApplicantsIdentification
        docId={docId}
        canBeModified={canBeModified}
        errorMessage={errorMessage}
        onDocumentInputFileChange={onDocumentInputFileChange}
        requestDetail={requestDetail}
        uploadingDocType={uploadingDocType}
        uploadIdStip={uploadIdStip}
        downloadDocument={downloadDocument}
      />
      <SolarContract
        canBeModified={canBeModified}
        docId={docId}
        errorMessage={errorMessage}
        onDocumentInputFileChange={onDocumentInputFileChange}
        requestDetail={requestDetail}
        uploadingDocType={uploadingDocType}
        solarContractStip={solarContractStip}
        downloadDocument={downloadDocument}
      />

      <AdditionalDocumentation
        requestDetail={requestDetail}
        downloadDocument={downloadDocument}
      />

      <LoanDocuments
        requestDetail={requestDetail}
        variant="new"
        canBeModified={canBeModified}
      />

      <hr className="bg-secondary my-5" />

      <LoanDocPackage
        requestDetail={requestDetail}
        downloadDocument={downloadDocument}
      />

      <Inspections
        canBeModified={canBeModified}
        docId={docId}
        errorMessage={errorMessage}
        onDocumentInputFileChange={onDocumentInputFileChange}
        requestDetail={requestDetail}
        uploadingDocType={uploadingDocType}
        ptoDocumentUploadedStip={ptoDocumentUploadedStip}
        downloadDocument={downloadDocument}
      />

      <Funding
        canBeModified={canBeModified}
        docId={docId}
        errorMessage={errorMessage}
        onDocumentInputFileChange={onDocumentInputFileChange}
        requestDetail={requestDetail}
        uploadingDocType={uploadingDocType}
        installationPhotoStip={installationPhotoStip}
        downloadDocument={downloadDocument}
      />
    </div>
  );
};

export default ReviwerDocuments;
