import Auth from "@auth/Auth";
import { LoadingSpinner } from "@shared/components/Loader";
import { useAppSelector } from "@store/hooks";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Features } from "./features";
import { ToastContainer } from "react-toastify";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-toastify/dist/ReactToastify.css";
import { PrivateRoute } from "@shared/components/PrivateRoute";
import "react-loading-skeleton/dist/skeleton.css";

function App() {
  const appSlice = useAppSelector((state) => state.app);
  const loanSlice = useAppSelector((state) => state.loan);

  return (
    <Router>
      {appSlice.loading &&
        !appSlice.isProcessing &&
        !loanSlice?.IsDocumentUploading &&
        !loanSlice.isDocumentDownloading && (
          <div className="loader">
            <LoadingSpinner />
          </div>
        )}
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Switch>
        <Route path="/auth" component={Auth} />
        <PrivateRoute path="/" component={Features} />
      </Switch>
    </Router>
  );
}

export default App;
