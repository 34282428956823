export enum ApplicationSteps {
  PreQual = "PreQual",
  FullApplication = "Full Application",
  Stipulations = "Submit Stipulations",
  UploadID = "Upload ID",
  UploadContract = "Upload Contract",
  IssueDocs = "Signed Loan Docs",
}

export enum ApplicationType {
  PreQual = "PreQual",
  Full = "Full",
}

export enum PortalDecisionStatus {
  Declined = "Declined", // [AutoDeclined = "Auto Declined", Declined = "Declined"] <Ineligible for financing>
  PrequalDeclined = "PrequalDeclined", // [ PrequalDeclined = "Prequal Declined", NotPrequalified = "Not Prequalified"] <>
  Prequalified = "Prequalified", // [Prequalified = "Prequalified"] <>
  Approved = "Approved", // [Approved = "Approved", AutoApproved = "Auto Approved", RecommendApprove = "Recommend Approve"] <Approved, Approved (stips pending)>
  Refer = "Refer", // [Refer = "Refer",] <Under review>
  Pending = "Pending", // [Pending = "Pending",] <Not yet submitted>
  NoDecisionRun = "NoDecisionRun", // [NoDecisionRun = "No Decision Run"] <>
  Expired = "Expired", // [Expired = "Expired"] <>
  Requested = "Requested", // [Requested = "Requested"] <>
  Unknown = "Unknown",
}

export const PortalDecisionStatusLabel: Record<PortalDecisionStatus, string> = {
  [PortalDecisionStatus.Declined]: "Declined",
  [PortalDecisionStatus.PrequalDeclined]: "Not Prequalified",
  [PortalDecisionStatus.Prequalified]: "Prequalified",
  [PortalDecisionStatus.Approved]: "Approved",
  [PortalDecisionStatus.Refer]: "Refer",
  [PortalDecisionStatus.Pending]: "Pending",
  [PortalDecisionStatus.NoDecisionRun]: "No Decision Run",
  [PortalDecisionStatus.Expired]: "Expired",
  [PortalDecisionStatus.Requested]: "Requested",
  [PortalDecisionStatus.Unknown]: "Unknown",
};

export enum ApplicantStatus {
  Primary = "Primary",
  CoApplicant = "CoApplicant",
}
export enum ApplicantType {
  Borrower = "Borrower",
  CoBorrower = "Co-Borrower",
}
