import { toast } from 'react-toastify';

class ToasterService {
    success(message: string) {
        toast.success(message,{
            icon:true
        });
    }
    error(message: string) {
        toast.error(message,{
            icon:true
        });
    }
    warn(message: string) {
        toast.warn(message,{
            icon:true
        });
    }
}

export const toasterService = new ToasterService();