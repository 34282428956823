import { ReactElement, ReactNode, useEffect } from "react";
import isFunction from "lodash/isFunction";
import clsx from "clsx";

export interface DropdownListItem {
  key?: string;
  title?: string;
  value: string;
  isDisabled?: boolean;
}

interface DropdownListProps {
  children?: ReactNode;
  className?: string;
  items: DropdownListItem[];
  onClose?: () => void;
  onSelect?: (value: string) => void;
}

/**
 * Dropdown List component
 */
const DropdownList = ({
  children,
  className,
  items,
  onClose,
  onSelect,
}: DropdownListProps): ReactElement => {
  // Handler to trigger close when element outside this component is clicked
  useEffect(() => {
    const onClick = () => {
      if (isFunction(onClose)) {
        onClose();
      }
    };
    window.addEventListener("click", onClick);
    return () => {
      window.removeEventListener("click", onClick);
    };
  }, [onClose]);
  return (
    <div
      className={clsx("dropdown-list-wrapper", className, {
        "is-open": items.length,
      })}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
    >
      {children}
      <div className="dropdown-list">
        {!!items.length && (
          <ul>
            {items.map(({ key, title, value, isDisabled }) => (
              <li
                key={key || value}
                className={clsx({
                  disabled: !!isDisabled,
                })}
              >
                {isDisabled ? (
                  <span>{title || value}</span>
                ) : (
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      if (isFunction(onSelect)) {
                        onSelect(value);
                      }
                      if (isFunction(onClose)) {
                        onClose();
                      }
                    }}
                  >
                    {title || value}
                  </span>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default DropdownList;
