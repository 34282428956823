import { NA } from "@shared/constants/app.constant";

const PercentageFormat = ({
  percentage,
  className,
}: {
  percentage: number | undefined;
  className?: string;
}) => {
  if (!percentage) {
    return <span className={className}>{NA}</span>;
  }
  return (
    <span className={className}>
      {!isNaN(Number(percentage)) ? `${Number(percentage.toFixed(2))}%` : "NA"}
    </span>
  );
};
export default PercentageFormat;
