import { networkService } from "./network-service";

interface Register {
  email: string;
  username: string;
  role: string;
  dealer: string | null;
  regionId: string | null;
  lenderId: number | null;
  productAccess: string[] | null;
}

interface ModifyUser extends Omit<Register, "email"> {
  employeeId: string;
}

interface CreateRegion {
  name: string;
  dealer: string;
}

class AppService {
  async addUser(model: Register) {
    return await networkService.post("register", model);
  }
  async modifyUser(id: string, model: ModifyUser) {
    return await networkService.put(`users/${id}`, model);
  }
  async verifyUser(model: Register) {
    return await networkService.post("verify", model);
  }
  async validateToken(model: any) {
    return await networkService.post("user/validate-token", model);
  }
  async addRegion(model: CreateRegion) {
    return await networkService.post("regions", model);
  }

  async generateClientCredentials(userId: string) {
    return await networkService.post("auth/client-credentials/generate", {
      userId,
    });
  }
}

export const appService = new AppService();
