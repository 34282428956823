import { UseFormTextField } from "@shared/components/TextInput";
import { Button, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { onSubmitError } from "@shared/helpers/global.helper";
import { UseFormTextAreaField } from "@shared/components/TextArea";
import { loanService } from "@services/loan.service";
import { useEffect, useState } from "react";
import { toasterService } from "@services/toaster.service";
import { addLenderNoteSchema } from "@shared/schema/request";
import { yupResolver } from "@hookform/resolvers/yup";
import { RequestDetailsTabs } from "@features/request-detail/RequestDetail";
import { QUERY_PARAMS_KEYS } from "@shared/constants/app.constant";
import { DecisionTabs } from "@features/request-detail/components/Decision";
import { IPortalApplication } from "@shared/interfaces/PortalApplicationTypes";

const AddLenderNote = () => {
  const history = useHistory();
  let { id } = useParams<{ id: string }>();

  const [applicationData, setApplicationData] = useState<IPortalApplication>();
  const { control, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(addLenderNoteSchema),
  });

  const isBusy = formState.isSubmitting;

  useEffect(() => {
    (async () => {
      const response = await loanService.getRequestById(id);
      setApplicationData(response?.data?.data);
    })();
  }, [id]);

  const onSubmit = async (event: any) => {
    try {
      if (isBusy || !applicationData?.losAppNumber) {
        return;
      }

      const response = await loanService.addLenderNote(
        {
          noteContent: event.noteContent,
          subject: event.subject,
          noteRecipient: "DEALER",
          noteType: "INTERNAL",
        },
        applicationData?.losAppNumber
      );
      if (response) {
        toasterService.success("Lender Note has been added successfully");
        history.push(
          `/request-detail/${id}?${QUERY_PARAMS_KEYS.REQUEST_DETAILS_TAB}=${RequestDetailsTabs.decision}&${QUERY_PARAMS_KEYS.REQUEST_DETAILS_SUB_TAB}=${DecisionTabs.notes}`
        );
        reset();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-center mt-3">
      <div className="login-container form-container">
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit, onSubmitError())}
        >
          <div className="row">
            <div className="col-xs-12 col-md-8 offset-md-2">
              <div className="d-flex justify-content">
                <h3 className="h3 flex-grow-1">Add Note</h3>
              </div>
              <h5>Subject</h5>
              <Row className="mb-3">
                <Col xs={12}>
                  <UseFormTextField
                    placeholder="Subject"
                    control={control}
                    name="subject"
                  />
                </Col>
              </Row>

              <div className="p-1" />

              <h5>Note</h5>
              <Row className="mb-3">
                <Col xs={12}>
                  <UseFormTextAreaField
                    placeholder="Note"
                    control={control}
                    name="noteContent"
                  />
                </Col>
              </Row>

              <Row className="justify-content-center mt-5">
                <Col xs={3} className="d-flex justify-content-end">
                  <Button
                    variant="outline-primary"
                    type="button"
                    onClick={() => history.goBack()}
                    disabled={formState.isSubmitting}
                  >
                    Exit
                  </Button>
                </Col>
                <Col xs={3} className="">
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={formState.isSubmitting}
                  >
                    Add Note
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddLenderNote;
