import NavLogo from "@assets/images/logo.svg";
import { Link } from "react-router-dom";

const HeaderLogo = () => {
  return (
    <div className="d-flex justify-content-between">
      <div className="flex-shrink-1">
        <Link to="/pipelines">
          <img src={NavLogo} alt="Flic Financial" />
        </Link>
      </div>
    </div>
  );
};

export default HeaderLogo;
