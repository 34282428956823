import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";

type FieldProps = {
  control: any;
  type?: any;
  elementName?: any;
  controlName: string;
  placeholder?: string;
  content: any;
};

export const UseFormCheck = ({
  control,
  elementName,
  controlName,
  content,
  type = "checkbox",
}: FieldProps) => {
  return (
    <Controller
      name={controlName}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <div className="d-flex flex-column">
            <Form.Check
              inline
              checked={value || false}
              type={type}
              value={value ? "true" : "false"}
              id={controlName || elementName}
              name={`${elementName} ${controlName}`}
              label={content}
              onChange={onChange}
              className={!!error ? "is-invalid" : ""}
            />

            {error && (
              <Form.Text
                id="passwordHelpBlock"
                className="text-danger px-2"
                muted
              >
                &nbsp;&nbsp;&nbsp; {error?.message}
              </Form.Text>
            )}
            {value}
          </div>
        </>
      )}
    />
  );
};
