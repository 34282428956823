interface ISystemInfoProps {
  label: string;
  value: number | string;
}

const SystemInfoText = ({ label, value }: ISystemInfoProps) => (
  <p className="display-text text-black fw-normal">
    {label}: <span className="text-primary">{value}</span>
  </p>
);

export default SystemInfoText;
