import { useState, useEffect } from "react";

// a Util function that will conver the absolute width into breakpoints
const getBreakPoint = (
  windowWidth: number | undefined
): "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | undefined => {
  if (windowWidth) {
    if (windowWidth < 433) {
      return "xs";
    } else if (windowWidth < 576) {
      return "sm";
    } else if (windowWidth < 768) {
      return "md";
    } else if (windowWidth < 992) {
      return "lg";
    } else if (windowWidth < 1200) {
      return "xl";
    } else {
      return "xxl";
    }
  } else {
    return undefined;
  }
};

function useWindowSize() {
  const isWindowClient = typeof window === "object";

  const [windowSize, setWindowSize] = useState(
    isWindowClient ? getBreakPoint(window.innerWidth) : undefined
  );

  useEffect(() => {
    // a handler which will be called on change of the screen resize
    const setSize = () => {
      setWindowSize(getBreakPoint(window.innerWidth));
    };

    if (isWindowClient) {
      // register the window resize listener
      window.addEventListener("resize", setSize);

      // unregister the listerner on destroy of the hook
      return () => window.removeEventListener("resize", setSize);
    }
  }, [isWindowClient, setWindowSize]);

  return {
    breakPoint: windowSize,
    windowWidth: window?.innerWidth,
  };
}

export default useWindowSize;
