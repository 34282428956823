export const environment = {
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  REACT_APP_PAGE_LIMIT: process.env.REACT_APP_PAGE_LIMIT,
  REACT_APP_STATUS_CHECK_ATTEMPTS: process.env.REACT_APP_STATUS_CHECK_ATTEMPTS,
  REACT_APP_UPLOAD_DOC_STATUS_ATTEMPTS:
    process.env.REACT_APP_STATUS_CHECK_ATTEMPTS || 30,
  REACT_APP_NO_DECISON_REF_ID:
    process.env.REACT_APP_NO_DECISON_REF_ID ||
    "2159197902897,2159197902898,2132294365997,2132294365998,2132294366000,2132294365999,2132294365988,2132294365989,2132294365991,2132294365990,2132294365986,2132294365985,2132294365995,2132294366004,2132294366015,2159197902909",
  //  REACT_APP_NO_DECISON_REF_ID=2132294366000,2132294365999,2132294365988,2132294365989,2132294365991,2132294365990,2132294365986,2132294365985,2132294365995
  //  Previous REACT_APP_NO_DECISON_REF_ID
  //  REACT_APP_NO_DECISON_REF_ID=2159197902887,2159197902888,2159197902889,2159197902890,2159197902891,2159197902892,2132294365995,2132294365998,2132294365999,2132294365997,2132294366000,2132294365989,2132294365991,2132294365990,2132294365986,2132294365985
  REACT_APP_IDENTIFICATION_REF_ID: process.env.REACT_APP_IDENTIFICATION_REF_ID,
  REACT_APP_SOLAR_CONTRACT_REF_ID: process.env.REACT_APP_SOLAR_CONTRACT_REF_ID,
  REACT_APP_INSTALLATION_PHOTOS_REF_ID:
    process.env.REACT_APP_INSTALLATION_PHOTOS_REF_ID || "2132294365982",
  REACT_APP_PTO_DOC_REF_ID:
    process.env.REACT_APP_PTO_DOC_REF_ID || "2132294365984",
  REACT_APP_EXCLUDE_STIP_REF_ID:
    process.env.REACT_APP_EXCLUDE_STIP_REF_ID || "2132294365987",
  REACT_APP_RESUBMIT_ON_NO_DECISION_REF_ID:
    process.env.REACT_APP_RESUBMIT_ON_NO_DECISION_REF_ID ||
    "2132294365988,2132294365989",
  REACT_APP_ISSUE_DOCS_REF_ID: process.env.REACT_APP_ISSUE_DOCS_REF_ID,
  REACT_APP_CRYPTOGRAPHIC_KEY: process.env.REACT_APP_CRYPTOGRAPHIC_KEY,
  REACT_APP_CRYPTOGRAPHIC_IV:
    process.env.REACT_APP_CRYPTOGRAPHIC_IV || "fswrqj99RMkxnKIf",
  REACT_APP_CRYPTOGRAPHY: process.env.REACT_APP_CRYPTOGRAPHY,
  REACT_APP_EXT_PRODUCT_REDIRECT_URL:
    process.env.REACT_APP_EXT_PRODUCT_REDIRECT_URL,
  REACT_APP_CLIENT_CRED_SECRET: process.env.REACT_APP_CLIENT_CRED_SECRET,
  REACT_APP_AWARDCO_URL:
    process.env.REACT_APP_AWARDCO_URL || "https://flicfinancial.awardco.com/",
  REACT_APP_BYPASS_MFA_USRS:
    process.env.REACT_APP_BYPASS_MFA_USRS ||
    "kishan-pa@yopmail.com,kishan-em11@yopmail.com",
  REACT_APP_SMS_MFA_ALLOW_COUNTRY_CODE:
    process.env.REACT_APP_SMS_MFA_ALLOW_COUNTRY_CODE || "false",
};
