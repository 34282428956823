import { loanService } from "@services/loan.service";
import { toasterService } from "@services/toaster.service";
import { ConditionalRender } from "@shared/components/ConditionalRender";
import SelectUserModal from "@shared/components/SelectUserModal";
import { MESSAGES } from "@shared/constants/app.constant";
import { IPortalApplication } from "@shared/interfaces/PortalApplicationTypes";
import { User, UserRole } from "@shared/interfaces/User";
import { useAppSelector } from "@store/hooks";
import { appUserSelector } from "@store/slices/app.slice";
import { useGetUsers } from "@store/slices/user.slice";
import React, { useMemo, useRef, useState } from "react";

const ProjectIdDisplay = ({
  id,
  editProjectId,
  canBeModified,
  onEdit,
  data,
  setEditProjectId,
  onUpdateProjectId,
  projectId,
  setProjectId,
  inputRef,
}: {
  id: string;
  editProjectId: boolean;
  canBeModified: boolean;
  onEdit: () => void;
  data: any;
  setEditProjectId: (val: boolean) => void;
  setProjectId: (val: string) => void;
  onUpdateProjectId: () => void;
  projectId: string;
  inputRef: any;
}) => {
  if (id === "new") {
    return null;
  }
  return (
    <div className="d-flex">
      <p>Project ID: </p>
      <div>
        <div className="d-flex">
          {!editProjectId && (
            <>
              &nbsp;
              <p>
                <ConditionalRender value={data?.xProjectID} />
              </p>
              &nbsp;
            </>
          )}
          {!editProjectId && canBeModified && (
            <a href="#/" className="project-link" onClick={() => onEdit()}>
              Edit
            </a>
          )}
        </div>
        {editProjectId && canBeModified && (
          <div className="d-flex justify-content-center align-items-center">
            &nbsp;
            <input
              ref={inputRef}
              value={projectId}
              placeholder="Project Id"
              className={"form-control w-110"}
              onChange={(event) => setProjectId(event.target.value)}
            />
            &nbsp;
            <a
              href="#/"
              className="text-primary text-decoration-none hover"
              onClick={() => onUpdateProjectId()}
            >
              Save
            </a>
            &nbsp;
            <a
              href="#/"
              className="text-danger text-decoration-none hover"
              onClick={() => setEditProjectId(false)}
            >
              Cancel
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export const InstallerInfo = ({
  data,
  id,
  canBeModified,
}: {
  data: IPortalApplication | undefined;
  id: string;
  canBeModified: boolean;
}) => {
  const xEmployeeEmail = data?.installer?.email || "";
  const [employeeEmail, setEmployeeEmail] = useState(xEmployeeEmail);
  const [showSelectUser, setShowSelectUser] = useState<boolean>(false);
  const [editProjectId, setEditProjectId] = useState(false);
  const loggedInUser = useAppSelector(appUserSelector);
  const [projectId, setProjectId] = useState("");
  const inputRef = useRef<any>(null);

  const isAdmin =
    loggedInUser?.role === UserRole.providerAdmin ||
    loggedInUser?.role === UserRole.enterpriseManager;

  const {
    users: [user],
  } = useGetUsers(
    useMemo(
      () => ({
        field: "email",
        page: 1,
        limit: 1,
        search: employeeEmail,
      }),
      [employeeEmail]
    ),
    !!employeeEmail
  );

  const updateInstallerRep = async (user: User) => {
    await loanService.updateInstallerRep(id, user.id);
    setEmployeeEmail(user.email);
  };

  const onEdit = () => {
    setEditProjectId(true);
    setTimeout(() => {
      inputRef.current.focus();
    }, 300);
  };

  const onUpdateProjectId = async () => {
    if (projectId.length === 0 || isNaN(Number(projectId))) {
      toasterService.warn("Please enter valid project id.");
      return;
    }
    const response = await loanService.updateProjectId(id, {
      name: "UpdateProjectID",
      loanType: "Solar Loan",
      appNumber: id,
      xProjectID: projectId,
    });
    setEditProjectId(false);
    if (response?.data?.data?.response?.taskStatusLabel === "Completed") {
      toasterService.success("Project id updated successfully");
    } else {
      toasterService.error(MESSAGES.DEFAULT_ERROR);
    }
  };

  return (
    <div>
      <h3 className="h3 text-primary-dark">Installer Info</h3>
      <hr className="bg-primary-dark height-2px opacity-1" />

      <div className="mt-3">
        <p>
          Installer:{" "}
          <ConditionalRender value={data?.installer?.installerName} />
        </p>
        <p>
          Installer Rep:{" "}
          <ConditionalRender value={user?.username || data?.installer?.name} />
          {isAdmin && (
            <>
              &nbsp;
              <a
                href="#/"
                className="project-link"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setShowSelectUser(true);
                }}
              >
                Edit
              </a>
              <SelectUserModal
                onClose={() => setShowSelectUser(false)}
                onSelect={(user) => {
                  updateInstallerRep(user);
                }}
                selectedUser={user}
                show={showSelectUser}
              />
            </>
          )}
        </p>

        <ProjectIdDisplay
          canBeModified={canBeModified}
          data={data}
          editProjectId={editProjectId}
          id={id}
          inputRef={inputRef}
          onEdit={onEdit}
          onUpdateProjectId={onUpdateProjectId}
          projectId={projectId}
          setEditProjectId={setEditProjectId}
          setProjectId={setProjectId}
        />
      </div>
    </div>
  );
};
