import { createMigrate, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./slices";

const migrations = {
  1: (state: any) => {
    return state;
  },
  2: (state: any) => {
    // migration to add documentsMetadata
    return {
      ...state,
      loan: {
        ...state.loan,
        documentsMetadata: [],
      },
    };
  },
  3: (state: any) => {
    // updating issue loan doc type
    return {
      ...state,
      loan: {
        ...state.loan,
        batteryType: undefined,
        inverterType: undefined,
        moduleType: undefined,
      },
    };
  },
  4: (state: any) => {
    // updating issue loan doc type
    return {
      ...state,
      app: {
        ...state.app,
        authAppMfaSetupData: {
          mfaSetupSecretCode: null,
        },
        smsMfaSetupData: null,
      },
    };
  },
};

const persistConfig = {
  key: "root",
  version: 4,
  storage,
  migrate: createMigrate(migrations, { debug: false }),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
