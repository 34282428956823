import React from "react";
import Skeleton from "react-loading-skeleton";

export const OfferSkeleton = () => {
  return (
    <div className="skeleton-container border p-3 mt-5">
      <div className="">
        <h6 className="">
          <Skeleton count={1} />{" "}
        </h6>
      </div>

      <div className="d-flex mt-5 justify-content-center align-items-center flex-column">
        <h6 className="w-100">
          <Skeleton count={1} />{" "}
        </h6>
        <h6 className="w-100">
          <Skeleton count={1} />{" "}
        </h6>
      </div>
      <div className="d-flex mt-2">
        <Skeleton count={1} height={40} />
      </div>
      <div className="d-flex mt-2 justify-content-center align-items-center flex-column">
        <div className="d-flex justify-content-center w-100 gap-3 ">
          <p className="w-100 mb-0">
            <Skeleton count={1} /> &nbsp; &nbsp; &nbsp;
          </p>
          <p className="w-100 mb-0">
            <Skeleton count={1} />
          </p>
        </div>
        <div className="d-flex justify-content-center  w-100 gap-3 ">
          <p className="w-100 mb-0">
            <Skeleton count={1} /> &nbsp; &nbsp; &nbsp;
          </p>
          <p className="w-100 mb-0">
            <Skeleton count={1} />
          </p>
        </div>
        <div className="d-flex justify-content-center  w-100 gap-3 ">
          <p className="w-100 mb-0">
            <Skeleton count={1} /> &nbsp; &nbsp; &nbsp;
          </p>
          <p className="w-100 mb-0">
            <Skeleton count={1} />
          </p>
        </div>
      </div>
      <div className="d-flex mt-3 justify-content-between">
        <h6 className="w-100">
          <Skeleton count={1} />{" "}
        </h6>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <Skeleton count={1} width={180} height={40} />
      </div>
    </div>
  );
};
