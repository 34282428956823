import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export const NotAuthorized = () => (
  <div>
    <h3 className="p-5 text-center">Not Authorized</h3>
    <div className="text-center mt-5">
      <Link to="/auth/login">
        <Button>Login to your account</Button>
      </Link>
    </div>
  </div>
);
