import { UserAuthStatus } from "@shared/enums/UserState";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

type ContextProps = {
  authUserStatus: UserAuthStatus;
  setAuthUserStatus: Dispatch<SetStateAction<UserAuthStatus>>;
};

const authUserContext = createContext<ContextProps>({
  authUserStatus: UserAuthStatus.Idle,
  setAuthUserStatus: () => {},
});

export const useAuthUserContext = () => useContext(authUserContext);

export const AuthUserContext = ({ children }: { children: JSX.Element }) => {
  const [authUserStatus, setAuthUserStatus] = useState<UserAuthStatus>(
    UserAuthStatus.Idle
  );

  return (
    <authUserContext.Provider value={{ authUserStatus, setAuthUserStatus }}>
      {children}
    </authUserContext.Provider>
  );
};
