import { useEffect, useState } from "react";
import { VerificationsAndExceptions } from "./decisionTabs/VerificationsAndExceptions";
import { LenderNotes } from "./decisionTabs/LenderNotes";
import { DecisionRules } from "./decisionTabs/DecisionRules";
import { Tab, Tabs } from "react-bootstrap";
import { useQuery } from "@shared/helpers/global.helper";
import { QUERY_PARAMS_KEYS } from "@shared/constants/app.constant";
import { getLenderNotes } from "@shared/helpers/application.helper";
import { IPortalApplication } from "@shared/interfaces/PortalApplicationTypes";

export enum DecisionTabs {
  verifications = "verifications",
  evaluation = "evaluation",
  notes = "notes",
}

export const Decision = ({
  data,
}: {
  data: IPortalApplication | undefined;
}) => {
  const [activeTab, setActiveTab] = useState<DecisionTabs>(
    DecisionTabs.verifications
  );
  const query = useQuery();

  useEffect(() => {
    const param = query.get(
      QUERY_PARAMS_KEYS.REQUEST_DETAILS_SUB_TAB
    ) as DecisionTabs;
    if (param && Object.values(DecisionTabs).includes(param)) {
      setActiveTab(param);
    }
  }, [query]);

  return (
    <div className="mt-4">
      <Tabs
        id="application-tab"
        activeKey={activeTab}
        onSelect={(k: any) => setActiveTab(k)}
        className="text-primary main-tab pt-0"
        fill
      >
        <Tab
          eventKey={DecisionTabs.verifications}
          title="Verifications"
          className="decision-tabs"
        >
          <h3 className="text-primary-dark mt-0">
            Verifications and Exceptions
          </h3>
          <hr className="bg-primary-dark height-2px opacity-1" />
          <VerificationsAndExceptions data={data} />
        </Tab>
        <Tab
          eventKey={DecisionTabs.evaluation}
          title="Evaluation"
          className="decision-tabs"
        >
          <h3 className="text-primary-dark mt-0">Decision Rules</h3>
          <hr className="bg-primary-dark height-2px opacity-1 mb-4" />
          <DecisionRules data={data} />
        </Tab>
        <Tab
          eventKey={DecisionTabs.notes}
          title="Notes"
          className="decision-tabs"
        >
          {/* Lender Notes */}
          <h3 className="text-primary-dark mt-0">Lender Notes</h3>
          <hr className="bg-primary-dark height-2px opacity-1 mb-4" />
          <LenderNotes notes={getLenderNotes(data)} data={data} />
        </Tab>
      </Tabs>
    </div>
  );
};
