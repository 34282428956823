import { Row, Col, Button } from "react-bootstrap";
import { UseFormCheck } from "@shared/components/UserFormCheck";
import { UseFormDatePicker } from "@shared/components/UserFormDatePicker";
import { UseFormTextField } from "@shared/components/TextInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch } from "@store/hooks";
import { useHistory, useParams } from "react-router-dom";
import {
  removeSpaces,
  useQuery,
  onSubmitError,
} from "@shared/helpers/global.helper";
import { updatePrequalForMultiPrequalAction } from "@store/slices/loan.slice";
import { useEffect, useState } from "react";
import { MyInputMask } from "@shared/components/MyInputMask";
import { preQualAddcoBorrowerSchema } from "@shared/schema/request";
import { loanService } from "@services/loan.service";
import {
  MONTHS_ARRAY,
  SSN_MASK,
  YEARS_ARRAY,
} from "@shared/constants/app.constant";
import { UserFormCurrencyField } from "@shared/components/UserFormCurrencyField";
import {
  ApplicantType,
  ApplicationType,
} from "@shared/enums/ApplicationStatus";
import UserConsent from "@shared/components/UserConsent";
import { GoogleAutoComplete } from "@shared/components/GoogleAutoComplete";
import { UseFormRadio } from "@shared/components/UserFormRadio";
import { UseFormSelect } from "@shared/components/UserFormSelect";
import { formatDateForDl4Submission } from "@utils/date";
import ApplicationGuard from "@shared/components/ApplicationGuard";
import {
  IPortalApplication,
  ProductLos,
} from "@shared/interfaces/PortalApplicationTypes";

const CoBorrower = () => {
  const dispatch = useAppDispatch();
  const [requestDetail, setRequestDetail] = useState<IPortalApplication>();
  let { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [type, setType] = useState<any>("");
  const query = useQuery();
  const { control, handleSubmit, watch, reset, setValue } = useForm({
    resolver: yupResolver(preQualAddcoBorrowerSchema),
  });

  const onSubmit = (event: any) => {
    if (!requestDetail || !requestDetail.losAppNumber) {
      return;
    }
    const employment = {
      employmentType: {
        name: "Primary",
        label: "Primary",
        value: 1,
      },
      grossIncome: Number(event.grossIncome),
      payPeriod: 5,
      employmentStatus: {
        name: "Undefined",
        label: "Undefined",
        value: 0,
      },
    };
    let coBorrowerAddress;
    if (event.xDiffAddressThanApplicant === "No") {
      coBorrowerAddress = {
        ownershipType: {
          name: "Other",
          label: "Other",
          value: 4,
        },
        addressType: {
          name: "Primary",
          label: "Primary",
          value: 1,
        },
        addressFormat: {
          name: "Postal Standard",
          label: "Postal Standard",
          value: 5,
        },
        postalCode: event.coBorrowerPostalCode,
        addressLine1: event.coBorrowerAddressLine1,
        city: event.coBorrowerCity,
        state: event.coBorrowerState,
        yearsAtResidence: Number(event.coBorrowerYearsAtResidence),
        monthsAtResidence: Number(event.coBorrowerMonthsAtResidence),
      };
    }
    const address = requestDetail?.primaryApplicant?.currentAddress;
    let primaryAddress = [];
    if (address) {
      primaryAddress.push({
        ownershipType: {
          name: "Other",
          label: "Other",
          value: 4,
        },
        addressType: {
          name: "Primary",
          label: "Primary",
          value: 1,
        },
        addressFormat: {
          name: "Postal Standard",
          label: "Postal Standard",
          value: 5,
        },
        postalCode: address.postalCode,
        addressLine1: address.addressLine,
        city: address.city,
        state: address.state,
        yearsAtResidence: Number(address.yearsAtResidence),
        monthsAtResidence: Number(address.monthsAtResidence),
      });
    }
    const coBorrowerModel = {
      jsonType: "Applicant",
      applicantType: {
        name: "coBorrower",
        label: "Co-Borrower",
        value: 2,
      },
      lastName: event.lastName,
      firstName: event.firstName,
      ...(type !== "edit" && {
        // As we will be appending values from backend for edit
        SSN: removeSpaces(event.SSN),
        dateOfBirth: formatDateForDl4Submission(event.dateOfBirth),
      }),
      addresses:
        event.xDiffAddressThanApplicant === "No"
          ? [coBorrowerAddress]
          : primaryAddress,
      employments: [employment],
      xInstallationHomeOwner: 1,
      xDiffAddressThanApplicant:
        event.xDiffAddressThanApplicant === "Yes" ? false : true,
      ...(type === "edit" && {
        internalClientId: requestDetail?.losInternalClientId,
      }),
      ...(type === "edit" && {
        applicationId: requestDetail?.losApplicationId,
      }),
      ...(type === "edit" && {
        applicantId: requestDetail?.coApplicant?.applicantId,
      }),
    };

    dispatch(
      updatePrequalForMultiPrequalAction({
        appNumber: id,
        history,
        loanInfo: {
          losAppNumber: requestDetail?.losAppNumber,
          productLos: ProductLos.DL4,
          coBorrowerModel,
        },
        isMultiPrequal: false,
        path: "/pre-qualification/confirmation",
      })
    );
  };

  useEffect(() => {
    const getRequestData = async (id: string) => {
      const param = query.get("type");

      const response = await loanService.getRequestById(id);
      setRequestDetail(response?.data?.data);

      //don't bind the form if it is create new mode
      if (param !== "edit") return;
      setType(param);
      const applicant = response?.data?.data?.coApplicant;
      const primaryAddress = applicant?.currentAddress;
      reset({
        firstName: applicant?.firstName,
        lastName: applicant?.lastName,
        isUSCitizen: applicant?.isUSCitizen ? "Yes" : "No",
        grossIncome: applicant?.employment?.grossIncome,
        xDiffAddressThanApplicant:
          applicant?.differentAddressThanPrimaryApplicant ? "No" : "Yes",

        coBorrowerAddressLine1: primaryAddress?.addressLine,
        coBorrowerCity: primaryAddress?.city,
        coBorrowerState: primaryAddress?.state,
        coBorrowerPostalCode: primaryAddress?.postalCode,
        coBorrowerYearsAtResidence: primaryAddress?.yearsAtResidence,
        coBorrowerMonthsAtResidence: primaryAddress?.monthsAtResidence,
      });
    };
    getRequestData(id);
    if (query.get("type") !== "edit") {
      setTimeout(
        () => reset({ xDiffAddressThanApplicant: "Yes", SSN: "" }),
        1000
      );
    }
  }, [id, query, reset]);

  const xDiffAddressThanApplicant = watch("xDiffAddressThanApplicant");

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit, onSubmitError())}
    >
      <ApplicationGuard application={requestDetail} />
      <Col xs={12} md={10} lg={7} className="mx-auto mt-3 fullapp-request">
        <h3 className="h3 text-primary">
          {type === "edit" ? "Modify" : "Add"} Co-Borrower
        </h3>
        <hr className="text-primary height-2px" />

        <Row className="mt-4">
          <Col xs={12}>
            <h4 className="font-bold">Co-Borrower Information</h4>
          </Col>
          <Col xs={12} md={6} className="mt-3 mt-md-0">
            <UseFormTextField
              placeholder="First Name"
              control={control}
              name="firstName"
            />
          </Col>
          <Col xs={12} md={6} className="mt-3 mt-md-0">
            <UseFormTextField
              placeholder="Last Name"
              control={control}
              name="lastName"
            />
          </Col>
          {type !== "edit" && (
            <Col xs={12} md={6} className="mt-3">
              <MyInputMask
                control={control}
                name="SSN"
                mask={SSN_MASK}
                placeholder="SSN"
              />
            </Col>
          )}
          {type !== "edit" && (
            <Col xs={12} md={6} className="mt-3">
              <UseFormDatePicker
                placeholder="Date of Birth"
                maxDate={new Date()}
                control={control}
                controlName="dateOfBirth"
              />
            </Col>
          )}
        </Row>
        <Row className="mt-3">
          {typeof xDiffAddressThanApplicant !== "undefined" && (
            <Col className="mt-4">
              <h4 className="font-bold mr-4 mb-0">Co-Residency </h4>
              <h4 className="fw-normal mt-2">
                Does coborrower reside at the same residence as the primary
                borrower?{" "}
              </h4>
              <div className="d-flex flex-column">
                <UseFormRadio
                  control={control}
                  elementName="co-residency"
                  controlName="xDiffAddressThanApplicant"
                  content="Yes"
                  checked={xDiffAddressThanApplicant === "Yes" ? true : false}
                />
                <UseFormRadio
                  control={control}
                  elementName="co-residency"
                  controlName="xDiffAddressThanApplicant"
                  content="No"
                  checked={xDiffAddressThanApplicant === "No" ? true : false}
                />
              </div>
            </Col>
          )}
        </Row>
        {xDiffAddressThanApplicant === "No" && (
          <>
            <Row>
              <Col xs={12} className="mt-3">
                <h4 className="font-bold">Co-Borrower Address</h4>
              </Col>
              <Col xs={12}>
                <GoogleAutoComplete
                  placeholder="Street"
                  control={control}
                  name="coBorrowerAddressLine1"
                  onSelect={(address) => {
                    setValue("coBorrowerAddressLine1", address.street);
                    setValue("coBorrowerCity", address.city);
                    setValue("coBorrowerState", address.state);
                    setValue("coBorrowerPostalCode", address.zipCode);
                  }}
                />
              </Col>
              <Col xs={12} md={6} className="mt-3">
                <UseFormTextField
                  placeholder="City"
                  control={control}
                  name="coBorrowerCity"
                />
              </Col>
              <Col xs={6} md={3} className="mt-3">
                <UseFormTextField
                  placeholder="State"
                  control={control}
                  name="coBorrowerState"
                />
              </Col>
              <Col xs={6} md={3} className="mt-3">
                <UseFormTextField
                  placeholder="Zip Code"
                  control={control}
                  name="coBorrowerPostalCode"
                />
              </Col>
            </Row>
            <Row className="mt-3 d-flex align-items-center">
              <Col xs={4} sm={4} lg={3}>
                <label className="form-check-label">
                  Time with Current Residence:
                </label>
              </Col>
              <Col xs={6} sm={4} lg={3}>
                <UseFormSelect
                  list={YEARS_ARRAY}
                  control={control}
                  name="coBorrowerYearsAtResidence"
                  placeholder="Years"
                  placeholderValue={""}
                />
              </Col>
              <Col xs={6} sm={4} lg={3} className="mt-0 mt-sm-0">
                <UseFormSelect
                  list={MONTHS_ARRAY}
                  control={control}
                  name="coBorrowerMonthsAtResidence"
                  placeholder="Months"
                  placeholderValue={""}
                />
              </Col>
            </Row>
          </>
        )}
        <Row className="mt-4">
          <Col xs={12} sm={6}>
            <h4 className="font-bold">Co-Borrower Gross Annual Income</h4>
            <UserFormCurrencyField
              placeholder="Gross Annual Income"
              control={control}
              name="grossIncome"
              disabled={type === "edit"}
            />
          </Col>
          <p className="mt-2">
            Please enter annual income, which may include any income that is
            verifiable and reported to the Government, such as employment
            income, social security, pensions, etc.
          </p>
        </Row>

        <UserConsent
          applicant={ApplicantType.CoBorrower}
          type={ApplicationType.PreQual}
          authorization={
            <UseFormCheck
              control={control}
              controlName="authorization"
              content="Yes, I have read and agree to the following credit authorization:"
            />
          }
          consent={
            <UseFormCheck
              control={control}
              controlName="consent"
              content="Yes, I have read and agree to the following consents and disclosures:"
            />
          }
        />

        <div className="mt-5 d-flex justify-content-center mb-5">
          <Button variant="primary" type="submit">
            Submit Request
          </Button>
        </div>
      </Col>
    </form>
  );
};

export default CoBorrower;
