import {
  EMPLOYMENT_STATUS_LABEL,
  LOAN_PRODUCTS_CLASSIFICATION,
} from "@shared/constants/app.constant";
import {
  DealerIdTypes,
  ICustomNotes,
  IModifyLoanPayload,
  IPrequalifiedOffer,
  IXPrequalProductsTypeValues,
  Nillable,
} from "@shared/interfaces/Application";
import { UserRole } from "@shared/interfaces/User";
import { IAuthData } from "@store/slices/app.slice";
import {
  getInternalProductsList,
  getProgramType,
  getValueIfNotNull,
} from "./global.helper";
import {
  IPortalApplication,
  PortalApplicationStatus,
} from "@shared/interfaces/PortalApplicationTypes";

export const generateModifyLoanPayload = (
  application: IPortalApplication,
  user: IAuthData | null
): IModifyLoanPayload => {
  const installationHomeOwner = getValueIfNotNull(
    application?.installationAddress?.installationHomeOwner
  );
  const installationResidency = getValueIfNotNull(
    application?.installationAddress?.installationResidency
  );

  const { xPrequalProductsSelected } = getXPrequalProducts(
    user,
    application?.programTypeCode?.toString()
  );
  const productInfo: IXPrequalProductsTypeValues | undefined =
    xPrequalProductsSelected?.[0];

  const payload: IModifyLoanPayload = {
    xProgramTypeName: productInfo?.xProgramTypeName,
    decisionProcessPrefs: {
      defaultCarletonDATFileName: productInfo?.defaultCarletonDATFileName,
    },
    xLender: productInfo?.xLender,
    xInstallationAddressLine: application?.installationAddress?.addressLine,
    xInstallationCity: application?.installationAddress?.city,
    xInstallationState: application?.installationAddress?.state,
    xInstallationZipCode: application?.installationAddress?.zipCode,
    xInstalationAddressSame:
      !!!application?.installationAddress?.isInstallationAddressDifferent,
    xStatedInstallationHomeOwner: installationHomeOwner,
    installationResidency: installationResidency,
    loanInformation: {
      loanVersions: [
        {
          prequalStage: 0,
          totalCashPrice: application?.loanOffer?.amountFinanced || 0,
          requestedTerm: productInfo?.requestedTerm,
          firstPaymentDateStandard: productInfo?.firstPaymentDateStandard,
          contractDateStandard: productInfo?.contractDateStandard,
          userRate: productInfo?.userRate,
          xRiskBasedRateTable: productInfo?.xRisckBasedIntialRateTable,
          xRiskBasedInitialRateTable: productInfo?.xRisckBasedIntialRateTable,
          commonLoanInfo: {
            xprogramtype: application?.programTypeCode || 0,
            xInstallationAddressLine:
              application?.installationAddress?.addressLine,
            xInstallationCity: application?.installationAddress?.city,
            xInstallationState: application?.installationAddress?.state,
            xInstallationZipCode: application?.installationAddress?.zipCode,
            xInstalationAddressSame:
              !!!application?.installationAddress
                ?.isInstallationAddressDifferent,
            xStatedInstallationHomeOwner: installationHomeOwner,
            installationResidency: installationHomeOwner,
            xinitialTerm: productInfo?.xinitialTerm,
            xAmortizationTerm: productInfo?.xAmortizationTerm,
            xFinalPmtDiff: productInfo?.xFinalPmtDiff,
            xMembershipFee: productInfo?.xMembershipFee,
            xFlicFlatFee: productInfo?.xFlicFlatFee,
            xFlicPlatformFeePercent: productInfo?.xFlicPlatformFeePrecent,
            xSellersPointsPercent: productInfo?.xSellerPointsPercent,
            xInitialRate: productInfo?.xInitialRate,
            xFinalRate: productInfo?.xFinalRate,
          },
        },
      ],
    },
  };

  return payload;
};

export const enableQcPassFail = (
  requestDetail: IPortalApplication | undefined,
  user: IAuthData | null
): boolean => {
  return (
    !!user?.role &&
    [UserRole.providerAdmin, UserRole.financialInstitution].includes(
      user?.role
    ) &&
    !!requestDetail &&
    requestDetail?.status?.applicationStatus ===
      PortalApplicationStatus.QCReview
    // (requestDetail?.applicationStatus?.value === 18 ||
    //   requestDetail?.applicationStatus?.value === 25)
  ); // QC Review and QC Re-review
};

export const enableBookingConfirmation = (
  requestDetail: IPortalApplication | undefined,
  user: IAuthData | null
): boolean => {
  return (
    !!user?.role &&
    [UserRole.providerAdmin, UserRole.financialInstitution].includes(
      user?.role
    ) &&
    !!requestDetail &&
    requestDetail?.status?.applicationStatus ===
      PortalApplicationStatus.RFFIssued
  ); // RFF Issued
};

export const isPrequalApplication = (
  data: IPortalApplication | undefined
): boolean => {
  return !!data?.prequal;
  // return data?.Prequal === true || data?.prequalStage?.value === 1;
};

export const generateXPrequalProducts = (
  programTypes: number[],
  dealer: Nillable<DealerIdTypes>
): IXPrequalProductsTypeValues[] => {
  const payload: IXPrequalProductsTypeValues[] = programTypes.map((item) => ({
    ...LOAN_PRODUCTS_CLASSIFICATION[item],
    xFlicPlatformFeePrecent:
      LOAN_PRODUCTS_CLASSIFICATION[item]?.xFlicPlatformFeePrecent?.(dealer),
    xSellerPointsPercent:
      LOAN_PRODUCTS_CLASSIFICATION[item]?.xSellerPointsPercent?.(dealer),
    userRate: LOAN_PRODUCTS_CLASSIFICATION[item]?.userRate?.(dealer),
    xInitialRate: LOAN_PRODUCTS_CLASSIFICATION[item]?.xInitialRate?.(dealer),
    xFinalRate: LOAN_PRODUCTS_CLASSIFICATION[item]?.xFinalRate?.(dealer),
    xRiskBasedRateTable:
      LOAN_PRODUCTS_CLASSIFICATION[item]?.xRiskBasedRateTable?.(dealer),
    xRisckBasedIntialRateTable:
      LOAN_PRODUCTS_CLASSIFICATION[item]?.xRisckBasedIntialRateTable?.(dealer),
  }));
  return payload;
};

export const getXPrequalProducts = (
  user: IAuthData | null,
  xprogramtype: string
): {
  xPrequalProductsSelected: IXPrequalProductsTypeValues[];
  xPrequalProductsAll: IXPrequalProductsTypeValues[];
} => {
  const xPrequalProductsProgramType: number[] = getInternalProductsList(
    user?.role,
    user?.userDetails?.accessManagement
  )?.map((item) => item.value as number);

  // if user doesn't want to apply to prequalify for the other products
  const xPrequalProductsSelected = generateXPrequalProducts(
    [Number(xprogramtype)],
    user?.userDetails?.dealer as Nillable<DealerIdTypes>
  );
  // xPrequalProductsAll will be sent when the system applies for the other prequal loans when the current loan is declined
  const xPrequalProductsAll = generateXPrequalProducts(
    xPrequalProductsProgramType,
    user?.userDetails?.dealer as Nillable<DealerIdTypes>
  );

  return {
    xPrequalProductsSelected,
    xPrequalProductsAll,
  };
};

export const generatePrimaryApplicantPayload = (
  application: IPortalApplication
) => {
  const primaryApplicant = application.primaryApplicant;
  const primaryAddress = primaryApplicant?.currentAddress;

  const applicantAddresses = [];

  const currentAddress = {
    ownershipType: {
      name: "Other",
      label: "Other",
      value: 4,
    },
    addressType: {
      name: "Primary",
      label: "Primary",
      value: 1,
    },
    addressFormat: {
      name: "Postal Standard",
      label: "Postal Standard",
      value: 5,
    },
    postalCode: primaryAddress?.postalCode,
    addressLine1: primaryAddress?.addressLine,
    city: primaryAddress?.city,
    state: primaryAddress?.state,
    yearsAtResidence: primaryAddress?.yearsAtResidence,
    monthsAtResidence: primaryAddress?.monthsAtResidence,
  };
  applicantAddresses.push(currentAddress);

  const employment = {
    employmentType: {
      name: "Primary",
      label: "Primary",
      value: 1,
    },
    grossIncome: primaryApplicant?.employment?.grossIncome,
    payPeriod: 5,
    employmentStatus: {
      name: "Undefined",
      label: "Undefined",
      value: 0,
    },
  };

  const primaryApplicantPayload = {
    jsonType: "Applicant",
    applicantType: {
      name: "primary",
      label: "primary",
      value: 1,
    },
    lastName: primaryApplicant?.lastName,
    firstName: primaryApplicant?.firstName,
    addresses: applicantAddresses,
    employments: [employment],
    xInstallationHomeOwner: 1,

    internalClientId: application?.losInternalClientId,
    applicationId: application?.losApplicationId,
    applicantId: primaryApplicant?.applicantId,
  };
  return primaryApplicantPayload;
};

export const generateCoBorrowerApplicantPayload = (
  application: IPortalApplication
) => {
  const coApplicant = application.coApplicant;
  if (!coApplicant) {
    return;
  }
  const coBorrowerPrimaryAddress = coApplicant?.currentAddress;

  if (coBorrowerPrimaryAddress === null) {
    throw new Error(
      "Address is null. This function is meant to be used with a DL4 application."
    );
  }

  const employment = {
    employmentType: {
      name: "Primary",
      label: "Primary",
      value: 1,
    },
    grossIncome: coApplicant?.employment?.grossIncome,
    payPeriod: 5,
    employmentStatus: {
      name: "Undefined",
      label: "Undefined",
      value: 0,
    },
  };
  let coBorrowerAddress;
  if (coApplicant.differentAddressThanPrimaryApplicant) {
    coBorrowerAddress = {
      ownershipType: {
        name: "Other",
        label: "Other",
        value: 4,
      },
      addressType: {
        name: "Primary",
        label: "Primary",
        value: 1,
      },
      addressFormat: {
        name: "Postal Standard",
        label: "Postal Standard",
        value: 5,
      },
      postalCode: coBorrowerPrimaryAddress.postalCode,
      addressLine1: coBorrowerPrimaryAddress.addressLine,
      city: coBorrowerPrimaryAddress.city,
      state: coBorrowerPrimaryAddress.state,
      yearsAtResidence: coBorrowerPrimaryAddress.yearsAtResidence,
      monthsAtResidence: coBorrowerPrimaryAddress.monthsAtResidence,
    };
  }
  const address = coApplicant?.currentAddress;
  let primaryAddress = [];
  if (address) {
    primaryAddress.push({
      ownershipType: {
        name: "Other",
        label: "Other",
        value: 4,
      },
      addressType: {
        name: "Primary",
        label: "Primary",
        value: 1,
      },
      addressFormat: {
        name: "Postal Standard",
        label: "Postal Standard",
        value: 5,
      },
      postalCode: address.postalCode,
      addressLine1: address.addressLine,
      city: address.city,
      state: address.state,
      yearsAtResidence: Number(address.yearsAtResidence),
      monthsAtResidence: Number(address.monthsAtResidence),
    });
  }
  const model = {
    jsonType: "Applicant",
    applicantType: {
      name: "coBorrower",
      label: "Co-Borrower",
      value: 2,
    },
    lastName: coApplicant?.lastName,
    firstName: coApplicant?.firstName,
    addresses: coApplicant.differentAddressThanPrimaryApplicant
      ? [coBorrowerAddress]
      : primaryAddress,
    employments: [employment],
    xInstallationHomeOwner: 1,
    xDiffAddressThanApplicant: coApplicant.differentAddressThanPrimaryApplicant,
    internalClientId: application?.losInternalClientId,
    applicationId: application?.losApplicationId,
    applicantId: coApplicant?.applicantId,
  };
  return model;
};

// TODO: Remove this after the deployment
// export const generateLoanObjForMultiPrequal = (
//   application: IApplication,
//   dataToUpdate?: {
//     xprogramtype?: number;
//     primaryApplicantModel?: any;
//     coBorrowerModel?: any;
//     xPrequalProducts?: IXPrequalProductsTypeValues[];
//   }
// ) => {
//   const applicants = [];

//   const {
//     xprogramtype,
//     primaryApplicantModel,
//     coBorrowerModel,
//     xPrequalProducts,
//   } = dataToUpdate || {};
//   applicants.push(
//     primaryApplicantModel || generatePrimaryApplicantPayload(application)
//   );

//   if (application?.coapplicant || coBorrowerModel) {
//     applicants.push(
//       coBorrowerModel || generateCoBorrowerApplicantPayload(application)
//     );
//   }

//   const loanObjPayload = {
//     applicants,
//     loanInformation: {
//       loanVersions: [
//         {
//           commonLoanInfo: {
//             xprogramtype:
//               xprogramtype ||
//               application?.loanInformation?.currentLoanVersion?.commonLoanInfo
//                 ?.xprogramtype,
//           },
//         },
//       ],
//     },
//     xprogramtype:
//       xprogramtype ||
//       application?.loanInformation?.currentLoanVersion?.commonLoanInfo
//         ?.xprogramtype,
//     xPrequalProducts: xPrequalProducts,
//   };

//   return loanObjPayload;
// };

export const isMultiPrequalApplication = (
  application: IPortalApplication
): boolean => {
  return (
    !!application?.prequalProducts && application?.prequalProducts.length > 0
  );
};

export const getPrequalifiedOfferFromAppData = (
  application: IPortalApplication,
  prequalifiedOfferId: number
): IPrequalifiedOffer | undefined => {
  return application?.prequalifiedOffers?.find(
    (item) => item.prequalifiedOfferId === prequalifiedOfferId && item.active
  );
};

export const getProductNameForOfferCard = (
  xprogramtype: string | number
): {
  productName: string;
  productNameInBrackets: string;
} => {
  const originalName = getProgramType(xprogramtype) || "";
  const productName = originalName.split("(", 1)[0];

  const productNameInBrackets =
    originalName.includes("(") && originalName.includes(")")
      ? originalName.substring(
          originalName.indexOf("(") + 1,
          originalName.lastIndexOf(")")
        )
      : "";
  return {
    productName,
    productNameInBrackets,
  };
};

const NOTES_CLIENT_TYPE = "Dealer";

export const getLenderNotes = (
  data: IPortalApplication | undefined
): ICustomNotes[] => {
  return data?.customNotes
    ? data?.customNotes?.filter((i) => i.toClientTypeStr === NOTES_CLIENT_TYPE)
    : [];
};

export const getEmployerNameForRequestPayloadToDl4 = (applicantPayload: {
  employmentStatus: number;
  employerName: string;
}): string => {
  switch (applicantPayload.employmentStatus) {
    case 1:
      return applicantPayload.employerName;
    case 2:
      // Retired case
      return EMPLOYMENT_STATUS_LABEL.RETIRED;
    case 3:
      // Self Employed case
      return EMPLOYMENT_STATUS_LABEL.SELF_EMPLOYED;
    case 4:
      // Unemployed case
      return EMPLOYMENT_STATUS_LABEL.UNEMPLOYED;
    default:
      return "";
  }
};
