import React from "react";
import Skeleton from "react-loading-skeleton";

export const UserCardSkeleton = () => {
  return (
    <div className="user-row skeleton-p">
      <div className="d-flex">
        <Skeleton count={1} width={280} />
        <Skeleton count={1} width={280} />
        <Skeleton count={1} width={280} />
      </div>
    </div>
  );
};
