import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@store/store";
import { getDropdownValueForIssueDoc } from "@shared/helpers/global.helper";
import { loanService } from "@services/loan.service";
import { ISungageEquipmentResponse } from "@shared/interfaces/SungageTypes";
import { sungageService } from "@services/sungage.service";

export interface IIssueDocType {
  label: string;
  value: number;
}

export interface IIssueDocWithSizeType extends IIssueDocType {
  size: number;
}

interface CommonDataState {
  issueLoanDocType: {
    batteryType: IIssueDocWithSizeType[];
    batteryExpansionType: IIssueDocWithSizeType[];
    inverterType: IIssueDocType[];
    moduleType: IIssueDocWithSizeType[];
  };
  sungageEquipment: ISungageEquipmentResponse;
}

const initialState: CommonDataState = {
  issueLoanDocType: {
    batteryType: [],
    inverterType: [],
    moduleType: [],
    batteryExpansionType: [],
  },
  sungageEquipment: {
    modules: [],
    batteries: [],
    batteryExtensions: [],
  },
};

export const getTypesAction = createAsyncThunk(
  "commonData/getTypes",
  async (args: undefined, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await loanService.getIssueDocsType();

      dispatch(
        setIssueLoanType({
          batteryType: data?.data?.batteryType,
          inverterType: data?.data?.inverterType,
          moduleType: data?.data?.moduleType,
          batteryExpansionType: data?.data?.batteryExpansionType,
        })
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSungageEquipmentAction = createAsyncThunk(
  "commonData/getSungageEquipment",
  async (args: undefined, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await sungageService.getSungageEquipment();

      dispatch(setSungageEquipment(data));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const commonDataSlice = createSlice({
  name: "commonData",
  initialState,
  reducers: {
    setIssueLoanType: (
      state: any,
      {
        payload: {
          batteryType,
          inverterType,
          moduleType,
          batteryExpansionType,
        },
      }: PayloadAction<{
        batteryType: IIssueDocWithSizeType[];
        inverterType: IIssueDocType[];
        moduleType: IIssueDocWithSizeType[];
        batteryExpansionType: IIssueDocWithSizeType[];
      }>
    ) => {
      state.issueLoanDocType.batteryType =
        getDropdownValueForIssueDoc(batteryType);
      state.issueLoanDocType.inverterType =
        getDropdownValueForIssueDoc(inverterType);
      state.issueLoanDocType.moduleType =
        getDropdownValueForIssueDoc(moduleType);
      state.issueLoanDocType.batteryExpansionType =
        getDropdownValueForIssueDoc(batteryExpansionType);
    },
    setSungageEquipment: (
      state: CommonDataState,
      { payload }: PayloadAction<ISungageEquipmentResponse>
    ) => {
      state.sungageEquipment = payload;
    },
  },
});

export const { reducer: commonDataReducer } = commonDataSlice;
export const { setIssueLoanType, setSungageEquipment } =
  commonDataSlice.actions;

export const issueLoanDocTypeSelector = (rootState: RootState) =>
  rootState.commonData.issueLoanDocType;

export const sungageEquipmentSelector = (rootState: RootState) =>
  rootState.commonData.sungageEquipment;
