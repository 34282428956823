import { Redirect } from "react-router-dom";
import { isNonModifiable } from "@shared/helpers/global.helper";
import { useAppSelector } from "@store/hooks";
import { appUserSelector } from "@store/slices/app.slice";
import { IPortalApplication } from "@shared/interfaces/PortalApplicationTypes";

/**
 * This component redirects the user to the Pipelines page
 * if an Application is restricted from being modified
 */
const ApplicationGuard = ({
  application,
}: {
  application?: IPortalApplication | null | any;
}) => {
  const user = useAppSelector(appUserSelector);
  if (!!application && isNonModifiable(application, user?.role)) {
    return <Redirect to="/pipelines" />;
  }
  return <></>;
};

export default ApplicationGuard;
