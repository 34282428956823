import { ChangeEvent } from "react";
import { IStipulation } from "@shared/interfaces/Application";
import { DownloadDocumentPayload } from "@store/slices/loan.slice";
import { isPending } from "@shared/helpers/global.helper";
import { StipulationCard } from "./StipulationCard";
import { IPortalApplication } from "@shared/interfaces/PortalApplicationTypes";

export const ApplicantsIdentification = ({
  requestDetail,
  uploadingDocType,
  errorMessage,
  docId,
  onDocumentInputFileChange,
  uploadIdStip,
  downloadDocument,
  canBeModified,
}: {
  requestDetail: IPortalApplication | undefined;
  uploadingDocType: string;
  errorMessage: string;
  docId: string;
  onDocumentInputFileChange: (
    event: ChangeEvent<HTMLInputElement>,
    docId: string,
    callback?: () => void
  ) => Promise<void>;
  uploadIdStip: Array<IStipulation>;
  downloadDocument: (value: DownloadDocumentPayload) => void;
  canBeModified: boolean;
}) => {
  if (
    !requestDetail?.status?.decisionStatus ||
    isPending(requestDetail?.status?.decisionStatus)
  ) {
    return null;
  }

  return (
    <div>
      <h3 className="h3 text-primary-dark mt-4">Applicant's Identification</h3>
      <hr className="bg-primary-dark height-2px opacity-1" />
      <div>
        {uploadIdStip ? (
          <h4 className="font-bold-800 mt-4">
            Upload Government Issued ID for Each Applicant:
          </h4>
        ) : (
          <h4 className="font-bold-800 mt-4">
            There are no documents required for this loan
          </h4>
        )}
        {uploadIdStip?.map((item: any, i: number) => {
          return (
            <StipulationCard
              key={i}
              requestDetail={requestDetail}
              stipulation={item}
              onDocumentInputFileChange={onDocumentInputFileChange}
              docId={docId}
              errorMessage={errorMessage}
              uploadingDocType={uploadingDocType}
              downloadDocument={downloadDocument}
              canBeModified={canBeModified}
            />
          );
        })}
      </div>
    </div>
  );
};
