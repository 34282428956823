import React from "react";
import NumberFormat from "@shared/components/NumberFormat";
import { getProductNameForOfferCard } from "@shared/helpers/application.helper";
import { IPrequalifiedOffer } from "@shared/interfaces/Application";
import { Button, Table } from "react-bootstrap";

const MultiPrequalLoanOfferCard = ({
  offer,
  handleConverToFullApp,
}: {
  offer: IPrequalifiedOffer;
  handleConverToFullApp: (offerId: number) => void;
}) => {
  const { productName, productNameInBrackets } = getProductNameForOfferCard(
    offer?.xprogramtype
  );
  return (
    <div className="multi-prequal-offer-card position-relative">
      <div className="title-container">
        <p className="title">{productName}</p>
        {productNameInBrackets ? (
          <p className="card-with-message">{productNameInBrackets}</p>
        ) : null}

        <p className="prequalified-for-text mt-3">
          Prequalified for up to{" "}
          <span className="amount d-inline">
            <NumberFormat amount={offer?.amount} />
          </span>
        </p>
      </div>
      <div>
        <div className="summary-table">
          <Table>
            <thead>
              <tr>
                <th className="py-3 term-for-prequal">Term</th>
                <th className="py-3 interest-rate-for-prequal">
                  Interest Rate
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {offer?.xHasIntroPeriod === false && (
                  <td className="term term-for-prequal">
                    Months 1-{offer?.term}
                  </td>
                )}
                {offer?.xHasIntroPeriod === true && (
                  <td className="term term-for-prequal">
                    Months 1-{offer?.xIntroTerm}
                  </td>
                )}
                <td className="interest-rate-for-prequal">
                  <NumberFormat
                    amount={offer?.xInitialRate}
                    isCurrency={false}
                    symbol="%"
                  />
                </td>
              </tr>
              {offer?.xHasIntroPeriod === true && (
                <tr>
                  <td className="term term-for-prequal">
                    Months {Number(offer?.xIntroTerm) + 1}-{offer?.term}
                  </td>
                  <td className="interest-rate-for-prequal">
                    <NumberFormat
                      amount={offer?.xFinalRate}
                      isCurrency={false}
                      symbol="%"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            onClick={() => handleConverToFullApp(offer.prequalifiedOfferId)}
          >
            Apply for this Loan
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MultiPrequalLoanOfferCard;
