import { useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { UseFormTextField } from "@shared/components/TextInput";
import { useFormContext } from "react-hook-form";
import { useAppSelector } from "@store/hooks";
import { UseFormSelect } from "@shared/components/UserFormSelect";
import { UseFormCheck } from "@shared/components/UserFormCheck";
import { UseFormDatePicker } from "@shared/components/UserFormDatePicker";
import {
  CITIZENSHIP,
  EMPLOYMENT_STATUS_LIST,
  HOME_OWNERSHIP_LIST,
  INSTALLATION_RESIDENCY,
  MOBILE_MASK,
  MONTHS_ARRAY,
  PRIVATE_PROGRAM_TYPE,
  PROPERTY_TYPE_LIST,
  REVERSE_MORTGAGE_LIST,
  SSN_MASK,
  TIME_ON_JOB_TEXT,
  YEARS_ARRAY,
} from "@shared/constants/app.constant";
import { onSubmitError, getProductsList } from "@shared/helpers/global.helper";
import { loanSliceSelector } from "@store/slices/loan.slice";
import { MyInputMask } from "@shared/components/MyInputMask";
import { UserFormCurrencyField } from "@shared/components/UserFormCurrencyField";
import {
  ApplicantType,
  ApplicationType,
} from "@shared/enums/ApplicationStatus";
import UserConsent from "@shared/components/UserConsent";
import { UseFormRadio } from "@shared/components/UserFormRadio";
import { GoogleAutoComplete } from "@shared/components/GoogleAutoComplete";
import { appUserSelector } from "@store/slices/app.slice";
import PrivateLoanProductRedirect from "@shared/components/PrivateLoanProductRedirect";
import { useAuthUserContext } from "@contexts/AuthContext";
import { EmploymentStatus } from "@shared/enums/EmploymentStatus";
import IncomePrompt from "@shared/components/IncomePrompt";
import SystemInfoText from "@shared/components/SystemInfoText";
import useSungageEquipment from "@hooks/useSungageEquipment";
import useTotalSystemSize from "@hooks/useTotalSystemSize";

interface IFullApplicationFormProps {
  onSubmit: (e: any) => void;
  /** Prequalified product in case of converting to full application */
  prequalProduct?: number;
  isConvertingtoFullApp?: boolean;
}

const FullApplicationForm = ({
  onSubmit,
  prequalProduct,
  isConvertingtoFullApp = false,
}: IFullApplicationFormProps) => {
  const loanSlice = useAppSelector(loanSliceSelector);
  const user = useAppSelector(appUserSelector);
  const sungageEquipment = useSungageEquipment();
  const { authUserStatus } = useAuthUserContext();

  const { control, handleSubmit, watch, setValue } = useFormContext();

  const [
    installationAddressIsDifferent,
    hasCoApplicant,
    isMailingAddressDifferent,
    isCoBorrowerMailingAddressDiff,
    isDiffAddressThanPrimary,
    isUSCitizen,
    coBorrowerusCitizen,
    loanType,
    panelCost,
    batteryCost,
    moduleType,
    batteryType,
    batteryExtensionType,
    panelQuantity,
    batteryQuantity,
    batteryExtensionQuantity,
    initialCoBorrowerData,
  ] = watch([
    "installationAddressIsDifferent",
    "hasCoApplicant",
    "isMailingAddressDifferent",
    "isCoBorrowerMailingAddressDifferrent",
    "isDiffAddressThanPrimary",
    "isUSCitizen",
    "coBorrowerusCitizen",
    "loanType",
    "panelCost",
    "batteryCost",
    "moduleType",
    "batteryType",
    "batteryExtensionType",
    "panelQuantity",
    "batteryQuantity",
    "batteryExtensionQuantity",
    "initialCoBorrowerData",
  ]);

  const isSungageProduct = loanType === "1001";

  const employmentStatus = Number(watch("employmentStatus"));
  const coBorrowerEmploymentStatus = Number(
    watch("coBorrowerEmploymentStatus")
  );

  const isPrimaryEmployed =
    employmentStatus === EmploymentStatus.EMPLOYED ||
    (isSungageProduct && employmentStatus === EmploymentStatus.SELF_EMPLOYED);

  const isSecondaryEmployed =
    coBorrowerEmploymentStatus === EmploymentStatus.EMPLOYED ||
    (isSungageProduct &&
      coBorrowerEmploymentStatus === EmploymentStatus.SELF_EMPLOYED);

  const { totalSolarSize, totalBatterySize, moduleSize, batterySize } =
    useTotalSystemSize({
      moduleType,
      batteryType,
      batteryExtensionType,
      panelQuantity,
      batteryQuantity,
      batteryExtensionQuantity,
    });

  useEffect(() => {
    setValue("totalBatterySize", totalBatterySize, { shouldValidate: true });
  }, [totalBatterySize, setValue]);

  const selectedModule =
    moduleType && sungageEquipment?.modules !== undefined
      ? sungageEquipment?.modules.find(
          (item) => item.value === Number(moduleType)
        )
      : undefined;

  const selectedBattery =
    batteryType && sungageEquipment?.batteries !== undefined
      ? sungageEquipment?.batteries.find(
          (item) => item.value === Number(batteryType)
        )
      : undefined;

  const selectedBatteryExtension =
    batteryExtensionType && sungageEquipment?.batteryExtensions !== undefined
      ? sungageEquipment?.batteryExtensions.find(
          (item) => item.value === Number(batteryExtensionType)
        )
      : undefined;

  const isPanelsFieldsDisabled =
    totalSolarSize === 0 && moduleSize === 0 && selectedModule?.text === "None";

  const isBatteryQuantityDisabled =
    batterySize === 0 &&
    selectedBattery !== undefined &&
    selectedBattery?.text === "None";

  const isBatteryExtensionQuantityDisabled =
    selectedBatteryExtension?.text === "None";

  const isBatteryCostDisabled =
    totalBatterySize === 0 &&
    isBatteryQuantityDisabled &&
    isBatteryExtensionQuantityDisabled;

  // for Sungage products, loan amount is calculated from panel and battery cost
  useEffect(() => {
    if (
      isSungageProduct &&
      (typeof panelCost !== "undefined" || typeof batteryCost !== "undefined")
    ) {
      const calculatedLoanAmount =
        (panelCost !== undefined ? Number(panelCost) : 0) +
        (batteryCost !== undefined ? Number(batteryCost) : 0);

      setValue("loanAmount", calculatedLoanAmount, { shouldValidate: true });
    }
  }, [panelCost, batteryCost, isSungageProduct, setValue]);

  const isExternalLoanProduct = !!PRIVATE_PROGRAM_TYPE.find(
    (product) =>
      loanType &&
      product.id === loanType?.toString() &&
      product.type === "EXTERNAL"
  );

  return (
    <Col xs={12} md={10} lg={7} className="mx-auto mt-3 fullapp-request">
      <h3 className="h3 text-primary">Solar Loan Application</h3>
      <hr className="text-primary height-2px" />
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit, onSubmitError())}
      >
        <Row className="mt-4">
          <Col xs={12}>
            <h4 className="font-bold">Loan Information</h4>
          </Col>
          <Col xs={12} md={6}>
            <UseFormSelect
              list={getProductsList(
                authUserStatus,
                user?.role,
                user?.userDetails?.accessManagement,
                isConvertingtoFullApp ? "modify" : "new",
                isConvertingtoFullApp && prequalProduct
                  ? prequalProduct
                  : loanSlice.requestDraft?.loanProduct
                  ? Number(loanSlice.requestDraft?.loanProduct)
                  : undefined
              )}
              control={control}
              name="loanType"
              placeholder="Select Loan Product"
            />
          </Col>
          {!isExternalLoanProduct && (
            <Col xs={12} md={6} className="mt-3 mt-md-0">
              <UserFormCurrencyField
                placeholder="Loan Amount"
                control={control}
                type="number"
                name="loanAmount"
                disabled={isSungageProduct}
              />
            </Col>
          )}
        </Row>
        {isSungageProduct && (
          <>
            <Row className="mt-4">
              <h4 className="font-bold">Estimated System Size</h4>
              <Col xs={12}>
                <Row className="align-items-start">
                  <Col md={3} xs={12}>
                    <label className="form-check-label">Panels:</label>
                  </Col>
                  <Col md={9} className="mt-3 mt-md-0">
                    <Row>
                      <Col md={6} xs={12}>
                        <UseFormSelect
                          list={sungageEquipment?.modules}
                          control={control}
                          name="moduleType"
                          placeholder="Module Model Number"
                        />
                      </Col>
                      <Col md={6} xs={12} className="mt-3 mt-md-0">
                        <UseFormTextField
                          placeholder="Panel Quantity"
                          control={control}
                          name="panelQuantity"
                          type="number"
                          disabled={isPanelsFieldsDisabled}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-3 align-items-start">
                  <Col md={3}>
                    <label className="form-check-label">Batteries:</label>
                  </Col>
                  <Col md={9} className="mt-3 mt-md-0">
                    <Row>
                      <Col md={6} xs={12}>
                        <UseFormSelect
                          list={sungageEquipment?.batteries}
                          control={control}
                          name="batteryType"
                          placeholder="Battery Type"
                        />
                      </Col>
                      <Col md={6} xs={12} className="mt-3 mt-md-0">
                        <UseFormTextField
                          placeholder="Battery Quantity"
                          control={control}
                          name="batteryQuantity"
                          type="number"
                          disabled={isBatteryQuantityDisabled}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-3 align-items-start">
                  <Col md={3}>
                    <label className="form-check-label">
                      Battery Extensions:
                    </label>
                  </Col>
                  <Col md={9} className="mt-3 mt-md-0">
                    <Row>
                      <Col md={6} xs={12}>
                        <UseFormSelect
                          list={sungageEquipment?.batteryExtensions}
                          control={control}
                          name="batteryExtensionType"
                          placeholder="Battery Extension Model"
                        />
                      </Col>
                      <Col md={6} xs={12} className="mt-3 mt-md-0">
                        <UseFormTextField
                          placeholder="Battery Extension Quantity"
                          control={control}
                          name="batteryExtensionQuantity"
                          type="number"
                          disabled={isBatteryExtensionQuantityDisabled}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col xs={12} md={6}>
                    <SystemInfoText
                      label="Total Solar System Size"
                      value={`${totalSolarSize} kW`}
                    />
                  </Col>
                  <Col xs={12} md={6} className="mt-2 mt-md-0">
                    <SystemInfoText
                      label="Module Wattage"
                      value={`${moduleSize} W`}
                    />
                  </Col>
                </Row>
                <Row className="mt-2 mt-md-4">
                  <Col xs={12} md={6}>
                    <SystemInfoText
                      label="Total Battery Size"
                      value={`${totalBatterySize} kWh`}
                    />
                    <UseFormTextField
                      control={control}
                      name="totalBatterySize"
                      type="hidden"
                      controlledValue={totalBatterySize}
                    />
                  </Col>
                  <Col xs={12} md={6} className="mt-2 mt-md-0">
                    <SystemInfoText
                      label="Battery Wattage"
                      value={`${batterySize} kWh`}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-4">
              <h4 className="font-bold">System Cost</h4>
              <Col xs={12}>
                <Row className="align-items-center">
                  <Col md={2}>
                    <label className="form-check-label">Solar Cost:</label>
                  </Col>
                  <Col className="mt-3 mt-md-0">
                    <UserFormCurrencyField
                      placeholder="Panel Cost"
                      control={control}
                      name="panelCost"
                      type="number"
                      disabled={isPanelsFieldsDisabled}
                    />
                  </Col>
                  <Col md={2} className="mt-3 mt-md-0">
                    <label className="form-check-label">Batteries:</label>
                  </Col>
                  <Col className="mt-3 mt-md-0">
                    <UserFormCurrencyField
                      placeholder="Battery Cost"
                      control={control}
                      name="batteryCost"
                      type="number"
                      disabled={isBatteryCostDisabled}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
        <Row className="mt-4">
          <h4 className="font-bold">Installation Address</h4>
          <Col xs={12} md={6}>
            <UseFormCheck
              control={control}
              controlName="installationAddressIsDifferent"
              content="I do not live at the installation address."
            />
          </Col>
        </Row>
        {installationAddressIsDifferent && (
          <>
            <Row className="mt-3">
              <Col xs={12}>
                <GoogleAutoComplete
                  placeholder="Street (Installation Address)"
                  control={control}
                  name="installationAddressLine"
                  onSelect={(address) => {
                    setValue("installationAddressLine", address.street);
                    setValue("installationCity", address.city);
                    setValue("installationState", address.state);
                    setValue("installationZipCode", address.zipCode);
                  }}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={12} md={6}>
                <UseFormTextField
                  placeholder="City (Installation Address)"
                  control={control}
                  name="installationCity"
                />
              </Col>
              <Col xs={6} md={2} className="mt-3 mt-md-0">
                <UseFormTextField
                  placeholder="State"
                  control={control}
                  name="installationState"
                />
              </Col>
              <Col xs={6} md={4} className="mt-3 mt-md-0">
                <UseFormTextField
                  placeholder="Zip Code"
                  control={control}
                  name="installationZipCode"
                />
              </Col>
            </Row>
          </>
        )}
        <Row className="mt-3">
          <Col xs={12} md={6}>
            <UseFormSelect
              list={HOME_OWNERSHIP_LIST}
              control={control}
              name="installationHomeOwner"
              placeholder="Who owns the property?"
            />
          </Col>

          <Col xs={12} md={6} className="mt-3 mt-md-0">
            <UseFormSelect
              list={PROPERTY_TYPE_LIST}
              control={control}
              name="propertyType"
              placeholder="What type of property is it?"
            />
          </Col>

          {installationAddressIsDifferent && (
            <Col xs={12} md={6} className={"mt-3"}>
              <UseFormSelect
                list={INSTALLATION_RESIDENCY}
                control={control}
                name="installationResidency"
                defaultValue={0}
                placeholderValue={0}
                placeholder="What is the occupancy of the property?"
              />
            </Col>
          )}
        </Row>

        {isSungageProduct && (
          <Row className="mt-3">
            <Col xs={12} md={6}>
              <Row className="align-items-center">
                <Col>
                  <label className="form-check-label">Mortgage Payment:</label>
                </Col>
                <Col>
                  <UserFormCurrencyField
                    placeholder="Quantity"
                    control={control}
                    name="mortgagePayment"
                    type="number"
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6} className="mt-3 mt-md-0">
              <Row className="align-items-center">
                <Col>
                  <label className="form-check-label">
                    Does the property have a reverse Mortgage?:
                  </label>
                </Col>
                <Col>
                  <UseFormSelect
                    list={REVERSE_MORTGAGE_LIST}
                    control={control}
                    name="reverseMortgage"
                    placeholder="Yes/No"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {isExternalLoanProduct && <PrivateLoanProductRedirect />}
        {!isExternalLoanProduct && (
          <>
            <Row className="mt-4">
              <Col xs={12}>
                <h4 className="font-bold">Your Information</h4>
              </Col>
              <Col xs={12} md={6} className="mt-3">
                <UseFormTextField
                  placeholder="First Name"
                  control={control}
                  name="firstName"
                />
              </Col>
              <Col xs={12} md={6} className="mt-3">
                <UseFormTextField
                  placeholder="Last Name"
                  control={control}
                  name="lastName"
                />
              </Col>

              {!isConvertingtoFullApp && (
                <>
                  <Col xs={12} md={6} className="mt-3">
                    <MyInputMask
                      control={control}
                      defaultValue={""}
                      name="SSN"
                      mask={SSN_MASK}
                      placeholder="SSN"
                    />
                  </Col>
                  <Col xs={12} md={6} className="mt-3">
                    <UseFormDatePicker
                      placeholder="Date of Birth"
                      maxDate={new Date()}
                      control={control}
                      controlName="dateOfBirth"
                    />
                  </Col>
                </>
              )}
              <Col xs={12} md={6} className="mt-3 ">
                <UseFormTextField
                  placeholder="Email"
                  control={control}
                  name="email"
                />
              </Col>
              <Col xs={12} md={6} className="mt-3 ">
                <MyInputMask
                  control={control}
                  defaultValue={""}
                  name="mobilePhone"
                  mask={MOBILE_MASK}
                  placeholder="Mobile Phone"
                />
              </Col>
              <Col xs={12} md={6} className="mt-3">
                <UseFormSelect
                  list={CITIZENSHIP}
                  control={control}
                  name="isUSCitizen"
                  placeholder="Are you a US citizen"
                />
              </Col>
              {isUSCitizen === "No" && (
                <Col xs={12} md={6} className="mt-3">
                  <UseFormSelect
                    list={CITIZENSHIP}
                    control={control}
                    name="xIsPermanentResident"
                    placeholder="Are you a Permanent Resident?"
                  />
                </Col>
              )}
            </Row>
            <Row className="mt-4">
              <Col xs={12}>
                <h4 className="font-bold">Current Address</h4>
              </Col>
              <Col xs={12}>
                <GoogleAutoComplete
                  placeholder="Street"
                  control={control}
                  name="addressLine1"
                  onSelect={(address) => {
                    setValue("addressLine1", address.street);
                    setValue("city", address.city);
                    setValue("state", address.state);
                    setValue("postalCode", address.zipCode);
                  }}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={12} md={6}>
                <UseFormTextField
                  placeholder="City"
                  control={control}
                  name="city"
                />
              </Col>
              <Col xs={6} md={2} className="mt-3 mt-md-0">
                <UseFormTextField
                  placeholder="State"
                  control={control}
                  name="state"
                />
              </Col>
              <Col xs={6} md={4} className="mt-3 mt-md-0">
                <UseFormTextField
                  placeholder="Zip Code"
                  control={control}
                  name="postalCode"
                />
              </Col>
            </Row>
            <Row className="mt-3 align-items-center flex-nowrap">
              <Col xs={4} md={3}>
                <label className="form-check-label">Time in Residence:</label>
              </Col>
              <Col xs={4} md={3}>
                <UseFormSelect
                  list={YEARS_ARRAY}
                  control={control}
                  name="yearsAtResidence"
                  placeholder="Years"
                  placeholderValue={""}
                />
              </Col>
              <Col xs={4} md={3}>
                <UseFormSelect
                  list={MONTHS_ARRAY}
                  control={control}
                  name="monthsAtResidence"
                  placeholder="Months"
                  placeholderValue={""}
                />
              </Col>
            </Row>
            <Row>
              {typeof isMailingAddressDifferent != "undefined" && (
                <Col xs={12} className="mt-3">
                  <UseFormCheck
                    control={control}
                    controlName="isMailingAddressDifferent"
                    content="My Mailing address is different than my current address."
                  />
                </Col>
              )}
            </Row>
            {isMailingAddressDifferent && (
              <>
                <Row>
                  <Col xs={12} className="mt-3">
                    <h4 className="font-bold">Mailing Address</h4>
                  </Col>
                  <Col xs={12} className="mt-3">
                    <GoogleAutoComplete
                      placeholder="Street"
                      control={control}
                      name="mailingAddressLine1"
                      onSelect={(address) => {
                        setValue("mailingAddressLine1", address.street);
                        setValue("mailingCity", address.city);
                        setValue("mailingState", address.state);
                        setValue("mailingZipCode", address.zipCode);
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs={12} md={6}>
                    <UseFormTextField
                      placeholder="City"
                      control={control}
                      name="mailingCity"
                    />
                  </Col>
                  <Col xs={6} md={2} className="mt-3 mt-md-0">
                    <UseFormTextField
                      placeholder="State"
                      control={control}
                      name="mailingState"
                    />
                  </Col>
                  <Col xs={6} md={4} className="mt-3 mt-md-0">
                    <UseFormTextField
                      placeholder="Zip Code"
                      control={control}
                      name="mailingZipCode"
                    />
                  </Col>
                </Row>
              </>
            )}
            <Row className="mt-4">
              <Col xs={12}>
                <h4 className="font-bold">Gross Annual Income</h4>
              </Col>
              <Col xs={12}>
                <UserFormCurrencyField
                  placeholder="Gross Annual Income"
                  control={control}
                  name="grossIncome"
                  type="number"
                />
              </Col>
              <Col xs={12} className="mt-3">
                <IncomePrompt isSungageProduct={isSungageProduct} />
              </Col>
            </Row>
            <Row className="mt-4 align-items-center">
              <Col xs={12}>
                <h4 className="font-bold">
                  Employer Information (If Employed)
                </h4>
              </Col>
              <Col xs={12} md={6}>
                <UseFormSelect
                  list={EMPLOYMENT_STATUS_LIST}
                  control={control}
                  name="employmentStatus"
                  placeholder="Employment Status"
                />
              </Col>
            </Row>
            {isPrimaryEmployed && (
              <Row className="mt-3">
                <Col xs={12} md={6}>
                  <UseFormTextField
                    placeholder="Employer Name"
                    control={control}
                    name="employerName"
                  />
                </Col>
                {isSungageProduct && (
                  <Col xs={12} md={6} className="mt-3 mt-md-0">
                    <UseFormTextField
                      placeholder="Title"
                      control={control}
                      name="title"
                    />
                  </Col>
                )}
              </Row>
            )}
            <Row className="mt-3 align-items-center flex-nowrap">
              <Col xs={4} md={3}>
                <label className="form-check-label me-3">
                  {TIME_ON_JOB_TEXT[employmentStatus as EmploymentStatus] ||
                    "Time with current employer"}
                  :
                </label>
              </Col>
              <Col xs={4} md={3}>
                <UseFormSelect
                  list={YEARS_ARRAY}
                  control={control}
                  name="yearsEmployed"
                  placeholder="Years"
                />
              </Col>
              <Col xs={4} md={3}>
                <UseFormSelect
                  list={MONTHS_ARRAY}
                  control={control}
                  name="monthsEmployed"
                  placeholder="Months"
                />
              </Col>
            </Row>

            <UserConsent
              applicant={ApplicantType.Borrower}
              type={ApplicationType.Full}
              isSungageProduct={isSungageProduct}
              authorization={
                <UseFormCheck
                  control={control}
                  controlName="authorization"
                  content="Yes, I have read and agree to the following credit authorization:"
                />
              }
              consent={
                <UseFormCheck
                  control={control}
                  controlName="consent"
                  content="Yes, I have read and agree to the following consents and disclosures:"
                />
              }
            />
            {typeof hasCoApplicant == "string" && (
              <Row className="py-4 mt-4 border-y g-0">
                <Col>
                  <div className="d-flex align-items-center">
                    <h4 className="font-bold me-4 mb-0">Add a Co-Borrower:</h4>
                    <div className="ml-3 d-flex">
                      <UseFormRadio
                        control={control}
                        elementName="co-borrower-group"
                        controlName="hasCoApplicant"
                        content="Yes"
                        checked={hasCoApplicant === "Yes" ? true : false}
                      />
                      <UseFormRadio
                        control={control}
                        elementName="co-borrower-group"
                        controlName="hasCoApplicant"
                        content="No"
                        checked={hasCoApplicant === "No" ? true : false}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            )}
            {hasCoApplicant === "Yes" && (
              <>
                <Row className="mt-4">
                  <Col xs={12}>
                    <h4 className="font-bold">Co-Borrower Information</h4>
                  </Col>
                  <Col xs={12} md={6} className="mt-3 mt-md-0">
                    <UseFormTextField
                      placeholder="First Name"
                      control={control}
                      name="coBorrowerFirstName"
                    />
                  </Col>
                  <Col xs={12} md={6} className="mt-3 mt-md-0">
                    <UseFormTextField
                      placeholder="Last Name"
                      control={control}
                      name="coBorrowerLastName"
                    />
                  </Col>

                  {!initialCoBorrowerData && (
                    <>
                      <Col xs={12} md={6} className="mt-3">
                        <MyInputMask
                          control={control}
                          mask={SSN_MASK}
                          name="coBorrowerssn"
                          placeholder="SSN"
                        />
                      </Col>
                      <Col xs={12} md={6} className="mt-3">
                        <UseFormDatePicker
                          placeholder="Date of Birth"
                          maxDate={new Date()}
                          control={control}
                          controlName="coBorrowerdob"
                        />
                      </Col>
                    </>
                  )}
                  <Col xs={12} md={6} className="mt-3">
                    <UseFormTextField
                      placeholder="Email"
                      control={control}
                      name="coBorrowerEmail"
                    />
                  </Col>
                  <Col xs={12} md={6} className="mt-3">
                    <MyInputMask
                      control={control}
                      defaultValue={""}
                      name="coBorrowerMobilePhone"
                      mask={MOBILE_MASK}
                      placeholder="Mobile Phone"
                    />
                  </Col>
                  <Col xs={12} md={6} className="mt-3">
                    <UseFormSelect
                      list={CITIZENSHIP}
                      control={control}
                      name="coBorrowerusCitizen"
                      placeholder="Are you a US citizen"
                    />
                  </Col>
                  {coBorrowerusCitizen === "No" && (
                    <Col xs={12} md={6} className="mt-3">
                      <UseFormSelect
                        list={CITIZENSHIP}
                        control={control}
                        name="coBorrowerxIsPermanentResident"
                        placeholder="Are you a Permanent Resident?"
                      />
                    </Col>
                  )}
                </Row>
                {typeof isDiffAddressThanPrimary !== "undefined" && (
                  <Row>
                    <Col className="mt-4">
                      <h4 className="font-bold mr-4 mb-0">Co-Residency </h4>
                      <h4 className="fw-normal">
                        Does coborrower reside at the same residence as the
                        primary borrower?{" "}
                      </h4>
                      <div className="d-flex flex-column">
                        <UseFormRadio
                          control={control}
                          elementName="co-residency"
                          controlName="isDiffAddressThanPrimary"
                          content="Yes"
                          checked={
                            isDiffAddressThanPrimary === "Yes" ? true : false
                          }
                        />
                        <UseFormRadio
                          control={control}
                          elementName="co-residency"
                          controlName="isDiffAddressThanPrimary"
                          content="No"
                          checked={
                            isDiffAddressThanPrimary === "No" ? true : false
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                )}
                {isDiffAddressThanPrimary === "No" && (
                  <>
                    <Row className="mt-3">
                      <Col xs={12}>
                        <GoogleAutoComplete
                          placeholder="Street"
                          control={control}
                          name="coBorrowerAddressLine1"
                          onSelect={(address) => {
                            setValue("coBorrowerAddressLine1", address.street);
                            setValue("coBorrowerCity", address.city);
                            setValue("coBorrowerState", address.state);
                            setValue("coBorrowerPostalCode", address.zipCode);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col xs={12} md={6}>
                        <UseFormTextField
                          placeholder="City"
                          control={control}
                          name="coBorrowerCity"
                        />
                      </Col>
                      <Col xs={6} md={2} className="mt-3 mt-md-0">
                        <UseFormTextField
                          placeholder="State"
                          control={control}
                          name="coBorrowerState"
                        />
                      </Col>
                      <Col xs={6} md={4} className="mt-3 mt-md-0">
                        <UseFormTextField
                          placeholder="Zip Code"
                          control={control}
                          name="coBorrowerPostalCode"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3 align-items-center flex-nowrap">
                      <Col xs={4} md={3}>
                        <label className="form-check-label">
                          Time in Residence:
                        </label>
                      </Col>
                      <Col xs={4} md={3}>
                        <UseFormSelect
                          list={YEARS_ARRAY}
                          control={control}
                          name="coBorrowerYearsAtResidence"
                          placeholder="Years"
                          placeholderValue={""}
                        />
                      </Col>
                      <Col xs={4} md={3}>
                        <UseFormSelect
                          list={MONTHS_ARRAY}
                          control={control}
                          name="coBorrowerMonthsAtResidence"
                          placeholder="Months"
                          placeholderValue={""}
                        />
                      </Col>
                    </Row>
                  </>
                )}

                <Row className="mt-4">
                  <h4 className="font-bold">Co-Borrower Mailing Address</h4>
                  <Col xs={12}>
                    <UseFormCheck
                      control={control}
                      controlName="isCoBorrowerMailingAddressDifferrent"
                      content="Is the co-borrower mailing address is different than their primary residence?"
                    />
                  </Col>
                </Row>

                {isCoBorrowerMailingAddressDiff && (
                  <>
                    <Row className="mt-3">
                      <Col xs={12}>
                        <GoogleAutoComplete
                          placeholder="Street"
                          control={control}
                          name="coBorrowerMailingAddressLine"
                          onSelect={(address) => {
                            setValue(
                              "coBorrowerMailingAddressLine",
                              address.street
                            );
                            setValue("coBorrowerMailingCity", address.city);
                            setValue("coBorrowerMailingState", address.state);
                            setValue(
                              "coBorrowerMailingZipCode",
                              address.zipCode
                            );
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col xs={12} md={6}>
                        <UseFormTextField
                          placeholder="City"
                          control={control}
                          name="coBorrowerMailingCity"
                        />
                      </Col>
                      <Col xs={6} md={2} className="mt-3 mt-md-0">
                        <UseFormTextField
                          placeholder="State"
                          control={control}
                          name="coBorrowerMailingState"
                        />
                      </Col>
                      <Col xs={6} md={4} className="mt-3 mt-md-0">
                        <UseFormTextField
                          placeholder="Zip Code"
                          control={control}
                          name="coBorrowerMailingZipCode"
                        />
                      </Col>
                    </Row>
                  </>
                )}

                <Row className="mt-4">
                  <Col xs={12} sm={6}>
                    <h4 className="font-bold">
                      Co-Borrower Gross Annual Income
                    </h4>
                    <UserFormCurrencyField
                      placeholder="Gross Annual Income"
                      control={control}
                      name="coBorrowerGrossIncome"
                    />
                  </Col>
                  <p className="mt-2">
                    Please enter annual income, which may include any income
                    that is verifiable and reported to the Government, such as
                    employment income, social security, pensions, etc.
                  </p>
                </Row>

                <Row>
                  <Col xs={12}>
                    <h4 className="font-bold">
                      Co-Borrower Employer Information
                    </h4>
                  </Col>
                  <Col xs={12} md={6}>
                    <UseFormSelect
                      list={EMPLOYMENT_STATUS_LIST}
                      control={control}
                      name="coBorrowerEmploymentStatus"
                      placeholder="Employment Status"
                    />
                  </Col>
                </Row>
                {isSecondaryEmployed && (
                  <Row className="mt-3">
                    <Col xs={12} md={6}>
                      <UseFormTextField
                        placeholder="Employer Name"
                        control={control}
                        name="coBorrowerEmployerName"
                      />
                    </Col>
                    {isSungageProduct && (
                      <Col xs={12} md={6} className="mt-3 mt-md-0">
                        <UseFormTextField
                          placeholder="Title"
                          control={control}
                          name="coBorrowerTitle"
                        />
                      </Col>
                    )}
                  </Row>
                )}
                <Row className="mt-3 align-items-center flex-nowrap">
                  <Col xs={4} md={3}>
                    <label className="form-check-label me-3">
                      {TIME_ON_JOB_TEXT[
                        coBorrowerEmploymentStatus as EmploymentStatus
                      ] || "Time with current employer"}
                      :
                    </label>
                  </Col>
                  <Col xs={4} md={3}>
                    <UseFormSelect
                      list={YEARS_ARRAY}
                      control={control}
                      name="coBorrowerYear"
                      placeholder="Years"
                    />
                  </Col>
                  <Col xs={4} md={3}>
                    <UseFormSelect
                      list={MONTHS_ARRAY}
                      control={control}
                      name="coBorrowerMonths"
                      placeholder="Months"
                    />
                  </Col>
                </Row>
              </>
            )}

            {hasCoApplicant === "Yes" && (
              <UserConsent
                applicant={ApplicantType.CoBorrower}
                type={ApplicationType.Full}
                isSungageProduct={isSungageProduct}
                authorization={
                  <UseFormCheck
                    control={control}
                    controlName="coBorrowerAuthorization"
                    content="Yes, I have read and agree to the following credit authorization:"
                  />
                }
                consent={
                  <UseFormCheck
                    control={control}
                    controlName="coBorrowerConsent"
                    content="Yes, I have read and agree to the following consents and disclosures:"
                  />
                }
              />
            )}

            <div className="mt-5 d-flex justify-content-center mb-5">
              <Button variant="primary" type="submit">
                Submit Application
              </Button>
            </div>
          </>
        )}
      </form>
    </Col>
  );
};

export default FullApplicationForm;
