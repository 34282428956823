import { useEffect, useState } from "react";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@store/store";
import { networkService } from "@services/network-service";
import { User } from "@shared/interfaces/User";
import {
  GetPaginationResult,
  GetUsersParams,
  IGetUserResponse,
  IGetUsersList,
  UserState,
} from "@store/types";

const initialState: UserState = {
  userData: null,
  usersData: {
    results: [],
    total: 0,
  },
};

export const getUsers = async ({
  field,
  page,
  limit,
  search,
  filterByRegion,
  filterByUserStatus,
}: GetUsersParams): Promise<GetPaginationResult<User>> => {
  const { data } = await networkService.get<
    { data: GetPaginationResult<User> } | undefined
  >(`/users`, {
    params: {
      field,
      maxUsers: limit,
      page,
      search,
      filterByRegion,
      filterByUserStatus,
    },
  });
  return {
    results: [],
    total: 0,
    ...(data?.data || {}),
  };
};

export const useGetUsers = (
  params: GetUsersParams,
  enabled = true
): { busy: boolean; users: User[] } => {
  const [busy, setBusy] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  useEffect(() => {
    if (!enabled) {
      return;
    }
    (async () => {
      try {
        setBusy(true);
        const { results } = await getUsers(params);
        setUsers(results);
      } finally {
        setBusy(false);
      }
    })();
  }, [enabled, params]);
  return {
    busy,
    users,
  };
};

export const getUsersAction = createAsyncThunk(
  "user/getUsers",
  async (
    { page, limit, search, filterByRegion, filterByUserStatus }: GetUsersParams,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const usersResult = await getUsers({
        page,
        limit,
        search,
        filterByRegion,
        filterByUserStatus,
      });
      dispatch(setUsersData(usersResult));
      return usersResult;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUserAction = createAsyncThunk(
  "user/getUser",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setUserData(null));
      const { data } = await networkService.get<{
        data: IGetUserResponse;
        errors: any;
        message: string;
      }>(`/users/${id}`);
      dispatch(setUserData(data?.data || null));
      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUsers: (state: UserState) => ({
      ...state,
      usersData: {
        ...state.usersData,
        results: [],
        total: 0,
      },
    }),
    setUserData: (
      state: UserState,
      { payload }: PayloadAction<IGetUserResponse | null>
    ) => ({
      ...state,
      userData: payload,
    }),
    setUsersData: (
      state: UserState,
      {
        payload,
      }: PayloadAction<{
        results: IGetUsersList[];
        total: number;
      }>
    ) => ({
      ...state,
      usersData: {
        ...state.usersData,
        results: payload.results || [],
        total: payload.total || 0,
      },
    }),
  },
  extraReducers: (builder) => {},
});

export const { reducer: userReducer } = userSlice;
export const { resetUsers, setUserData, setUsersData } = userSlice.actions;

export const usersDataSelector = (rootState: RootState) =>
  rootState.user.usersData;
export const userDataSelector = (
  rootState: RootState
): IGetUserResponse | null => rootState.user.userData;
