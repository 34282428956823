import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useEffect, useState } from "react";
import {
  getUserAction,
  setUserData,
  userDataSelector,
} from "@store/slices/user.slice";
import { LoginChallenge } from "@store/types";
import { networkService } from "@services/network-service";
import { toasterService } from "@services/toaster.service";
import ChoiceModal from "@shared/components/ChoiceModal";

interface ResetOptions {
  busy: boolean;
  id: string | null;
  name: string;
  open: boolean;
}

const choices = [
  {
    title: "No",
    value: "no",
    variant: "secondary",
  },
  {
    title: "Yes",
    value: "yes",
    variant: "primary",
  },
];

const ResetAuth = () => {
  const dispatch = useAppDispatch();
  const { id: userId } = useParams<{ id: string }>();
  const userResponse = useAppSelector(userDataSelector);
  const user = userResponse?.user;
  const cognitoUser = userResponse?.cognitoData;

  const [passwordResetOptions, setPasswordResetOptions] =
    useState<ResetOptions>({
      busy: false,
      id: null,
      name: "",
      open: false,
    });

  const [mfaResetOptions, setMfaResetOptions] = useState<ResetOptions>({
    busy: false,
    id: null,
    name: "",
    open: false,
  });

  useEffect(() => {
    dispatch(getUserAction(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    return () => {
      // Reset user data on leave page
      dispatch(setUserData(null));
    };
  }, [dispatch]);

  const hidePasswordResetModal = () => {
    setPasswordResetOptions((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const hideMfaResetModal = () => {
    setMfaResetOptions((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const resetUserPassword = async (userId: string) => {
    try {
      setPasswordResetOptions((prev) => ({
        ...prev,
        busy: true,
      }));
      await networkService.post(`/users/${userId}/reset-password`);
      toasterService.success("Password reset has been successfully initiated.");
      dispatch(getUserAction(userId));
    } finally {
      setPasswordResetOptions((prev) => ({
        busy: false,
        id: null,
        name: "",
        open: false,
      }));
    }
  };

  const resetUserMfa = async (userId: string) => {
    try {
      setMfaResetOptions((prev) => ({
        ...prev,
        busy: true,
      }));
      await networkService.post(`/users/${userId}/reset-mfa`);
      toasterService.success("MFA has been reset successfully.");
      dispatch(getUserAction(userId));
    } finally {
      setMfaResetOptions((prev) => ({
        busy: false,
        id: null,
        name: "",
        open: false,
      }));
    }
  };

  if (!user) {
    return null;
  }

  return (
    <div className="d-flex align-items-center justify-content-center mt-3">
      <div className="login-container form-container">
        <div className="col-xs-12 col-md-8 offset-md-2">
          <h3 className="h3 flex-grow-1">Login Help</h3>

          <div className="my-2">
            <p className="info-text">
              If a user is having problems logging in, we first need to identify
              the source of the login issue. The user may either have an
              incorrect email, incorrect password or their MFA may be
              incorrectly setup.
            </p>
          </div>

          <div className="p-1" />
          <div className="my-2">
            <p className="sub-title">
              Step 1: Verify the username used to login{" "}
            </p>
            <p className="info-text">
              Ensure the user is using the correct login. The user’s login will
              always be their email. For{" "}
              <span className="login-help-highlight-text">
                {user?.username}
              </span>{" "}
              they must login with{" "}
              <span className="login-help-highlight-text">{user?.email}</span>.
            </p>
          </div>

          <div className="p-2" />

          <div className="my-2">
            <p className="sub-title">Step 2: Reset user password </p>
            <p className="info-text">
              Ensure the user is using the correct password. If the user does
              not know their password, or if they have received an error stating
              “incorrect username or password,” you can reset their password.
            </p>
            <p className="info-text">
              On password reset, the user will be sent an email from{" "}
              <span className="login-help-highlight-text">
                care@flicportal.com
              </span>
              . Please allow up to 1 minute for the email to process then
              encourage the user to check their junk folder, focused mail
              groups, or use search to locate the reset email.
            </p>
            <div className="d-flex justify-content-end my-4">
              <Button
                type="button"
                variant="outline-primary-dark"
                onClick={() => {
                  setPasswordResetOptions((prev) => ({
                    ...prev,
                    id: user?.id || "",
                    name: user?.username || "",
                    open: true,
                  }));
                }}
              >
                Reset Password
              </Button>
            </div>
          </div>

          <div className="p-2" />

          {cognitoUser?.PreferredMfaSetting && (
            <div className="my-2">
              <p className="sub-title">Step 3: Reset MFA </p>
              {cognitoUser.PreferredMfaSetting === LoginChallenge.SMS_MFA && (
                <p className="info-text">
                  Users are setup to receive a text message or use a code from
                  an authenticator app.{" "}
                  <span className="login-help-highlight-text">
                    {user?.email}
                  </span>{" "}
                  is setup with{" "}
                  <span className="login-help-highlight-text">text based</span>{" "}
                  MFA and will receive an MFA code via text. If the user does
                  not receive a text message, or receives an error when entering
                  their code, you can reset their MFA.
                </p>
              )}
              {cognitoUser.PreferredMfaSetting ===
                LoginChallenge.SOFTWARE_TOKEN_MFA && (
                <p className="info-text">
                  Users are setup to receive a text message or use a code from
                  an authenticator app.{" "}
                  <span className="login-help-highlight-text">
                    {user?.email}
                  </span>{" "}
                  is setup with{" "}
                  <span className="login-help-highlight-text">app based</span>{" "}
                  MFA and will enter an MFA code from their authenticator app.
                  If the user cannot locate their code, or receives an error
                  when entering their code, you can reset their MFA.
                </p>
              )}
              <p className="info-text">
                After clicking reset MFA, the user will need to start the login
                process over and will be directed to the MFA setup screen to
                setup MFA via phone number or with an authenticator app after
                entering their username and password.
              </p>
              <p className="reset-mfa-italic-text">
                Always ensure you are confident you are speaking to the actual
                user before reseting their MFA.
              </p>
              <div className="d-flex justify-content-end my-4">
                <Button
                  type="button"
                  variant="outline-primary-dark"
                  onClick={() => {
                    setMfaResetOptions((prev) => ({
                      ...prev,
                      id: user?.id || "",
                      name: user?.username || "",
                      open: true,
                    }));
                  }}
                >
                  Reset MFA
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>

      <ChoiceModal
        choices={choices}
        onChoose={(choice) => {
          if (choice === "no") {
            return hidePasswordResetModal();
          }
          if (passwordResetOptions.id) {
            resetUserPassword(passwordResetOptions.id);
          }
        }}
        onHide={() => {
          hidePasswordResetModal();
        }}
        disabled={passwordResetOptions.busy}
        show={passwordResetOptions.open}
        title={`Reset password for ${passwordResetOptions.name}`}
      >
        Are you sure you want to reset password for {passwordResetOptions.name}?
      </ChoiceModal>

      <ChoiceModal
        choices={choices}
        onChoose={(choice) => {
          if (choice === "no") {
            return hideMfaResetModal();
          }
          if (mfaResetOptions.id) {
            resetUserMfa(mfaResetOptions.id);
          }
        }}
        onHide={() => {
          hideMfaResetModal();
        }}
        disabled={mfaResetOptions.busy}
        show={mfaResetOptions.open}
        title={`Reset MFA for ${mfaResetOptions.name}`}
      >
        Are you sure you want to reset MFA for {mfaResetOptions.name}?
      </ChoiceModal>
    </div>
  );
};

export default ResetAuth;
