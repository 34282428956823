import { useAuthUserContext } from "@contexts/AuthContext";
import { UserAuthStatus } from "@shared/enums/UserState";
import {
  generateModifyLoanPayload,
  getXPrequalProducts,
  isPrequalApplication,
} from "@shared/helpers/application.helper";
import { getCreditFreezStips } from "@shared/helpers/documents.helper";
import { isPending } from "@shared/helpers/global.helper";
import {
  IPortalApplication,
  ProductLos,
} from "@shared/interfaces/PortalApplicationTypes";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { appUserSelector } from "@store/slices/app.slice";
import {
  prequalModifyLoanAction,
  resubmitApplicationAction,
} from "@store/slices/loan.slice";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

export const PendingDecision = ({
  pendingDocumentInstructions,
  data,
}: {
  data: IPortalApplication | undefined;
  pendingDocumentInstructions: string[] | undefined;
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const appNumber = data?.losAppNumber;
  const user = useAppSelector(appUserSelector);
  const { authUserStatus } = useAuthUserContext();

  const resubmitApplication = () => {
    if (!data || !appNumber) {
      return;
    }

    if (isPrequalApplication(data)) {
      if (!data.programTypeCode || !user) {
        return;
      }
      const isMultiPrequal =
        data.prequalProducts && data.prequalProducts?.length > 1;
      const xprogramtype = Number(data.programTypeCode);
      const { xPrequalProductsSelected, xPrequalProductsAll } =
        getXPrequalProducts(user, xprogramtype.toString());

      dispatch(
        prequalModifyLoanAction({
          appNumber,
          history,
          loanInfo: {
            losAppNumber: appNumber,
            productLos: ProductLos.DL4,
            prequalProducts: xPrequalProductsSelected,
            programTypeCode: xprogramtype,
          },
          path: "/pre-qualification/confirmation",
          isMultiPrequal,
          xprogramtype,
          xPrequalProducts: xPrequalProductsAll,
        })
      );
    } else {
      const payload = generateModifyLoanPayload(data, user);
      dispatch(
        resubmitApplicationAction({
          loanInfo: payload,
          appNumber,
          history,
          path: "/new-application/confirmation",
        })
      );
    }
  };

  const creditFreezeStip = data ? getCreditFreezStips(data) : [];

  if (data?.status?.decisionStatus && isPending(data?.status?.decisionStatus)) {
    return (
      <div className="alert alert-warning">
        <div className="row">
          <div className="col-md-10">
            <p className="text-danger">
              <strong>
                This application has not been decisioned. Additional items are
                needed prior to resubmiting for credit elligbility.
              </strong>
            </p>
            <ul className="mt-3">
              {pendingDocumentInstructions?.map((item: string) => (
                <li key={item}>
                  <p className="text-danger">{item}</p>
                </li>
              ))}
            </ul>
          </div>
          {authUserStatus === UserAuthStatus.Authorized &&
            creditFreezeStip.length > 0 && (
              <div className="col-md-2">
                <Button
                  variant="default"
                  className="btn-outline-danger"
                  onClick={resubmitApplication}
                >
                  Resubmit App
                </Button>
              </div>
            )}
        </div>
      </div>
    );
  }

  return null;
};
