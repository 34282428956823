import { ConditionalRender } from "@shared/components/ConditionalRender";
import NumberFormat from "@shared/components/NumberFormat";
import { DATE_FORMAT, NA } from "@shared/constants/app.constant";
import {
  getHomeOwnerShip,
  getProgramType,
  getValueIfNotNull,
} from "@shared/helpers/global.helper";
import { useAppSelector } from "@store/hooks";
import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import Moment from "react-moment";
import { SystemInfo } from "../documentCards/SystemInfo";
import moment from "moment";
import { IPortalApplication } from "@shared/interfaces/PortalApplicationTypes";

export const LoanInformation = ({
  id,
  data,
  isPrequal,
}: {
  id: string;
  data: IPortalApplication | undefined;
  isPrequal: boolean;
}) => {
  const loanSlice = useAppSelector((state) => state.loan);

  const initialRate = data?.loanOffer?.introRate;
  const finalRate = data?.loanOffer?.rate;

  const homeOwnership = useMemo(
    () => getValueIfNotNull(data?.installationAddress?.installationHomeOwner),
    [data]
  );

  const getExpiry = () => {
    if (data?.loanOffer?.expirationDate) {
      return moment(data?.loanOffer?.expirationDate);
    }
  };

  return (
    <div>
      <h3 className="h3 text-primary-dark mt-4">Loan Information</h3>
      <hr className="bg-primary-dark height-2px opacity-1" />

      <Row className="mt-3">
        <Col xs={12} sm={6} md={4}>
          <h4 className="font-bold-800">Product</h4>
          {id === "new" && (
            <p>
              Product Name:&nbsp;
              <ConditionalRender
                value={getProgramType(loanSlice.draftDetail?.loan_product)}
              />
            </p>
          )}
          {id !== "new" && (
            <p>
              Product Name:&nbsp;
              <ConditionalRender
                value={
                  data?.programTypeCode
                    ? getProgramType(data?.programTypeCode)
                    : null
                }
              />
            </p>
          )}
          <p>
            Term:&nbsp;
            <ConditionalRender value={data?.loanOffer?.term} />
          </p>
          {id === "new" ? (
            <p>
              Loan Amount:&nbsp;
              <NumberFormat amount={loanSlice.draftDetail?.loan_amount} />
            </p>
          ) : (
            <p>
              {isPrequal ? "Prequalified For" : "Amount Financed"}
              :&nbsp;
              <NumberFormat amount={data?.loanOffer?.amountFinanced} />
            </p>
          )}

          <p>
            Tax Credit:&nbsp;
            <NumberFormat amount={data?.loanOffer?.taxCredit} />
          </p>
          <p>
            Rate:&nbsp;
            <NumberFormat amount={initialRate} isCurrency={false} symbol="%" />
            {initialRate !== finalRate && (
              <>
                &nbsp;|&nbsp;
                <NumberFormat
                  amount={finalRate}
                  isCurrency={false}
                  symbol="%"
                />
              </>
            )}
          </p>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <h4 className="font-bold-800">Payment Structure*</h4>
          <p>
            Intro Payment: &nbsp;
            <NumberFormat amount={data?.loanOffer?.introPayment} />
            &nbsp;|&nbsp;
            <NumberFormat amount={data?.loanOffer?.introPayment} />
          </p>
          <p>
            Reg Payment: &nbsp;
            <NumberFormat amount={data?.loanOffer?.payment} />
            &nbsp;|&nbsp;
            <NumberFormat amount={data?.loanOffer?.paymentFtcApplied} />
          </p>
          <p>
            Final Payment: &nbsp;
            <NumberFormat amount={data?.loanOffer?.finalPayment} />
            &nbsp;|&nbsp;
            <NumberFormat amount={data?.loanOffer?.finalPaymentFtcApplied} />
          </p>
          <p>
            <br />
          </p>
          <p>*(Payment | Payment w/ FTC)</p>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <h4 className="font-bold-800">Submission Data</h4>
          <p>
            Submitted:&nbsp;
            {data?.loanOffer?.createdOn ? (
              <ConditionalRender
                value={
                  <Moment format={DATE_FORMAT}>
                    {data?.loanOffer?.createdOn}
                  </Moment>
                }
              />
            ) : (
              NA
            )}
          </p>
          <p>
            Expires:&nbsp;
            {data?.loanOffer?.expirationDate ? (
              <ConditionalRender
                value={<Moment format={DATE_FORMAT}>{getExpiry()}</Moment>}
              />
            ) : (
              NA
            )}
          </p>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12} sm={6} md={4}>
          <h4 className="font-bold-800">Installation Address</h4>
          {data?.installationAddress?.addressLine ? (
            <p>
              {data?.installationAddress?.addressLine
                ? data?.installationAddress?.addressLine
                : ""}
              {data?.installationAddress?.city
                ? ", " + data?.installationAddress?.city
                : ""}
              {data?.installationAddress?.state
                ? ", " + data?.installationAddress?.state
                : ""}
              {data?.installationAddress?.zipCode
                ? " " + data?.installationAddress?.zipCode
                : ""}
            </p>
          ) : (
            <p>{NA}</p>
          )}
          <p>
            Home Ownership:&nbsp;
            <ConditionalRender value={getHomeOwnerShip(homeOwnership)} />
          </p>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <SystemInfo requestDetail={data} />
        </Col>
      </Row>
    </div>
  );
};
