import NumberFormat from "@shared/components/NumberFormat";
import { getProgramType, getYear } from "@shared/helpers/global.helper";
import { IPrequalifiedOffer } from "@shared/interfaces/Application";
import { IPortalApplication } from "@shared/interfaces/PortalApplicationTypes";
import clsx from "clsx";
import { Button, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const LoanOptions = ({
  loanOffers,
  data,
  canBeCoverted,
}: {
  loanOffers: IPrequalifiedOffer[];
  data: IPortalApplication | undefined;
  canBeCoverted: boolean;
}) => {
  const history = useHistory();

  const handleConverToFullApp = (prequalifiedOfferId: number) => {
    history.push(`/pre-qualification/convert/${data?.losAppNumber}`, {
      prequalifiedOfferId,
    });
  };
  return (
    <div>
      <h3 className="h3 text-primary-dark mt-4">Loan Options</h3>
      <hr className="bg-primary-dark height-2px opacity-1" />

      <p className="my-4">
        The customer is prequalified for the following loan options:
      </p>

      <div className="d-flex flex-column gap-4">
        {loanOffers.map((i) => (
          <LoanOptionsCard
            loanOfferData={i}
            key={i.xprogramtype}
            handleConverToFullApp={handleConverToFullApp}
            canBeCoverted={canBeCoverted}
          />
        ))}
      </div>
    </div>
  );
};

const LoanOptionsCard = ({
  loanOfferData,
  handleConverToFullApp,
  canBeCoverted,
}: {
  loanOfferData: IPrequalifiedOffer;
  handleConverToFullApp: (offerId: number) => void;
  canBeCoverted: boolean;
}) => {
  const isActive = loanOfferData.active;
  return (
    <Row className="multi-prequal-loan-options-card g-0">
      <Col
        xs={12}
        sm={12}
        md={12}
        lg={4}
        className={clsx(
          "separator1 mb-lg-0 mb-3 align-items-center",
          !isActive && "opacity-50"
        )}
      >
        <p>{getProgramType(loanOfferData?.xprogramtype)}</p>
      </Col>
      <Col
        xs={3}
        sm={3}
        md={3}
        lg={2}
        className={clsx("separator2 display-center", !isActive && "opacity-50")}
      >
        <p className="text-center">
          Up to <NumberFormat amount={loanOfferData?.amount} />
        </p>
      </Col>
      <Col
        xs={2}
        sm={2}
        md={2}
        lg={1}
        className={clsx("separator2 display-center", !isActive && "opacity-50")}
      >
        <p className="text-center">{getYear(loanOfferData?.term)} Year</p>
      </Col>
      <Col
        xs={2}
        sm={2}
        md={2}
        lg={1}
        className={clsx("separator1 display-center", !isActive && "opacity-50")}
      >
        <p className="text-center">
          <NumberFormat
            amount={loanOfferData?.xFinalRate}
            isCurrency={false}
            symbol="%"
          />
        </p>
      </Col>
      <Col xs={5} sm={5} md={5} lg={4} className="action-item">
        {canBeCoverted && (
          <>
            {isActive ? (
              <>
                <Button
                  variant="outline-primary"
                  type="button"
                  className="d-lg-none"
                  onClick={() =>
                    handleConverToFullApp(loanOfferData.prequalifiedOfferId)
                  }
                >
                  Convert To Full Application
                </Button>
                <Button
                  variant="primary"
                  className="d-none d-lg-block"
                  type="button"
                  onClick={() =>
                    handleConverToFullApp(loanOfferData.prequalifiedOfferId)
                  }
                >
                  Convert To Full Application
                </Button>
              </>
            ) : (
              <p className="ineligible">Ineligible</p>
            )}
          </>
        )}
      </Col>
    </Row>
  );
};

export default LoanOptions;
