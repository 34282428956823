import { useAuthUserContext } from "@contexts/AuthContext";
import { UserAuthStatus } from "@shared/enums/UserState";
import { UserRole } from "@shared/interfaces/User";
import { useAppSelector } from "@store/hooks";
import { appUserSelector } from "@store/slices/app.slice";
import React from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import NoPermission from "../features/no-permission/NoPermission";

export const RoleGuardRoute = ({
  component,
  authCheckType,
  allowedAuthStatus,
  allowedRoles,
  ...rest
}: {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  allowedRoles?: UserRole[];
  allowedAuthStatus?: UserAuthStatus[];
  authCheckType: "Roles" | "AuthStatus";
  path?: string | readonly string[] | undefined;
  exact?: boolean | undefined;
}) => {
  const { authUserStatus } = useAuthUserContext();
  const user = useAppSelector(appUserSelector);

  // Role conditions to check for Authorized users of the system
  const rolesConditios =
    authCheckType === "Roles" &&
    allowedRoles &&
    !!user?.role &&
    allowedRoles.some((role) => role === user.role);

  // To check for the authenticity (Public, Authorized, ...)
  const authStatusConditions =
    authCheckType === "AuthStatus" &&
    allowedAuthStatus &&
    allowedAuthStatus.some((status) => status === authUserStatus);

  const routeComponent = (props: any) => React.createElement(component, props);

  if (authStatusConditions || rolesConditios) {
    return <Route {...rest} render={routeComponent} />;
  }

  return <NoPermission />;
};
