import { useEffect, useMemo, useRef } from "react";
import Navbar from "@layout/Navbar";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import PreQualification from "./pre-qualification/PreQualification";
import RequestDetail from "./request-detail/RequestDetail";
import NewApplication from "./new-application/NewApplication";
import Pipelines from "./pipelines/Pipelines";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { appSelector, appUserSelector } from "@store/slices/app.slice";
import RequestProcessing from "../shared/components/RequestProcessing";
import Users from "./users/Users";
import AddUser from "./users/add-user/addUser";
import ModifyUser from "./users/modify-user/modifyUser";
import AddRegion from "./regions/add-region/addRegion";
import RequestFailed from "./request-failed/RequestFailed";
import AppLinkHome from "./send-application-link/Home";
import IssueDocs from "./issue-docs/IssueDocs";
import ModifyLoan from "./modify-loan/ModifyLoan";
import NoDecision from "./no-decision/NoDecision";
import PublicSucess from "./public-success/PublicSucess";
import DuplicateApplications from "./duplicate-applications/DuplicateApplications";
import AddLenderNote from "./application/add-note/AddLenderNote";
import IpWhitelist from "./ip-whitelist/IpWhitelist";
import { UserAuthStatus } from "@shared/enums/UserState";
import NoPermission from "./no-permission/NoPermission";
import SetupMfa from "./setup-mfa/SetupMfa";
import { useAuthUserContext } from "@contexts/AuthContext";
import { UserRole } from "@shared/interfaces/User";
import { RoleGuardRoute } from "@guards/RoleGuard";
import { getAwardcoBalanceAction } from "@store/slices/awardco.slice";
import { isSalesRole } from "@shared/helpers/global.helper";
import ResetAuth from "./users/reset-auth/ResetAuth";
import SetupMfaGuard from "./setup-mfa/SetupMfaGuard";
import clsx from "clsx";
import UpdateBooking from "./application/update-booking/UpdateBooking";

export const Features = () => {
  const dispatch = useAppDispatch();

  const appSlice = useAppSelector(appSelector);
  const user = useAppSelector(appUserSelector);
  const wrapperDiv = useRef<HTMLDivElement>(null);
  const { authUserStatus } = useAuthUserContext();

  const { pathname } = useLocation();

  useEffect(() => {
    wrapperDiv?.current?.scrollTo(0, 0);
  }, [pathname]);

  const isAuthorizedAuthStatus = authUserStatus === UserAuthStatus.Authorized;
  const isPublicAuthStatus = authUserStatus === UserAuthStatus.Public;

  useEffect(() => {
    isSalesRole(user?.role) && dispatch(getAwardcoBalanceAction());
  }, [dispatch, user]);

  const renderNavigationbar = useMemo(
    () => !appSlice.isProcessing && !pathname.startsWith("/setup/mfa"),
    [appSlice.isProcessing, pathname]
  );

  return (
    <>
      {appSlice.isProcessing && (
        <div
          style={{
            overflow: "auto",
            padding: "24px",
            position: "absolute",
            zIndex: 1,
            width: "100%",
            backgroundColor: "white",
          }}
        >
          <RequestProcessing />
        </div>
      )}
      {renderNavigationbar && <Navbar />}

      {/* Setup MFA guard will redirect user to set-up the MFA if they have not setup yet */}
      <SetupMfaGuard />

      <div
        className={clsx(
          renderNavigationbar ? "wrapper" : "wrapper-without-navbar"
        )}
        ref={wrapperDiv}
      >
        <Switch>
          <RoleGuardRoute
            path="/email-link"
            component={AppLinkHome}
            authCheckType="Roles"
            allowedRoles={[
              UserRole.providerAdmin,
              UserRole.regionalManager,
              UserRole.eneryConsultant,
              UserRole.enterpriseManager,
            ]}
          />

          <RoleGuardRoute
            authCheckType="Roles"
            allowedRoles={[
              UserRole.providerAdmin,
              UserRole.financialInstitution,
            ]}
            path="/application/:id/add-note"
            component={AddLenderNote}
          />

          <RoleGuardRoute
            authCheckType="Roles"
            allowedRoles={[
              UserRole.providerAdmin,
              UserRole.financialInstitution,
            ]}
            path="/application/:id/update-booking"
            component={UpdateBooking}
          />

          <RoleGuardRoute
            path="/request-detail/:id"
            component={RequestDetail}
            authCheckType="AuthStatus"
            allowedAuthStatus={[UserAuthStatus.Authorized]}
          />
          <RoleGuardRoute
            path="/pipelines"
            component={Pipelines}
            authCheckType="AuthStatus"
            allowedAuthStatus={[UserAuthStatus.Authorized]}
          />

          <RoleGuardRoute
            path="/duplicate/applications"
            component={DuplicateApplications}
            authCheckType="AuthStatus"
            allowedAuthStatus={[UserAuthStatus.Authorized]}
          />
          <Route path="/request-failed" component={RequestFailed} />
          <Route path="/no-decision" component={NoDecision} />

          <RoleGuardRoute
            authCheckType="Roles"
            allowedRoles={[
              UserRole.providerAdmin,
              UserRole.regionalManager,
              UserRole.eneryConsultant,
              UserRole.enterpriseManager,
            ]}
            path="/issue-docs/:id"
            component={IssueDocs}
          />
          <RoleGuardRoute
            authCheckType="Roles"
            allowedRoles={[
              UserRole.providerAdmin,
              UserRole.regionalManager,
              UserRole.eneryConsultant,
              UserRole.enterpriseManager,
            ]}
            path="/modify-loan/:id"
            component={ModifyLoan}
          />

          {/* /pre-qualification route */}
          {isAuthorizedAuthStatus && (
            <RoleGuardRoute
              authCheckType="Roles"
              allowedRoles={[
                UserRole.providerAdmin,
                UserRole.regionalManager,
                UserRole.eneryConsultant,
                UserRole.enterpriseManager,
              ]}
              path="/pre-qualification"
              component={PreQualification}
            />
          )}
          {isPublicAuthStatus && (
            <RoleGuardRoute
              authCheckType="AuthStatus"
              allowedAuthStatus={[UserAuthStatus.Public]}
              path="/pre-qualification"
              component={PreQualification}
            />
          )}

          {/* /new-application route */}
          {isAuthorizedAuthStatus && (
            <RoleGuardRoute
              authCheckType="Roles"
              allowedRoles={[
                UserRole.providerAdmin,
                UserRole.regionalManager,
                UserRole.eneryConsultant,
                UserRole.enterpriseManager,
              ]}
              path="/new-application"
              component={NewApplication}
            />
          )}
          {isPublicAuthStatus && (
            <RoleGuardRoute
              authCheckType="AuthStatus"
              allowedAuthStatus={[UserAuthStatus.Public]}
              path="/new-application"
              component={NewApplication}
            />
          )}

          <Route path="/public-success" component={PublicSucess} />

          <RoleGuardRoute
            authCheckType="Roles"
            allowedRoles={[UserRole.providerAdmin, UserRole.regionalManager]}
            path="/users"
            component={Users}
          />

          <RoleGuardRoute
            authCheckType="Roles"
            allowedRoles={[UserRole.providerAdmin]}
            path="/add-user"
            component={AddUser}
          />
          <RoleGuardRoute
            authCheckType="Roles"
            allowedRoles={[UserRole.providerAdmin]}
            path="/add-region"
            component={AddRegion}
          />
          <RoleGuardRoute
            authCheckType="Roles"
            allowedRoles={[UserRole.providerAdmin]}
            path="/modify-user/:id"
            component={ModifyUser}
          />
          <RoleGuardRoute
            authCheckType="Roles"
            allowedRoles={[UserRole.providerAdmin]}
            path="/reset/auth/:id"
            component={ResetAuth}
          />
          <RoleGuardRoute
            authCheckType="Roles"
            allowedRoles={[UserRole.providerAdmin]}
            path="/ip-whitelist"
            component={IpWhitelist}
          />

          <RoleGuardRoute
            authCheckType="AuthStatus"
            allowedAuthStatus={[UserAuthStatus.Authorized]}
            path={`/setup/mfa`}
            component={SetupMfa}
          />

          <Route path="/not-allowed" component={NoPermission} />
          <Route
            path="*"
            render={() => (
              <Redirect
                to={{
                  pathname: "/pipelines",
                }}
              />
            )}
          />
        </Switch>
      </div>
    </>
  );
};
