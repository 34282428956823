
import React from 'react'
import { Button, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const ButtonDropdown = ({ buttonColor, dropDownClass, buttonText, dropDownProps, onChange, btnRedirectUrl }: { buttonColor: string, dropDownClass: string, buttonText: string, dropDownProps: Array<any>, onChange: Function, btnRedirectUrl: string }) => {

    let list = dropDownProps.map(function (dropdownOption, i) {
        return <Dropdown.Item onClick={() => onChange(dropdownOption)} key={i}>{dropdownOption.text}</Dropdown.Item>
    });

    return (
        <>
            <Dropdown className={dropDownClass}
                align={{ sm: 'start' }}
            >
                <Link to={btnRedirectUrl}>
                    <Button variant={buttonColor} className="mx-1-1" >{buttonText}</Button>
                </Link>

                <Dropdown.Toggle split variant={buttonColor} id="dropdown-split-basic" />
                <Dropdown.Menu>
                    {list}
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}