import NavLogo from "@assets/images/logo.svg";
import { useHistory, Link } from "react-router-dom";
import { forgotPasswordSchema } from "@auth/schema";
import { UseFormTextField } from "@shared/components/TextInput";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch } from "@store/hooks";

import "../style.scss";
import { forgotPasswordAction } from "@store/slices/auth.slice";
import { onSubmitError } from "@shared/helpers/global.helper";

const ForgotPassword = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(forgotPasswordSchema),
  });

  const onSubmit = (event: any) => {
    dispatch(forgotPasswordAction({ email: event.email, history }));
  };

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <div className="login-container">
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit, onSubmitError())}
        >
          <div className="row">
            <div className="col-xs-12 col-md-6  offset-md-3">
              <div className="d-flex justify-content-center">
                <img src={NavLogo} alt="Flic Financial" />
              </div>
              <div className="d-flex justify-content-center mt-4">
                <h3 className="h3 text-primary">Forgot Password</h3>
              </div>
              <div className="d-flex justify-content-center mt-2">
                <h4 className="fw-normal text-muted">
                  Enter your email address below to reset your password.
                </h4>
              </div>
              <div className="mt-4">
                <UseFormTextField
                  placeholder="Email"
                  control={control}
                  name="email"
                />
              </div>
              <div className="d-flex flex-row justify-content-center mt-3 w-100">
                <Button variant="primary" className="w-100" type="submit">
                  Submit
                </Button>
              </div>
              <div className="d-flex justify-content-center mt-3 ">
                <Link to="/auth/login" className="text-primary">
                  Login to your account
                </Link>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
