import React from "react";
import Skeleton from "react-loading-skeleton";

export const FullAppOfferSkeleton = () => {
  return (
    <div className="full-app-skeleton-container border p-3 mt-5">
      <div className="d-flex justify-content-center mt-5">
        <h6 className="">
          <Skeleton count={1} width={460} />{" "}
        </h6>
      </div>

      <div className="d-flex mt-5  flex-column">
        <h6 className="">
          <Skeleton count={1} width={"100%"} />{" "}
        </h6>
        <h6 className="">
          <Skeleton count={1} width={150} />{" "}
        </h6>
      </div>
      <div className="mt-2">
        <Skeleton count={1} width={"100"} height={60} />
      </div>
      <div className="d-flex mt-2 justify-content-center align-items-center flex-column">
        <div className="d-flex flex-row mt-3">
          <Skeleton count={4} width={100} style={{ marginLeft: "50px" }} />
          <Skeleton count={4} width={100} style={{ marginLeft: "50px" }} />
          <Skeleton count={4} width={100} style={{ marginLeft: "50px" }} />
          <Skeleton count={4} width={100} style={{ marginLeft: "50px" }} />
        </div>
      </div>
      <div className="d-flex mt-3 flex-column justify-content-between">
        <h6 className="">
          <Skeleton count={1} width={"100%"} />{" "}
        </h6>
        <h6 className="">
          <Skeleton count={1} width={150} />{" "}
        </h6>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <Skeleton count={1} width={130} height={40} />
      </div>
    </div>
  );
};
