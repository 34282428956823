import { Switch, Route, useRouteMatch } from 'react-router-dom'
import SendLink from './views/SendLink';
import SendLinkSuccess from './views/Success';

const AppLinkHome = () => {
    let { url } = useRouteMatch();

    return (
        <div>
            <Switch>
                <Route path={`${url}/send/:type`} component={SendLink} />
                <Route path={`${url}/success/:type`} component={SendLinkSuccess} />
            </Switch>
        </div>
    )
}

export default AppLinkHome