import { Region } from "@store/slices/region.slice";

export enum UserRole {
  providerAdmin = "provider-admin",
  eneryConsultant = "energy-consultant",
  regionalManager = "regional-manager",
  financialInstitution = "financial-institution",
  enterpriseManager = "enterprise-manager",
}

export enum UserRoleLabel {
  "provider-admin" = "Provider Admin",
  "energy-consultant" = "Energy Consultant",
  "regional-manager" = "Regional Manager",
  "financial-institution" = "Financial Institution",
  "enterprise-manager" = "Enterprise Manager",
}

export interface AccessManagement {
  // id of accessible products in productAccess
  productAccess: string[];
}

export enum UserActivationState {
  active = "active",
  deactivated = "deactivated",
  inactive = "inactive",
}

export interface User {
  id: string;
  dealer: string | null;
  email: string;
  regionId: string | null;
  region?: Region;
  role: UserRole;
  username: string;
  xEmployeeID: string;
  employeeId: string;
  sublenderId: string | null;
  accessManagement: null | AccessManagement;
  state: UserActivationState;
  clientId: string | null;
  clientSecret: string | null;
  lenderId: number | null;
}
