import { useEffect, useMemo, useState } from "react";

interface IAddressFields {
  addressLine: string;
  city: string;
  state: string;
  zipCode: string;
}

interface IInstallationAddressFields {
  currentAddressFields: IAddressFields;
}

const useInstallationAddress = ({
  currentAddressFields,
}: IInstallationAddressFields) => {
  const [initialInstAddressFields, setInitialInstAddressFields] = useState({
    xInstallationAddressLine: "",
    xInstallationCity: "",
    xInstallationState: "",
    xInstallationZipCode: "",
  });

  useEffect(() => {
    return () => {
      setInitialInstAddressFields({
        xInstallationAddressLine: "",
        xInstallationCity: "",
        xInstallationState: "",
        xInstallationZipCode: "",
      });
    };
  }, []);

  const isInstallationAddressUpdated = useMemo(
    () =>
      initialInstAddressFields.xInstallationAddressLine !==
        currentAddressFields.addressLine ||
      initialInstAddressFields.xInstallationCity !==
        currentAddressFields.city ||
      initialInstAddressFields.xInstallationState !==
        currentAddressFields.state ||
      initialInstAddressFields.xInstallationZipCode !==
        currentAddressFields.zipCode,
    [
      currentAddressFields.addressLine,
      currentAddressFields.city,
      initialInstAddressFields.xInstallationAddressLine,
      initialInstAddressFields.xInstallationCity,
      initialInstAddressFields.xInstallationState,
      initialInstAddressFields.xInstallationZipCode,
      currentAddressFields.state,
      currentAddressFields.zipCode,
    ]
  );

  return {
    isInstallationAddressUpdated,
    initialInstAddressFields,
    setInitialInstAddressFields,
  };
};

export default useInstallationAddress;
