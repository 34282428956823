import {
  API_PATHS_WITHOUT_LOADER_STATE,
  API_SLICES_WITHOUT_LOADER_STATE,
} from "@shared/constants/app.constant";

export const allowloadingStateUpdate = (path?: string | undefined): boolean => {
  return path ? !API_PATHS_WITHOUT_LOADER_STATE.includes(path) : true;
};

export const allowloadingStateUpdateForActions = (
  actionType?: string | undefined
): boolean => {
  return actionType
    ? !API_SLICES_WITHOUT_LOADER_STATE.includes(actionType)
    : true;
};
