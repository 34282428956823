import { networkService } from "./network-service";

class AwardcoService {
  async getAwardcoBalance() {
    return await networkService.get<{
      data: {
        success: boolean;
        balance: number;
        currencyCode: string;
        message: string;
      };
      errors: any;
      message: string;
    }>("awardco/balance");
  }
}

export const awardcoService = new AwardcoService();
