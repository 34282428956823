import { Fragment, useState, useEffect, SyntheticEvent } from "react";
import { Button } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";

import Moment from "react-moment";
import {
  ApplicationType,
  PortalDecisionStatus,
  PortalDecisionStatusLabel,
} from "@shared/enums/ApplicationStatus";
import {
  getStatusBackground,
  isApproved,
  isDeclined,
  isExpired,
  isPrequalApproved,
  isPrequalDeclined,
  isSalesRole,
} from "@shared/helpers/global.helper";
import NumberFormat from "./NumberFormat";
import {
  isIdentificationUploaded,
  isIssueDocsUploaded,
  isSolarContractUploaded,
  isStipulationUploaded,
} from "@shared/helpers/documents.helper";
import { DATE_FORMAT, QUERY_PARAMS_KEYS } from "@shared/constants/app.constant";
import AppModal from "./AppModal";
import { useAppSelector } from "@store/hooks";
import { appUserSelector } from "@store/slices/app.slice";
import { UserRole } from "@shared/interfaces/User";
import { RequestDetailsTabs } from "../../features/request-detail/RequestDetail";
import { IPipelineApplicationResponse } from "@store/slices/loan.slice";
import { PortalApplicationStatus } from "@shared/interfaces/PortalApplicationTypes";

export const getStatusInfo = (
  decisionStatusString: string | undefined,
  applicationStatusString: string | undefined,
  requestedAmount: number
) => {
  if (
    !decisionStatusString ||
    !applicationStatusString ||
    requestedAmount === undefined
  )
    return;
  if (
    isDeclined(decisionStatusString) &&
    applicationStatusString === PortalApplicationStatus.Application
  ) {
    return (
      <span>
        Requested for <NumberFormat amount={requestedAmount} />
      </span>
    );
  } else if (
    isPrequalDeclined(decisionStatusString) &&
    applicationStatusString === PortalApplicationStatus.Prequal
  ) {
    return "Does not qualify ";
  } else if (
    isPrequalApproved(decisionStatusString) &&
    applicationStatusString === PortalApplicationStatus.Prequal
  ) {
    return <span>Prequalified for 1 or more offers</span>;
  } else if (
    applicationStatusString === PortalApplicationStatus.Application ||
    applicationStatusString === PortalApplicationStatus.WaitingOnDocs ||
    applicationStatusString === PortalApplicationStatus.NTPIssued ||
    applicationStatusString === PortalApplicationStatus.NTPReview ||
    applicationStatusString === PortalApplicationStatus.QCReview
  ) {
    return (
      <span>
        Approved for <NumberFormat amount={requestedAmount} />
      </span>
    );
  } else return "";
};

const PipelineCard = ({ data }: { data: IPipelineApplicationResponse }) => {
  const user = useAppSelector(appUserSelector);
  const [width, setWidth] = useState(window.innerWidth);
  const [stipulationUploaded, setStipulationUploaded] = useState(false);
  const [identificationDocUploaded, setIdentificationDocUploaded] =
    useState(false);
  const [solarContractUploaded, setSolarContractUploaded] = useState(false);
  const [issueDocsUploaded, setIssueDocsUploaded] = useState(false);
  const [warning, setWarning] = useState<{
    content: string;
    show: boolean;
  }>({
    content: "",
    show: false,
  });
  const history = useHistory();
  const isMutable = user?.role && user.role !== UserRole.financialInstitution;

  const checkForWarnings = () => {
    if (data?.inReviewWithSublender) {
      setWarning({
        content:
          "Application is currently under review with the lender and cannot be viewed at this time.",
        show: true,
      });
      return true;
    }
  };
  const onClickHandler = (event: SyntheticEvent<HTMLElement>) => {
    let target = event.target as any;
    while (target) {
      if ((target.className || "").split(" ").includes("modal")) {
        // Ignore click events that originated from a modal
        return;
      }
      target = target.parentNode;
    }
    if (checkForWarnings()) {
      return;
    }
    history.push(`/request-detail/${data?.applicationNumber}`);
  };

  const goToDocs = (event: SyntheticEvent<HTMLButtonElement>) => {
    if (event) {
      event.stopPropagation();
    }
    if (checkForWarnings()) {
      return;
    }
    history.push(
      `/request-detail/${data?.applicationNumber}?${
        QUERY_PARAMS_KEYS.REQUEST_DETAILS_TAB
      }=${
        isSalesRole(user?.role)
          ? RequestDetailsTabs.noticeToProceed
          : RequestDetailsTabs.documents
      }`
    );
  };

  const getDecisionStatus = (status: string) => {
    if (isPrequalApproved(status)) {
      return PortalDecisionStatusLabel.Prequalified;
    } else if (isPrequalDeclined(status)) {
      return PortalDecisionStatusLabel.PrequalDeclined;
    } else if (isDeclined(status)) {
      return PortalDecisionStatusLabel.Declined;
    } else if (isApproved(status)) {
      return PortalDecisionStatusLabel.Approved;
    } else return status;
  };

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  const showButtons = () => {
    const buttons = [];
    if (!stipulationUploaded) {
      buttons.push(
        <Button
          key="Stipulations"
          variant="outline-primary font-14 mx-1 w-md-auto"
          onClick={goToDocs}
        >
          Stipulations
        </Button>
      );
    }
    if (!identificationDocUploaded) {
      buttons.push(
        <Button
          key="uploadId"
          variant="outline-primary font-14 mx-1 w-md-auto"
          onClick={goToDocs}
        >
          Upload ID
        </Button>
      );
    }
    if (!solarContractUploaded) {
      buttons.push(
        <Button
          key="uploadContract"
          variant="outline-primary font-14 mx-1 w-md-auto "
          onClick={goToDocs}
        >
          Upload Contract
        </Button>
      );
    }
    if (!issueDocsUploaded) {
      buttons.push(
        <Button
          key="issueDocs"
          variant="outline-primary font-14 mx-1 w-md-auto "
          onClick={goToDocs}
        >
          Issue Docs
        </Button>
      );
    }
    if (buttons.length < 3) return buttons;
    switch (true) {
      case width > 1425:
        buttons.splice(buttons.length - 1);
        buttons.push(
          <Button
            key="plus-1"
            onClick={goToDocs}
            variant="outline-primary font-14 mx-1 w-md-auto"
          >
            +{buttons.length - (buttons.length - 1)}
          </Button>
        );
        break;
      case width > 1000:
        buttons.splice(buttons.length - 2);
        buttons.push(
          <Button
            key="plus-2"
            onClick={goToDocs}
            variant="outline-primary font-14 mx-1 w-md-auto"
          >
            +{buttons.length - (buttons.length - 2)}
          </Button>
        );
        break;
      case width < 1000 && buttons.length > 3:
        buttons.splice(buttons.length - 3);
        buttons.push(
          <Button
            key="plus-3"
            onClick={goToDocs}
            variant="outline-primary font-14 mx-1 w-md-auto"
          >
            +{buttons.length - (buttons.length - 3)}
          </Button>
        );
        break;
      case width < 1000 && buttons.length === 3:
        buttons.splice(buttons.length - 2);
        buttons.push(
          <Button
            key="plus-4"
            onClick={goToDocs}
            variant="outline-primary font-14 mx-1 w-md-auto"
          >
            +{buttons.length - (buttons.length - 2)}
          </Button>
        );
        break;
      default:
        break;
    }
    // Assign fragment parent to define a key
    return buttons.map((button, i) => <Fragment key={i}>{button}</Fragment>);
  };
  const isRecordProcessed = () => {
    return (
      data?.applicationStatus === PortalApplicationStatus.NTPIssued ||
      data?.applicationStatus === PortalApplicationStatus.NTPReview ||
      data?.applicationStatus === PortalApplicationStatus.QCReview
    );
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    if (!data?.stipulations || data?.stipulations?.length < 1) return;
    setStipulationUploaded(
      isStipulationUploaded(
        data?.stipulations,
        data?.applicationStatus !== ApplicationType.PreQual
      )
    );
    setIdentificationDocUploaded(
      isIdentificationUploaded(
        data?.stipulations,
        data?.applicationStatus !== ApplicationType.PreQual
      )
    );
    setSolarContractUploaded(isSolarContractUploaded(data?.stipulations));
    setIssueDocsUploaded(isIssueDocsUploaded(data?.stipulations));
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  return (
    <div
      className="border p-3 hover"
      id={data.applicationNumber}
      onClick={onClickHandler}
    >
      <div>
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-row align-items-center ellipsis-container-name">
            <h6 className="mb-0 ">{data?.applicationNumber}</h6>
            <div className="separator border-dark top-2"></div>
            <h6 className="mb-0 ellipsis-truncate">
              {data?.name[0] ? data?.name[0] : ""}
            </h6>
          </div>
          <h6 className="text-end ellipsis-container-status">
            {data?.applicationStatus}
          </h6>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <span className="text-muted font-12 currency">
            {getStatusInfo(
              data?.decisionStatusString,
              data?.applicationStatus,
              data?.requestedAmount
            )}
          </span>
          <h6 className={getStatusBackground(data?.decisionStatusString)}>
            {getDecisionStatus(data?.decisionStatusString)}
          </h6>
        </div>
        <div className="d-flex flex-row ">
          <p className="text-muted mb-2">
            Created on:&nbsp;
            <Moment style={{ fontSize: "12px" }} format={DATE_FORMAT}>
              {data?.createdOn}
            </Moment>
          </p>
        </div>
      </div>
      {isMutable && (
        <div className="d-flex justify-content-end">
          {data?.applicationStatus === PortalApplicationStatus.Prequal &&
            isPrequalApproved(data?.decisionStatusString) &&
            !isExpired(data?.decisionStatusString) && (
              <>
                <Link
                  to={`/request-detail/${data?.applicationNumber}`}
                  onClick={(event) => event.stopPropagation()}
                >
                  <Button variant="outline-primary font-14">
                    Convert To Full Application
                  </Button>
                </Link>
                &nbsp;
              </>
            )}

          {data?.applicationStatus !== PortalApplicationStatus.Prequal &&
            !isRecordProcessed() &&
            !isDeclined(data?.decisionStatusString) &&
            !isPrequalDeclined(data?.decisionStatusString) &&
            !isExpired(data?.decisionStatusString) && (
              <div className="mt-1">{showButtons()}</div>
            )}
        </div>
      )}
      <AppModal
        show={warning.show}
        title={"In Review with Lender"}
        content={<>{warning.content}</>}
        size="md"
        onHide={() => {
          setWarning((prev) => ({
            ...prev,
            show: false,
          }));
        }}
      />
    </div>
  );
};

export default PipelineCard;
