import {
  ApplicantType,
  ApplicationType,
} from "@shared/enums/ApplicationStatus";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import AppModal from "./AppModal";
import ElectronicDisclosure from "./ElectronicDisclosure";
import HardCreditPull from "./HardCreditPull";
import PrivacyPolicy from "./PrivacyPolicy";
import SoftCreditPull from "./SoftCreditPull";
import TermsOfUse from "./TermsOfUse";
import DisclosureContainer from "./DisclosureContainer";
import { useAppSelector } from "@store/hooks";
import { sungageDisclosuresSelector } from "@store/slices/loan.slice";

interface IUserConsentProps {
  applicant?: ApplicantType;
  type: ApplicationType;
  authorization?: any;
  consent?: any;
  isSungageProduct?: boolean;
}

const UserConsent = ({
  applicant,
  type,
  authorization,
  consent,
  isSungageProduct = false,
}: IUserConsentProps) => {
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const [openHardCreditModal, setOpenHardCreditModal] = useState(false);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  const [openSoftCreditModal, setOpenSoftCreditModal] = useState(false);
  const [openDisclosure, setOpenDisclosure] = useState(false);
  const [openSungagePrivacyPolicy, setOpenSungagePrivacyPolicy] =
    useState(false);
  const [openSungageTerms, setOpenSungageTerms] = useState(false);
  const [openSungageDisclosure, setOpenSungageDisclosure] = useState(false);
  const sungageDisclosures = useAppSelector(sungageDisclosuresSelector);

  return (
    <>
      {type === ApplicationType.Full && (
        <>
          <AppModal
            show={openHardCreditModal}
            title={"Hard Credit Pull Authorization"}
            content={
              isSungageProduct ? (
                <DisclosureContainer
                  content={sungageDisclosures?.creditAuthorization}
                />
              ) : (
                <HardCreditPull />
              )
            }
            onHide={() => setOpenHardCreditModal(false)}
          />
        </>
      )}
      {type === ApplicationType.PreQual && (
        <>
          <AppModal
            show={openSoftCreditModal}
            title={"Soft Credit Pull Authorization"}
            content={
              isSungageProduct ? (
                <DisclosureContainer
                  content={sungageDisclosures?.creditAuthorization}
                />
              ) : (
                <SoftCreditPull />
              )
            }
            onHide={() => setOpenSoftCreditModal(false)}
          />
        </>
      )}
      <AppModal
        show={openDisclosure}
        title={"Flic Electronic Disclosure Authorization"}
        content={<ElectronicDisclosure />}
        onHide={() => setOpenDisclosure(false)}
      />
      <AppModal
        show={openTermsModal}
        title={"Flic Terms of Use"}
        content={<TermsOfUse />}
        onHide={() => setOpenTermsModal(false)}
      />
      <AppModal
        show={openPrivacyPolicy}
        title={"Flic Privacy Policy"}
        content={<PrivacyPolicy />}
        onHide={() => setOpenPrivacyPolicy(false)}
      />
      {isSungageProduct && (
        <>
          <AppModal
            show={openSungageDisclosure}
            title={"Sungage Electronic Disclosure Authorization"}
            content={
              <DisclosureContainer
                content={sungageDisclosures?.electronicCommunications}
              />
            }
            onHide={() => setOpenSungageDisclosure(false)}
          />
          <AppModal
            show={openSungageTerms}
            title={"Sungage Terms of Use"}
            content={
              <DisclosureContainer
                content={sungageDisclosures?.termsAndConditions}
              />
            }
            onHide={() => setOpenSungageTerms(false)}
          />
          <AppModal
            show={openSungagePrivacyPolicy}
            title={"Sungage Privacy Policy"}
            content={
              <DisclosureContainer content={sungageDisclosures?.disclosures} />
            }
            onHide={() => setOpenSungagePrivacyPolicy(false)}
          />
        </>
      )}

      <div className="d-flex flex-column mt-2 ">
        <Row className="mt-4">
          <Col md={12}>
            <h4 className="font-bold">{applicant} Credit Pull Authorization</h4>
            {authorization}
          </Col>
          <Col md={12}>
            {type === ApplicationType.Full && (
              <div className="d-flex flex-column">
                <button
                  type="button"
                  onClick={() => setOpenHardCreditModal(true)}
                  className="link-btn"
                >
                  Hard Pull Credit Authorization
                </button>
              </div>
            )}
            {type === ApplicationType.PreQual && (
              <button
                type="button"
                onClick={() => setOpenSoftCreditModal(true)}
                className="link-btn"
              >
                Soft Pull Credit Authorization
              </button>
            )}
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={12}>
            <h4 className="font-bold">{applicant} Consent</h4>
            {consent}
          </Col>
          <Col md={12}>
            <div className="d-flex flex-column">
              <button
                type="button"
                onClick={() => setOpenDisclosure(true)}
                className="link-btn"
              >
                Flic Electronic Disclosure Authorization
              </button>
              <button
                type="button"
                onClick={() => setOpenTermsModal(true)}
                className="link-btn"
              >
                Flic Terms Of Use
              </button>
              <button
                type="button"
                onClick={() => setOpenPrivacyPolicy(true)}
                className="link-btn"
              >
                Flic Privacy Policy
              </button>

              {isSungageProduct && (
                <>
                  <button
                    type="button"
                    onClick={() => setOpenSungageDisclosure(true)}
                    className="link-btn"
                  >
                    Sungage Electronic Disclosure Authorization
                  </button>
                  <button
                    type="button"
                    className="link-btn"
                    onClick={() => setOpenSungageTerms(true)}
                  >
                    Sungage Terms Of Use
                  </button>
                  <button
                    type="button"
                    className="link-btn"
                    onClick={() => setOpenSungagePrivacyPolicy(true)}
                  >
                    Sungage Privacy Policy
                  </button>
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UserConsent;
