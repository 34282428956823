import React, { ReactNode } from "react";
import Button from "react-bootstrap/Button";
import Modal, { ModalProps } from "react-bootstrap/Modal";

interface ChoiceButton {
  title: string;
  value: string;
  variant: string;
}

interface ChoiceModalProps extends ModalProps {
  children: ReactNode;
  choices: ChoiceButton[];
  disabled?: boolean;
  onChoose: (value: string) => void;
  title: string;
}

const ChoiceModal = ({
  choices,
  children,
  disabled,
  onChoose,
  title,
  ...props
}: ChoiceModalProps) => {
  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        {choices.map((choice) => (
          <Button
            key={choice.value}
            disabled={disabled}
            variant={choice.variant}
            onClick={() => onChoose(choice.value)}
          >
            {choice.title}
          </Button>
        ))}
      </Modal.Footer>
    </Modal>
  );
};

export default ChoiceModal;
