import { Redirect } from "react-router-dom";
import { isApplicationNotConvertable } from "@shared/helpers/global.helper";
import { useAppSelector } from "@store/hooks";
import { appUserSelector } from "@store/slices/app.slice";
import { useAuthUserContext } from "@contexts/AuthContext";
import { IDl4Application } from "@shared/interfaces/Application";

/**
 * This component redirects the user to the Pipelines page
 * if an Application is restricted from being modified
 */
const ConvertApplicationGuard = ({
  application,
}: {
  application?: IDl4Application | null | any;
}) => {
  const user = useAppSelector(appUserSelector);
  const { authUserStatus } = useAuthUserContext();

  if (
    !!application &&
    isApplicationNotConvertable(application, user?.role, authUserStatus)
  ) {
    return <Redirect to="/pipelines" />;
  }
  return <></>;
};

export default ConvertApplicationGuard;
