import React from "react";
import { Row, Col } from "react-bootstrap";

const HardCreditPull = () => {
  return (
    <Col xs={11} className="mx-auto terms">
      <div className="">
        <Row className="">
          <Col xs={12}>
            <strong>Last Updated: October 28, 2024</strong>
            <br />
            <br />
            I, the applicant and co-applicant (if applicable), understand,
            acknowledge, and consent under the Fair Credit Reporting Act that
            SunRisa Solutions LLC dba Flic Financial, Almika Renewable Finance
            LLC, A+ Federal Credit Union, Texas Dow Employees Credit Union or
            NASA Federal Credit Union may review my loan application to obtain a
            consumer credit report on me from one or more consumer reporting
            agencies to process my loan application and determine my eligibility
            for the loan for which I am applying. I further understand,
            acknowledge, and consent that such consumer credit report will be
            obtained by means of a hard credit inquiry that may impact my credit
            scores, and that the information provided in such consumer credit
            report will be part of my loan application.
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default HardCreditPull;
