import NumberFormat from "@shared/components/NumberFormat";

import { createUrl } from "@shared/helpers/global.helper";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import { OfferSkeleton } from "@shared/skeletons/OfferSkeleton";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import {
  getRequestAction,
  isMultiPrequalSelector,
  loanRequestSelector,
  setIsMultiPrequal,
  setRequestData,
} from "@store/slices/loan.slice";
import { useCallback, useEffect, useLayoutEffect } from "react";
import { Col, Button, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import MultiPrequalLoanOfferCard from "./MultiPrequalLoanOfferCard";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
  DotGroup,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import useWindowSize from "@hooks/windowSize";
import { IPrequalifiedOffer } from "@shared/interfaces/Application";
import { UserAuthStatus } from "@shared/enums/UserState";
import { useAuthUserContext } from "@contexts/AuthContext";
import { isPrequalApplication } from "@shared/helpers/application.helper";
import { IPortalApplication } from "@shared/interfaces/PortalApplicationTypes";

const getVisibleSlides = (
  breakPoint: "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | undefined
) => {
  switch (breakPoint) {
    case "sm":
    case "xs":
    case "md":
      return 1;
    case "lg":
    case "xl":
      return 2;

    default:
      return 3;
  }
};

const Confirmation = () => {
  const dispatch = useAppDispatch();

  const requestDetail = useAppSelector(
    loanRequestSelector
  ) as IPortalApplication | null;
  const isMultiPrequal = useAppSelector(isMultiPrequalSelector);
  const { breakPoint } = useWindowSize();
  const history = useHistory();
  let { id } = useParams<{ id: string }>();
  const loanOfferData = requestDetail?.prequalifiedOffers?.filter(
    (item) => item.active
  );
  const visibleSlides = getVisibleSlides(breakPoint);

  useEffect(() => {
    return () => {
      dispatch(setIsMultiPrequal(false));
    };
  }, [dispatch]);

  useLayoutEffect(() => {
    dispatch(setRequestData(null));
    dispatch(getRequestAction(id));
  }, [dispatch, id]);

  const handleExit = useCallback(() => {
    history.push(createUrl(`/request-detail/${id}`));
  }, [history, id]);

  const handleConverToFullApp = (prequalifiedOfferId: number) => {
    history.push(
      createUrl(`/pre-qualification/convert/${requestDetail?.losAppNumber}`),
      {
        prequalifiedOfferId,
      }
    );
  };

  useEffect(() => {
    // Guard to only proces Prequal apps here
    if (requestDetail && !isPrequalApplication(requestDetail)) {
      handleExit();
    }
  }, [handleExit, requestDetail]);

  return isMultiPrequal || (loanOfferData && loanOfferData?.length > 1) ? (
    <CarouselProvider
      naturalSlideWidth={1200}
      naturalSlideHeight={900}
      isIntrinsicHeight
      totalSlides={loanOfferData?.length || 3} // Here the default 3 value is for displayin 3 loading skeleton
      visibleSlides={visibleSlides}
    >
      <MultiPrequalLoanConfirmation
        id={id}
        loanOfferData={loanOfferData}
        handleExit={handleExit}
        handleConverToFullApp={handleConverToFullApp}
        visibleSlides={visibleSlides}
      />
    </CarouselProvider>
  ) : (
    <SinglePrequalLoanConfirmation
      loanOfferData={loanOfferData?.[0]}
      handleExit={handleExit}
      handleConverToFullApp={handleConverToFullApp}
    />
  );
};

const SinglePrequalLoanConfirmation = ({
  loanOfferData,
  handleExit,
  handleConverToFullApp,
}: {
  loanOfferData: IPrequalifiedOffer | undefined;
  handleExit: () => void;
  handleConverToFullApp: (offerId: number) => void;
}) => {
  const { authUserStatus } = useAuthUserContext();
  return (
    <Row>
      <Col xs={12} className="mx-auto d-flex justify-content-center">
        {!loanOfferData ? (
          <OfferSkeleton />
        ) : (
          <div className="prequal-offers">
            <p className="text-center mb-3">
              <span className="highlight mb-2">Congratulations,</span>{" "}
              <span className="message mb-2">you have been</span>{" "}
              <span className="highlight">prequalified</span>{" "}
              <span className="message"> for</span>
              <span className="amount">
                {" "}
                <NumberFormat amount={loanOfferData?.amount} />
              </span>
            </p>

            <div className={"single-prequal-offer-container"}>
              <MultiPrequalLoanOfferCard
                handleConverToFullApp={handleConverToFullApp}
                offer={loanOfferData}
              />
            </div>

            <div className="text-center mt-4">
              <p className="disclaimer">
                *A hard pull of the applicant's credit, which includes a full
                search of his or her credit history, may change these results.
              </p>
            </div>
            <div className="mt-3 d-flex justify-content-center mb-5">
              {authUserStatus === UserAuthStatus.Authorized && (
                <Button
                  variant="outline-primary"
                  type="button"
                  onClick={handleExit}
                >
                  Exit
                </Button>
              )}
            </div>
          </div>
        )}
      </Col>
    </Row>
  );
};

const MultiPrequalLoanConfirmation = ({
  id,
  loanOfferData,
  handleExit,
  handleConverToFullApp,
  visibleSlides,
}: {
  id: string;
  loanOfferData: IPrequalifiedOffer[] | undefined;
  handleExit: () => void;
  handleConverToFullApp: (offerId: number) => void;
  visibleSlides: number;
}) => {
  const { windowWidth } = useWindowSize();
  // const carouselContext = useContext(CarouselContext);
  const { breakPoint } = useWindowSize();

  return (
    <div className="prequal-offers">
      {!loanOfferData && (
        <div className="multi-prequal-offer-loading-container">
          <Slider trayProps={{}}>
            {[1, 2, 3].map((i) => (
              <Slide index={i} innerClassName="d-flex justify-content-center">
                <OfferSkeleton />
              </Slide>
            ))}
          </Slider>
        </div>
      )}

      {loanOfferData && loanOfferData.length === 0 && (
        <div className="multi-prequal-offer-loading-container">
          <p className="text-center no-prequal-offers mb-4">
            There are no prequal offers for this application.
          </p>
          <div className="d-flex justify-content-center">
            <Button
              variant="outline-primary"
              type="button"
              onClick={handleExit}
            >
              Exit
            </Button>
          </div>
        </div>
      )}

      {loanOfferData && loanOfferData.length > 0 && (
        <div>
          <div className="d-flex flex-column gap-3 align-items-center mb-2 mt-2">
            <p className="message text-danger text-center">
              At this time we are unable to prequalify you for the requested
              product.
            </p>
            <p className="message text-center">
              However, you are prequalified for other products.
            </p>
            <p className="message text-center">
              Showing{" "}
              {loanOfferData.length > 3 &&
                breakPoint &&
                ["lg", "xl", "xxl"].includes(breakPoint) && (
                  <>
                    <span className="highlight-page">{visibleSlides}</span> out
                    of{" "}
                  </>
                )}
              <span className="highlight-page">{loanOfferData.length}</span>{" "}
              prequalified products.
            </p>
          </div>

          {/* Desktop and Tablet view */}
          {breakPoint && ["lg", "xl", "xxl"].includes(breakPoint) ? (
            loanOfferData.length > 3 ? (
              <div>
                <div className={"multi-prequal-offer-container"}>
                  <Slider trayProps={{}}>
                    {loanOfferData.map((offer, index) => (
                      <Slide
                        index={index}
                        innerClassName="d-flex justify-content-center"
                      >
                        <MultiPrequalLoanOfferCard
                          offer={offer}
                          handleConverToFullApp={handleConverToFullApp}
                        />
                      </Slide>
                    ))}
                  </Slider>
                  <ButtonBack className="multi-prequal-offer-slider-button back-button h-9">
                    <ChevronLeft
                      width={windowWidth > 1200 ? 90 : 30}
                      height="auto"
                    />
                  </ButtonBack>
                  <ButtonNext className="multi-prequal-offer-slider-button next-button">
                    <ChevronRight
                      width={windowWidth > 1200 ? 90 : 30}
                      height="auto"
                      className=""
                    />
                  </ButtonNext>
                </div>
                <div className="dot-group-container d-flex justify-content-center">
                  <DotGroup />
                </div>
              </div>
            ) : (
              <Row className="justify-content-center flex-nowrap">
                {/* To Display 3 or less than 3 products without the carousel */}
                {loanOfferData.map((offer, index) => (
                  <MultiPrequalLoanOfferCard
                    offer={offer}
                    handleConverToFullApp={handleConverToFullApp}
                  />
                ))}
              </Row>
            )
          ) : null}

          {/* Mobile view  */}
          {breakPoint && ["sm", "xs", "md"].includes(breakPoint) && (
            <div className="d-flex flex-column align-items-center">
              {loanOfferData.map((offer) => (
                <MultiPrequalLoanOfferCard
                  offer={offer}
                  handleConverToFullApp={handleConverToFullApp}
                />
              ))}
            </div>
          )}

          <p className="bottom-text my-4 text-center">
            *A hard pull of the applicant's credit, which includes a full search
            of his or her credit history, may change these results.
          </p>

          <div className="d-flex justify-content-center">
            <Button
              variant="outline-primary"
              type="button"
              onClick={handleExit}
            >
              Exit
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Confirmation;
