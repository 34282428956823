import { Redirect } from "react-router-dom";
import { enableIssueLoanDocs } from "@shared/helpers/global.helper";
import { useAppSelector } from "@store/hooks";
import { appUserSelector } from "@store/slices/app.slice";
import { IPortalApplication } from "@shared/interfaces/PortalApplicationTypes";

/**
 * This component redirects the user to the Application details page
 * if an Application is restricted from Issue loan docs
 */
const IssueLoanDocsGuard = ({
  application,
}: {
  application?: IPortalApplication | null;
}) => {
  const user = useAppSelector(appUserSelector);
  if (!!application && !enableIssueLoanDocs(application, user?.role)) {
    return <Redirect to={`/request-detail/${application.losAppNumber}`} />;
  }
  return <></>;
};

export default IssueLoanDocsGuard;
