import { NA } from "@shared/constants/app.constant";

const numberFormat = (value: number, minimumFractionDigits: number) =>
  new Intl.NumberFormat("en-US", { minimumFractionDigits }).format(value);

const currencyFormat = (
  value: number,
  minimumFractionDigits: number,
  maximumFractionDigits: number
) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value);

const NumberFormat = ({
  amount,
  isCurrency = true,
  symbol,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
}: {
  amount: number | undefined;
  isCurrency?: Boolean;
  symbol?: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}) => {
  if (amount === undefined || amount === null) {
    return <span>{NA}</span>;
  }
  return (
    <>
      {isCurrency && (
        <span>
          {!isNaN(amount)
            ? currencyFormat(
                amount,
                minimumFractionDigits,
                maximumFractionDigits
              )
            : NA}
        </span>
      )}
      {!isCurrency && (
        <span>
          {!isNaN(amount)
            ? numberFormat(amount, minimumFractionDigits) + symbol
            : NA}
        </span>
      )}
    </>
  );
};
export default NumberFormat;
