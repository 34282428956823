import React from 'react'
import { Row, Col } from 'react-bootstrap'

const PrivacyPolicy = () => {
    return (
        <Col xs={11} className="mx-auto terms">
            <div className="">
                <Row className="">
                    <Col xs={12}>
                        <strong> Last Updated:‎ August 29, 2023</strong>
                        <br/><br/>
                        <p>  At ‎SunRisa Solutions LLC dba Flic Financial‎ ‎(collectively, ‎‎“Flic,” “we,” “our,” and “us”)‎, the protection of the information we collect about you is important to us. This privacy policy (“Privacy Policy”) applies to any websites or web services that we own and control that posts a link to this Privacy Policy, and any related products and services, including any associated interactive features, widgets, plug-ins, applications, content, downloads and other services we may offer to you (collectively, the “Web Services”), regardless of how you access or use them, whether via personal computer, browser, laptop, tablet, mobile phone or other device (each a “Device”). This Privacy Policy covers how Flic collects, uses, shares, and processes personal and other ‎information and data of users of our Web Services. To the extent that we provide you with notice through our Web Services of different or additional privacy policies or practices (e.g., at the point of collection), those additional privacy policies shall govern such data collection and use. </p>
                        <p> By using or accessing our Web Services or by submitting information to Flic, you consent to the terms of this Privacy Policy. </p>
                        <p>In addition to reading this Privacy Policy, please review our Terms of Use, which governs your use of our Web Services. If you do not agree to our Terms of Use and the collection, use and sharing of your information as detailed in this Privacy Policy, please do not access or otherwise use our Web Services or any information or content accessible on our Web Services.</p>
                        <p> We reserve the right to change this Privacy Policy at any time, and we will post a notice or inform you of any such ‎changes on our Web Services. Your use of our Web Services following the posting of an updated Privacy Policy constitutes your acceptance of such updated Privacy Policy. We reserve the right to apply the amended terms to the information that we have already collected, subject to any legal constraints. We will not, however, use your previously collected Personal Information (defined herein) in a manner materially different than represented at the time of collection. To the extent any provision of this Privacy Policy is found by a competent tribunal to be invalid or unenforceable, such provision shall be severed to the extent necessary for the remainder to be valid and enforceable. </p>
                        <p>Please note that this Privacy Policy applies only to our information-gathering, use, and ‎dissemination practices in connection with our Web Services. This Privacy Policy does not apply to ‎any of our information practices conducted separately by Flic outside of our Web Services.‎</p>
                        <strong>1.  Information Collection.</strong>

                        <p>We collect information from you in several ways, including when you choose to share information with us by entering it through our Web Services and by using automated processes. We also collect information about your transactions with us, such as any information you submit to us through our Web Services. </p>
                        <p>(a)  Information You Provide.</p>

                        <p> In connection with providing you with the Web Services, we may ask you and you may choose to provide certain “Personal Information” to us, which is information that identifies you personally, such as your or a co-borrower’s first and last name, date of birth, social security number and other government identifiers, income information, address, financial information, phone numbers, e-mail address, employment information, citizenship, property ownership information, and other technical and other information collected automatically. Information you submit may also include comments, photos, messages, feedback, and other content you generate. We also collect information from you about the products or services that you are seeking. We may collect this information in a variety of places, including submission forms, through our Web Services in its various forms including any applications. </p>
                        <p>(b)  Information About Third Parties.</p>

                        <p> By submitting Personal Information about any third party, you represent and warrant to Flic that you have the consent of any third party to do so, and that the information you submit is factually accurate.</p>
                        <p> (c)  Automated Information Collection.
                        </p>
                        <p> In addition to any information that you choose to submit to us via our Web Services, we and our third-party service providers may use a variety of technologies that automatically (or passively) store or collect certain information whenever you visit or interact with our Web Services (“Usage Information”). This Usage Information may be stored or accessed using a variety of technologies that may be downloaded to your Device whenever you visit or interact with our Web Services. To the extent we associate Usage Information with your Personal Information we collect directly from you through our Web Services, we will treat it as Personal Information. </p>
                        <p>This Usage Information may include:
                        </p>
                        <ul>
                            <li>your IP address, UDID or other unique identifier (“Device Identifier”). A Device Identifier is a number that is automatically assigned to your Device used to access our Web Services, and our computers identify your Device by its Device Identifier;</li>
                            <li> your Device functionality (including browser, operating system, hardware, mobile network information);</li>
                            <li> the URL that referred you to our Web Services;</li>
                            <li>  the areas within our Web Services that you visit and your activities there, including remembering you and your preferences;</li>
                            <li> your Device location;</li>
                            <li> 	your Device characteristics; and</li>
                            <li> 	certain other Device data, including the time of day, among other information.</li>
                        </ul>




                        <strong>Tracking Technologies.</strong>
                        <p> We may use various methods and technologies to store or collect Usage Information (“Tracking Technologies”). Tracking Technologies may set, change, alter or modify settings or configurations on your Device. A few of the Tracking Technologies include, without limitation, the following (and subsequent technology and methods later developed):</p>
                        <strong>Cookies.</strong>
                        <p> A cookie is a data file placed on a Device when it is used to visit our website. HTML5 cookies can be programmed through HTML5 local storage.</p>
                        <strong> Web Beacons. </strong>
                        <p>
                            Small graphic images or other web programming code called web beacons (also known as “1×1 GIFs” or “clear GIFs”) may be included in our Web Services’ pages and messages. Web beacons may be invisible to you, but any electronic image or other web programming code inserted into a page or e-mail can act as a web beacon. Web beacons or similar technologies may be used for a number of purposes, including, without limitation, to count visitors to our Web Services, to monitor how users navigate our Web Services, to count how many e-mails that were sent were actually opened or to count how many particular articles or links were actually viewed.   </p>
                        <strong>Embedded Scripts.</strong>
                        <p>
                            An embedded script is programming code that is designed to collect information about your interactions with our Web Services, such as the links you click on. The code is temporarily downloaded onto your Device from our web server or a third-party service provider, is active only while you are connected to our Web Services and is deactivated or deleted thereafter.  </p>
                        <strong> Browser Fingerprinting.</strong>
                        <p>Collection and analysis of information from your Device, such as, without limitation, your operating system, plug-ins, system fonts and other data, for purposes of identification.</p>
                        <strong> ETag, or Entity Tag.</strong>
                        <p>                A feature of the cache in browsers. It is an opaque identifier assigned by a web server to a specific version of a resource found at a URL. If the resource content at that URL ever changes, a new and different ETag is assigned. Used in this manner ETags are a form of Device Identifier. ETag tracking may generate unique tracking values even where the consumer blocks HTTP, and/or HTML5 cookies.</p>
                        <strong> Recognition Technologies.</strong>
                        <p>  Technologies, including application of statistical probability to data sets, which attempt to recognize or make assumptions about users and devices (e.g., that a user of multiple devices in the same user).</p>
                        <strong>Tracking Technologies Usage.</strong>

                        <p>We may use Tracking Technologies for a variety of purposes, including:</p>
                        <strong>Strictly Necessary.</strong> <span>  We may use cookies or other Tracking Technologies that we consider are strictly necessary to allow you to use and access our Web Services, including cookies required to prevent fraudulent activity and improve security.</span>
                       <br/> <strong>Performance‑Related.</strong> <span> We may use cookies or other Tracking Technologies that are useful in order to assess the performance of our Web Services, including as part of our analytic practices or otherwise to improve the content, products or services offered through our Web Services.</span>
                       <br/> <strong>Functionality‑Related.</strong><span>  We may use cookies or other Tracking Technologies that are required to offer you enhanced functionality when accessing our Web Services, including identifying you when you sign in to our Web Services or keeping track of our specified preferences, including in terms of the presentation of content on our Web Services.</span>
                       <br/> <strong>Targeting‑Related.</strong> <span>  We may use Tracking Technologies to deliver content relevant to your interests on our Web Services and third-party sites based on how you interact with our content. This includes using Tracking Technologies to understand the usefulness to you of the content that has been delivered to you. </span>
                        <br/>  <strong>2.  Use of Collected Information.</strong>
                        <p> We may use the information that we collect from you to process transactions, and provide products, services, and information to you. We may use your information to process your applications, payments, provide products and services, to inform you about offers, and to provide you with information that may be of interest to you. We may anonymize or aggregate your information for marketing and purposes. We may also use your information to process your application and to enable you to use certain Web Services features. We may also use the information that we collect to customize your experience on our Web Services, to better tailor our product offerings, to improve our Web Services and the content provided on and through our Web Services, to provide customer support, to enforce our Terms of Use, to meet our legal obligations or to inform a legal proceeding, and to provide you with content or advertisements that may be of interest to you. We may also use the information we collect from you for additional purposes with your consent. </p>
                        <strong>3.  Information Sharing.</strong>
                        <p>
                            We may share the information that we collect from you, including Personal Information, with third parties for a variety of purposes. For example, we may share information with service providers or partners who assist us in our business operations or who offer you products or services or process your pre-qualification or loan application. We may also share information where required by law or to satisfy any applicable law, regulation, subpoena, government request, or other legal process. We may also share information with third parties, including law enforcement, to protect this Web Services and to enforce our Terms of Use. We also reserve the right to share the information that we collect with our subsidiaries and affiliates for marketing and other business purposes and with any subsequent owner in the event of a merger, consolidation, sale of our assets, or other change in our business, including during the course of any due diligence process.</p>
                        <p>(a)  Co-Branded Areas.</p>

                        <p> We may provide certain parts of our Web Services in association with third parties, such as promotional partners or affiliates. These “co-branded areas” will identify the third party. If you choose to use these co-branded areas, we may share your information with the identified third party, and that third party may also collect information from you, in addition to the information that we collect, as described above. You should review the privacy policies of those identified third parties to understand how they collect and use information. </p>
                        <p>(b)  Links to Third-Party Web sites.</p>

                        <p> Our Web Services may include links to third-party websites or other online services. We are not responsible for these other sites and services, and they may collect and use information about you. You should review the privacy policies for such third parties before using their sites or services to understand how they collect and use information. </p>
                        <p> (c)  Third-Party Tracking and Do Not Track.</p>

                        <p>Third parties may use tracking technologies in connection with our Web Services, which may include the collection of information about your online activities over time and across third-party websites. This Privacy Policy does not apply to these third-party technologies because we may not control them and we are not responsible for them. Do Not Track is a technology that enables users to opt out of tracking by websites they do not visit. Currently, we do not monitor or take any action with respect to Do Not Track technology. </p>
                        <p> (d) Analytic Services and Targeted Ads</p>

                        <p>We may use third-party analytics services, such as Google Analytics. These services may track details about your online activities over time and across different sites. These services help us to improve our Site and the products and services that we offer you. These services may also allow us and others to provide you with targeted advertisements or other content that you may be interested in based on your online activities. If you would like to learn more about targeted ads that may be based on your online activities, and the choices that you may exercise for certain sites and advertisers, you may wish to visit the Network Advertising Initiative or the Digital Advertising Alliance. </p>
                        <strong> 4.  Information Protection.</strong>

                        <p>We use commercially reasonable and industry standard security technologies and safeguards to protect the information that we collect and use. For example, we use Secure Sockets Layer (SSL) for our registration and submission functions. We also expect our service providers to protect information in the same manner. However, no data transmission over the Internet, mobile networks, wireless transmission or electronic storage of information can be guaranteed 100% secure. Please note that we cannot guarantee the security of any information you transmit to us. </p>
                        <strong> 5.  Changing Information and Communication Preferences.
                        </strong>
                        <p>You are responsible for maintaining the accuracy of the information you submit to us, such as your contact information provided as part of requests you make for an application, product, service, or otherwise.
                            You can always contact us in order to request that we (i) remove the Personal Information that you have provided to us from our systems, (ii) update the Personal Information that you have provided to us, and (iii) change your preferences with respect to our use of your Personal Information by e-mailing us at care@flicfinancial.com If so, we will make good faith efforts to make requested changes in our then active databases as ‎soon as reasonably practicable (but we may retain prior information as business records). Please ‎note that it is not always possible to completely remove or delete all of your information from our ‎databases and that residual data may remain on backup media or for other reasons‎
                        </p>
                        <strong>6.  Users Outside of the United States.</strong>

                        <p> If you are from a non-U.S. country, please be aware that the personal information you submit, including information provided through our Web Services, is being sent to a location in the United States. The data protection laws in the United States are likely different from those of the country in which you are located, and your personal information may be subject to access requests from governments, courts, or law enforcement in the United States according to laws of the United States. By providing such personal information through the Web Services, you are consenting to (and represent that you have authority to consent to), the transfer of such information to the United States for the uses and purposes described in this Privacy Policy. Without limiting the above, for the avoidance of doubt we do not have an establishment or target ‎people in the EEA or UK. </p>
                        <strong> 7.  Social Security Protection Policy Statement‎.</strong>

                        <p>It is the policy of Flic to protect the confidentiality of Social Security numbers (SSNs) from misuse and improper disclosure by maintaining and enforcing physical, ‎‎electronic, and procedural safeguards. We prohibit unlawful disclosure of SSNs, and limit access to SSNs to our personnel who need access to SSNs in order to perform their job functions. We do not disclose SSNs to third parties except where required to provide the Web Services or ‎‎permitted by law.  </p>
                        <strong>  8.  Children’s Privacy.</strong>

                        <p> Our Web Services and applications are intended for general audiences, and we do not knowingly seek or collect personal information from children under the age of eighteen (18). In accordance with the Child Online Privacy Protection Act, in the event that we learn that we have collected personal information from a child under age thirteen (13) without verification of parental consent, we will delete that information as quickly as possible. If you believe that we might have any personal information from or about a minor, please contact us at care@flicfinancial.com. </p>
                        <strong>    9.  Contact Us.</strong>

                        <p> If you have any questions about our privacy policy or comments on our Web Services or become aware of misuse of our Web Services by any person, please contact us at:

                        </p>
                        <p> Flic Financial</p>
                        <p> Mailing Address: 520 Post Oak Blvd, Suite 850 Houston, TX 77027</p>

                        <p>  Email: care@flicfinancial.com</p>
                    </Col>
                </Row>
            </div>
        </Col>
    )
}

export default PrivacyPolicy