import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import {
  setAccessToken,
  setLoading,
  setLoggedInUser,
} from "@store/slices/app.slice";
import { ExtractErrorMessage, createUrl } from "@shared/helpers/global.helper";
import { environment } from "@env/environment";
import { toasterService } from "./toaster.service";
import { decrypt, encrypt } from "@utils/cryptographyForApiData";
import {
  MESSAGES,
  isProducitonEnv,
  isStagingEnv,
} from "@shared/constants/app.constant";
import { allowloadingStateUpdate } from "@shared/helpers/api.helper";
import { setPipelineData } from "@store/slices/loan.slice";
import { resetAwardcoBalanceAction } from "@store/slices/awardco.slice";

const headers: Readonly<Record<string, string | boolean>> = {
  Accept: "application/json",
  "Content-Type": "application/json; charset=utf-8",
  "Access-Control-Allow-Credentials": true,
  "X-Requested-With": "XMLHttpRequest",
};

const enableCryptoGraphy = environment.REACT_APP_CRYPTOGRAPHY
  ? environment.REACT_APP_CRYPTOGRAPHY === "true"
  : isProducitonEnv || isStagingEnv || false;

// We can use the following function to inject the JWT token through an interceptor
// We get the `accessToken` from the localStorage that we set when we authenticate
const requestInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
  try {
    // Inject JWT token in request headers
    const { store } = require("@store/store");
    const { app } = store.getState();
    const params = new URLSearchParams(window.location.search);

    allowloadingStateUpdate(config?.url) && store.dispatch(setLoading(true));

    if (app && app.accessToken) {
      config.headers["Authorization"] = `Bearer ${app.accessToken}`;
    }
    if (params.get("token")) {
      const token = params.get("token");
      const url = config.url?.includes("?")
        ? `&token=${token}`
        : `?token=${token}`;
      config.url = config.url + url;
    }

    // Skipping the encryption for multi-part form data requests
    if (config.data instanceof FormData) {
      return config;
    }

    // Encrypt request body
    if (config.data && enableCryptoGraphy) {
      // console.log("original payload", config.data);
      const encrypted = encrypt(JSON.stringify(config.data));
      config.data = {
        payload: encrypted,
      };
    }
    return config;
  } catch (error: any) {
    console.log("error in request interceptor", error);
    throw new Error(error);
  }
};

class NetworkService {
  private instance: AxiosInstance | null = null;

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  initHttp() {
    const http = axios.create({
      baseURL: environment.API_BASE_URL,
      headers,
      withCredentials: true,
    });

    http.interceptors.request.use(requestInterceptor, (error) =>
      Promise.reject(error)
    );
    http.interceptors.response.use(
      (response) => {
        const { store } = require("@store/store");

        allowloadingStateUpdate(response.config?.url) &&
          store.dispatch(setLoading(false));

        if (response.data?.payload && enableCryptoGraphy) {
          try {
            const decryptedString = decrypt(response.data.payload);
            response.data = JSON.parse(decryptedString);
          } catch (error) {
            console.error(error);
            toasterService.error(MESSAGES.API_DATA_CRYPTOGRAPHY_FAILED);
            return Promise.reject(MESSAGES.API_DATA_CRYPTOGRAPHY_FAILED);
          }
        }
        // console.log("decrypted response", response.config.url ,response.data);
        return response;
      },
      (error) => {
        const { response } = error;
        console.error(error);
        return this.handleError(response);
      }
    );

    this.instance = http;
    return http;
  }

  // Handle global app errors
  // We can handle generic app errors depending on the status code
  private handleError(error: any) {
    const { store } = require("@store/store");

    allowloadingStateUpdate(error.config?.url) &&
      store.dispatch(setLoading(false));

    if (
      error?.data?.status === 401 &&
      window.location.pathname !== "/auth/login" &&
      window.location.pathname !== "/auth/login/"
    ) {
      toasterService.error("Your session has expired");
      store.dispatch(setLoggedInUser(null));
      store.dispatch(setAccessToken(null));
      store.dispatch(setPipelineData(null));
      store.dispatch(resetAwardcoBalanceAction());
      setTimeout(() => {
        window.location.href = `/auth/login/?returnUrl=${window.location.pathname}`;
      }, 1000);
      return Promise.reject(error?.data);
    }
    if (error?.data?.message === "You do not have permission to view this") {
      window.location.replace(createUrl("/not-allowed"));
    }

    if (
      error?.data?.status === 403 &&
      error?.data?.message === "FORBIDDEN" &&
      window.location.pathname !== "/auth/login" &&
      window.location.pathname !== "/auth/login/"
    ) {
      toasterService.error("You are not allowed to access this");
      store.dispatch(setLoggedInUser(null));
      store.dispatch(setAccessToken(null));
      store.dispatch(setPipelineData(null));
      store.dispatch(resetAwardcoBalanceAction());
      // NOTE: This could be better creating a single logout action in a slice, after we stop `state.loading` on every actions
      // Currently it is creating a problem, keeps loading state to true even after logout

      setTimeout(() => {
        window.location.href = `/auth/login/?returnUrl=${window.location.pathname}`;
      }, 1000);
      return Promise.reject(error?.data);
    }
    const message = ExtractErrorMessage(error?.data);
    toasterService.error(message);
    return Promise.reject(error?.data);
  }

  request<T = any, R = AxiosResponse<T>>(
    config: AxiosRequestConfig
  ): Promise<R> {
    return this.http.request(config);
  }

  get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.get<T, R>(url, config);
  }

  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ) {
    return this.http.post<T, R>(url, data, config);
  }

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.put<T, R>(url, data, config);
  }

  delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.delete<T, R>(url, config);
  }
}

export const networkService = new NetworkService();
