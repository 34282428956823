import React from "react";
import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";

type FieldProps = {
  control: any;
  type?: any;
  elementName?: any;
  controlName: string;
  placeholder?: string;
  content: any;
  checked?: boolean;
  helperText?: string;
  label?: React.ReactNode;
  disabled?: boolean;
};

export const UseFormRadio = ({
  control,
  elementName,
  controlName,
  checked = false,
  content,
  helperText,
  label,
  disabled,
}: FieldProps) => {
  return (
    <Controller
      name={controlName}
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { error, isDirty, isTouched },
      }) => (
        <>
          <div className="d-flex flex-column">
            <Form.Check
              inline
              defaultChecked={checked}
              type={"radio"}
              value={content}
              id={elementName}
              name={`${controlName}`}
              label={label || content}
              onChange={onChange}
              disabled={disabled}
            />
            {helperText && (
              <p className="ms-4 mb-0 radio-button-helper-text">{helperText}</p>
            )}
          </div>
        </>
      )}
    />
  );
};
