import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@store/store";
import { AwardcoState } from "@store/types";
import { awardcoService } from "@services/awardco.service";

const initialState: AwardcoState = {
  balance: 0,
  success: false,
};

export const getAwardcoBalanceAction = createAsyncThunk(
  "awardco/getAwardcoBalance",
  async (args: undefined, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await awardcoService.getAwardcoBalance();
      dispatch(
        setBalance({
          balance: data?.data?.balance || 0,
          success: data.data.success,
        })
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const resetAwardcoBalanceAction = createAsyncThunk(
  "awardco/resetAwardcoBalance",
  async (args: undefined, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setBalance(initialState));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const awardcoSlice = createSlice({
  name: "awardco",
  initialState,
  reducers: {
    setBalance: (
      state: AwardcoState,
      { payload }: PayloadAction<AwardcoState>
    ) => ({
      success: payload.success,
      balance: payload.balance,
    }),
  },
  extraReducers: (builder) => {},
});

export const { reducer: awardcoReducer } = awardcoSlice;
export const { setBalance } = awardcoSlice.actions;

export const awardcoStateSelector = (rootState: RootState) => rootState.awardco;
