import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Controller } from "react-hook-form";
import InputMask from "react-input-mask";

type FieldProps = {
  control: any;
  type?: string;
  name: any;
  placeholder?: string;
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  inputProps?: any;
  children?: React.ReactNode;
  mask: string;
  autoFocus?: boolean;
};

export const MyInputMask = ({
  control,
  name,
  type,
  defaultValue,
  placeholder,
  className,
  disabled,
  mask,
  autoFocus,
}: FieldProps) => {
  return (
    <Controller
      name={name}
      key={name}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <InputMask mask={mask} value={value} onChange={onChange}>
          {(inputProps: any) => (
            <InputGroup style={{ flexDirection: "column" }}>
              <Form.Control
                {...inputProps}
                type={type}
                placeholder={placeholder}
                isInvalid={!!error}
                disabled={disabled}
                className={className}
                style={{ width: "100%" }}
                autoFocus={autoFocus}
              />
              {error && (
                <Form.Text className="text-danger" muted>
                  {error?.message}
                </Form.Text>
              )}
            </InputGroup>
          )}
        </InputMask>
      )}
    />
  );
};
