import { ConditionalRender } from "@shared/components/ConditionalRender";
import {
  getConsumerStatement,
  getConsumerStatementManualClearStatusColor,
  getConsumerStatementStatusColor,
  getCoreLogicPropertyTypeCode,
  getIsManufacturedHome,
  getIsManufacturedHomeStatusColor,
  getIsOwnedByLlc,
  getIsOwnedByLlcStatusColor,
  getOfacAlert,
  getOfacAlertStatusColor,
  getOfacManualClear,
  getOfacManualClearStatusColor,
  getStatementManuallyCleared,
  getUnderWritingExceptionStatusColor,
  getUnderwritingException,
} from "@shared/helpers/evaluations.helper";
import { IPortalApplication } from "@shared/interfaces/PortalApplicationTypes";
import clsx from "clsx";
import { Col, Row } from "react-bootstrap";

type ApplicationDetailsType1 = Array<{
  label: JSX.Element;
  value1: JSX.Element;
  value2: JSX.Element;
  isHeader?: boolean;
}>;

type ApplicationDetailsType2 = Array<{
  label: JSX.Element;
  value1: JSX.Element;
  isHeader?: boolean;
}>;

const RequestTable = ({ data }: { data: ApplicationDetailsType1 }) => (
  <>
    {data.map((item, index) => (
      <Row className="" key={index}>
        <Col xs={6}>{item.isHeader ? item.label : <p>{item.label}</p>}</Col>

        <Col xs={3}>{item.value1}</Col>

        <Col xs={3}>{item.value2}</Col>
      </Row>
    ))}
  </>
);

const RequestTable2 = ({ data }: { data: ApplicationDetailsType2 }) => (
  <>
    {data.map((item, index) => (
      <Row className="" key={index}>
        <Col xs={6}>{item.isHeader ? item.label : <p>{item.label}</p>}</Col>

        <Col xs={6}>{item.value1}</Col>
      </Row>
    ))}
  </>
);

export const VerificationsAndExceptions = ({
  data,
}: {
  data: IPortalApplication | undefined;
}) => {
  const primaryApplicant = data?.primaryApplicant;
  const coapplicant = data?.coApplicant;
  const verifications = data?.verifications;
  const coreLogic = data?.verifications?.coreLogic;

  const primaryApplicantConsumerStatementNotes = `${
    primaryApplicant?.consumerStatementNotes ?? ""
  }`;
  const coApplicantConsumerStatementNotes = `${
    coapplicant?.consumerStatementNotes ?? ""
  }`;

  const consumerStatements: ApplicationDetailsType1 = [
    {
      label: <h4>Consumer Statements</h4>,
      value1: <h4>App</h4>,
      value2: <h4>CoApp</h4>,
      isHeader: true,
    },
    {
      label: <>Consumer Statement:</>,
      value1: (
        <p
          className={clsx(
            getConsumerStatementStatusColor(
              primaryApplicant?.consumerStatementExist
            )
          )}
        >
          {getConsumerStatement(primaryApplicant?.consumerStatementExist)}
        </p>
      ),
      value2: (
        <p
          className={clsx(
            getConsumerStatementStatusColor(coapplicant?.consumerStatementExist)
          )}
        >
          {getConsumerStatement(coapplicant?.consumerStatementExist)}
        </p>
      ),
    },
    {
      label: <>Statement Manually Cleared:</>,
      value1: (
        <p
          className={clsx(
            getConsumerStatementManualClearStatusColor(
              getStatementManuallyCleared(primaryApplicant)
            )
          )}
        >
          {getStatementManuallyCleared(primaryApplicant)}
        </p>
      ),
      value2: (
        <p
          className={clsx(
            getConsumerStatementManualClearStatusColor(
              getStatementManuallyCleared(coapplicant)
            )
          )}
        >
          {getStatementManuallyCleared(coapplicant)}
        </p>
      ),
    },
  ];

  const ofacProcessing: ApplicationDetailsType1 = [
    {
      label: <h4>OFAC Processing</h4>,
      value1: <h4>App</h4>,
      value2: <h4>CoApp</h4>,
      isHeader: true,
    },
    {
      label: <>OFAC Alert: </>,
      value1: (
        <p
          className={clsx(
            getOfacAlertStatusColor(primaryApplicant?.xOFACMatch)
          )}
        >
          {getOfacAlert(primaryApplicant?.xOFACMatch)}
        </p>
      ),
      value2: (
        <p className={clsx(getOfacAlertStatusColor(coapplicant?.xOFACMatch))}>
          {getOfacAlert(coapplicant?.xOFACMatch)}
        </p>
      ),
    },
    {
      label: <>OFAC Manually Cleared:</>,
      value1: (
        <p
          className={clsx(
            getOfacManualClearStatusColor(getOfacManualClear(primaryApplicant))
          )}
        >
          {getOfacManualClear(primaryApplicant)}
        </p>
      ),
      value2: (
        <p
          className={clsx(
            getOfacManualClearStatusColor(getOfacManualClear(coapplicant))
          )}
        >
          {getOfacManualClear(coapplicant)}
        </p>
      ),
    },
  ];

  const proofOfOwnership: ApplicationDetailsType1 = [
    {
      label: <h4>Proof of Ownership</h4>,
      value1: <h4>First</h4>,
      value2: <h4>Last</h4>,
      isHeader: true,
    },
    {
      label: <>Corelogic Owners:</>,
      value1: (
        <p>
          <ConditionalRender
            value={coreLogic?.firstHomeOwner?.firstNameAndMiddleInitial}
          />
        </p>
      ),
      value2: (
        <p>
          <ConditionalRender value={coreLogic?.firstHomeOwner?.lastName} />
        </p>
      ),
    },
    ...(coreLogic?.secondHomeOwner?.firstNameAndMiddleInitial ||
    coreLogic?.secondHomeOwner?.lastName
      ? [
          {
            label: <></>,
            value1: coreLogic?.secondHomeOwner?.firstNameAndMiddleInitial ? (
              <p>
                <ConditionalRender
                  value={coreLogic?.secondHomeOwner?.firstNameAndMiddleInitial}
                />
              </p>
            ) : (
              <></>
            ),
            value2: coreLogic?.secondHomeOwner?.lastName ? (
              <p>
                <ConditionalRender
                  value={coreLogic?.secondHomeOwner?.lastName}
                />
              </p>
            ) : (
              <></>
            ),
          },
        ]
      : []),
  ];

  const propertyTypeVerification: ApplicationDetailsType2 = [
    {
      label: <h4>Property Type Verification</h4>,
      value1: <h4>Installation Property</h4>,
      isHeader: true,
    },
    {
      label: <>Core Logic Proprty Type:</>,
      value1: (
        <p>{getCoreLogicPropertyTypeCode(coreLogic?.propertyTypeCode)}</p>
      ),
    },
    {
      label: <>Is a Manufactured Home:</>,
      value1: (
        <p
          className={clsx(
            getIsManufacturedHomeStatusColor(
              coreLogic?.propertyIsManufacturedHome
            )
          )}
        >
          {getIsManufacturedHome(coreLogic?.propertyIsManufacturedHome)}
        </p>
      ),
    },
  ];

  const UnderwritingException = () => (
    <div>
      <h4>Underwriting Exception</h4>
      <p>
        Exception on file:{" "}
        <span
          className={clsx(
            getUnderWritingExceptionStatusColor(verifications?.hasUwException)
          )}
        >
          {getUnderwritingException(verifications?.hasUwException)}
        </span>
      </p>
      {verifications?.exception && <p>Reason: {verifications?.exception}</p>}
    </div>
  );

  return (
    <div className="">
      <Row className="">
        <Col xs={12} md={12} lg={6} xl={6} className="mt-4">
          <RequestTable data={consumerStatements} />
          <p>{primaryApplicantConsumerStatementNotes}</p>
          <p>{coApplicantConsumerStatementNotes}</p>
        </Col>
        <Col xs={12} md={12} lg={6} xl={6} className="mt-4">
          <RequestTable data={ofacProcessing} />
        </Col>
        <Col xs={12} md={12} lg={6} xl={6} className="mt-4">
          <RequestTable data={proofOfOwnership} />
          <p>
            Core Logic Owneership Rights Code:{" "}
            <ConditionalRender value={coreLogic?.ownershipRightsCode} />
          </p>
          <p>
            Core Logic Is Owned By LLC?:{" "}
            <span
              className={clsx(
                getIsOwnedByLlcStatusColor(coreLogic?.isCorporate)
              )}
            >
              {getIsOwnedByLlc(coreLogic?.isCorporate)}
            </span>
          </p>
        </Col>
        <Col xs={12} md={12} lg={6} xl={6} className="mt-4">
          <RequestTable2 data={propertyTypeVerification} />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12}>
          <UnderwritingException />
        </Col>
      </Row>
    </div>
  );
};
