import PipelineCard from "@shared/components/PipelineCard";
import AllAppIcon from "@assets/images/all-apps.svg";
import DocsIcon from "@assets/images/docs-signed.svg";
import EmailIcon from "@assets/images/email.svg";
import QcReviewIcon from "@assets/images/qc-review-icon.svg";
import RffReviewIcon from "@assets/images/rff-review-icon.svg";
import { useEffect, useLayoutEffect, useState } from "react";
import range from "lodash/range";

import { useAppDispatch, useAppSelector } from "@store/hooks";
import {
  getPipelinesAction,
  pipelineDataSelector,
  setPipelineData,
} from "@store/slices/loan.slice";
import Pagination from "react-js-pagination";
import { environment } from "@env/environment";
import { PipelineCardSkeleton } from "@shared/skeletons/PipelineCardSkeleton";
import { PipelineFilter } from "@shared/enums/filters";
import { DebounceInput } from "react-debounce-input";
import { isDeclined, isPrequalDeclined } from "@shared/helpers/global.helper";
import DraftApplication from "./DraftApplication";
import { Col } from "react-bootstrap";
import { VISIBLE_RECENT_ITEM } from "@shared/constants/app.constant";
import { appUserSelector } from "@store/slices/app.slice";
import { UserRole } from "@shared/interfaces/User";
import { PortalApplicationStatus } from "@shared/interfaces/PortalApplicationTypes";

const Pipelines = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(appUserSelector);
  const pipelineData = useAppSelector(pipelineDataSelector);
  const isLoading = useAppSelector((state) => state.app.loading);
  const [selectedTab, setSelectedTab] = useState(
    PipelineFilter.AllApplications
  );
  const [searchAndPagination, setSearchAndPagination] = useState<{
    search: string;
    pageLimit: number;
    page: number;
  }>({
    search: "",
    page: 1,
    pageLimit: Number(environment.REACT_APP_PAGE_LIMIT),
  });
  const [itemsVisible, setItemsVisible] = useState(VISIBLE_RECENT_ITEM);
  const [query, setQuery] = useState<string>();
  const currentPageLimit =
    searchAndPagination.pageLimit ||
    parseInt(environment.REACT_APP_PAGE_LIMIT || "0", 10);
  const isTabsVisible =
    user?.role && user?.role !== UserRole.financialInstitution; // FI role can only access All applications tab
  const isQcReviewAndRffReviewTabEnable =
    user?.role &&
    (user?.role === UserRole.financialInstitution ||
      user?.role === UserRole.providerAdmin); // FI role can only access All applications tab

  const onPageChange = (e: any) => {
    setSearchAndPagination((value) => ({
      ...value,
      page: e,
    }));
  };

  const onSearch = (search: string) => {
    setSearchAndPagination((value) => ({
      ...value,
      page: 1,
      search,
    }));
  };

  const onPageSizeChange = (e: any) => {
    setSearchAndPagination((value) => ({
      ...value,
      page: 1,
      pageLimit: e.target.value,
    }));
  };

  const onFilter = (type: PipelineFilter) => {
    if (type === selectedTab) return;
    let searchCriteria = "";
    setSelectedTab(type);
    setSearchAndPagination((value) => ({
      ...value,
      search: "",
      page: 1,
    }));
    switch (type) {
      case PipelineFilter.SignedDoc:
        searchCriteria = "docs-signed";
        break;
      case PipelineFilter.QcReview:
        searchCriteria = "qc-review";
        break;
      case PipelineFilter.RffReview:
        searchCriteria = "rff-review";
        break;
      case PipelineFilter.AllApplications:
        searchCriteria = "";
        break;
      case PipelineFilter.Draft:
        searchCriteria = "";
        break;
    }
    setQuery(searchCriteria);
  };
  const onViewMoreClick = () => {
    setItemsVisible(itemsVisible + VISIBLE_RECENT_ITEM);
  };

  useLayoutEffect(() => {
    dispatch(setPipelineData(null));
  }, [dispatch, searchAndPagination.pageLimit]);

  useEffect(() => {
    if (!searchAndPagination.search) {
      dispatch(
        getPipelinesAction({
          page: searchAndPagination.page,
          limit: searchAndPagination.pageLimit,
          tab: query,
        })
      );
      return;
    }

    if (selectedTab === PipelineFilter.Draft) {
      dispatch(
        getPipelinesAction({
          page: searchAndPagination.page,
          search: searchAndPagination.search,
          limit: searchAndPagination.pageLimit,
          tab: query,
        })
      );
    } else
      dispatch(
        getPipelinesAction({
          page: searchAndPagination.page,
          searchCriteria: searchAndPagination.search,
          limit: searchAndPagination.pageLimit,
          tab: query,
        })
      );
  }, [searchAndPagination, query, selectedTab, dispatch]);

  const pipelines = pipelineData?.results;
  const draftApplications = pipelineData?.newApplication;
  const newSubmittedApplication = pipelineData?.newSubmittedApplication;

  return (
    <div>
      <div className="d-flex justify-content-start flex-wrap pb-2">
        <div
          className="d-flex flex-row align-items-center justify-content-start hover tab-right"
          onClick={() => {
            onFilter(PipelineFilter.AllApplications);
          }}
        >
          <img src={AllAppIcon} className="icon-size" alt="" /> &nbsp;&nbsp;
          <h6
            className={`relative top-6 ${
              selectedTab === PipelineFilter.AllApplications &&
              "pipeline-filter-select"
            }`}
          >
            All Applications
          </h6>
        </div>
        {isTabsVisible && (
          <>
            <div
              className="d-flex flex-row align-items-center justify-content-start hover tab-right"
              onClick={() => {
                onFilter(PipelineFilter.SignedDoc);
              }}
            >
              <img src={DocsIcon} className="icon-size" alt="" /> &nbsp;&nbsp;
              <h6
                className={`relative top-6 ${
                  selectedTab === PipelineFilter.SignedDoc &&
                  "pipeline-filter-select"
                }`}
              >
                Docs Signed
              </h6>
            </div>

            <div
              className="d-flex flex-row align-items-center justify-content-start hover tab-right"
              onClick={() => {
                onFilter(PipelineFilter.Draft);
              }}
            >
              <img src={EmailIcon} className="icon-size" alt="" /> &nbsp;&nbsp;
              <h6
                className={`relative top-6 ${
                  selectedTab === PipelineFilter.Draft &&
                  "pipeline-filter-select"
                }`}
              >
                Emailed Applications{" "}
              </h6>
            </div>
          </>
        )}

        {isQcReviewAndRffReviewTabEnable && (
          <>
            <div
              className="d-flex flex-row align-items-center justify-content-start hover tab-right"
              onClick={() => {
                onFilter(PipelineFilter.QcReview);
              }}
            >
              <img src={QcReviewIcon} className="icon-size" alt="" />{" "}
              &nbsp;&nbsp;
              <h6
                className={`relative top-6 ${
                  selectedTab === PipelineFilter.QcReview &&
                  "pipeline-filter-select"
                }`}
              >
                QC Review
              </h6>
            </div>

            <div
              className="d-flex flex-row align-items-center justify-content-start hover tab-right"
              onClick={() => {
                onFilter(PipelineFilter.RffReview);
              }}
            >
              <img src={RffReviewIcon} className="icon-size" alt="" />{" "}
              &nbsp;&nbsp;
              <h6
                className={`relative top-6 ${
                  selectedTab === PipelineFilter.RffReview &&
                  "pipeline-filter-select"
                }`}
              >
                RFF Review
              </h6>
            </div>
          </>
        )}

        <div className=" d-flex flex-row align-items-center justify-content-start">
          <DebounceInput
            minLength={2}
            className={"form-control pipeline-search"}
            placeholder="Search"
            value={searchAndPagination.search}
            debounceTimeout={1200}
            onChange={(event) => onSearch(event.target.value)}
          />
        </div>
      </div>
      <hr />
      {isTabsVisible && selectedTab === PipelineFilter.Draft && (
        <>
          <DraftApplication
            draftApplications={draftApplications || []}
            onPageSizeChange={onPageSizeChange}
            page={searchAndPagination.page}
            pageLimit={searchAndPagination.pageLimit}
            onPageChange={onPageChange}
          />
        </>
      )}

      {(selectedTab === PipelineFilter.AllApplications ||
        selectedTab === PipelineFilter.SignedDoc ||
        selectedTab === PipelineFilter.QcReview ||
        selectedTab === PipelineFilter.RffReview) && (
        <>
          {/* As we are fetching all the applications from Portal DB, we do not need to maintain the Recent applications, as the submitted applications will be visible immediately after the successful submission
          We can complete the Recent applications after the succes deployment and UAT of fetching/utilizing applications from Portal DB. Currently, the API doesn't send the newSubmittedApplication in response. so, it won't show up*/}
          {/* Recent Application Block */}
          {!isLoading &&
            newSubmittedApplication &&
            newSubmittedApplication?.length > 0 && (
              <>
                <br />
                <div className="row">
                  <Col xs={12}>
                    <h4 className="font-bold text-primary">
                      Recent Applications
                    </h4>
                  </Col>
                </div>

                <hr className="text-primary height-2px" />
                <div className="row">
                  {!isLoading &&
                    newSubmittedApplication
                      .slice(0, itemsVisible)
                      .map((item: any, i: number) => (
                        <div
                          key={item?.internalAppId || item?.id || i}
                          className={`col-md-6 col-lg-4 col-sm-12 pipeline-box ${
                            // TODO: Need to verify this item?.results?.applicationStatus === PortalApplicationStatus.WaitingOnDocs
                            item?.results?.applicationStatus ===
                              PortalApplicationStatus.WaitingOnDocs &&
                            !isDeclined(item?.decisionStatusString)
                              ? "box-scroll"
                              : ""
                          } `}
                        >
                          <PipelineCard data={item?.results} />
                        </div>
                      ))}
                </div>
                <div className="row">
                  <Col xs={12}>
                    {newSubmittedApplication?.length > VISIBLE_RECENT_ITEM &&
                      itemsVisible < newSubmittedApplication?.length && (
                        <div className="d-flex justify-content-center">
                          <a
                            href="#/"
                            className="hover text-primary"
                            onClick={() => {
                              onViewMoreClick();
                            }}
                          >
                            View More
                          </a>
                        </div>
                      )}
                  </Col>
                </div>
              </>
            )}

          {/* Show Only If there are recent applications */}
          {!isLoading &&
            pipelines &&
            newSubmittedApplication &&
            pipelines?.length > 0 &&
            newSubmittedApplication?.length > 0 && (
              <>
                <Col xs={12}>
                  <div className="row">
                    <Col xs={12}>
                      <h4 className="font-bold text-primary">
                        All Applications
                      </h4>
                    </Col>
                  </div>
                  <hr className="text-primary height-2px" />
                </Col>
              </>
            )}
          {/* All Applications Block */}
          <div className="row">
            {!isLoading &&
              pipelines?.map((item, i) => (
                <div
                  key={item?.applicationNumber}
                  className={`col-md-6 col-lg-4 col-sm-12 pipeline-box ${
                    // TODO: Need to verify this item?.applicationStatus === PortalApplicationStatus.WaitingOnDocs
                    item?.applicationStatus ===
                      PortalApplicationStatus.WaitingOnDocs &&
                    !isDeclined(item?.decisionStatusString) &&
                    !isPrequalDeclined(item?.decisionStatusString)
                      ? "box-scroll"
                      : ""
                  } `}
                >
                  <PipelineCard data={item} />
                </div>
              ))}
          </div>

          {/* Pagination Block */}
          {!isLoading && pipelines && pipelines?.length > 0 && (
            <div className="row mt-5">
              <div className="col-12 d-block mx-auto pagination-container">
                <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center">
                  <div className="order-2 order-sm-1">
                    <span>Records per active page</span>
                    <select
                      className="page-size mx-3"
                      value={searchAndPagination.pageLimit}
                      onChange={(e) => {
                        onPageSizeChange(e);
                      }}
                    >
                      <option value="6">6</option>
                      <option value="12">12</option>
                      <option value="18">18</option>
                    </select>
                  </div>
                  <div className="order-1 order-sm-2 mb-3 mb-sm-0">
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={searchAndPagination.page}
                      itemsCountPerPage={Number(currentPageLimit)}
                      totalItemsCount={pipelineData?.total || 0}
                      pageRangeDisplayed={9}
                      onChange={onPageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Skeleton Block */}
          <div className="row">
            {isLoading &&
              range(currentPageLimit).map((item) => (
                <div
                  key={item}
                  className="col-md-6 col-lg-4 col-sm-12 skeleton-box"
                >
                  <PipelineCardSkeleton />
                </div>
              ))}
          </div>
          {/* No Application Block */}
          {!isLoading &&
            pipelines?.length === 0 &&
            newSubmittedApplication?.length === 0 && (
              <div className="row mt-5">
                <div className="col-12 text-center">
                  <h4 className="h3 text-primary">No application found</h4>
                </div>
              </div>
            )}
        </>
      )}
    </div>
  );
};

export default Pipelines;
