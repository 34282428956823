import { useAppSelector } from "@store/hooks";
import { appUserSelector } from "@store/slices/app.slice";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { enforceMfa } from "@shared/helpers/auth.helper";
import { LoginMethod } from "@store/types";
import { toasterService } from "@services/toaster.service";
import { UserRole } from "@shared/interfaces/User";

const SetupMfaGuard = () => {
  const history = useHistory();
  const user = useAppSelector(appUserSelector);
  const location = useLocation();

  useEffect(() => {
    if (
      user?.loginMethod === LoginMethod.DEFAULT &&
      location.pathname !== "/setup/mfa" &&
      user &&
      user?.role === UserRole.providerAdmin &&
      enforceMfa(user)
    ) {
      history.push("/setup/mfa");
      toasterService.warn(
        "MFA setup is required. Complete the setup process to access your account."
      );
    }

    return () => {};
  }, [history, user?.loginMethod, location.pathname, user]);

  return <></>;
};

export default SetupMfaGuard;
