import React, { useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { UseFormTextField } from "@shared/components/TextInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { UseFormSelect } from "@shared/components/UserFormSelect";
import { UseFormCheck } from "@shared/components/UserFormCheck";
import { UseFormDatePicker } from "@shared/components/UserFormDatePicker";
import { GoogleAutoComplete } from "@shared/components/GoogleAutoComplete";
import {
  postRequestAction,
  loanSliceSelector,
  setDuplicateAppsandCurrentAppSubmissionData,
} from "@store/slices/loan.slice";
import {
  removeSpaces,
  onSubmitError,
  getProductsList,
  unmaskMobile,
} from "@shared/helpers/global.helper";
import { MyInputMask } from "@shared/components/MyInputMask";
import { preQualSchema } from "@shared/schema/request";
import {
  MONTHS_ARRAY,
  PRIVATE_PROGRAM_TYPE,
  SSN_MASK,
  YEARS_ARRAY,
} from "@shared/constants/app.constant";
import { UserFormCurrencyField } from "@shared/components/UserFormCurrencyField";
import UserConsent from "@shared/components/UserConsent";
import {
  ApplicantType,
  ApplicationType,
} from "@shared/enums/ApplicationStatus";
import { UseFormRadio } from "@shared/components/UserFormRadio";
import { appUserSelector } from "@store/slices/app.slice";
import { UserAuthStatus } from "@shared/enums/UserState";
import PrivateLoanProductRedirect from "@shared/components/PrivateLoanProductRedirect";
import { useAuthUserContext } from "@contexts/AuthContext";
import { formatDateForDl4Submission } from "@utils/date";
import { getXPrequalProducts } from "@shared/helpers/application.helper";
import {
  ISubmitPortalFullApplication,
  ISubmitPortalPrequalApplication,
  ProductLos,
} from "@shared/interfaces/PortalApplicationTypes";
import IncomePrompt from "@shared/components/IncomePrompt";

const Request = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const loanSlice = useAppSelector(loanSliceSelector);
  const user = useAppSelector(appUserSelector);
  const { authUserStatus } = useAuthUserContext();

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(preQualSchema),
  });

  const onSubmit = (event: any) => {
    const isMultiPrequal = !!event.isMultiPrequal;
    const { xPrequalProductsSelected, xPrequalProductsAll } =
      getXPrequalProducts(user, event.loanType);

    const currentAddressOfPrimaryApplicant = {
      ownershipType: 4,
      addressType: 1,
      addressFormat: 5,
      postalCode: event.postalCode,
      addressLine: event.addressLine1,
      city: event.city,
      state: event.state,
      yearsAtResidence: Number(event.yearsAtResidence),
      monthsAtResidence: Number(event.monthsAtResidence),
    };

    const hasCoApplicant = event.hasCoApplicant === "Yes" ? true : false;

    const submitPortalAppPayload: ISubmitPortalPrequalApplication = {
      prequal: true,
      isMultiPrequal,
      prequalProducts: xPrequalProductsSelected,
      xSecondSubmission: false,
      appSource: "Flic Frontend",
      hasCoApplicant,
      loanAmount: 0,
      xprogramtype: Number(event?.loanType),
      productLos: ProductLos.DL4,
      primaryApplicant: {
        applicantType: 1,
        lastName: event.lastName,
        firstName: event.firstName,
        SSN: removeSpaces(event.SSN),
        dateOfBirth: formatDateForDl4Submission(event.dateOfBirth),
        authorization: event.authorization,
        eSignatureConsent: event.consent,
        disclosureConsent: event.consent,
        /** Current Address */
        currentAddress: currentAddressOfPrimaryApplicant,
        employment: {
          employmentType: 1, // DL4 specific
          grossIncome: Number(event.grossIncome),
          payPeriod: 5, // DL4 specific
          employmentStatus: 0, // Fixed for DL4
        },
      },
      secondaryApplicant: hasCoApplicant
        ? {
            applicantType: 2,
            lastName: event.coBorrowerLastName,
            firstName: event.coBorrowerFirstName,
            SSN: removeSpaces(event.coBorrowerssn),
            dateOfBirth: formatDateForDl4Submission(event.coBorrowerdob),
            authorization: event.coBorrowerAuthorization,
            eSignatureConsent: event.coBorrowerConsent,
            disclosureConsent: event.coBorrowerConsent,
            differentAddressThanPrimaryApplicant:
              event.xDiffAddressThanApplicant === "Yes" ? false : true,
            currentAddress:
              event.xDiffAddressThanApplicant === "Yes"
                ? currentAddressOfPrimaryApplicant
                : {
                    ownershipType: 4,
                    addressType: 1,
                    addressFormat: 5,
                    postalCode: event.coBorrowerPostalCode,
                    addressLine: event.coBorrowerAddressLine1,
                    city: event.coBorrowerCity,
                    state: event.coBorrowerState,
                    yearsAtResidence: Number(event.coBorrowerYearsAtResidence),
                    monthsAtResidence: Number(
                      event.coBorrowerMonthsAtResidence
                    ),
                  },
            employment: {
              employmentType: 1, // DL4 specific
              grossIncome: Number(event.coBorrowerGrossIncome),
              payPeriod: 5,
              employmentStatus: 0, // Fixed for DL4
            },
          }
        : undefined,
    };

    //create new request flow
    // For customers, we would not display Duplicate applications
    const force = authUserStatus === UserAuthStatus.Public ? true : false;
    dispatch(
      postRequestAction({
        model: submitPortalAppPayload,
        history,
        successUrl: "/pre-qualification/confirmation",
        force,
        isMultiPrequal: !!isMultiPrequal,
        xPrequalProducts: xPrequalProductsAll,
        programTypeCode: Number(event?.loanType),
      })
    );
  };

  useEffect(() => {
    const bindDraftData = () => {
      reset({
        firstName: loanSlice.requestDraft?.firstName,
        lastName: loanSlice.requestDraft?.lastName,
        loanType: loanSlice.requestDraft?.loanProduct,
        hasCoApplicant: "No",
        xDiffAddressThanApplicant: "Yes",
        SSN: "",
      });
    };
    reset({
      xDiffAddressThanApplicant: undefined,
    });
    if (loanSlice.requestDraft) {
      bindDraftData();
      return;
    }

    setTimeout(
      () =>
        reset({
          hasCoApplicant: "No",
          xDiffAddressThanApplicant: "Yes",
          SSN: "",
        }),
      1000
    );
  }, [reset, loanSlice.requestDraft]);

  const ssn = watch("SSN");
  const hasCoApplicant = watch("hasCoApplicant");
  const xDiffAddressThanApplicant = watch("xDiffAddressThanApplicant");
  const loanType = watch("loanType");
  const isSungageProduct = loanType === "1001";

  useEffect(() => {
    if (ssn && loanSlice.duplicateApps?.length > 0) {
      // Redirect to /duplicate-application
      history.push("/duplicate/applications");
    }
  }, [loanSlice.duplicateApps, ssn, history]);

  useEffect(() => {
    if (!ssn) {
      dispatch(
        setDuplicateAppsandCurrentAppSubmissionData({
          duplicateApps: [],
          currentApplicationSubmissionData: undefined,
        })
      );
    }
  }, [dispatch, ssn]);

  const isExternalLoanProduct = !!PRIVATE_PROGRAM_TYPE.find(
    (product) =>
      loanType &&
      product.id === loanType?.toString() &&
      product.type === "EXTERNAL"
  );
  console.log("form errors", errors);

  return (
    <Col xs={12} md={10} lg={7} className="mx-auto preQual-request">
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit, onSubmitError())}
      >
        <div className="mt-3">
          <h3 className="h3 text-primary">PreQual Application</h3>
          <hr className="text-primary height-2px" />
          <Row className="mt-4">
            <Col>
              <h4 className="font-bold">Loan Information</h4>
              <UseFormSelect
                list={getProductsList(
                  authUserStatus,
                  user?.role,
                  user?.userDetails?.accessManagement,
                  "new",
                  loanSlice.requestDraft?.loanProduct
                    ? Number(loanSlice.requestDraft?.loanProduct)
                    : undefined
                )}
                control={control}
                name="loanType"
                placeholder="Select Loan Product"
              />
            </Col>
          </Row>
          {authUserStatus === UserAuthStatus.Authorized && (
            // Customer users are not allowed to submit with Multi-prequal
            <Row className="mt-4">
              <Col>
                <UseFormCheck
                  control={control}
                  controlName="isMultiPrequal"
                  content="If I am not prequalified for the above product, try to prequalify me for other products."
                />
              </Col>
            </Row>
          )}
          {isExternalLoanProduct && <PrivateLoanProductRedirect />}
          {!isExternalLoanProduct && (
            <>
              <Row className="mt-4">
                <Col xs={12}>
                  <h4 className="font-bold">Your Information</h4>
                </Col>
                <Col xs={12} md={6}>
                  <UseFormTextField
                    placeholder="First Name"
                    control={control}
                    name="firstName"
                  />
                </Col>
                <Col xs={12} md={6} className="mt-3 mt-md-0">
                  <UseFormTextField
                    placeholder="Last Name"
                    control={control}
                    name="lastName"
                  />
                </Col>
                <Col xs={12} md={6} className="mt-3">
                  <MyInputMask
                    defaultValue=""
                    control={control}
                    mask={SSN_MASK}
                    name="SSN"
                    placeholder="SSN"
                  />
                </Col>
                <Col xs={12} md={6} className="mt-3">
                  <UseFormDatePicker
                    placeholder="Date of Birth"
                    maxDate={new Date()}
                    control={control}
                    controlName="dateOfBirth"
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xs={12}>
                  <h4 className="font-bold">Your Current Address</h4>
                </Col>
                <Col xs={12}>
                  <GoogleAutoComplete
                    placeholder="Street"
                    control={control}
                    name="addressLine1"
                    onSelect={(address) => {
                      setValue("addressLine1", address.street);
                      setValue("city", address.city);
                      setValue("state", address.state);
                      setValue("postalCode", address.zipCode);
                    }}
                  />
                </Col>
                <Col xs={12} md={7} className="mt-3">
                  <UseFormTextField
                    placeholder="City"
                    control={control}
                    name="city"
                  />
                </Col>
                <Col xs={6} md={2} className="mt-3">
                  <UseFormTextField
                    placeholder="State"
                    control={control}
                    name="state"
                  />
                </Col>
                <Col xs={6} md={3} className="mt-3">
                  <UseFormTextField
                    placeholder="Zip Code"
                    control={control}
                    name="postalCode"
                  />
                </Col>
              </Row>

              <Row className="mt-3 d-flex align-items-center">
                <Col xs={4} sm={4} lg={3}>
                  <label className="form-check-label">
                    Time with Current Residence:
                  </label>
                </Col>
                <Col xs={6} sm={4} lg={3}>
                  <UseFormSelect
                    list={YEARS_ARRAY}
                    control={control}
                    name="yearsAtResidence"
                    placeholder="Years"
                    placeholderValue={""}
                  />
                </Col>
                <Col xs={6} sm={4} lg={3} className="mt-0 mt-sm-0">
                  <UseFormSelect
                    list={MONTHS_ARRAY}
                    control={control}
                    name="monthsAtResidence"
                    placeholder="Months"
                    placeholderValue={""}
                  />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col xs={12}>
                  <h4 className="font-bold">Gross Annual Income</h4>
                </Col>
                <Col xs={12}>
                  <UserFormCurrencyField
                    placeholder="Gross Annual Income"
                    control={control}
                    name="grossIncome"
                  />
                </Col>
                <Col xs={12} className="mt-2">
                  <IncomePrompt
                    isSungageProduct={isSungageProduct}
                    isPrequal={true}
                  />
                </Col>
              </Row>

              <UserConsent
                applicant={ApplicantType.Borrower}
                type={ApplicationType.PreQual}
                isSungageProduct={isSungageProduct}
                authorization={
                  <UseFormCheck
                    control={control}
                    controlName="authorization"
                    content="Yes, I have read and agree to the following credit authorization:"
                  />
                }
                consent={
                  <UseFormCheck
                    control={control}
                    controlName="consent"
                    content="Yes, I have read and agree to the following consents and disclosures:"
                  />
                }
              />

              {typeof hasCoApplicant == "string" && (
                <Row className="py-4 mt-4 border-y g-0">
                  <Col>
                    <div className="d-flex align-items-center">
                      <h4 className="font-bold me-4 mb-0">
                        Add a Co-Borrower:
                      </h4>
                      <div className="ml-3 d-flex">
                        <UseFormRadio
                          control={control}
                          elementName="co-borrower-group"
                          controlName="hasCoApplicant"
                          content="Yes"
                          checked={hasCoApplicant === "Yes" ? true : false}
                        />
                        <UseFormRadio
                          control={control}
                          elementName="co-borrower-group"
                          controlName="hasCoApplicant"
                          content="No"
                          checked={hasCoApplicant === "No" ? true : false}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              )}

              {hasCoApplicant === "Yes" ? (
                <>
                  <Row className="mt-4">
                    <Col xs={12}>
                      <h4 className="font-bold">Co-Borrower Information</h4>
                    </Col>
                    <Col xs={12} md={6} className="mt-3">
                      <UseFormTextField
                        placeholder="First Name"
                        control={control}
                        name="coBorrowerFirstName"
                      />
                    </Col>
                    <Col xs={12} md={6} className="mt-3">
                      <UseFormTextField
                        placeholder="Last Name"
                        control={control}
                        name="coBorrowerLastName"
                      />
                    </Col>

                    <Col xs={12} md={6} className="mt-3">
                      <MyInputMask
                        control={control}
                        mask={SSN_MASK}
                        name="coBorrowerssn"
                        placeholder="SSN"
                      />
                    </Col>
                    <Col xs={12} md={6} className="mt-3">
                      <UseFormDatePicker
                        placeholder="Date of Birth"
                        maxDate={new Date()}
                        control={control}
                        controlName="coBorrowerdob"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="mt-3">
                      <p className="subdetails">
                        Please enter annual income, which may include any income
                        that is verifiable and reported to the Government, such
                        as employment income, social security, pensions, etc.{" "}
                      </p>
                    </Col>
                    {typeof xDiffAddressThanApplicant !== "undefined" && (
                      <Col className="mt-4">
                        <h4 className="font-bold mr-4 mb-0">Co-Residency </h4>
                        <h4 className="fw-normal mt-2">
                          Does coborrower reside at the same residence as the
                          primary borrower?{" "}
                        </h4>
                        <div className="d-flex flex-column">
                          <UseFormRadio
                            control={control}
                            elementName="co-residency"
                            controlName="xDiffAddressThanApplicant"
                            content="Yes"
                            checked={
                              xDiffAddressThanApplicant === "Yes" ? true : false
                            }
                          />
                          <UseFormRadio
                            control={control}
                            elementName="co-residency"
                            controlName="xDiffAddressThanApplicant"
                            content="No"
                            checked={
                              xDiffAddressThanApplicant === "No" ? true : false
                            }
                          />
                        </div>
                      </Col>
                    )}
                  </Row>
                  {xDiffAddressThanApplicant === "No" && (
                    <>
                      <Row>
                        <Col xs={12} className="mt-3">
                          <h4 className="font-bold">Coborrower Address</h4>
                        </Col>
                        <Col xs={12}>
                          <GoogleAutoComplete
                            placeholder="Street"
                            control={control}
                            name="coBorrowerAddressLine1"
                            onSelect={(address) => {
                              setValue(
                                "coBorrowerAddressLine1",
                                address.street
                              );
                              setValue("coBorrowerCity", address.city);
                              setValue("coBorrowerState", address.state);
                              setValue("coBorrowerPostalCode", address.zipCode);
                            }}
                          />
                        </Col>
                        <Col xs={12} md={6} className="mt-3">
                          <UseFormTextField
                            placeholder="City"
                            control={control}
                            name="coBorrowerCity"
                          />
                        </Col>
                        <Col xs={6} md={3} className="mt-3">
                          <UseFormTextField
                            placeholder="State"
                            control={control}
                            name="coBorrowerState"
                          />
                        </Col>
                        <Col xs={6} md={3} className="mt-3">
                          <UseFormTextField
                            placeholder="Zip Code"
                            control={control}
                            name="coBorrowerPostalCode"
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3 d-flex align-items-center">
                        <Col xs={4} sm={4} lg={3}>
                          <label className="form-check-label">
                            Time with Current Residence:
                          </label>
                        </Col>
                        <Col xs={6} sm={4} lg={3}>
                          <UseFormSelect
                            list={YEARS_ARRAY}
                            control={control}
                            name="coBorrowerYearsAtResidence"
                            placeholder="Years"
                            placeholderValue={""}
                          />
                        </Col>
                        <Col xs={6} sm={4} lg={3} className="mt-0 mt-sm-0">
                          <UseFormSelect
                            list={MONTHS_ARRAY}
                            control={control}
                            name="coBorrowerMonthsAtResidence"
                            placeholder="Months"
                            placeholderValue={""}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row className="mt-4">
                    <Col xs={12} sm={6}>
                      <h4 className="font-bold">
                        Co-Borrower Gross Annual Income
                      </h4>
                      <UserFormCurrencyField
                        placeholder="Gross Annual Income"
                        control={control}
                        name="coBorrowerGrossIncome"
                      />
                    </Col>
                    <p className="mt-2">
                      Please enter annual income, which may include any income
                      that is verifiable and reported to the Government, such as
                      employment income, social security, pensions, etc.
                    </p>
                  </Row>
                </>
              ) : (
                ""
              )}

              {hasCoApplicant === "Yes" && (
                <UserConsent
                  applicant={ApplicantType.CoBorrower}
                  type={ApplicationType.PreQual}
                  isSungageProduct={isSungageProduct}
                  authorization={
                    <UseFormCheck
                      control={control}
                      controlName="coBorrowerAuthorization"
                      content="Yes, I have read and agree to the following credit authorization:"
                    />
                  }
                  consent={
                    <UseFormCheck
                      control={control}
                      controlName="coBorrowerConsent"
                      content="Yes, I have read and agree to the following consents and disclosures:"
                    />
                  }
                />
              )}

              <div className="mt-5 d-flex justify-content-center mb-5">
                <Button variant="primary" type="submit">
                  Submit For PreQualification
                </Button>
              </div>
            </>
          )}
        </div>
      </form>
    </Col>
  );
};

export default Request;
