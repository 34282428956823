import { CardCustom } from "@shared/components/CardCustom";
import { getStatusInfo } from "@shared/components/PipelineCard";
import { PortalDecisionStatusLabel } from "@shared/enums/ApplicationStatus";
import { isPrequalApplication } from "@shared/helpers/application.helper";
import {
  createUrl,
  getProgramType,
  getStatusBackground,
} from "@shared/helpers/global.helper";
import { IPortalApplication } from "@shared/interfaces/PortalApplicationTypes";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import {
  loanSliceSelector,
  postRequestAction,
  setDuplicateAppsandCurrentAppSubmissionData,
} from "@store/slices/loan.slice";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const content = {
  title:
    "Duplicate application(s) exist with similar borrower information provided on this application.",
  subText:
    "For changes made to an application, including converting a prequal to a full application, adding a co-borrower, correcting borrower information, or changes to a loan product, please modify the existing application be selecting from the below before submitting a new",
  option1: {
    subTitle: "Modify Exisitng Application",
    description:
      "Please modify the existing application be selecting from the below before submitting a new application, for changes including converting a prequal to a full application, adding a co-borrower, correcting borrower information, or changes to a loan product.",
  },
  option2: {
    subTitle: "Continue to Submit a New Application",
    description:
      "New Applications should not be started except when applying for credit for an installation on a different property, removing a borrower, or otherwise when an existing application cannot be modified.",
    errorDescription: "Note. This Will Repull the Borrower's Credit Report.",
  },
};

const ApplicationSmallCard = ({
  app,
  onAppClick,
}: {
  app: IPortalApplication;
  onAppClick: (appNumber: string) => void;
}) => (
  <div
    className="application-small-card mt-4"
    onClick={() =>
      app?.losAppNumber ? onAppClick(app.losAppNumber) : undefined
    }
  >
    <div className="d-flex flex-row">
      <div className="flex-grow-1">
        <p className="applicant-name">
          {app?.losAppNumber} | {app?.primaryApplicant?.fullName}
        </p>
        <p className="application-details">
          {getStatusInfo(
            app?.status?.decisionStatus,
            app?.status?.applicationStatus,
            app?.loanOffer?.amountFinanced ?? 0
          )}
        </p>
        <p className="application-details mb-0">
          {getProgramType(app?.programTypeCode?.toString() ?? "")}
        </p>
      </div>
      <div>
        <p className="applicant-name text-end">
          {isPrequalApplication(app) ? "Prequal" : "Full Application"}
        </p>
        <p
          className={`applicant-name text-end ${getStatusBackground(
            app?.status.decisionStatus ?? ""
          )}`}
        >
          {app.status?.decisionStatus &&
            PortalDecisionStatusLabel[app.status?.decisionStatus]}
        </p>
      </div>
    </div>
  </div>
);
const DuplicateApplications = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const loanSlice = useAppSelector(loanSliceSelector);

  useEffect(() => {
    if (
      loanSlice.duplicateApps?.length === 0 ||
      !loanSlice.currentApplicationSubmissionData
    ) {
      history.push(createUrl("/pre-qualification/request"));
    }
  }, [loanSlice.duplicateApps, history]);

  const onAppClick = (applicationNumber: string) => {
    dispatch(
      setDuplicateAppsandCurrentAppSubmissionData({
        duplicateApps: [],
        currentApplicationSubmissionData: undefined,
      })
    );
    history.push(createUrl(`/request-detail/${applicationNumber}`));
  };

  const applications = loanSlice.duplicateApps as IPortalApplication[];
  return (
    <div className="duplicate-applications container">
      <div className="text-center container login-container mb-5">
        <p className="title mt-6">{content.title}</p>
        <p className="sub-text">{content.subText}</p>
      </div>

      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-6 duplicate-app-card-container">
          <CardCustom title="Option 1">
            <div className="text-center">
              <p className="card-content-title mb-4">
                {content.option1.subTitle}
              </p>
              <p className="card-content-description">
                {content.option1.description}
              </p>
            </div>

            <div className="mt-3">
              {applications.map((app) => (
                <ApplicationSmallCard
                  app={app}
                  key={app.losAppNumber}
                  onAppClick={onAppClick}
                />
              ))}
            </div>
          </CardCustom>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6 duplicate-app-card-container">
          <CardCustom title="Option 2">
            <div className="text-center">
              <p className="card-content-title mb-4">
                {content.option2.subTitle}
              </p>
              <p className="card-content-description">
                {content.option2.description}
              </p>
              <p className="card-content-description mt-4 text-danger">
                {content.option2.errorDescription}
              </p>
              <Button
                variant="primary"
                className="mt-4"
                onClick={() => {
                  dispatch(
                    postRequestAction({
                      model: loanSlice.currentApplicationSubmissionData.model,
                      history,
                      successUrl:
                        loanSlice.currentApplicationSubmissionData.successUrl,
                      force: true,
                      isMultiPrequal:
                        !!loanSlice.currentApplicationSubmissionData
                          ?.isMultiPrequal,
                      xPrequalProducts:
                        loanSlice.currentApplicationSubmissionData
                          ?.xPrequalProducts,
                      programTypeCode:
                        loanSlice.currentApplicationSubmissionData
                          .programTypeCode,
                    })
                  );
                }}
              >
                Submit New Application
              </Button>
            </div>
          </CardCustom>
        </div>
      </div>
    </div>
  );
};

export default DuplicateApplications;
