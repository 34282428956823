import { useAppDispatch, useAppSelector } from "@store/hooks";
import {
  appUserSelector,
  getTotpQrCode,
  authAppMfaSetupDataSelector,
  setMfaSetupData,
  smsMfaSetupDataSelector,
  setSmsMfaSetupData,
  setLoginMethod,
} from "@store/slices/app.slice";
import Select from "react-select";
import { useEffect, useMemo } from "react";
import { QRCodeSVG } from "qrcode.react";
import { UseFormTextField } from "@shared/components/TextInput";
import { Row, Col, Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  authenticatorMfaSetupSchema,
  smsMfaPhoneNumberSchema,
  smsMfaVerifySchema,
} from "@shared/schema/request";
import {
  getThemeForReactSelect,
  onSubmitError,
  unmaskMobile,
} from "@shared/helpers/global.helper";
import { userService } from "@services/user.service";
import { Redirect, useHistory } from "react-router-dom";
import { UseFormRadio } from "@shared/components/UserFormRadio";
import {
  addSpaceEveryFourChars,
  enforceMfa,
} from "@shared/helpers/auth.helper";
import HeaderLogo from "@layout/HeaderLogo";
import { LoginMethod } from "@store/types";
import { toasterService } from "@services/toaster.service";
import { MyInputMask } from "@shared/components/MyInputMask";
import {
  COUNTRY_CODE_OPTIONS,
  DEFAULT_COUNTRY_CODE,
  MOBILE_MASK,
} from "@shared/constants/app.constant";
import { CheckCircle } from "react-bootstrap-icons";
import { environment } from "@env/environment";

const isProd = process.env.NODE_ENV === "production";
const allowSelectingMfaSmsCountryCode =
  environment.REACT_APP_SMS_MFA_ALLOW_COUNTRY_CODE === "true";

const SetupMfa = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(appUserSelector);
  const authAppMfaSetupData = useAppSelector(authAppMfaSetupDataSelector);
  const smsMfaSetupData = useAppSelector(smsMfaSetupDataSelector);
  const history = useHistory();
  const appName = isProd ? "Flicportal" : "Flicportal-Dev";

  const enforceMfaSetup = user && enforceMfa(user);

  const authenticatorMfaFormHandler = useForm({
    resolver: yupResolver(authenticatorMfaSetupSchema),
  });

  const chooseMfaMethodFormHandler = useForm({});

  const smsMfaPhoneNumberFormHandler = useForm({
    resolver: yupResolver(smsMfaPhoneNumberSchema),
  });

  const smsMfaVerifyFormHandler = useForm({
    resolver: yupResolver(smsMfaVerifySchema),
  });

  const qrCodeUrl = useMemo(
    () =>
      authAppMfaSetupData?.mfaSetupSecretCode
        ? `otpauth://totp/${appName}:${decodeURI(user?.email || "")}?secret=${
            authAppMfaSetupData.mfaSetupSecretCode
          }&issuer=${appName}`
        : null,
    [appName, authAppMfaSetupData.mfaSetupSecretCode, user?.email]
  );

  const onSubmit = async (event: any) => {
    try {
      await userService.setupMfaTotp({
        mfaCode: event.mfaCode,
      });
      toasterService.success(
        "MFA with the authentication app has been successfully set up!"
      );
      // Update login method
      dispatch(setLoginMethod(LoginMethod.TOTP));
    } catch (error) {
      console.error(error);
    }
  };

  const onAddPhoneNumber = async (event: any) => {
    try {
      const phoneNumber = unmaskMobile(event.phoneNumber);
      const contryCode = event.countryCode || DEFAULT_COUNTRY_CODE;
      const phoneWithCountryCode = `${contryCode}${phoneNumber}`;
      await userService.addPhoneNumber({
        phoneNumber: phoneWithCountryCode,
      });
      dispatch(
        setSmsMfaSetupData({
          phoneNumber: phoneWithCountryCode,
          status: "SmsCodeSent",
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const onVerifyPhoneNumber = async (event: any) => {
    try {
      await userService.verifyPhoneNumber({
        mfaCode: event.mfaCode,
      });
      dispatch(setLoginMethod(LoginMethod.SMS));
      toasterService.success("MFA with the SMS has been successfully set up!");
    } catch (error) {
      console.error(error);
    }
  };

  const onChangePhoneNumber = async (event: any) => {
    try {
      smsMfaPhoneNumberFormHandler.reset();
      smsMfaVerifyFormHandler.reset();
      dispatch(
        setSmsMfaSetupData({
          phoneNumber: "",
          status: "GetPhoneNumber",
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    dispatch(getTotpQrCode());
    dispatch(
      setMfaSetupData({
        mfaSetupSecretCode: null,
      })
    );
    dispatch(setSmsMfaSetupData(null));

    return () => {
      dispatch(
        setMfaSetupData({
          mfaSetupSecretCode: null,
        })
      );
      dispatch(setSmsMfaSetupData(null));
    };
  }, [dispatch]);

  const handleBypassForNow = () => {
    history.push("/pipelines");
  };

  const mfaMethod = chooseMfaMethodFormHandler.watch("mfaMethod");

  if (user?.loginMethod !== LoginMethod.DEFAULT) {
    return <Redirect to="/pipelines" />;
  }

  const smsMfaStep1Disabled = smsMfaSetupData?.status === "SmsCodeSent";

  return (
    <>
      <HeaderLogo />
      <div className="d-flex align-items-center justify-content-center mt-3 mb-5">
        <div className="login-container form-container">
          <div className="col-xs-12 col-md-8 offset-md-2">
            <div className="mt-4">
              <h3 className="h3 flex-grow-1">
                {enforceMfaSetup
                  ? "Setup Multi-Factor Authentication (MFA)"
                  : "Flic Is Implementing Multi-Factor Authentication (MFA)"}
              </h3>
              {!enforceMfaSetup && (
                <>
                  <div className="my-2">
                    <p className="sub-title">
                      You will need to setup MFA on your account
                    </p>
                    <p className="info-text">
                      If you are with a customer and need to complete the setup
                      at a more convenient time, please click “bypass for now”
                      to complete the process on your next login.
                    </p>
                  </div>
                  <div className="d-flex justify-content-end my-4">
                    <Button
                      type="button"
                      variant="outline-primary-dark"
                      onClick={handleBypassForNow}
                    >
                      Bypass for now
                    </Button>
                  </div>
                </>
              )}

              <div className="my-2">
                <p className="sub-title">Why are we making you do this?</p>
                <p className="info-text">
                  To ensure the highest level of security for our mutual
                  customer’s financial data, we are introducing Multi-Factor
                  Authentication (MFA). This additional security step helps
                  protect your account from unauthorized access by requiring
                  more than just a password to log in. Setting up MFA is a
                  simple process that adds only a few seconds to your login time
                  but significantly enhances protection against cyber threats.
                  Our mutual customer’s as well as your security is our top
                  priority, and we appreciate your cooperation in keeping our
                  shared data safe. If you need assistance during setup, please
                  call the Flic hotline, or contact us at
                  care@flicfinancial.com.
                </p>
              </div>
            </div>

            <div className="p-2" />

            <div className="mt-4">
              <h3 className="h3 flex-grow-1">
                Select Your Multi-Factor Authentication Method (Required)
              </h3>

              <p className="sub-title mb-0">
                Choose Your Multi-Factor Authentication Method
              </p>
              <p className="info-text">
                Please choose between these two forms of authentication
              </p>

              <form noValidate autoComplete="off" className="">
                <UseFormRadio
                  control={chooseMfaMethodFormHandler.control}
                  elementName="authenticator-app-mfa"
                  controlName="mfaMethod"
                  content="authenticationAppMfa"
                  label={
                    <p className="mb-0 radio-button-label">
                      Use an Authentication App{" "}
                      <span className="highlight-text">Recomended</span>
                    </p>
                  }
                  checked={mfaMethod === "authenticationAppMfa" ? true : false}
                  helperText="Get a code on any device from an authentication app such as Authy or Google Authenticator."
                  disabled={smsMfaStep1Disabled}
                />

                <div className="p-1" />
                <UseFormRadio
                  control={chooseMfaMethodFormHandler.control}
                  elementName="sms-mfa"
                  controlName="mfaMethod"
                  content="smsCodeMfa"
                  helperText="We’ll send a code in a text message to your phone."
                  label={
                    <p className="mb-0 radio-button-label">
                      Get A Text Message
                    </p>
                  }
                  checked={mfaMethod === "smsCodeMfa" ? true : false}
                  disabled={smsMfaStep1Disabled}
                />
              </form>
            </div>

            <div className="p-3" />

            {mfaMethod === "authenticationAppMfa" && (
              <form
                autoComplete="off"
                onSubmit={authenticatorMfaFormHandler.handleSubmit(
                  onSubmit,
                  onSubmitError()
                )}
              >
                {" "}
                <h3 className="h3 flex-grow-1">Setup Authenticator App </h3>
                <p className="sub-title mb-1">
                  Step 1: Install an Authenticator App
                </p>
                <p className="info-text mb-3">
                  If you haven't already installed an authenticator app on your
                  mobile device, please do so by downloading one from your
                  device’s app store. Recommended options include:
                </p>
                <ul className="info-text mb-3">
                  <li>Authy (available on iOS and Android)</li>
                  <li>Google Authenticator (available on iOS and Android)</li>
                  <li>
                    Microsoft Authenticator (available on iOS and Android)
                  </li>
                </ul>
                <p className="sub-title mb-1">
                  Step 2: Scan this QR code with your authentication app
                </p>
                <ul className="info-text mb-3">
                  <li>Open your authenticator app on your mobile device.</li>
                  <li>
                    In the authenticator app, select the option to add a new
                    account (this is usually indicated by a "+" sign or "Add
                    Account").
                  </li>
                  <li>
                    Scan the QR code displayed on your computer screen or
                    manually enter the long code below the QR code.
                  </li>
                </ul>
                {qrCodeUrl && (
                  <div>
                    <div className="d-flex justify-content-center mt-3">
                      <QRCodeSVG value={qrCodeUrl} size={200} />
                    </div>
                    <p className="p-2 text-center mfa-secret-code">
                      {addSpaceEveryFourChars(
                        authAppMfaSetupData?.mfaSetupSecretCode || ""
                      )}
                    </p>
                  </div>
                )}
                <p className="sub-title mb-1">Step 3: Enter the 6-digit code</p>
                <p className="info-text mb-3">
                  Once your app reacts the QR code you'll get a 6-digit code.
                </p>
                <Row className="my-3">
                  <Col xs={4}>
                    <UseFormTextField
                      placeholder="6-Digit Code"
                      control={authenticatorMfaFormHandler.control}
                      name="mfaCode"
                    />
                  </Col>
                </Row>
                <div className="d-flex justify-content-center m-4">
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={
                      authenticatorMfaFormHandler.formState.isSubmitting
                    }
                  >
                    Continue
                  </Button>
                </div>
              </form>
            )}

            {mfaMethod === "smsCodeMfa" && (
              <>
                <div>
                  <h3 className="h3 flex-grow-1">Setup Your Phone </h3>

                  <div>
                    <p className="sub-title mb-1">
                      Step 1: What phone number do you want to use?
                    </p>
                    <p className="info-text mb-3">
                      Enter the 10 digit US phone number you want to use to
                      recieve a verification code.
                    </p>
                  </div>
                  <form
                    autoComplete="off"
                    onSubmit={smsMfaPhoneNumberFormHandler.handleSubmit(
                      onAddPhoneNumber,
                      onSubmitError()
                    )}
                  >
                    <Row>
                      {allowSelectingMfaSmsCountryCode && (
                        <Col xs="auto" className="my-2">
                          <Controller
                            control={smsMfaPhoneNumberFormHandler.control}
                            defaultValue={COUNTRY_CODE_OPTIONS[0].value}
                            name="countryCode"
                            render={({ field: { onChange, value } }) => (
                              <Select
                                id="countryCode"
                                options={COUNTRY_CODE_OPTIONS}
                                placeholder="Country Code"
                                value={COUNTRY_CODE_OPTIONS.find(
                                  (c) => c.value === value
                                )}
                                onChange={(val) => onChange(val?.value)}
                                theme={getThemeForReactSelect}
                                isDisabled={smsMfaStep1Disabled}
                              />
                            )}
                          />
                        </Col>
                      )}
                      <Col xs="auto" className="my-2">
                        <MyInputMask
                          control={smsMfaPhoneNumberFormHandler.control}
                          defaultValue={""}
                          name="phoneNumber"
                          mask={MOBILE_MASK}
                          placeholder="Phone Number"
                          disabled={smsMfaStep1Disabled}
                          autoFocus
                        />
                      </Col>
                      <Col
                        xs="auto"
                        className="flex-grow d-flex justify-content-center align-items-center my-2"
                      >
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={
                            smsMfaPhoneNumberFormHandler.formState
                              .isSubmitting || smsMfaStep1Disabled
                          }
                        >
                          Send Text
                        </Button>
                      </Col>
                      {smsMfaSetupData?.status === "SmsCodeSent" && (
                        <>
                          <Col
                            xs="auto"
                            className="d-flex align-items-center my-2"
                          >
                            <p className="mb-0 highlight-phone-code-sent d-flex align-items-center">
                              <CheckCircle
                                className="sms-mfa-sent-icon me-1"
                                viewBox="0 0 17 17"
                              />
                              <span className="ms-1">Message Sent</span>
                            </p>
                          </Col>

                          {smsMfaStep1Disabled && (
                            <Col className="my-2" xs="auto">
                              <Button
                                variant="outline-primary"
                                size="sm"
                                disabled={
                                  smsMfaPhoneNumberFormHandler.formState
                                    .isSubmitting ||
                                  smsMfaVerifyFormHandler.formState
                                    .isSubmitting ||
                                  !smsMfaStep1Disabled
                                }
                                className="width-210px"
                                onClick={onChangePhoneNumber}
                              >
                                Change Phone Number
                              </Button>
                            </Col>
                          )}
                        </>
                      )}
                    </Row>
                  </form>
                </div>

                <div className="p-3" />

                {smsMfaSetupData?.status === "SmsCodeSent" && (
                  <form
                    autoComplete="off"
                    onSubmit={smsMfaVerifyFormHandler.handleSubmit(
                      onVerifyPhoneNumber,
                      onSubmitError()
                    )}
                  >
                    <div>
                      <p className="sub-title mb-1">
                        Step 2: Enter the 6-digit code{" "}
                      </p>
                      <p className="info-text mb-3">
                        Enter the 6-digit code sent to the phone number above.
                      </p>
                    </div>
                    <Row>
                      <Col xs={6} md={5} lg={4}>
                        <UseFormTextField
                          placeholder="6-Digit Code"
                          control={smsMfaVerifyFormHandler.control}
                          name="mfaCode"
                          autoFocus
                        />
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-center m-4">
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={
                          smsMfaVerifyFormHandler.formState.isSubmitting
                        }
                      >
                        Continue
                      </Button>
                    </div>
                  </form>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SetupMfa;
