import { useEffect, useMemo, useState } from "react";
import { InputGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import { DebounceInput } from "react-debounce-input";
import isFunction from "lodash/isFunction";
import DropdownList, { DropdownListItem } from "./DropdownList";
import { getUsers } from "@store/slices/user.slice";
import { User, UserActivationState } from "@shared/interfaces/User";

interface SelectUserModalProps extends ModalProps {
  onClose: () => void;
  onSelect: (user: User) => void;
  selectedUser?: User;
  title?: string;
}

const SelectUserModal = ({
  onClose,
  onSelect,
  selectedUser,
  title = "Select user",
  ...props
}: SelectUserModalProps) => {
  const [user, setUser] = useState<User | undefined>(selectedUser);
  const [query, setQuery] = useState<string>(user?.username || "");
  const [users, setUsers] = useState<User[]>([]);
  const items = useMemo<DropdownListItem[]>(
    () =>
      users.map(({ email, id, username, state }) => ({
        title: `${username} (${email})`,
        value: id,
        isDisabled: state === UserActivationState.deactivated,
      })),
    [users]
  );
  const onSearch = (search: string = "") => {
    getUsers({
      page: 1,
      limit: 20,
      search,
    }).then(({ results }) => {
      setUsers(results);
    });
  };

  useEffect(() => {
    setUser(selectedUser);
  }, [selectedUser]);

  useEffect(() => {
    setQuery(user?.username || "");
  }, [user]);

  return (
    <Modal {...props} onHide={onClose}>
      <Modal.Header closeButton onHide={onClose}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup style={{ flexDirection: "column" }}>
          <DropdownList
            items={items}
            onClose={() => setUsers([])}
            onSelect={(value: string) => {
              const foundUser = users.find((item) => item.id === value);
              if (foundUser && isFunction(onSelect)) {
                setQuery(foundUser.username);
                setUser(foundUser);
              }
            }}
          >
            <DebounceInput
              className="form-control"
              placeholder="Search user"
              value={query}
              debounceTimeout={500}
              onChange={(event) => {
                setQuery(event.target.value);
                onSearch(event.target.value);
              }}
              onBlur={() => {
                // Set delay to allow for touch actions like click
                setTimeout(() => {
                  setUsers([]);
                }, 200);
              }}
              onFocus={(event: any) => {
                onSearch(event.target.value);
              }}
              style={{ width: "100%" }}
            />
          </DropdownList>
        </InputGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary-outline" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          variant="primary"
          disabled={!user}
          onClick={() => {
            if (user) {
              onSelect(user);
              onClose();
            }
          }}
        >
          Select user
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectUserModal;
