import React from "react";
import { Row, Col } from "react-bootstrap";

interface IDisclosureContainerProps {
    content?: string
}

const DisclosureContainer = ({ content }: IDisclosureContainerProps) => {
  return (
    <Row className="terms">
      <Col xs={11} className="mx-auto">
        <div
          dangerouslySetInnerHTML={{
            __html: content || "",
          }}
        />
      </Col>
    </Row>
  );
};

export default DisclosureContainer;
