import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import ViewIpWhitelist from "./view-ip-whitelist/ViewIpWhitelist";
import AddIpWhiteList from "./add-ip-whitelist/AddIpWhiteList";

const IpWhitelist = () => {
  let { url } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route path={`${url}/`} exact component={ViewIpWhitelist} />
        <Route path={`${url}/add`} exact component={AddIpWhiteList} />

        <Route
          path="*"
          render={() => (
            <Redirect
              to={{
                pathname: `${url}/`,
              }}
            />
          )}
        />
      </Switch>
    </div>
  );
};

export default IpWhitelist;
