import { Button } from "react-bootstrap";
import { enableBookingConfirmation } from "@shared/helpers/application.helper";
import { useAppSelector } from "@store/hooks";
import { appUserSelector } from "@store/slices/app.slice";
import { useHistory } from "react-router-dom";
import { IPortalApplication } from "@shared/interfaces/PortalApplicationTypes";

export const UpdateBookingConfirmation = ({
  data,
}: {
  data: IPortalApplication | undefined;
}) => {
  const history = useHistory();
  const user = useAppSelector(appUserSelector);

  if (!enableBookingConfirmation(data, user)) {
    return null;
  }

  const handleRedirectCompleteFunding = () => {
    if (!data?.losAppNumber) {
      return;
    }
    history.push(`/application/${data?.losAppNumber}/update-booking`);
  };

  return (
    <div className="d-flex mt-4">
      <Button
        className=""
        variant="primary"
        type="button"
        onClick={handleRedirectCompleteFunding}
      >
        Update Booking Information
      </Button>
    </div>
  );
};
