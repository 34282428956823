import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RouteComponentProps } from "react-router-dom";

import { networkService } from "@services/network-service";
import { RootState } from "@store/store";
import {
  ILoginProcessData,
  resetApp,
  setAccessToken,
  setLoggedInUser,
  setLoginProcessData,
} from "@store/slices/app.slice";
import { toasterService } from "@services/toaster.service";
import { UserAuthStatus } from "@shared/enums/UserState";
import { resetLoanData } from "./loan.slice";
import {
  ILoginResponse,
  ILoginSuccessfulResponse,
  LoginChallenge,
} from "@store/types";
import { userService } from "@services/user.service";
import { handleLoginSuccessRedirection } from "@shared/helpers/auth.helper";
import { AxiosResponse } from "axios";

type Verification = {
  userConfirmed: string;
  username: string;
};
type ResetPassword = {
  email: string;
};
type LoginWithMfaType = {
  mfaCode: string;
};
interface AuthState {
  user: any;
  verification: Verification;
  resetPassword: ResetPassword;
}
const initialState: AuthState = {
  user: {} as any,
  verification: {} as Verification,
  resetPassword: {} as ResetPassword,
};

export const signInAction = createAsyncThunk(
  "auth/signIn",
  async (
    {
      model,
      path,
      history,
      setAuthUserStatus,
      isResendSmsMfa,
    }: {
      model: any;
      path: string;
      history: RouteComponentProps["history"];
      setAuthUserStatus: (val: UserAuthStatus) => void;
      isResendSmsMfa?: boolean;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(setLoggedInUser(null));
      if (!isResendSmsMfa) {
        // This will not run if user is requesting for resend mfa sms
        dispatch(resetApp());
        dispatch(resetLoanData());
      }
      const loginResponse = await networkService.post<{
        data: ILoginResponse;
        errors: any;
        message: string;
      }>("login", model);
      const response = loginResponse?.data?.data?.response;

      // User logging in first time, redirecting to set password
      if (typeof response === "string") {
        toasterService.success("Please set your password.");
        history.push("/auth/set-password", {
          email: model.email,
        });
        return;
      }

      // MFA Challenge to handle
      if ("challengeName" in response) {
        dispatch(
          setLoginProcessData({
            challengeName: response.challengeName,
            email: model.email,
            session: response.session,
            password: model.password,
          })
        );
        // MFA for login handling
        return;
      }

      // Login
      if (
        typeof loginResponse?.data?.data?.response === "object" &&
        "token" in loginResponse?.data?.data?.response
      ) {
        dispatch(setLoggedInUser(loginResponse.data.data.response));
        dispatch(
          setAccessToken(loginResponse.data.data.response.token.accessToken)
        );
        setAuthUserStatus(UserAuthStatus.Authorized);
        handleLoginSuccessRedirection({
          history,
          loginSuccessResponse: loginResponse.data.data.response,
          path,
        });
      }
      return loginResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const signInSubmitMfaAction = createAsyncThunk(
  "auth/signInSubmitMfaAction",
  async (
    {
      model,
      loginProcessData,
      path,
      history,
      setAuthUserStatus,
      loginChallenge,
    }: {
      model: LoginWithMfaType;
      loginProcessData: ILoginProcessData;
      path: string;
      history: RouteComponentProps["history"];
      setAuthUserStatus: (val: UserAuthStatus) => void;
      loginChallenge: LoginChallenge;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { email, session } = loginProcessData;
      const loginWithMfaCodeResponse = await userService.loginWithMfa({
        mfaCode: model.mfaCode,
        email,
        loginChallenge,
        session,
      });

      // Login
      if (
        typeof loginWithMfaCodeResponse?.data?.data?.response === "object" &&
        "token" in loginWithMfaCodeResponse?.data?.data?.response
      ) {
        dispatch(setLoggedInUser(loginWithMfaCodeResponse.data.data?.response));
        dispatch(
          setAccessToken(
            loginWithMfaCodeResponse.data.data.response.token.accessToken
          )
        );
        setAuthUserStatus(UserAuthStatus.Authorized);
        handleLoginSuccessRedirection({
          history,
          loginSuccessResponse: loginWithMfaCodeResponse.data.data.response,
          path,
        });
      }
      return loginWithMfaCodeResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const forgotPasswordAction = createAsyncThunk(
  "auth/forgotPassword",
  async (
    {
      email,
      history,
    }: { email: string; history: RouteComponentProps["history"] },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response: any = await networkService.post(`/reset-password`, {
        email,
      });
      dispatch(setResetPassword({ email }));
      toasterService.success(
        "Verification code has been sent to your email address."
      );
      history.push("/auth/reset-password");

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const resetPasswordAction = createAsyncThunk(
  "auth/resetPassword",
  async (
    {
      email,
      password,
      history,
      code,
    }: {
      email: string;
      password: string;
      history: RouteComponentProps["history"];
      code: string;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await networkService.post(`/confirm-password`, {
        password,
        email,
        code,
      });
      toasterService.success("Password has been updated successfully.");
      dispatch(clearResetPassword({}));
      history.push("/auth/login");

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const setNewPasswordAction = createAsyncThunk(
  "auth/password",
  async (
    {
      email,
      password,
      history,
      setAuthUserStatus,
      path,
    }: {
      email: string;
      password: string;
      history: RouteComponentProps["history"];
      setAuthUserStatus: (val: UserAuthStatus) => void;
      path: string;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await networkService.post<
        {
          email: string;
          password: string;
        },
        AxiosResponse<{
          data: ILoginSuccessfulResponse;
          errors: any;
          message: string;
        }>
      >(`/password`, {
        password,
        email,
      });
      if (
        typeof response?.data?.data?.response === "object" &&
        "token" in response?.data?.data?.response
      ) {
        dispatch(setLoggedInUser(response.data.data?.response));
        dispatch(setAccessToken(response.data.data.response.token.accessToken));
        setAuthUserStatus(UserAuthStatus.Authorized);
        handleLoginSuccessRedirection({
          history,
          loginSuccessResponse: response.data.data.response,
          path,
          isNewUser: true,
        });
      }
      toasterService.success("Password has been updated successfully.");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetVerification: (state: AuthState, { payload }: PayloadAction<any>) => {
      state.verification = {} as Verification;
    },
    setVerification: (state: AuthState, { payload }: PayloadAction<any>) => {
      state.verification = payload;
    },
    clearResetPassword: (state: AuthState, { payload }: PayloadAction<any>) => {
      state.resetPassword = {} as ResetPassword;
    },
    setResetPassword: (state: AuthState, { payload }: PayloadAction<any>) => {
      state.resetPassword = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { reducer: authReducer } = authSlice;
export const {
  resetVerification,
  setVerification,
  clearResetPassword,
  setResetPassword,
} = authSlice.actions;

export const signupUserSelector = (rootState: RootState) => rootState.auth.user;
export const verificationSelector = (rootState: RootState) =>
  rootState.auth.verification;
export const resetPasswordDataSelector = (rootState: RootState) =>
  rootState.auth.resetPassword;
