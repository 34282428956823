import { ApplicationType } from "@shared/enums/ApplicationStatus";
import React from "react";
import { Col, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

const SendLinkSuccess = () => {
  let { type } = useParams<{ type: string }>();

  return (
    <div className="mt-3">
      <Col xs={12} sm={9} md={7} lg={5} className="mx-auto mt-5">
        <h6 className="font-bold-800 text-center">
          A{" "}
          <span className="text-primary">
            {type === ApplicationType.Full
              ? "Full Credit Application"
              : ApplicationType.PreQual + " Application"}
          </span>{" "}
          has been sent to the customer.{" "}
        </h6>
        <div className="text-center mt-5">
          <Link to={`/pipelines`}>
            <Button>Return to Pipeline</Button>
          </Link>
        </div>
      </Col>
    </div>
  );
};

export default SendLinkSuccess;
