import { NA } from "@shared/constants/app.constant";
import moment from "moment";

const DateFormat = ({ date }: { date: number | undefined }) => {
  if (!date) {
    return <span>{NA}</span>;
  }
  return (
    <>
      <span>{moment(date).format("MM/DD/YYYY")}</span>
    </>
  );
};
export default DateFormat;
