import { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { UseFormTextField } from "@shared/components/TextInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { UseFormSelect } from "@shared/components/UserFormSelect";
import { UseFormCheck } from "@shared/components/UserFormCheck";
import {
  CITIZENSHIP,
  EMPLOYMENT_STATUS_LIST,
  MOBILE_MASK,
  MONTHS_ARRAY,
  TIME_ON_JOB_TEXT,
  YEARS_ARRAY,
} from "@shared/constants/app.constant";
import { MyInputMask } from "@shared/components/MyInputMask";
import { useHistory, useParams } from "react-router-dom";
import { loanService } from "@services/loan.service";
import {
  maskMobile,
  unmaskMobile,
  onSubmitError,
} from "@shared/helpers/global.helper";
import { editApplicantAction } from "@store/slices/loan.slice";
import { modifyNewRequestSchema } from "@shared/schema/request";
import { UserFormCurrencyField } from "@shared/components/UserFormCurrencyField";
import ApplicationGuard from "@shared/components/ApplicationGuard";
import { GoogleAutoComplete } from "@shared/components/GoogleAutoComplete";
import { IPortalApplication } from "@shared/interfaces/PortalApplicationTypes";
import { appUserSelector } from "@store/slices/app.slice";
import {
  getEmployerNameForRequestPayloadToDl4,
  getXPrequalProducts,
} from "@shared/helpers/application.helper";
import { IXPrequalProductsTypeValues } from "@shared/interfaces/Application";
import useInstallationAddress from "@hooks/useInstallationAddress";
import IncomePrompt from "@shared/components/IncomePrompt";
import { EmploymentStatus } from "@shared/enums/EmploymentStatus";

const ModifyApplicant = () => {
  const dispatch = useAppDispatch();
  let { id } = useParams<{ id: string }>();
  const [requestDetail, setRequestDetail] = useState<IPortalApplication>();
  const history = useHistory();
  const { control, handleSubmit, watch, reset, setValue } = useForm({
    resolver: yupResolver(modifyNewRequestSchema),
  });
  const user = useAppSelector(appUserSelector);

  const getInstallationAddress = () => {
    const isInstallationAddressDifferent =
      !!requestDetail?.installationAddress?.isInstallationAddressDifferent;
    return {
      xInstallationAddressLine: isInstallationAddressDifferent
        ? requestDetail?.installationAddress?.addressLine
        : watch("addressLine1"),
      xInstallationCity: isInstallationAddressDifferent
        ? requestDetail?.installationAddress?.city
        : watch("city"),
      xInstallationState: isInstallationAddressDifferent
        ? requestDetail?.installationAddress?.state
        : watch("state"),
      xInstallationZipCode: isInstallationAddressDifferent
        ? requestDetail?.installationAddress?.zipCode
        : watch("postalCode"),
    };
  };

  const { isInstallationAddressUpdated, setInitialInstAddressFields } =
    useInstallationAddress({
      currentAddressFields: {
        addressLine: getInstallationAddress().xInstallationAddressLine,
        city: getInstallationAddress().xInstallationCity,
        state: getInstallationAddress().xInstallationState,
        zipCode: getInstallationAddress().xInstallationZipCode,
      },
    });

  const onSubmit = (event: any) => {
    if (!requestDetail?.programTypeCode) {
      return;
    }

    const applicantAddresses = [];
    const currentAddress = {
      ownershipType: {
        name: "Other",
        label: "Other",
        value: 4,
      },
      addressType: {
        name: "Primary",
        label: "Primary",
        value: 1,
      },
      addressFormat: {
        name: "Postal Standard",
        label: "Postal Standard",
        value: 5,
      },
      postalCode: event.postalCode,
      addressLine1: event.addressLine1,
      city: event.city,
      state: event.state,
      yearsAtResidence: Number(event.yearsAtResidence),
      monthsAtResidence: Number(event.monthsAtResidence),
    };
    applicantAddresses.push(currentAddress);
    if (event.isMailingAdressSame) {
      applicantAddresses.push({
        ownershipType: {
          name: "Other",
          label: "Other",
          value: 4,
        },
        addressType: {
          name: "Mailing",
          label: "Mailing",
          value: 3,
        },
        addressFormat: {
          name: "Postal Standard",
          label: "Postal Standard",
          value: 5,
        },
        postalCode: event.mailingZipCode,
        addressLine1: event.mailingAddressLine1,
        city: event.mailingCity,
        state: event.mailingState,
      });
    }
    const employment = {
      employmentType: {
        name: "Primary",
        label: "Primary",
        value: 1,
      },
      grossIncome: Number(event.grossIncome),
      employerName: getEmployerNameForRequestPayloadToDl4({
        employmentStatus: event?.employmentStatus,
        employerName: event?.employerName || "",
      }),
      monthsEmployed: event.monthsEmployed ? Number(event.monthsEmployed) : 0,
      yearsEmployed: event.yearsEmployed ? Number(event.yearsEmployed) : 0,
      payPeriod: 5,
      employmentStatus: {
        name: "Undefined",
        label: "Undefined",
        value: 0,
      },
    };
    const model = {
      content: {
        jsonType: "Applicant",
        applicantType: {
          name: "primary",
          label: "primary",
          value: 1,
        },
        lastName: event.lastName,
        firstName: event.firstName,
        email: event.email,
        isUSCitizen: event.isUSCitizen === "Yes" ? true : false,
        xIsPermanentResident:
          event.isUSCitizen === "Yes"
            ? null
            : event.xIsPermanentResident === "Yes"
            ? true
            : false,
        mobilePhone: unmaskMobile(event.mobilePhone),
        addresses: applicantAddresses,
        employments: [employment],
        xInstallationHomeOwner: 1,

        internalClientId: requestDetail?.losInternalClientId,
        applicationId: requestDetail?.losApplicationId,
        applicantId: requestDetail?.primaryApplicant?.applicantId,
      },
      redecisionRequested: 0,
    };

    const { xPrequalProductsSelected } = getXPrequalProducts(
      user,
      requestDetail?.programTypeCode?.toString()
    );
    const productInfo: IXPrequalProductsTypeValues | undefined =
      xPrequalProductsSelected?.[0];

    const installtionAddress = getInstallationAddress();

    const loanModel = {
      xProgramTypeName: productInfo?.xProgramTypeName,
      decisionProcessPrefs: {
        defaultCarletonDATFileName: productInfo?.defaultCarletonDATFileName,
      },
      xLender: productInfo?.xLender,
      xInstallationAddressLine: installtionAddress.xInstallationAddressLine,
      xInstallationCity: installtionAddress.xInstallationCity,
      xInstallationState: installtionAddress.xInstallationState,
      xInstallationZipCode: installtionAddress.xInstallationZipCode,
      xInstalationAddressSame:
        !!!requestDetail?.installationAddress?.isInstallationAddressDifferent,
      xStatedInstallationHomeOwner:
        requestDetail?.installationAddress?.installationHomeOwner,
      installationResidency:
        requestDetail?.installationAddress?.installationResidency,
      loanInformation: {
        loanVersions: [
          {
            totalCashPrice: requestDetail?.loanOffer?.amountFinanced,
            requestedTerm: productInfo?.requestedTerm,
            firstPaymentDateStandard: productInfo?.firstPaymentDateStandard,
            contractDateStandard: productInfo?.contractDateStandard,
            userRate: productInfo?.userRate,
            xRiskBasedRateTable: productInfo?.xRisckBasedIntialRateTable,
            xRiskBasedInitialRateTable: productInfo?.xRisckBasedIntialRateTable,
            commonLoanInfo: {
              xprogramtype: requestDetail?.programTypeCode,
              xInstallationAddressLine:
                installtionAddress.xInstallationAddressLine,
              xInstallationCity: installtionAddress.xInstallationCity,
              xInstallationState: installtionAddress.xInstallationState,
              xInstallationZipCode: installtionAddress.xInstallationZipCode,
              xInstalationAddressSame:
                !!!requestDetail?.installationAddress
                  ?.isInstallationAddressDifferent,
              xStatedInstallationHomeOwner:
                requestDetail?.installationAddress?.installationHomeOwner,
              installationResidency:
                requestDetail?.installationAddress?.installationResidency,
              xinitialTerm: productInfo?.xinitialTerm,
              xAmortizationTerm: productInfo?.xAmortizationTerm,
              xFinalPmtDiff: productInfo?.xFinalPmtDiff,
              xMembershipFee: productInfo?.xMembershipFee,
              xFlicFlatFee: productInfo?.xFlicFlatFee,
              xFlicPlatformFeePercent: productInfo?.xFlicPlatformFeePrecent,
              xSellersPointsPercent: productInfo?.xSellerPointsPercent,
              xInitialRate: productInfo?.xInitialRate,
              xFinalRate: productInfo?.xFinalRate,
            },
          },
        ],
      },
    };
    dispatch(
      editApplicantAction({
        model,
        loanInfo: loanModel,
        appNumber: id,
        history,
        path: "/new-application/confirmation",
        isInstallationAddressUpdated,
      })
    );
  };

  useEffect(() => {
    const getRequestData = async (id: string) => {
      const response = await loanService.getRequestById(id);
      setRequestDetail(response?.data?.data);
      const primaryAddress =
        response?.data?.data?.primaryApplicant?.currentAddress;
      const mailingAddress =
        response?.data?.data?.primaryApplicant?.mailingAddress;
      const myMailingAddressIsDifferentThanCurrentAddress =
        response?.data?.data?.primaryApplicant
          ?.myMailingAddressIsDifferentThanCurrentAddress;
      const applicant = response?.data?.data?.primaryApplicant;
      const coApplicant = response?.data?.data?.coApplicant;
      const installationAddress = response?.data?.data?.installationAddress;

      // To check if installation address is updated or not
      setInitialInstAddressFields({
        xInstallationAddressLine: installationAddress?.addressLine || "",
        xInstallationCity: installationAddress?.city || "",
        xInstallationState: installationAddress?.state || "",
        xInstallationZipCode: installationAddress?.zipCode || "",
      });
      reset({
        firstName: applicant?.firstName,
        lastName: applicant?.lastName,
        email: applicant?.email,
        mobilePhone: maskMobile(applicant?.mobilePhone || ""),
        isUSCitizen: applicant?.isUSCitizen ? "Yes" : "No",
        ...(typeof applicant?.isUSCitizen == "boolean" &&
          !applicant?.isUSCitizen && {
            xIsPermanentResident: applicant?.isPermanentResident ? "Yes" : "No",
          }),
        grossIncome: applicant?.employment?.grossIncome,
        xDiffAddressThanApplicant:
          applicant?.differentAddressThanPrimaryApplicant ? "Yes" : "No",
        addressLine1: primaryAddress?.addressLine,
        city: primaryAddress?.city,
        state: primaryAddress?.state,
        postalCode: primaryAddress?.postalCode,
        yearsAtResidence: primaryAddress?.yearsAtResidence,
        monthsAtResidence: primaryAddress?.monthsAtResidence,

        employerName:
          applicant?.employment?.employmentStatus === EmploymentStatus.EMPLOYED
            ? applicant?.employment?.employerName
            : "",
        employmentStatus: applicant?.employment?.employmentStatus,
        yearsEmployed: Number(applicant?.employment?.yearsEmployed),
        monthsEmployed: Number(applicant?.employment?.monthsEmployed),
        mailingAddressLine1: mailingAddress
          ? mailingAddress?.mailingAddress
          : "",
        mailingCity: mailingAddress ? mailingAddress?.mailingCity : "",
        mailingState: mailingAddress ? mailingAddress?.mailingCity : "",
        mailingZipCode: mailingAddress ? mailingAddress?.mailingPostalCode : "",
        isMailingAdressSame:
          mailingAddress && myMailingAddressIsDifferentThanCurrentAddress
            ? true
            : false,
        coApplicantEmail: coApplicant?.email,
        // title: '' TODO: Pending for sungage integration
      });
    };
    getRequestData(id);
  }, [id, reset, setInitialInstAddressFields]);

  const isMailingAdressSame = watch("isMailingAdressSame");
  const isUSCitizen = watch("isUSCitizen");
  const employmentStatus = Number(watch("employmentStatus"));
  const isSungageProduct =
    requestDetail?.programTypeCode?.toString() === "1001";

  const isPrimaryEmployed =
    employmentStatus === EmploymentStatus.EMPLOYED ||
    (isSungageProduct && employmentStatus === EmploymentStatus.SELF_EMPLOYED);

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit, onSubmitError())}
    >
      <ApplicationGuard application={requestDetail} />
      <Col xs={12} md={10} lg={7} className="mx-auto mt-3 fullapp-request">
        <h3 className="h3 text-primary">Modify Applicant</h3>
        <hr className="text-primary height-2px" />
        <Row className="mt-4">
          <Col xs={12}>
            <h4 className="font-bold">Applicant Information</h4>
          </Col>
          <Col xs={12} md={6} className="mt-3">
            <UseFormTextField
              placeholder="First Name"
              control={control}
              name="firstName"
            />
          </Col>
          <Col xs={12} md={6} className="mt-3">
            <UseFormTextField
              placeholder="Last Name"
              control={control}
              name="lastName"
            />
          </Col>
          <Col xs={12} md={6} className="mt-3 ">
            <UseFormTextField
              placeholder="Email"
              control={control}
              name="email"
            />
          </Col>
          <Col xs={12} md={6} className="mt-3 ">
            <MyInputMask
              control={control}
              defaultValue={""}
              name="mobilePhone"
              mask={MOBILE_MASK}
              placeholder="Mobile Phone"
            />
          </Col>
          <Col xs={12} md={6} className="mt-3">
            <UseFormSelect
              list={CITIZENSHIP}
              control={control}
              name="isUSCitizen"
              placeholder="Are you a US citizen"
            />
          </Col>
          {isUSCitizen === "No" && (
            <Col xs={12} md={6} className="mt-3">
              <UseFormSelect
                list={CITIZENSHIP}
                control={control}
                name="xIsPermanentResident"
                placeholder="Are you a Permanent Resident?"
              />
            </Col>
          )}
        </Row>
        <Row className="mt-4">
          <Col xs={12}>
            <h4 className="font-bold">Current Address</h4>
          </Col>
          <Col xs={12}>
            <GoogleAutoComplete
              placeholder="Street"
              control={control}
              name="addressLine1"
              onSelect={(address) => {
                setValue("addressLine1", address.street);
                setValue("city", address.city);
                setValue("state", address.state);
                setValue("postalCode", address.zipCode);
              }}
            />
          </Col>
          <Col xs={12} md={6} className="mt-3">
            <UseFormTextField
              placeholder="City"
              control={control}
              name="city"
            />
          </Col>
          <Col xs={6} md={3} className="mt-3">
            <UseFormTextField
              placeholder="State"
              control={control}
              name="state"
            />
          </Col>
          <Col xs={6} md={3} className="mt-3">
            <UseFormTextField
              placeholder="Zip Code"
              control={control}
              name="postalCode"
            />
          </Col>
        </Row>
        <Row className="mt-3 d-flex align-items-center">
          <Col xs={4} sm={4} lg={3}>
            <label className="form-check-label">
              Time with Current Residence:
            </label>
          </Col>
          <Col xs={6} sm={4} lg={3}>
            <UseFormSelect
              list={YEARS_ARRAY}
              control={control}
              name="yearsAtResidence"
              placeholder="Years"
              placeholderValue={""}
            />
          </Col>
          <Col xs={6} sm={4} lg={3} className="mt-0 mt-sm-0">
            <UseFormSelect
              list={MONTHS_ARRAY}
              control={control}
              name="monthsAtResidence"
              placeholder="Months"
              placeholderValue={""}
            />
          </Col>
        </Row>

        <Row>
          {typeof isMailingAdressSame != "undefined" && (
            <Col xs={12} className="mt-3">
              <UseFormCheck
                control={control}
                controlName="isMailingAdressSame"
                content="My Mailing address is different than my current address."
              />
            </Col>
          )}
          {isMailingAdressSame && (
            <>
              <Col xs={12} className="mt-3">
                <h4 className="font-bold">Mailing Address</h4>
              </Col>
              <Col xs={12} className="mt-3">
                <GoogleAutoComplete
                  placeholder="Street"
                  control={control}
                  name="mailingAddressLine1"
                  onSelect={(address) => {
                    setValue("mailingAddressLine1", address.street);
                    setValue("mailingCity", address.city);
                    setValue("mailingState", address.state);
                    setValue("mailingZipCode", address.zipCode);
                  }}
                />
              </Col>
              <Col xs={12} md={6} className="mt-3">
                <UseFormTextField
                  placeholder="City"
                  control={control}
                  name="mailingCity"
                />
              </Col>
              <Col xs={6} md={3} className="mt-3">
                <UseFormTextField
                  placeholder="State"
                  control={control}
                  name="mailingState"
                />
              </Col>
              <Col xs={6} md={3} className="mt-3">
                <UseFormTextField
                  placeholder="Zip Code"
                  control={control}
                  name="mailingZipCode"
                />
              </Col>
            </>
          )}
        </Row>

        <Row className="mt-4">
          <Col xs={12}>
            <h4 className="font-bold">Gross Annual Income</h4>
          </Col>
          <Col xs={12}>
            <UserFormCurrencyField
              placeholder="Gross Annual Income"
              control={control}
              name="grossIncome"
              type="number"
              disabled
            />
          </Col>
          <Col xs={12} className="mt-3">
            <IncomePrompt isSungageProduct={isSungageProduct} />
          </Col>
        </Row>
        <Row className="mt-4 align-items-center">
          <Col xs={12}>
            <h4 className="font-bold">Employer Information (If Employed)</h4>
          </Col>
          <Col xs={12} md={6}>
            <UseFormSelect
              list={EMPLOYMENT_STATUS_LIST}
              control={control}
              name="employmentStatus"
              placeholder="Employment Status"
            />
          </Col>
        </Row>
        {isPrimaryEmployed && (
          <Row className="mt-3">
            <Col xs={12} md={6}>
              <UseFormTextField
                placeholder="Employer Name"
                control={control}
                name="employerName"
              />
            </Col>
            {isSungageProduct && (
              <Col xs={12} md={6} className="mt-3 mt-md-0">
                <UseFormTextField
                  placeholder="Title"
                  control={control}
                  name="title"
                />
              </Col>
            )}
          </Row>
        )}
        <Row className="mt-3 align-items-center flex-nowrap">
          <Col xs={4} md={3}>
            <label className="form-check-label me-3">
              {TIME_ON_JOB_TEXT[employmentStatus as EmploymentStatus] ||
                "Time with current employer"}
              :
            </label>
          </Col>
          <Col xs={4} md={3}>
            <UseFormSelect
              list={YEARS_ARRAY}
              control={control}
              name="yearsEmployed"
              placeholder="Years"
            />
          </Col>
          <Col xs={4} md={3}>
            <UseFormSelect
              list={MONTHS_ARRAY}
              control={control}
              name="monthsEmployed"
              placeholder="Months"
            />
          </Col>
        </Row>

        <div className="mt-5 d-flex justify-content-center mb-5">
          <Button variant="primary" type="submit">
            Submit Request
          </Button>
        </div>
      </Col>
    </form>
  );
};

export default ModifyApplicant;
