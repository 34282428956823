import React from 'react'
import Skeleton from 'react-loading-skeleton'

export const PipelineCardSkeleton = () => {
    return (
        <div className="border skeleton-p"  >
            <div className="d-flex justify-content-between">
                <div >
                    <Skeleton count={1} width={200} />
                </div>
                <h6 className=""><Skeleton count={1} width={100} /> </h6>
            </div>
            <div className="d-flex justify-content-between">
                <p className="text-muted"> <Skeleton count={1} width={150} /></p>
                <h6 className=" text-success"> <Skeleton count={1} width={80} /> </h6>
            </div>
            <div className="d-flex flex-row ">
                <p className="text-muted"><Skeleton count={1} width={80} /></p>
                <div className="separator border-light top-2"></div>
                <p className="text-muted"><Skeleton count={1} width={80} /></p>
            </div>
            <div className="d-flex justify-content-end">
                <Skeleton count={1} width={150} height={35} /> &nbsp;
                <Skeleton count={1} width={80} height={35} />&nbsp;
                <Skeleton count={1} width={80} height={35} />
            </div>
        </div>
    )
}
