import Select, { SingleValue } from "react-select";
import {
  filterRules,
  getActualValue,
  getRuleResult,
  getRuleResultStatusColor,
  getSortIndexForRule,
} from "@shared/helpers/evaluations.helper";
import {
  getProgramType,
  getThemeForReactSelect,
} from "@shared/helpers/global.helper";
import { IRule } from "@shared/interfaces/Application";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import {
  Clipboard2CheckFill,
  Clipboard2DataFill,
  Clipboard2MinusFill,
  ClipboardXFill,
  ReplyFill,
} from "react-bootstrap-icons";
import { DebounceInput } from "react-debounce-input";
import { isPrequalApplication } from "@shared/helpers/application.helper";
import { IPortalApplication } from "@shared/interfaces/PortalApplicationTypes";

const decisionRulTableColSpanClasses = {
  result: "col-2 border-0",
  ruleCriteria: "col-5",
  actualValue: "col-2",
  adverseAction: "col-3",
};

export enum DecisionRulesTab {
  AllRules = "AllRules",
  Refer = "Refer",
  Pass = "Pass",
  Fail = "Fail",
  NotRun = "NotRun",
}

export const RULE_DIDNT_RUN = `Rule Didn't Run`;

const DecisionRuleRow = ({ rule }: { rule: IRule }) => {
  return (
    <div className="row decision-rules-table-row" key={rule.resultId}>
      <div
        className={clsx(
          decisionRulTableColSpanClasses.result,
          "result",
          getRuleResultStatusColor(rule)
        )}
      >
        {getRuleResult(rule)}
      </div>
      <div className={clsx(decisionRulTableColSpanClasses.ruleCriteria)}>
        {rule.label}
      </div>
      <div
        className={clsx(
          decisionRulTableColSpanClasses.actualValue,
          "actualValue"
        )}
      >
        {getActualValue(rule)}
      </div>
      <div
        className={clsx(
          decisionRulTableColSpanClasses.adverseAction,
          "adverseAction",
          "text-danger"
        )}
      >
        {rule.denialReasonsAsString ?? ""}
      </div>
    </div>
  );
};

export const DecisionRules = ({
  data,
}: {
  data: IPortalApplication | undefined;
}) => {
  const [rules, setRules] = useState<IRule[]>([]);
  const [programTypeOptions, setProgramTypesOptions] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >([]);
  const [programtype, setProgramtype] = useState<
    SingleValue<{
      label: string;
      value: string;
    }>
  >();
  const [searchRule, setSearchRule] = useState("");

  useEffect(() => {
    // Check if application is Prequal
    if (isPrequalApplication(data)) {
      if (!programtype) {
        setRules([]);
        return;
      }
      const offerRules =
        data?.prequalifiedOffers
          ?.find((item) => item.xprogramtype === programtype.value)
          ?.results.map((result) => ({
            ...result,
            _sortIndex: getSortIndexForRule(result),
          })) || [];

      setRules(offerRules);
    } else {
      const resultsOfTypeLabelEvaluations =
        data?.decisionRules?.resultGroups.filter(
          (group) => group.typeLabel === "Evaluations"
        );
      const combnedRules: IRule[] = [];
      resultsOfTypeLabelEvaluations?.forEach((group) => {
        group?.results.length > 0 &&
          combnedRules.push(
            ...group.results.map((result) => ({
              ...result,
              _sortIndex: getSortIndexForRule(result),
            }))
          );
      });
      setRules(combnedRules);
    }
  }, [data, programtype]);

  useEffect(() => {
    // Setting product types for Prequal loan selection
    if (isPrequalApplication(data)) {
      const programTypeOptions = data?.prequalifiedOffers
        ? data?.prequalifiedOffers?.map((item) => ({
            label: getProgramType(item.xprogramtype) || "",
            value: item.xprogramtype,
          }))
        : [];
      setProgramTypesOptions(programTypeOptions);
    }
  }, [data]);

  const [activeTab, setActiveTab] = useState<DecisionRulesTab>(
    DecisionRulesTab.AllRules
  );
  const decisionRulesTabs = [
    {
      id: DecisionRulesTab.AllRules,
      title: (
        <p className="">
          <Clipboard2DataFill className="mb-1 me-1" /> All Rules
        </p>
      ),
    },
    {
      id: DecisionRulesTab.Refer,
      title: (
        <p>
          <ReplyFill className="mb-1 me-1" />
          Refer
        </p>
      ),
    },
    {
      id: DecisionRulesTab.Pass,
      title: (
        <p className="text-success">
          <Clipboard2CheckFill className="mb-1 me-1" />
          Pass
        </p>
      ),
    },
    {
      id: DecisionRulesTab.Fail,
      title: (
        <p className="text-danger">
          <ClipboardXFill className="mb-1 me-1" />
          Fail
        </p>
      ),
    },
    {
      id: DecisionRulesTab.NotRun,
      title: (
        <p className="text-warning">
          <Clipboard2MinusFill className="mb-1 me-1" />
          Not Run
        </p>
      ),
    },
  ];

  const filterdData = useMemo(
    () => filterRules(rules, activeTab, searchRule),
    [rules, activeTab, searchRule]
  );

  return (
    <div className="">
      {isPrequalApplication(data) && (
        <div className="mb-4">
          <Select
            id="programtype"
            options={programTypeOptions}
            placeholder="Select Loan Product"
            value={programtype}
            onChange={(newValue, actionMeta) => {
              setProgramtype(newValue);
            }}
            theme={getThemeForReactSelect}
            isClearable
            isSearchable={false}
          />
        </div>
      )}
      <div className="mb-2 d-flex justify-content-between align-items-center flex-wrap">
        <div className="tab-custom">
          <ul className="mb-0">
            {decisionRulesTabs.map((tab) => (
              <li
                key={tab.id}
                role="tab"
                className={clsx(
                  activeTab !== tab.id ? "" : "custom-active-tab"
                )}
                onClick={() => activeTab !== tab.id && setActiveTab(tab.id)}
              >
                {tab.title}
              </li>
            ))}
          </ul>
        </div>
        <DebounceInput
          className="form-control pipeline-search"
          placeholder="Search"
          value={searchRule}
          debounceTimeout={300}
          onChange={(event) => {
            setSearchRule(event.target.value);
          }}
        />
      </div>
      <div className="p-3 mt-6">
        <div>
          <div className="row evaluation-tab-table-header">
            <div className={clsx(decisionRulTableColSpanClasses.result)}>
              Result
            </div>
            <div className={clsx(decisionRulTableColSpanClasses.ruleCriteria)}>
              Rule Criteria
            </div>
            <div className={clsx(decisionRulTableColSpanClasses.actualValue)}>
              Actual Value
            </div>
            <div className={clsx(decisionRulTableColSpanClasses.adverseAction)}>
              Adverse Action
            </div>
          </div>

          {/* Rows */}
          {filterdData.map((rule) => (
            <DecisionRuleRow rule={rule} key={rule.resultId} />
          ))}

          {filterdData.length === 0 && (
            <p className="text-center m-5">No Decision Rules.</p>
          )}
        </div>
      </div>
    </div>
  );
};
